import React, { useState, useContext, useReducer, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MultipleDishesRow } from "@components";
// ui-kit
import {
    Form,
    Modal,
} from "@ui-kit"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// utils
import { serviceProcessing } from "@utils";

const fieldsMin = 6;
const fieldsMax = 30;
const fieldsAddCount = 3;

function reducer(form, action) {
    switch (action.type) {
        case "INIT_FORM":
            let initForm = {
                ...form,
                rules: action.rules
            };

            let formArray = [];
            for (let i = 0; i < action.count; i++ ) {
                formArray.push(action.fieldCreatorFunction(i, action.rules));
            }
            initForm.columns[0].fields = [...formArray];
            return initForm;
        case "ADD_NEW_FIELDS":
            let addForm = {...form};
            let fieldsLength = form.columns[0].fields.length;

            if (fieldsLength < fieldsMax) {
                let formArray = [];
                for (let i = 0; i < action.count; i++ ) {
                    formArray.push(action.fieldCreatorFunction(fieldsLength+i, form.rules));
                }
                addForm.columns[0].fields = [
                    ...form.columns[0].fields,
                    ...formArray
                ]

                if ((fieldsLength + action.count) >= fieldsMax) {
                    addForm.columns[1].fields[0].type = null;
                }
            }
            
            return addForm;
        default:
            return form;
    }
}

export default function DishesMultipleWrapper({children, onAdd, rules}) {
    const { id: submenu_id } = useParams();
    const { createMultipleDishes } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }

    const [ loading, setLoading ] = useState(true);

    const createDishField = (i, rules) => {
        return {
            type: "wrapper",
            Wrapper: (props) => <MultipleDishesRow {...props} i={i}/>,
            columns: [{
                fields: [
                    {
                        type: "text",
                        name: `dishes_list[${i}][title]`,
                        defaultValue: "",
                        label: t("fields.menu.title"),
                        wrapperClass: "col-md-5",
                    },
                    {
                        type: "mask",
                        mask: "9{*}[.9{0,2}]",
                        name: `dishes_list[${i}][price]`,
                        inputMode: "numeric",
                        defaultValue: "",
                        label: t("fields.menu.price"),
                        wrapperClass: "col-md-2",
                    },
                    {
                        type: "text",
                        name: `dishes_list[${i}][description]`,
                        defaultValue: "",
                        label: t("fields.menu.description"),
                        wrapperClass: "col-md-5",
                    },
                    {
                        type: "hidden",
                        name: `dishes_list[${i}][key]`,
                        defaultValue: i,
                    }
                ]
            }],
            rules
        }
    }
    
    function _transformData(data) {
        let dishes_list = {...data};
        dishes_list.dishes_list.forEach((dish, index) => {
            let empty = true;
            for (const [key, value] of Object.entries(dish)) {
                if (!value) {
                    dishes_list.dishes_list[index][key] = null;
                } else {
                    if (key !== "key")
                    empty = false;
                }
            }
            if (empty) {
                dishes_list.dishes_list[index] = null;
            }
        });
        dishes_list.dishes_list = dishes_list.dishes_list.filter(el => el !== null);

        return dishes_list;
    }
    
    function callbackCreateMultiple(data, reset, resolve, reject) {
        const dishes_list = _transformData(data);
        if (dishes_list.dishes_list.length) {
            setLoading(true);
        } else {
            return notify({title: t("warning"), text: t("menu.notification.multiple_dishes_empty"), type: "warning"})
        }
        
        createMultipleDishes(dishes_list, submenu_id).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    resolve(res);
                    onAdd(data.dishes_list);
                    closeModal();
                },
                (errors) => reject({messages: errors})
            )
        }).catch(reject)
        .finally(() => setLoading(false));
    }
    
    const initialForm = {
        type: "multiple_dishes",
        submit: t("buttons.create"),
        callback: callbackCreateMultiple,
        columns: [{
            size: 12,
            fields: []
        }, {
            size: 12,
            fields: [{
                type: "button-text",
                label: t("fields.menu.add_multitple", {count: fieldsAddCount}),
                onClick: () => dispatch({type: "ADD_NEW_FIELDS", count: fieldsAddCount, fieldCreatorFunction: createDishField})
            }]
        }]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);

    useEffect(() => {
        let isMounted = true;
        if (isMounted && isOpen && rules) {
            setLoading(false)
            dispatch({type: "INIT_FORM", count: fieldsMin, fieldCreatorFunction: createDishField, rules})
        }
        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [isOpen, rules])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("menu.form.create_multiple")}
                description={t("menu.form.description_multiple")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
        </>
    )
}