import React, { useEffect } from "react";
import classNames from "classnames";
import { Button } from "@ui-kit"
import { getByPath } from "@utils";

// style
import s from "./Fields.module.sass";

export default function ButtonsField({options, description, refRegister, rules, errors, wrapperClass = "col-md-12", name, defaultValue, setValue, onChange, watch, ...props}) {
    useEffect(() => {
        setValue(name, defaultValue);
    }, [defaultValue])

    return (
        <div className={wrapperClass}>
            <input 
                style={{display: "none"}}
                name={name}
                defaultValue={defaultValue}
                ref={refRegister({...rules})}
                {...props}
            />
            <div className="mb-3">
                {
                    options.map((value, index) => {
                        return <Button type="tag" onClick={() => {
                            onChange(value);
                            setValue(name, value);
                        }} key={index}> {value} </Button>
                    })
                }
            </div>
            { getByPath(errors, name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${name}.message`)}</p> }
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
}