import React, { useContext, useEffect, useState } from "react"
import useWebSocket from 'react-use-websocket';
import { notificationsContext } from "@context"
import { useTranslation } from "react-i18next";

const token = localStorage.getItem("token") || sessionStorage.getItem("token");
function createSocketUrl() {
    return `wss://console.cheflist.org/ws/orders/${token}/`
}

const _attempts = 5;

export default function WithInnerOrderSocket({children, order_type, archive, setLoading = () => {}}) {
    const { t } = useTranslation();
    const { notify } = useContext(notificationsContext);
    const [ attempts, setAttempts ] = useState(0);
    if (typeof archive === "boolean") {
        archive = archive - 0
    }
    const [ socketURL, setSocketUrl ] = useState(createSocketUrl(order_type));
    
    const {
        lastJsonMessage
    } = useWebSocket(socketURL, {
        onOpen: () => {
            setLoading(false)
            setAttempts(0);
        },
        onClose: () => {
            setLoading(true)
        },
        onError: () => {
            setLoading(true)
        },
        shouldReconnect: (closeEvent) => {
            if (_attempts >= attempts + 1) {
                setAttempts(attempts + 1);
                notify({
                    type: "error",
                    title: t("sockets.reconnection_error_title", {
                        attempt: attempts + 1, 
                        attempts: _attempts
                    }),
                    interval: 2000
                })
            }

            return true
        },
        onReconnectStop: (numAttempted) => {
            notify({
                type: "error",
                title: t("sockets.reconnection_failed_title"),
                text: t("sockets.reconnection_failed_description"),
                interval: null
            });
            setLoading(false)
        },
        reconnectAttempts: _attempts,
        reconnectInterval: 2000,
        share: true
    }, !archive)
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (order_type !== undefined && archive !== undefined) {
                setSocketUrl(createSocketUrl(order_type, archive))
            }
        }

        return () => {
            isMounted = false;
        }
    }, [order_type, archive])
    
    return React.cloneElement(children, {
        lastJsonMessage
    })
}