import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
// components
import {
    Layout,
    Breadcrumbs,
    ManagerNews,
    LoadingSpinner
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import { 
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// routes
import ROUTES from "@routes";

const NewsPage = () => {
    const { t } = useTranslation();
    const { getAllNews } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);

    const [data, setData] = useState({});

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getAllNews().then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    setData(data);
                })
            }).catch(error => errorProcessing(error, notify, "page_data"))
            .finally(() => setLoading(false))
        }
        return () => {
            isMounted = false;
            setLoading(true)
        }
    }, [])
    
    const breadcrumbs = [
        {
            title: t("news.title"),
            link: ROUTES.news
        }
    ]
    
    return (
        <>
            {loading ? <LoadingSpinner/> : null}
            <Breadcrumbs list={breadcrumbs} />
            <section>
                <div className="container-fluid">
                    <Title className="mb-md-5 mb-4" type="page">{t("news.title")}</Title>
                    <ManagerNews {...data} />
                </div>
            </section>
        </>
    )
}

export default NewsPage;