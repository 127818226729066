import React, { useRef, useContext } from "react";
import classNames from "classnames";
import { motion } from "framer-motion"
// components
import { 
    ContextMenu,
    SimpleAppearAnimation,
    LoadingSpinner
} from "@components";
// ui
import { Title } from "@ui-kit";
// utils
import {
    makeSelectorFromClassList,
    setThousandthDivision
} from "@utils";
// context
import { globalVariablesContext } from "@context";
// style
import s from "./ManageBlock.module.sass";
// svg
import { ReactComponent as Check } from "@svg/checkbox.svg"
import { ReactComponent as CheckActive } from "@svg/checkbox_active.svg"
import { ReactComponent as PublishEye } from "@svg/hidden.svg"

const ManageDish = ({forwardedRef, ...props}) => {
    const {
        title, 
        description, 
        price, 
        image, 
        checked, 
        temporary_checked, 
        is_published, 
        onClick, 
        onCheck = () => {}, // clear onClik function for parent click
        animationCount,
        loading = false,
        contextmenu,
        isHoverable
    } = props;
    const { variables: { currency } } = useContext(globalVariablesContext);

    const checkRef = useRef();
    return (
        <div onClick={(e) => {
                if (!e.target.closest(makeSelectorFromClassList(checkRef.current.className))) // check click for checkIcon or block
                    onClick(e);
            }} 
            className={ classNames(s.clickable) }
        >
            {/* ref is lost when rendering a motion component, so a parent div is created */}
            <SimpleAppearAnimation index={animationCount}>
                <motion.div className={classNames(s.short, s.item, s.dishItem)}>
                    <div className={s.menuWrapper}>
                        <ContextMenu list={contextmenu} isHoverable={isHoverable}/>
                    </div>
                    <div ref={forwardedRef}>
                        <div className={classNames(s.checker, {
                            [s.checked]: checked || temporary_checked
                        })} onClick={onCheck} ref={checkRef}>
                            {(checked || temporary_checked) ? <CheckActive className={s.checkIcon}/> : <Check className={s.checkIcon}/>}
                        </div>
                        <div className={classNames(s.body, {
                            [s.dishHasImage]: image,
                            [s.publishHide]: !is_published
                        })}>
                            <div className={s.dishContent}>
                                <Title className={classNames(s.smallTitle, s.dishTitle)}>{title}</Title>
                                <p className={classNames(s.smallDescription, s.dishDescription)}>{description}</p>
                                <p className={s.dishPrice}>{setThousandthDivision(price)} {currency}</p>
                            </div>
                            {
                                image ? <div className={s.dishImage}>
                                    <img alt={title} title={title} src={image} className={s.fitImg}/>
                                </div> : null
                            }
                            {
                                !is_published ? (
                                    image ? 
                                    <div className={s.publishStatusImg}><PublishEye className={s.fillWhite}/></div> :
                                    <div className={s.publishStatus}><PublishEye className={s.fillGray}/></div>
                                ) : null
                            }
                        </div>
                        { loading ? <LoadingSpinner position="absolute" className="item" /> : null }
                    </div>
                </motion.div>
            </SimpleAppearAnimation>
        </div>
    )
}

export default ManageDish;