import React, { useEffect, useRef, useState, useContext } from "react"
import classNames from "classnames"
import { motion, useMotionValue } from "framer-motion"
import { useTranslation } from "react-i18next"
import {
    LoadingSpinner
} from "@components"
import {
    Field,
    NoUiSlider,
    Button
} from "@ui-kit"
// style
import s from "./VideoEditor.module.sass"
// icons
import { ReactComponent as Play } from "@svg/play-video.svg"
import { ReactComponent as Pause } from "@svg/pause-video.svg"
import { ReactComponent as CutStart } from "@svg/video-cut-start.svg"
import { ReactComponent as CutEnd } from "@svg/video-cut-end.svg"
import { ReactComponent as Delete } from "@svg/delete.svg"
// context
import { 
    globalVariablesContext,
    notificationsContext
} from "@context"
// utils
import { 
    formatTime,
    useCombinedRefs
} from "@utils"

export default function VideoEditor({
    src, 
    onError = () => { alert("video sizes incorrect") }, 
    hasVideo,
    t_start,
    t_end,
    onRemove,
    refs,
    ...form
}) {
    const { t } = useTranslation();
    const { variables: { customization: { second } } } = useContext(globalVariablesContext);
    const { notify } = useContext(notificationsContext);
    const [ max, setMax ] = useState(1);
    const [ range, setRange ] = useState([0,1]);
    const [ wrapperWidth, setWrapperWidth ] = useState(1);
    const [ play, setPlay ] = useState({
        status: false,
        initialized: false
    });
    const [ ignore, setIgnore ] = useState(false); // ignoring any controller
    const [ loading, setLoading ] = useState(true);
    
    const x = useMotionValue(0);

    const videoRef = useCombinedRefs(useRef, useEffect, refs)
    const inputsRef = useRef([])
    const wrapperRef = useRef()
    const sliderRef = useRef();
    const timeRef = useRef();
    
    function setTime(time) {
        timeRef.current.innerText = formatTime(time)
    }

    const sliderStart = form.watch("t_start") - 0;
    const sliderEnd = form.watch("t_end") - 0;

    // set inputs time when slider's range udated
    useEffect(() => {
        let isMouted = true;
        if (isMouted) {
            let video = videoRef.current
            let startTime = range[0]-0;
            let endTime = range[1]-0;

            if (play) {
                setPlay({
                    ...play,
                    status: false
                });
            }
            
            if (!ignore) {
                if (form.getValues("t_start") - 0 !== startTime) {
                    form.setValue("t_start", startTime);
                    video.currentTime = startTime;
                    
                }
                if (form.getValues("t_end") - 0 !== endTime) {
                    form.setValue("t_end", endTime);
                    video.currentTime = endTime;
                }
            }
        }
        return () => { isMouted = false }
    }, [range, ignore])
    // callback when sliding end
    function onEnd() {
        let posX = x.get(); // time indicator's position
        let width = wrapperRef.current.offsetWidth; // slider's width
        // max video's duration
        videoRef.current.currentTime = posX/width*max; // indicator pos / full width * video duration to get current time
    }
    
    // initialize component
    useEffect(() => {
        let isMounted = true;
        if (isMounted && videoRef.current) {
            let video = videoRef.current;
            let sliderWrapper = wrapperRef.current;
            video.addEventListener("loadedmetadata", function() {
                // check for valide video sizes
                if (this.videoWidth < 1280 && this.videoHeight < 1280) {
                    onError();
                    if (typeof notify === "function") {
                        notify({title: t("warning"), text: t("manage.video.notify.video_sizes", {size: 1280}), type: "warning"})
                    } else {
                        alert(t("manage.video.notify.video_sizes", {size: 1280}));
                    }
                } else {
                    setMax(parseFloat(this.duration.toFixed(2))); // video's duration
                    setWrapperWidth(sliderWrapper.offsetWidth); // slider's width for calc time from time-indicator position
                }
                setLoading(false)
            });
        }
        return () => isMounted = false;
    }, [])
    
    // set video current time when start time changes
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let video = videoRef.current;
            video.currentTime = t_start;
        }
        return () => isMounted = false;
    }, [t_start])
    // set time indicator when input's value changes
    useEffect(() => {
        let isMounted = true;
        if (isMounted && videoRef.current) {
            if (!ignore) {
                let posX = x.get();
                let startPos = sliderStart/max*wrapperWidth
                let endPos = sliderEnd/max*wrapperWidth

                if ( posX < startPos) {
                    x.set(startPos);
                    setTime(sliderStart);
                }
                if ( posX > endPos) {
                    x.set(endPos);
                    setTime(sliderEnd);
                }
            }
        }
        return () => isMounted = false;
    }, [sliderStart, sliderEnd, x, max, wrapperWidth, ignore])
    // on time indicator dragging
    function onDrag(event, info) {
        let posX = x.get();
        let video = videoRef.current;
        let currentTime = posX/wrapperWidth*max

        if (currentTime < sliderStart) {
            let minPos = sliderStart/max*wrapperWidth
            x.set(minPos);
            setTime(sliderStart)
        } else if (currentTime > sliderEnd) {
            let maxPos = sliderEnd/max*wrapperWidth
            x.set(maxPos);
            setTime(sliderEnd)
        }

        video.currentTime = currentTime;
        setTime(currentTime.toFixed(2));
    }
    // cut video for t_start or t_end from current time
    function cutVideo(direction) {
        let video = videoRef.current;
        let value = video.currentTime.toFixed(2) - 0;
        if (direction === "left") {
            form.setValue("t_start", value);
            sliderRef.current.set([value, sliderEnd])
        }
        if (direction === "right") {
            form.setValue("t_end", video.currentTime.toFixed(2) - 0)
            sliderRef.current.set([sliderStart, value])
        }
    }
    // update indicator on video playing
    function onPlaying(e) {
        let video = e.target;
        let time = video.currentTime.toFixed(2) - 0;
        let posX = time/max*wrapperWidth
        
        setTime(time);
        x.set(posX);
        if (time > sliderEnd) {
            video.currentTime = sliderStart; // start video from t_start if current time pass a t_end
        }
    }
    
    useEffect(() => {
        let isMounted = true;
        const video = videoRef.current;

        if (isMounted && play.initialized) {
            if (play.status) {
                video.play().then(() => {
                    console.log("editor");
                    video.addEventListener("timeupdate", onPlaying);
                })
            } else {
                video.pause();
                video.removeEventListener("timeupdate", onPlaying);
            }
        }
        return () => {
            isMounted = false;
            video.removeEventListener("timeupdate", onPlaying);
        }
    }, [play])
    
    const onClick = (e) => {
        e.stopPropagation();
        const bounds = e.currentTarget.getBoundingClientRect();
        const posX = e.clientX - bounds.left;
        const width = bounds.width;
        const video = videoRef.current;

        let calcTime = (posX/width*max).toFixed(2) - 0;

        if (!(calcTime > sliderEnd || calcTime < sliderStart)) {
            video.currentTime = calcTime;
    
            x.set(posX);
            setTime(calcTime);
        }
    } 
    
    return (<>
        {
            loading ? <LoadingSpinner position="absolute"/> : null
        }
        <div className="col-md-12">
            <div className={s.videoWrapper}>
                <video loop ref={videoRef} className={s.video} src={src} disablePictureInPicture ></video>
            </div>
        </div>
        <div className={classNames(s.control, "col-12")}>
            <div className="row justify-content-center">
                <div className="col-2 col-md-1 mr-auto"></div>
                <div className="col-2 col-md-1 pr-0">
                    <Button 
                        type="icon" 
                        className="video mb-3" 
                        onClick={() => cutVideo("left")}
                        style={{color: `#${second}`}}
                        title={"Cut Left"}
                    >
                        <CutStart/>
                    </Button>
                </div>
                <Field 
                    name="t_start" 
                    defaultValue={t_start} 
                    {...form} 
                    refs={ref=>inputsRef.current[0]=ref} 
                    wrapperClass="col-0"
                    label={t("manage.video.form.start")}
                    onChange={e => {
                        if (!ignore) { setIgnore(true); }
                        if (sliderStart > sliderEnd) {
                            sliderRef.current.set([sliderEnd, sliderEnd])
                        } else {
                            sliderRef.current.set([sliderStart, sliderEnd])
                        }
                    }}
                    onBlur={e => {
                        setIgnore(false);
                        if (sliderStart > sliderEnd) {
                            sliderRef.current.set([sliderStart, sliderStart]);
                            form.setValue("t_end", sliderStart);
                        }
                    }}
                    type="hidden"
                    min={0}
                    max={max}
                />
                <div className="col-2 col-md-1">
                    <div className="d-flex flex-column align-items-center">
                        <Button 
                            type="icon" 
                            className="video mb-3 icon-md"
                            onClick={() => {
                                setPlay({
                                    status: !play.status,
                                    initialized: true
                                });
                            }}
                            style={{color: `#${second}`}}
                            title={!play.status ? "play" : "pause"}
                        >
                            { !play.status ? <Play/> : <Pause/> }
                        </Button>
                    </div>
                </div>
                <Field 
                    name="t_end" 
                    defaultValue={t_end} 
                    {...form} 
                    refs={ref=>inputsRef.current[1]=ref} 
                    wrapperClass="col-0"
                    label={t("manage.video.form.end")}
                    onChange={e => {
                        if (!ignore) { setIgnore(true); }
                        if (sliderStart > sliderEnd) {
                            sliderRef.current.set([sliderStart, sliderStart])
                        } else {
                            sliderRef.current.set([sliderStart, sliderEnd])}
                        }
                    }
                    onBlur={e => {
                        setIgnore(false);
                        if (sliderStart > sliderEnd) {
                            sliderRef.current.set([sliderStart, sliderStart])
                            form.setValue("t_end", sliderStart);
                        }
                    }}
                    type="hidden"
                    min={0}
                    max={max}
                />
                <div className="col-2 col-md-1 pl-0">
                    <Button 
                        type="icon" 
                        className="video mb-3" 
                        onClick={() => cutVideo("right")}
                        style={{color: `#${second}`}}
                        title={"Cut Right"}
                    >
                        <CutEnd/>
                    </Button>
                </div>
                <div className="col-2 col-md-1 ml-auto">
                    <Button
                        type="icon"
                        onClick={onRemove}
                        style={{color: `#${second}`}}
                        title={"clear video"}
                        className="video icon-md" 
                    >
                        <Delete/>
                    </Button>
                </div>
            </div>
        </div>
        <div className={classNames("col-md-12 text-right", s.timeWrapper)}>
            <span className={s.time} ref={timeRef}>00:00:00</span>
            <span> / </span>
            <span className={s.max}>{formatTime(Math.floor(max))}</span>
        </div>
        <div className="col-md-12">
            <div className={classNames(s.sliderWrapper, "my-4")} ref={wrapperRef} >
                <div className={s.sliderOverlay} onClick={onClick}></div>
                <motion.div 
                    drag="x" 
                    dragElastic={0}
                    dragMomentum={false}
                    dragConstraints={{left: sliderStart/max*wrapperWidth, right: sliderEnd/max*wrapperWidth }}
                    dragDirectionLock
                    style={{
                        x,
                        "--second-color": `#${second}`
                    }}
                    onDrag={onDrag}
                    className={s.timePos} 
                ></motion.div>
                <NoUiSlider 
                    max={max} 
                    refs={sliderRef}
                    onUpdate={setRange}
                    onEnd={onEnd}
                    width={wrapperWidth}
                    tooltipFormat={(val) => formatTime(val, false)}
                    defaultValue={[t_start || 0, t_end || max]}
                />
            </div>
        </div>
    </>)
}