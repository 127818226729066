import { sliceStringPattern } from ".";

export default function updateRules(newForm, rules) {
    if (rules) {
        for (const [key, value] of Object.entries(rules)) {
            newForm.columns && newForm.columns.forEach(column => {
                column.fields.filter(field => {
                    if (field !== null) {
                        if (field.type === "wrapper") {
                            return updateRules(field, rules)
                        } else {
                            // check key for integrity
                            /*
                            * rule = delivery[free_delivery_price]
                            * if key = delivery_price return false
                            * if key = free_delivery_price return true
                            */
                            let regIntegrity = `(?![_]).${key}.(?![_])`;
                            // check for exact match
                            // return true if regex is exact for rule key
                            let regIsExact = new RegExp(`\\b${key}\\b`);
                            if (field.name && (field.name.match(regIntegrity) || field.name.match(regIsExact))) {
                                let { validate, rules: rrules } = value;
                                if (rrules) {
                                    const { pattern, ...rules } = rrules
                                    field.rules = {...rules};
                                    if (pattern) {
                                        field.rules.pattern = {
                                            value: sliceStringPattern(pattern.value),
                                            message: pattern.message
                                        }
                                    }
                                    if ( validate ) {
                                        let obj = {};
                                        
                                        validate.forEach(({pattern, message}, index) => {
                                            obj[index] = (value) => !value || value.match(sliceStringPattern(pattern)) || message;
                                        })
                                        
                                        field.rules.validate = {...obj};
                                    }
                                }
                            }
                            return field;
                        }
                    } else {
                        return field
                    }
                })
            })
        }
    }

    return {...newForm};
}
