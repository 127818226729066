import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select';
import {
    SortableContainer,
    SortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import classNames from "classnames";
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
// styles
import s from "./Fields.module.sass";
import "./react-select.css";
// ui
import {
    getByPath,
    arrayMove
} from "@utils"
const animatedComponents = makeAnimated();

const SortableMultiValue = SortableElement(props => {
    const onMouseDown = e => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle(props => (
    <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

const ControlIcon = ({children, ...props}) => {
    let icon = null;
    if (!props.isMulti && props.getValue()[0]) {
        icon = props.getValue()[0].icon;
    }
    return (
        <components.Control {...props}>
            {icon ? <span className={s.selectIcon} style={{backgroundImage: `url(${icon})`}}></span> : null}
            {children}
        </components.Control>
    )
}

const OptionIcon = ({children, ...props}) => {
    let { icon } = props.data;
    return (
        <components.Option {...props}>
            {icon ? <span className={s.selectIcon} style={{backgroundImage: `url(${icon})`}}></span> : null}
            {children}
        </components.Option>
    )
}

export default function SelectField({label, description, control, errors, rules, options, noOptionsMessage, onChangeHandler, name, defaultValue, wrapperClass = "col-md-12", refs, labelRef, setValue, getValues, className = "", readOnly = false, isCreatable = false, ...props}) {
    const { t } = useTranslation();
    const [ selectRules, setRules ] = useState(rules);

    useEffect(() => {
        if (rules && rules.required && props.isMulti) {
            setRules({
                ...rules,
                validate: value => value.length || rules.required.message
            })
        } else {
            setRules({...rules})
        }
    }, [rules])
    
    useEffect(() => {
        if (defaultValue) {
            if (typeof defaultValue === "string") {
                setValue(name, {
                    label: defaultValue,
                    value: defaultValue
                });
            } else {
                setValue(name, defaultValue);
            }
        }
    }, [defaultValue])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(getValues(name), oldIndex, newIndex);
        setValue(name, newValue, { shouldValidate: true });
    };
    
    return (
        <div className={wrapperClass}>
            <div className={s.group}>
                <Controller
                    render={
                        ({onChange, value}) => (
                            isCreatable ? 
                            <CreatableSelect
                                className={classNames("react-select", className)}
                                classNamePrefix="react-select"
                                closeMenuOnSelect={true}
                                noOptionsMessage={noOptionsMessage || (() => t("fields.general.select_empty"))}
                                placeholder=" "
                                options={options}
                                onChange={(selected) => {
                                    if (typeof onChangeHandler === "function") onChangeHandler(selected);
                                    // setValue(name, selected, { shouldValidate: true });
                                    onChange(selected);
                                }}
                                components={{
                                    animatedComponents,
                                    Control: ControlIcon,
                                    Option: OptionIcon
                                }}
                                {...props}
                                ref={refs}
                                value={value}
                                isDisabled={readOnly}
                                formatCreateLabel={val => val}
                                innerProps={{
                                    autoComplete: false
                                }}
                            /> : 
                            <SortableSelect
                                useDragHandle
                                // react-sortable-hoc props:
                                axis="xy"
                                onSortEnd={onSortEnd}
                                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                                // react-select props:
                                className={classNames("react-select", className)}
                                classNamePrefix="react-select"
                                closeMenuOnSelect={true}
                                noOptionsMessage={noOptionsMessage || (() => t("fields.general.select_empty"))}
                                placeholder=" "
                                options={options}
                                onChange={(selected) => {
                                    if (typeof onChangeHandler === "function") onChangeHandler(selected);
                                    setValue(name, selected, { shouldValidate: true });
                                    onChange(selected);
                                }}
                                components={{
                                    animatedComponents,
                                    MultiValue: SortableMultiValue,
                                    MultiValueLabel: SortableMultiValueLabel,
                                    Control: ControlIcon,
                                    Option: OptionIcon
                                }}
                                {...props}
                                ref={refs}
                                value={value}
                                isDisabled={readOnly}
                            />
                        )
                    }
                    control={control}
                    rules={selectRules}
                    name={name}
                    defaultValue={defaultValue}
                />
                <label className={classNames(s.label, s.labelSelect)} ref={labelRef}>
                    {label}
                </label>
                { getByPath(errors,name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${name}.message`)}</p> }
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
}