import React, { useState, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next"
import {
    Form,
    Modal,
} from "@ui-kit"
// utils
import {
    updateRules,
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";

function reducer(form, action) {
    const { phones, rules } = action.payload;
    switch (action.type) {
        case "INIT_CREATE":
            let formCreate = {...form};
            return updateRules(formCreate, rules.phones);
        case "INIT_UPDATE":
            let formUpdate = {
                ...form,
                callback: action.callbackUpdate,
                submit: action.t("buttons.save"),
            };
            formUpdate.columns[0].fields[0].defaultValue = phones.main;
            formUpdate.columns[0].fields[1].defaultValue = phones.delivery;
            formUpdate.columns[1].fields[0].defaultValue = phones.administration;
            formUpdate.columns[1].fields[1].defaultValue = phones.preorder;
            return updateRules(formUpdate, rules.phones);
        default:
            return {...form}
    }
}

export default function ManageContactsWrapper({children, id, updateShort}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    // close modal
    function closeModal() {
        setOpen(false);
    }
    // methods
    const {
        getMyContacts,
        createContacts,
        updateContacts,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { variables: { phoneMask } } = useContext(globalVariablesContext);
    const [ loading, setLoading ] = useState(true);
    // form
    const initialForm = {
        type: "restaurant_contacts",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 6,
                fields: [
                    {
                        type: "mask",
                        inputMode: "tel",
                        initialType: "tel",
                        mask: phoneMask,
                        defaultValue: "",
                        name: "phones[main]",
                        description: "",
                        label: t("fields.contacts.main"),
                        autoFocus: "autofocus",
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        },
                    },
                    {
                        type: "mask",
                        inputMode: "tel",
                        initialType: "tel",
                        mask: phoneMask,
                        defaultValue: "",
                        name: "phones[delivery]",
                        description: "",
                        label: t("fields.contacts.delivery")
                    }
                ]
            },
            {
                size: 6,
                fields: [
                    {
                        type: "mask",
                        inputMode: "tel",
                        initialType: "tel",
                        mask: phoneMask,
                        defaultValue: "",
                        name: "phones[administration]",
                        description: "",
                        label: t("fields.contacts.administration"),
                    },
                    {
                        type: "mask",
                        inputMode: "tel",
                        initialType: "tel",
                        mask: phoneMask,
                        defaultValue: "",
                        name: "phones[preorder]",
                        description: "",
                        label: t("fields.contacts.preorder")
                    }
                ]

            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);

    function _transformData(data) {
        const { phones } = data;
        let newPhones = {};
        for ( const [ key, value ] of Object.entries(phones)) {
            newPhones[key] = value.replace(/[\D]/g,"")
        }

        return {
            phones: {
                ...newPhones
            }
        }
    }
    // form submit callbacks
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createContacts(_transformData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, (errors) => reject({messages: errors}))
        })
        .catch(reject)
        .finally(() => setLoading(false))
    }
    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateContacts(_transformData(data), id).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, (errors) => reject({messages: errors}))
        })
        .catch(reject)
        .finally(() => setLoading(false));
    }
    
    useEffect(() => {        
        let isMounted = true;

        if (isMounted) {
            if (isOpen) {
                getMyContacts().then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (id) {
                            dispatch({type: "INIT_UPDATE", callbackUpdate, t, payload: data})
                        } else {
                            dispatch({type: "INIT_CREATE", payload: data})
                        }
                    })
                })
                .catch(error => errorProcessing(error, notify, "detail_data"))
                .finally(() => setLoading(false));
            }
        }
        
        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [id, isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true),
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_contacts")}
                description={t("manage.form.description_contacts")}
                loading={loading}
            >
                <Form form={form} />
            </Modal>
        </>
    )
}