import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next";

const lng = localStorage.getItem("i18nextLng") || 'ru'

i18n
    .use(Backend)
    .use(LanguageDetector) // also create i18nextLng in localStorage
    .use(initReactI18next)
    .init({
        lng: lng,
        fallbackLng: 'en',
        debug: false,
        backend: {
            // manager is homepage wich changing in package.json
            loadPath: `/manager/locales/{{lng}}/translation.json?v=${new Date().getTime()}` // The URL to download the locales
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false
        }

    });

export default i18n;