import React from "react";
import { useTranslation } from "react-i18next";
import { Title }from "@ui-kit"
import classNames from "classnames"
import s from "./Sorting.module.sass"

const Sorting = ({list, onSort, sortingOptions}) => {
    const { t } = useTranslation();
    return <div className={s.sorting}>
        <div className="row align-items-center mb-4 pb-2">
            <div className="col-md-auto "><Title className="mb-0">{t("orders.sorting_options.title")}</Title></div>
            <div className="col-md">
                <ul className={s.list}>
                    {
                        list.map(({value, label, Icon}) => {
                            return <li 
                                className={classNames(s.item, {
                                    [s.active]: sortingOptions[value]
                                })} 
                                key={value} 
                                onClick={()=>onSort(value)}>
                                    <Icon/>{label}
                                </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </div>
}

export default Sorting