import React, { useState, useContext, useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next"
import {
    Form,
    Modal,
} from "@ui-kit"
// utils
import { 
    errorProcessing,
    serviceProcessing,
    setAsyncTimeout
} from "@utils"
// components
import {
    ScheduleRow
} from "@components"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

const openTimeInMiliseconds = (10*3600); //hours to seconds
const closeTimeInMiliseconds = (22*3600);

function reducer(form, action) {
    switch (action.type) {
        case "INIT_UPDATE":
            let newForm = {
                ...form,
                submit: action.t("buttons.save"),
                callback: action.callbackUpdate
            };
            newForm.columns[0].fields = form.columns[0].fields.map((el, index) => {
                let { day, status, time_open, time_close } = action.payload[index];
                return {
                    ...el,
                    day: {
                        ...el.day,
                        defaultValue: day
                    },
                    select: {
                            ...el.select,
                            defaultValue: {
                                value: status,
                                label: action.t("manage.schedule.status", { returnObjects: true })[status]
                            }
                    },
                    open: {
                        ...el.open,
                        defaultValue: time_open || time_open === 0 ? time_open : openTimeInMiliseconds
                    },
                    close: {
                        ...el.close,
                        defaultValue: time_close || time_close === 0 ? time_close : closeTimeInMiliseconds
                    }
                }
            });
            return newForm
        case "COPY_SCHEDULE":
            let copyForm = {...form};
            let { status, time_open, time_close } = action.payload;
            copyForm.columns[0].fields = form.columns[0].fields.map((el) => {
                return {
                    ...el,
                    select: {
                            ...el.select,
                            defaultValue: {
                                value: status,
                                label: action.t("manage.schedule.status", { returnObjects: true })[status]
                            }
                    },
                    open: {
                        ...el.open,
                        defaultValue: time_open
                    },
                    close: {
                        ...el.close,
                        defaultValue: time_close
                    }
                }
            });
            
            return copyForm
        default:
            return form
    }
}

export default function ManageScheduleWrapper({children, updateShort, id}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    
    function closeModal() {
        setOpen(false);
    }
    // methods
    const {
        getMySchedule,
        createSchedule,
        updateSchedule,
    } = useContext(managerMethodsContext);
    const { 
        notify,
        notifyList
    } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);

    const formRef = useRef();
    
    function copyRow(day) {
        let status = formRef.current.getFormValues(`days[${day}][status]`).value;
        let time_open = formRef.current.getFormValues(`days[${day}][time_open]`);
        let time_close = formRef.current.getFormValues(`days[${day}][time_close]`);
        
        time_open = time_open ? time_open.valueOf()/1000 : null;
        time_close = time_close ? time_close.valueOf()/1000 : null;
        
        dispatch({type: "COPY_SCHEDULE", payload: {status,time_open,time_close}, t});
    }
    
    function commonField(day, status) {
        return {
            type: "wrapper",
            Wrapper: (props) => <ScheduleRow {...props} onCopy={(setTimes) => copyRow(day, setTimes)}/>,
            day: {
                defaultValue: day,
                type: "hidden",
                name: `days[${day}][day]`,
            },
            select: {
                wrapperClass: "col-md-5",
                defaultValue: {
                    value: status,
                    label: t("manage.schedule.status", { returnObjects: true })[status]
                },
                type: "select",
                name: `days[${day}][status]`,
                label: t("fields.schedule.status"),
                options: [
                    {
                        value: 0,
                        label: t("manage.schedule.status", { returnObjects: true })[0]
                    },
                    {
                        value: 1,
                        label: t("manage.schedule.status", { returnObjects: true })[1]
                    },
                    {
                        value: 2,
                        label: t("manage.schedule.status", { returnObjects: true })[2]
                    }
                ]
            },
            open: {
                wrapperClass: "col-6 col-md",
                defaultValue: openTimeInMiliseconds,
                type: "time",
                name: `days[${day}][time_open]`,
                label: t("fields.schedule.opening"),
            },
            close: {
                wrapperClass: "col-6 col-md",
                defaultValue: closeTimeInMiliseconds,
                type: "time",
                name: `days[${day}][time_close]`,
                label: t("fields.schedule.closing"),
            }
        }
    }
    
    const initialForm = {
        type: "restaurant_schedule",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 12,
                fields: [
                    commonField(0,0),
                    commonField(1,0),
                    commonField(2,0),
                    commonField(3,0),
                    commonField(4,0),
                    commonField(5,2),
                    commonField(6,2),
                ]
            },
        ]
    }
    
    function _transformFormData(data) {
        return {
            days: [
                ...data.days.map(({day, status, time_open, time_close}) => {
                    return {
                        day,
                        status: parseInt(status.value),
                        time_open: time_open ? time_open.valueOf()/1000 : null,
                        time_close: time_close ? time_close.valueOf()/1000 : null,
                    }
                })
            ]
        }
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createSchedule(_transformFormData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false))
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateSchedule(_transformFormData(data), id).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    
    // form state
    const [ form, dispatch ] = useReducer(reducer, initialForm);

    useEffect(() => {
        let isMounted = true;
        let transitionPromise = async (cb) => await setAsyncTimeout(cb, 500);
        let succesFunc = () => {};
        
        if (isMounted && isOpen) {
            getMySchedule()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (data.days && data.days.length) {
                            succesFunc = () => dispatch({type: "INIT_UPDATE", payload: data.days, callbackUpdate, t});
                        }
                    })
                }).catch(error => {
                    errorProcessing(error, notify, "detail_data");
                })
                .finally(() => {
                    transitionPromise(() => { 
                        setLoading(false)
                        succesFunc();
                    })
                });
        }

        return () => {
            isMounted = false;
            setLoading(true);
            transitionPromise = undefined;
            succesFunc = () => {};
        };
    }, [isOpen]);
        
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true)
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_schedule")}
                description={t("manage.form.description_schedule")}
                loading={loading}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
        </>
    )

}