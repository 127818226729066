function getByPath(obj, path, def) {
    if (!path) {
        throw new Error("field should have \"name\" attribute")
    }
    path = path
        .replace(/\[/g, '.')
        .replace(/]/g, '')
        .split('.');

    path.forEach(function (level) {
        if (!obj) return false;
        obj = obj[level];
    });

    if (obj === undefined) {
		return def;
	}
    
	return obj;
};

export default getByPath;