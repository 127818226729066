import React from "react";
import {
    ManageSubmenu,
    SubmenuBlockWrapper
} from "@components"
import { SortableWrapper } from "@ui-kit"

const Submenu = ({item, index, ...props}) => {
    return (
        <div className="col-md-auto">
            <SubmenuBlockWrapper 
                {...props}
                id={item.id}
            >
                <ManageSubmenu {...item}/>
            </SubmenuBlockWrapper>
        </div>
    )
}

export default function SubmenuList({
    submenu = [], 
    onSortEnd, 
    onAdd,
    onDelete,
    onUpdate,
    AddComponent,
}) {
    return (
        <SortableWrapper
            useDragHandle={false}
            onSortEnd={onSortEnd}
            axis="xy"
            className="row"
        >
            {
                [
                    <AddComponent 
                        onAdd={onAdd}
                        sortKey={-1}
                        key={-1}
                        index={0}
                        collection="static"
                    />,
                    ...submenu.map((item, index) => {
                        return <Submenu
                            sortKey={item.id}
                            key={item.id}
                            index={index+1}
                            collection="submenu"
                            item={item}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            onAdd={onAdd}
                        />
                    })
                ]
            }
        </SortableWrapper>
        
    )
}