import React, { useState, useReducer, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
    Modal,
    Form
} from "@ui-kit"
import { 
    updateRules,
    serviceProcessing
} from "@utils"
import {
    managerMethodsContext,
    notificationsContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "INIT_UPDATE":
            let updateForm = { ...form };
            return updateRules(updateForm, action.payload);
        default:
            return form
    }
}

export default function SettingsProfileWrapper({children, rules}) {
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    const { t } = useTranslation();

    const { updatePassword } = useContext(managerMethodsContext);
    const { notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    
    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updatePassword(data).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                () => {
                    resolve(res);
                    closeModal()
                },
                (errors) => reject({messages: errors})
            )
        })
        .catch(reject)
        .finally(()=> setLoading(false));
    }
    
    const initialForm = {
        type: "restaurant_profile",
        submit: t("buttons.save"),
        callback: callbackUpdate,
        columns: [
            {
                size: 6,
                fields: [{
                    type: "password",
                    name: "current_password",
                    label: t("fields.settings.current_password"),
                    defaultValue: "",
                },
                {
                    type: "password",
                    name: "password",
                    label: t("fields.settings.new_password"),
                    defaultValue: "",
                },
                {
                    type: "password",
                    name: "password_confirm",
                    label: t("fields.settings.confirm_password"),
                    defaultValue: "",
                }]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);

    useEffect(() => {
        let isMounted = true;
        if (isMounted && rules) {
            dispatch({type: "INIT_UPDATE", payload: rules})
            setLoading(false);
        }
        return () => {
            isMounted = false;
            setLoading(true)
        }
    }, [rules])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("settings.form.title_password")}
                description={t("settings.form.description_password")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
        </>
    )
}