import React from "react";

// fields
import {
    CheckboxField,
    TextField,
    TextareaField,
    SelectField,
    HiddenField,
    MaskField,
    DatetimeField,
    FileField,
    ButtonsField,
} from "./"
import DishesSelect from "./DishesSelect/DishesSelect";

export default function Field({ refRegister, type, control, setValue, setError, getValues, watch, initialType, mask, ...props}) {
    if (type === "checkbox")
        return (
            <CheckboxField
                ref={refRegister}
                defaultChecked={Boolean(props.checked)}
                watch={watch}
                {...props}
            />
        )
    if (type === "textarea")
        return (
            <TextareaField
                refRegister={refRegister}
                setValue={setValue}
                {...props}
            />
        )
    if (type === "select")
        return (
            <SelectField
                control={control}
                setValue={setValue}
                getValues={getValues}
                {...props}
            />
        )
    if (type === "select-dish")
        return (
            <DishesSelect
                control={control}
                setValue={setValue}
                getValues={getValues}
                {...props}
            />
        )
    if (type === "hidden")
        return <HiddenField 
            refRegister={refRegister} 
            initialType={initialType}
            setValue={setValue}
            {...props}
         />

    if (type === "mask")
        return <MaskField
            setValue={setValue}
            refRegister={refRegister}
            initialType={initialType}
            mask={mask}
            {...props}
        />
         
    if (type === "time" || type === "date" || type === "datetime")
        return <DatetimeField
            setValue={setValue}
            watch={watch}
            control={control}
            type={type}
            {...props}
        />
        
    if (type === "file") return <FileField {...props} refRegister={refRegister}/>
    if (type === "buttons") return <ButtonsField {...props} refRegister={refRegister} watch={watch} setValue={setValue}/>
        
    return <TextField
            setValue={setValue}
            refRegister={refRegister}
            type={type}
            {...props}
        />
}