import React, { useRef, useEffect, forwardRef } from "react";
import classNames from "classnames";
import Datetime  from "react-datetime";
import moment from "moment"
import { Controller } from "react-hook-form";
// styles
import './react-datetime.css'
import s from "./Fields.module.sass";
// uifrontend\node_modules\react-datetime\css\react-datetime.css
import {
    getByPath,
    useCombinedRefs
} from "@utils";
import { Button } from "@ui-kit";
// locale
import "moment/locale/ru";

const DatetimeField = forwardRef(({type, label, errors, wrapperClass = "col-md-12", refs, setValue, watch, defaultValue, control, name, description, placeholder = " ", isClearable = false, timeZone = false, hidden, className = "", onChangeHandler, ...props}, forwardedRef) => {    
    useEffect(() => {
        if (defaultValue !== null && defaultValue !== undefined) {
            if (timeZone) {
                setValue(name, moment(defaultValue*1000))
            } else {
                setValue(name, moment(defaultValue*1000).utc(0))
            }
        } 
    }, [defaultValue])
    
    const dateTimeRef = useRef();
    const combinedRef = useCombinedRefs(useRef, useEffect, forwardedRef, refs);
    function onClear() {
        setValue(name, null)
    }
    
    return (
        <div className={wrapperClass}>
            <div className={classNames(s.group, {
                [s.clearableGroup]: isClearable
            })}>
                <Controller
                    render={state => {
                        return <Datetime 
                                {...state}
                                onChange={(val) => {
                                    if (typeof onChangeHandler === "function") {
                                        onChangeHandler(val)
                                    }
                                    state.onChange(val)
                                }}
                                ref={ref => dateTimeRef.current = ref}
                                inputProps={{ 
                                    className: s.input, 
                                    readOnly: true, 
                                    placeholder,
                                    ref: combinedRef,
                                    type: hidden ? "hidden" : "text"
                                }}
                                timeFormat={(type === "time" || type === "datetime") ? "HH:mm" : false}
                                dateFormat={(type === "date" || type === "datetime") ? "DD.MM.yyyy" : false}
                                locale={localStorage.getItem("i18nextLng")}
                                timeConstraints={{
                                    minutes: { step: 10 }
                                }}
                                viewMode={type}
                                {...props}
                                renderInput={({value, ...props}) => {
                                    return <input {...props} value={(watch(name) === null || watch(name) === undefined) ? "" : value } />;
                                }}
                                className={classNames({
                                    "static": className.includes("static")
                                })}
                                renderDay={RenderDay}
                            />
                    }}
                    defaultValue={defaultValue}
                    control={control}
                    name={name}
                />
                <label className={classNames(s.label, s.labelPicker)}>
                    {label}
                </label>
                {
                    isClearable ? (
                        <Button type="clear" onClick={(e) => {
                            onClear(e);
                        }}/>
                    ) : null
                }
                { getByPath(errors, name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${name}.message`)}</p> }
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )

})

function RenderDay( props, currentDate ) {
    return <td {...props}><span style={{pointerEvents: "none"}}>{currentDate.date()}</span></td>;
}

export default DatetimeField;