import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { notificationsContext, globalVariablesContext } from "@context"
import s from "./DashboardRestaurant.module.sass"
import { ReactComponent as Copy } from "@svg/copy-dashboard.svg"

export default function DashboardRestaurant({
    qr
}) {
    const { t } = useTranslation();
    const { notify } = useContext(notificationsContext);
    const { variables: { customization: { second }, tag } } = useContext(globalVariablesContext)
    
    function copyText(text) {
        navigator.clipboard.writeText(text).then(() => {
            notify({
                title: "Адрес скопирован",
                type: "success"
            })
        }).catch(err => {
            notify({
                title: "Не удалось скопировать адрес",
                type: "error"
            })
        })
    }
    
    return (
        <div className={s.item}>
            <div className="row mb-3">
                <div className="col-md-auto pr-0">
                    <div className={s.qr} style={{
                        backgroundImage: `url("${qr}")`
                    }}></div>
                </div>
                <div className="col">
                    <p className={s.text} dangerouslySetInnerHTML={{__html: t("dashboard.restaurant.restaurant", {link: `https://cheflist.org/${tag}`})}}></p>
                    <span onClick={() => copyText(`https://cheflist.org/${tag}`)} className={s.copy} style={{color: `#${second}`}}>{t("dashboard.restaurant.copy_link")} <Copy/></span>
                </div>
            </div>
            <p className={s.label}>{t("dashboard.tips.check_page")}</p>
        </div>
    )
}