import React, {useEffect, useState} from "react"
import {
    Field
} from "@ui-kit"

export default function MultipleDishesRow({columns, rules = {}, type, watch, i, setError, clearErrors, ...form}) {
    const [ fields, setFields ] = useState();
    const [ withRules, setRules ] = useState(false)

    useEffect(() => {
        let isMounted = true;
        let fields = {columns}
        if (isMounted) {
            // console.log(rules, withRules);
            for (const [key, value] of Object.entries(rules)) {
                // console.log(key, value, fields.columns[0].fields);
                fields.columns[0].fields.find(field => field.name.match(key)).rules = {validate: (val) => {
                    // console.log(val);
                    if (val === "" && withRules && value.rules.required.value) {
                        return value.rules.required.message
                    } else {
                        return true
                    }
                }}
            }
            setFields(fields);
        }
        return () => isMounted = false;
    }, [withRules])
    // const fields = updateRules({columns}, rules);
    function onChange() {
        let hasContent = Boolean(watch(`dishes_list[${i}]`).title || watch(`dishes_list[${i}]`).price || watch(`dishes_list[${i}]`).description);
        console.log("change", i, hasContent, withRules);
        if (hasContent) {
            if (!withRules) {
                setRules(true);
                // console.log("Rules");
            }
        } else {
            if (withRules) {
                setRules(false);
                clearErrors(`dishes[${i}]`)
            }
        }
    }
    
    return (
        fields ? fields.columns[0].fields.map((field, index) => {
            return <Field {...field} {...form} key={index} onChange={onChange}/>
        }) : null
    )
}
