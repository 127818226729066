import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { 
    Breadcrumbs,
    ManagerSettings,
    InfoAlerts,
    LoadingSpinner
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
import ROUTES from "@routes";

export default function Settings() {
    const { t } = useTranslation();
    const breadcrumbs = [
        {
            title: t("settings.title"),
            link: ROUTES.menu
        },
    ]
    const [ data, setData ] = useState();
    const { getSettings } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getSettings().then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    setData(data)
                })
            })
            .catch(error => errorProcessing(error, notify, "page_data"))
            .finally(() => setLoading(false));
        }
        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [])
    
    return (
        <>
            { loading ? <LoadingSpinner/> : null }
            <Breadcrumbs list={breadcrumbs} />
            <section>
                <div className="container-fluid">
                    <Title className="mb-md-5 mb-4" type="page">{t("settings.title")}</Title>
                    <ManagerSettings {...data} />
                    <InfoAlerts className="notify" text={[t("settings.info")]}/>
                </div>
            </section>
        </>
    )
}