import React, { useRef, useEffect, forwardRef, useState } from "react";
import Inputmask from 'inputmask';
import classNames from "classnames";
// styles
import s from "./Fields.module.sass";
// ui
import {
    getByPath,
    useCombinedRefs
} from "@utils"

const MaskField = forwardRef(({label, description, errors, name, wrapperClass = "col-md-12", refs, rules, refRegister, mask, regex, setValue, initialType = "text", defaultValue, ...props}, forwardedRef) => {
    const [fieldValidate, setValidate] = useState();
    const combinedRef = useCombinedRefs(useRef, useEffect, refRegister({
        validate: {...fieldValidate},
        ...rules,
    }), forwardedRef, refs);
    
    useEffect(() => {
        Inputmask({
            mask: mask ? mask : null,
            regex: regex ? regex : null,
            showMaskOnHover: false,
            showMaskOnFocus: true,
            skipOptionalPartCharacter: " ",
            placeholder: " ",
            greedy: false
        }).mask(combinedRef.current);
    }, [mask])
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setValue(name, defaultValue);
        }
        return () => isMounted = false;
    }, [defaultValue])
    
    useEffect(() => {
        let isMounted = true;
        
        if (isMounted) {
            let validate = {};
            if (rules) {
                if (props.inputMode === "tel") {
                    if (rules.validate) {
                        validate = {
                            ...rules.validate,
                            [rules.validate.length]: (value) => !value || value.replace(/[^\d|+]/g,"").length >= rules.minLength.value || rules.minLength.message
                        }
                    } else {
                        validate = {val: (value) => !value || value.replace(/[^\d]/g,"").length >= rules.minLength.value || rules.minLength.message}
                    }
                } else {
                    if (rules.validate) {
                        validate = {...rules.validate};
                    }
                }
            }
            setValidate({...validate});
        }
    }, [rules])
    
    return (
        <div className={wrapperClass}>
            <div className={classNames(s.group, {
                [s.readonly]: props.readOnly
            })}>
                <input 
                    {...props}
                    type={initialType}
                    ref={combinedRef}
                    className={s.input}
                    placeholder=" "
                    name={name}
                    defaultValue={defaultValue}
                />
                <label className={s.label}>
                    {label}
                </label>
                { getByPath(errors, name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${name}.message`)}</p> }
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
})

export default MaskField;