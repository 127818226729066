import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { 
    ManagerDashboard, 
    LoadingSpinner,
    Breadcrumbs
} from "@components"
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
import { managerMethodsContext, notificationsContext } from "@context"
import ROUTES from "@routes" 

const DashboardPage = () => {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState({
        qr_code: null,
        tip: null,
        start_day: 0,
        revenue: {
            online: [0,0,0,0,0,0,0],
            cash: [0,0,0,0,0,0,0],
        },
        orders: {
            delivery: [0,0,0,0,0,0,0],
            self_pick_up: [0,0,0,0,0,0,0],
        },
        daily_impressions: {
            views: { count: 0, overall: 0, percent: 0 },
            actions: { count: 0, overall: 0, percent: 0 },
            subscriptions: { count: 0, overall: 0, percent: 0 },
            reviews: { count: 0, overall: 0, percent: 0 },
        },
        popular_food: { title: "", image: "" },
        popular_category: { title: "", image: "" },
        impressions: {
            views_count: [0,0,0,0,0,0,0],
            actions_count: [0,0,0,0,0,0,0]
        }
    })
    const { getDashboard } = useContext(managerMethodsContext)
    const { notify, notifyList } = useContext(notificationsContext)
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getDashboard()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        setData({...data});
                    })
                })
                .catch(err => errorProcessing(err, notify, "page_data"))
                .finally(() => { setLoading(false) })
        }
    }, [])
    
    const breadcrumbs = [
        {
            title: t("dashboard.title"),
            link: ROUTES.dashboard
        },
    ]
    
    return <>
        {loading ? <LoadingSpinner/> : null}
        <Breadcrumbs list={breadcrumbs} />
        <section>
            <div className="container mx-0">
                <ManagerDashboard {...data}/>
            </div>
        </section>
    </>
}

export default DashboardPage