import React, {useEffect, useReducer, useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    ManageAddBlock,
    PushBlockWrapper,
    PushTemplateWrapper,
    PushList
} from "@components";
import {
    updateList,
    deleteItemFromList
} from "@utils";
import ROUTES from "@routes";
import { ReactComponent as AddPushIcon } from "@svg/push_add.svg"
import { ReactComponent as GiftPushIcon } from "@svg/push_gift.svg"
import { ReactComponent as ArchiveIcon } from "@svg/archive.svg"

function reducer(pushes, action) {
    switch (action.type) {
        case "INIT":
            return action.payload.map(push => {
                return {
                    ...push,
                    animationCount: 0
                }
            });
        case "ADD_PUSH":
            return [
                {
                    ...action.payload,
                    animationCount: 0
                },
                ...pushes
            ]      
        case "UPDATE_PUSH":
            return updateList(pushes, action.payload)
        case "DELETE_PUSH":
            return deleteItemFromList(pushes, action.payload)      
        default:
            return pushes
    }
}

export default function ManagerPush({model, push_template, rules, archive}) {
    const { t } = useTranslation();
    const [ status, setStatus] = useState(false);
    const [ list, dispatch ] = useReducer(reducer, model);
    const [ count, setCount ] = useState(0);
    
    useEffect(() => {
        let isMounted = true;
        if (model && isMounted) dispatch({type: "INIT", payload: model});
        return () => {
            isMounted = false;
            dispatch({type: "INIT", payload: []});
        }
    }, [model])

    useEffect(() => {
        setStatus(push_template);
    }, [push_template])
    
    return (<>
        {archive ? null : 
        <div className="row">
            <div className="col-md-auto">
                <PushBlockWrapper 
                    rules={rules}
                    onAdd={(data) => dispatch({type: "ADD_PUSH", payload: data})}
                >
                    <ManageAddBlock className="short icon-right" title={t("push.create")} Icon={AddPushIcon}/>
                </PushBlockWrapper>
            </div>
            <div className="col-md-auto">
                <PushTemplateWrapper 
                    setStatus={setStatus} 
                    setCount={setCount}
                    count={count}
                >
                    <ManageAddBlock 
                        className="short icon-right" 
                        title={t("push.congrats")} 
                        Icon={GiftPushIcon}
                        status={status}
                        animationCount={count}
                    />
                </PushTemplateWrapper>
            </div>
            <div className="col-md-auto">
                <Link to={`${ROUTES.push}/archive/1`}>
                    <ManageAddBlock className="short icon-right" title={t("push.archive")} Icon={ArchiveIcon}/>
                </Link>
            </div>
        </div>}
        {
            (list && list.length) ? 
            <div className="row">
                <PushList 
                    list={list}
                    onDelete={(id) => dispatch({type: "DELETE_PUSH", payload: id})}
                    onUpdate={(data) => dispatch({type: "UPDATE_PUSH", payload: data})}
                />
            </div> : null
        }
    </>)
}