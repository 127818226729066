import React, { useEffect, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useHistory } from "react-router-dom";
// components
import {
    Breadcrumbs,
    ManagerPush,
    InfoAlerts,
    LoadingSpinner,
    Pagination
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import { 
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// routes
import ROUTES from "@routes";

function reducer(data, action) {
    switch (action.type) {
        case "UPDATE_DATA":
            return {
                data: action.payload,
                loading: false
            }
        case "RESET_DATA":
            return {
                data: {},
                loading: true
            }
        case "SET_LOADING":
            return {
                ...data,
                loading: action.payload
            }
        default:
            return data
    }
}

const linkPattern = `${ROUTES.push}/archive`

const PushPage = () => {
    // push archive pagination has 20 elements
    const { t } = useTranslation();
    const { getAllPush } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    // location
    const { pathname } = useLocation();
    const match = Boolean(pathname.match('archive'));
    // current pagination page
    const { page } = useParams();
    const history = useHistory();

    const [ data, dispatch ] = useReducer(reducer, {loading: true, data: {count_page: 1}})

    useEffect(() => {
        let isMounted = true;

        console.log(match, page);

        if (isMounted) {
            if (match && !page) {
                history.replace(`${ROUTES.push}/archive/1`)
            } else {
                getAllPush({archive: match, page}).then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (data.count_page < page) {
                            history.replace(ROUTES.not_found)
                        } else {
                            dispatch({type: "UPDATE_DATA", payload: data})
                        }
                    })
                })
                .catch(error => errorProcessing(error, notify, "page_data"))
                .finally(() => dispatch({type: "SET_LOADING", payload: false}));
            }
        }
        
        return () => {
            isMounted = false;
            dispatch({type: "RESET_DATA"})
        }
        
    }, [match, page])
    
    const breadcrumbs = [
        {
            title: t("push.title"),
            link: ROUTES.push
        }
    ]
    
    return (
        <>
            {data.loading ? <LoadingSpinner/> :null}
            <Breadcrumbs list={breadcrumbs} />
            <section>
                <div className="container-fluid">
                    <Title 
                        className="mb-md-5 mb-4" 
                        type="page"
                        back={match ? ROUTES.push : null}
                    >
                        {t("push.title")}{match ? ` (${t("archive")})` : null}
                    </Title>
                    <ManagerPush {...data.data} archive={match}/>
                    {/* there should be pagination if archive is match */}
                    { match ? <Pagination link={linkPattern} count={data.data.count_page} page={page}/> : null }
                    <InfoAlerts className="mt-5 center notify" text={[t("push.notice")]}/>
                </div>
            </section>
        </>
    )
}

export default PushPage;