import React, { useState, useContext, useReducer, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Form,
    Modal,
} from "@ui-kit"
import { 
    errorProcessing, 
    serviceProcessing, 
    uploadFile, 
    updateRules
} from "@utils"
import {
    managerMethodsContext,
    notificationsContext
} from "@context";

const titleMax = 30;
const descriptionMax = 120;

function reducer(form, action) {
    switch (action.type) {
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
            };
            // title
            updateForm.columns[0].fields[0].defaultValue = action.model.title;
            updateForm.columns[0].fields[0].label = action.text.title;
            // points
            updateForm.columns[0].fields[1].defaultValue = action.model.points;
            // description
            updateForm.columns[0].fields[2].defaultValue = action.model.description;
            updateForm.columns[0].fields[2].label = action.text.description;
            // checkbox
            updateForm.columns[1].fields[0].defaultChecked = action.model.enable;

            if (action.model.image) {
                updateForm.columns[1].fields[1].type = "checkbox";
                updateForm.columns[1].fields[2].label = null;
                updateForm.columns[1].fields[2].description = "";
                updateForm.columns[1].fields[2].file = action.model.image;
            } else {
                updateForm.columns[1].fields[1].type = null;
                updateForm.columns[1].fields[2].description = action.pushPhotoEmptyDescription(20);
                updateForm.columns[1].fields[2].file = "none";
                updateForm.columns[1].fields[2].label = action.text.emptyPhoto;
            }
            
            return updateRules(updateForm, action.rules);
        case "LOAD_IMAGE":
            let loadForm = {...form};

            loadForm.columns[1].fields[2].description = "";
            loadForm.columns[1].fields[2].file = action.image;
            return updateRules(loadForm, action.rules);
        default:
            return { ...form }
    }
}

export default function PushTemplateWrapper({children, setStatus, setCount, count}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    const {
        getPushTemplate,
        createPushTemplate
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);

    const titleRef = useRef();
    const descriptionRef = useRef();
    const titleLabelRef = useRef();
    const descriptionLabelRef = useRef();
    
    const pushPhotoEmpty = t("fields.push.photo");
    const pushPhotoEmptyDescription = (file_size = 20) => {
        return t("fields.general.photo_info", {file_size})
    }
    
    function _transformData(data) {
        const { enable, points, image, title, description, remove_image } = data;
        let form = new FormData();
        
        if (typeof remove_image === "boolean") {
            form.append("remove_image", remove_image - 0);
            if (!remove_image && image && image[0]) {
                form.append("image", image[0]);
            }
        } else {
            if (image && image[0]) {
                form.append("image", image[0]);
            }
        }

        form.append("title", title);
        form.append("description", description);
        form.append('points', points);
        form.append('enable', enable);
        
        return form;
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true)
        createPushTemplate(_transformData(data))
        .then(res => {
            serviceProcessing(res, notifyList, data => {
                setStatus(data.push_template || false);
                setOpen(false);
                setCount(count + 1);
                resolve(res);
            }, errors => {
                reject({messages: errors});
            })
        })
        .catch(reject)
        .finally(() => setLoading(false));
    }
    
    function onFileChange(e) {
        e.preventDefault();
        uploadFile(e, dispatch, "LOAD_IMAGE", [400,400], 20, notify);
    }
    
    const initialForm = {
        type: "restaurant_push_template",
        submit: t("buttons.save"),
        callback: callbackCreate,
        columns: [
            {
                size: 8,
                fields: [{
                    type: "text",
                    defaultValue: "",
                    maxLength: titleMax,
                    name: "title",
                    label: t("fields.push.title", {count: titleMax}),
                    refs: titleRef,
                    labelRef: titleLabelRef,
                    onChange: (e) => {
                        titleLabelRef.current.innerText = t("fields.push.title", {count: titleMax - titleRef.current.value.length})
                    }
                },
                {
                    type: null,
                    // type: "mask",
                    mask: "9{*}",
                    inputMode: "number",
                    defaultValue: "",
                    name: "points",
                    label: t("fields.push.gift"),
                },
                {
                    type: "textarea",
                    defaultValue: "",
                    maxLength: descriptionMax,
                    name: "description",
                    label: t("fields.push.description", {count: descriptionMax}),
                    refs: descriptionRef,
                    labelRef: descriptionLabelRef,
                    onChange: (e) => {
                        descriptionLabelRef.current.innerText = t("fields.push.title", {count: descriptionMax - descriptionRef.current.value.length})
                    }
                }]
            },
            {
                size: 4,
                fields: [
                    {
                        type: "checkbox",
                        mode: "switch",
                        defaultChecked: true,
                        name: "enable",
                        label: t("fields.push.enable"),
                        description: t("fields.push.enable_description"),
                    },
                    {
                        type: null,
                        mode: "switch",
                        defaultChecked: false,
                        name: "remove_image",
                        label: `${t("fields.general.photo_delete")} (${t("yes")})`,
                        negativeLabel: `${t("fields.general.photo_delete")} (${t("no")})`
                    },
                    {
                        type: "file",
                        defaultValue: "",
                        file: "none",
                        name: "image",
                        label: pushPhotoEmpty,
                        description: pushPhotoEmptyDescription(20),
                        accept: ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG",
                        onChange: onFileChange,
                        className: "push-template default"
                    }
                ]
            }
        ]
    }
        
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {            
            if (isOpen) {
                getPushTemplate()
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            dispatch({
                                type: "INIT_UPDATE", 
                                ...data, 
                                text: {
                                    title: t("fields.push.title", {count: titleMax - data.model.title?.length || 0}),
                                    description: t("fields.push.description", {count: descriptionMax - (data.model.description?.length || 0)}),
                                    emptyPhoto: pushPhotoEmpty
                                },
                                pushPhotoEmptyDescription
                            });
                        })
                    }).catch(errors => errorProcessing(errors, notify, "detail_data"))
                    .finally(() => setLoading(false))

            }
        }
        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("push.form.title_gift")}
                description={t("push.form.description_gift")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
        </>
    )
}