import React, {useContext, useEffect, useReducer} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { 
    ManageAddBlock,
    DishesBlockWrapper,
    DishesMultipleWrapper,
    DishesList,
    Breadcrumbs
} from "@components";
import {
    updateList,
    deleteItemFromList,
    arrayMove,
    errorProcessing,
    serviceProcessing
} from "@utils";
import { 
    managerMethodsContext,
    notificationsContext,
} from "@context";
import { ReactComponent as AddMenuIcon } from "@svg/addmenu.svg"

function calculateCheckedList(list = []) {
    let dishes_list = [...list].filter(el => {
        return el.checked
    }).map(el => {
        return el.id
    })

    return dishes_list;
}

function reducer(dishes, action) {
    switch (action.type) {
        case "INIT":
            let initDish = [...action.payload].map(el => {
                return {
                    ...el,
                    checked: false,
                    animationCount: 0
                }
            })
            
            return {
                dishes_list: [],
                list: initDish
            };
        case "ADD_DISH":
            let addDish = [
                {
                    ...action.payload,
                    checked: false,
                    animationCount: 0
                },
                ...dishes.list,
            ]
            
            return {
                dishes_list: calculateCheckedList(addDish),
                list: addDish
            }
        case "ADD_MULTIPLE_DISH":
            let newDishes = action.payload.map(el => {
                return {
                    ...el,
                    checked: false,
                    animationCount: 0
                }
            })
            
            let addMultipleDish = [
                ...newDishes,
                ...dishes.list,
            ]
            
            return {
                dishes_list: calculateCheckedList(addMultipleDish),
                list: addMultipleDish
            }
        case "UPDATE_DISH":
            let updateDish = updateList(dishes.list, action.payload)
            return {
                list: updateDish,
                dishes_list: calculateCheckedList(updateDish),
            }
        case "DELETE_DISH":
            let deleteDish = deleteItemFromList(dishes.list, action.payload);
            
            return {
                list: deleteDish,
                dishes_list: calculateCheckedList(deleteDish)
            }
        case "TOGGLE_CHECKER":
            const toggleList = dishes.list.map(el => {
                if (action.payload.includes(el.id)) {
                    return {
                        ...el,
                        checked: !el.checked
                    }
                } else {
                    return el;
                }
            })
            
            return {
                list: toggleList,
                dishes_list: calculateCheckedList(toggleList)
            };
        case "CHECK_DISH": 
            const checkFormList = dishes.list.map(el => {
                if (action.payload.includes(el.id)) {
                    return {
                        ...el,
                        checked: true
                    }
                } else {
                    return el;
                }
            })

            return {
                list: checkFormList,
                dishes_list: calculateCheckedList(checkFormList)
            };
        case "PUBLISH_DISH":
            let publishDishes = [...dishes.list].map(el => {
                if (action.payload.includes(el.id)) {
                    return {
                        ...el,
                        is_published: true,
                        checked: action.uncheck ? false : el.checked
                    }
                } else {
                    return {
                        ...el,
                        // checked: false
                    };
                }
            }) 
            
            return {
                list: publishDishes,
                dishes_list: action.uncheck ? [] : calculateCheckedList(publishDishes)
            };
        case "UNPUBLISH_DISH":
            let unpublishDishes = [...dishes.list].map(el => {
                if (action.payload.includes(el.id)) {
                    return {
                        ...el,
                        is_published: false,
                        checked: action.uncheck ? false : el.checked
                    }
                } else {
                    return {
                        ...el,
                        // checked: false
                    };
                }
            }) 
            return {
                list: unpublishDishes,
                dishes_list: action.uncheck ? [] : calculateCheckedList(unpublishDishes)
            };
        case "CHECK_ALL":
            let checkDishes = dishes.list.map(el => {
                return {
                    ...el,
                    checked: true
                }
            });

            return {
                list: checkDishes,
                dishes_list: checkDishes.map(el => el.id)
            }
        case "UNCHECK_ALL":
            let uncheckDishes = dishes.list.map(el => {
                return {
                    ...el,
                    checked: false
                }
            });
            return {
                list: uncheckDishes,
                dishes_list: []
            }
        case "INVERT_CHECKER":
            let invertDish = dishes.list.map(el => {
                return {
                    ...el,
                    checked: !el.checked
                }
            })
            
            return {
                list: invertDish,
                dishes_list: calculateCheckedList(invertDish)
            };
        case "SORT":
            let sortDishes = {
                ...dishes
            }
            sortDishes.list = action.sortedArray;
            return sortDishes;
        default:
            return dishes
    }
}

function diff(a1, a2) {
    return a1.filter(i=>a2.indexOf(i)<0)
    .concat(a2.filter(i=>a1.indexOf(i)<0))
}

export default function ManagerDishes({
    model, 
    isSearch = false, 
    sortable = true,  
    breadcrumbs,
    submenuId: submenu_id, // parent submenu id
    context,
    setContext,
    dishConnection,
    setConnection,
    setPageLoading,
    // dishes
    temporary, // temporary checked white contextmenu open
    setTemporary,
    dishesId, // all checked dishes list
    setDishesId,
    // 
    isDishPage = false,
    ...props
}) {
    const { t } = useTranslation();
    const [ list, dispatch ] = useReducer(reducer, { list: [], dishes_list: []});

    const { sortDishes } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);

    const { id: param_id } = useParams();
    
    useEffect(() => {
        let isMounted = true;
        if (model && isMounted) dispatch({type: "INIT", payload: model});
        return () => isMounted = false;
    }, [model])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const oldList = [...list.list];
            const sortedArray = arrayMove(list.list, oldIndex, newIndex);
            const sortedArrayIndex = sortedArray.map(el => el.id);
            
            dispatch({
                type: "SORT",
                sortedArray
            });
            
            sortDishes({
                positions: sortedArrayIndex
            }, submenu_id)
            .then(res => serviceProcessing(res, notifyList))
            .catch(error => {
                errorProcessing(error, notify, "wrapper_data");
                dispatch({
                    type: "SORT",
                    sortedArray: oldList
                });
            });
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (dishConnection && typeof setConnection === "function") {
                // dishOnly - for dishes page only
                // forSearch - for search result list too
                // console.log(dishConnection, isSearch, submenu_id);
                const { dishOnly, submenuId: id, exeptSearch = false, ...action } = dishConnection;
                // exeptSearch - not for searched items
                if (!(dishOnly && isSearch)) {
                    if (!isSearch || (!exeptSearch && isSearch)) {
                        if (dishOnly) {
                            if (id === submenu_id) {
                                dispatch(action);
                            }
                        } else {
                            dispatch(action);
                        }
                    }
                }
                setConnection(null);
            }
        }
        return () => isMounted = false;
    }, [dishConnection])
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (dishesId[submenu_id] && list.dishes_list && diff(dishesId[submenu_id], list.dishes_list).length) {
                // condition for stop repeating a response if dishes doubling in dish and search lists.
                // (param_id - 0 === submenu_id) - compare of Component parent id and page params id.
                // isDishPage for send response from search component on menu or submenu pages
                if (!((param_id - 0 === submenu_id) && isSearch && isDishPage)) {
                    setDishesId({
                        ...dishesId,
                        [submenu_id]: list.dishes_list
                    });
                }
            } else if (dishesId[submenu_id] === undefined) {
                setDishesId({
                    ...dishesId,
                    [submenu_id]: list.dishes_list
                });
            }
        }
        return () => {
            isMounted = false;
        }
    }, [list.dishes_list, submenu_id])
    
    // contextmenu options
    function onDelete(id) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "DELETE_DISH", payload: id});
        } else {
            dispatch({type: "DELETE_DISH", payload: id})
        }
    }
    
    function onDeleteMultiple(id_list) {
        if (isSearch) {
            setConnection({type: "DELETE_DISH", payload: id_list, dishOnly: true, submenu_id})
        } else {
            dispatch({type: "DELETE_DISH", payload: id_list})
        }
    }
    
    function onUpdate(data) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "UPDATE_DISH", payload: data});
        } else {
            dispatch({type: "UPDATE_DISH", payload: data});
        }
    }
    
    function onPublish(id_list, uncheck) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "PUBLISH_DISH", payload: id_list, uncheck});
        } else {
            dispatch({type: "PUBLISH_DISH", payload: id_list, uncheck});
        }
    }
    
    function onHide(id_list, uncheck) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "UNPUBLISH_DISH", payload: id_list, uncheck});
        } else {
            dispatch({type: "UNPUBLISH_DISH", payload: id_list, uncheck})
        }
    }
    
    function onCopy(data) {
        // (data) => dispatch({type: "INIT", payload: data})
        if (isSearch) {
            if (typeof setConnection === "function") {
                if (data.length === undefined) {
                    setConnection({type: "ADD_DISH", payload: data, dishOnly: true, submenuId: submenu_id, exeptSearch: true})
                } else {
                    setConnection({type: "ADD_MULTIPLE_DISH", payload: data, dishOnly: true, submenuId: submenu_id, exeptSearch: true})
                }
            }
        } else {
            onAdd(data);
        }
        setContext({
            ...context,
            id: null
        })
    }
    
    function onAdd(data) {
        if (data.length === undefined) {
            dispatch({type: "ADD_DISH", payload: data})
        } else {
            dispatch({type: "ADD_MULTIPLE_DISH", payload: data})
        }
    }
    
    function updateBeforeSortStart(data) {
        setContext({
            // sortable,
            ...context,
            id: null
        });
    }
    
    function onCheckAll() {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "CHECK_ALL"});
        } else {
            dispatch({type: "CHECK_ALL"})
        }
        
    }
    
    function onUncheckAll() {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "UNCHECK_ALL"});
        } else {
            dispatch({type: "UNCHECK_ALL"})
        }
    }
    
    function onCheckInvert() {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "INVERT_CHECKER"});
        } else {
            dispatch({type: "INVERT_CHECKER"})
        }
    }
    
    function onCheckToggle(id) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "TOGGLE_CHECKER", payload: id});
        } else {
            dispatch({type: "TOGGLE_CHECKER", payload: id})
        }
    }
    
    function onCheck(id) {
        if ( typeof setConnection === "function" ) {
            setConnection({type: "TOGGLE_CHECKER", payload: id});
        } else {
            dispatch({type: "TOGGLE_CHECKER", payload: id})
        }
    } 
    
    const methods = {
        onAdd,
        onDelete,
        onDeleteMultiple,
        onUpdate,
        onCheckToggle,
        onCheck,
        onPublish,
        onHide,
        onCheckAll,
        onUncheckAll,
        onCheckInvert,
        onCopy,
        onSortEnd,
        updateBeforeSortStart,
    }
    // contextmenu options end
    
    return (
        <div className="row">
            {
                (breadcrumbs && (list && list.list && list.list.length)) ? <div className="col-12"><Breadcrumbs list={breadcrumbs} noGap={true} type="dishes"/></div> : null
            }
            {isSearch ? null : <>
                <div className="col-md-auto">
                    <DishesBlockWrapper 
                        {...props}
                        onAdd={onAdd}
                    >
                        <ManageAddBlock className="short icon-right" title={t("menu.add_dishes")} Icon={AddMenuIcon}/>
                    </DishesBlockWrapper>
                </div>
                <div className="col-md-auto">
                    <DishesMultipleWrapper 
                        {...props}
                        onAdd={onAdd}
                    >
                        <ManageAddBlock className="short icon-right" title={t("menu.add_multiple_dishes")} Icon={AddMenuIcon}/>
                    </DishesMultipleWrapper>
                </div>
            </>}
            <DishesList 
                dishes={list}
                {...methods}
                sortable = {sortable}
                context = {context}
                setContext = {setContext}
                submenuId = {submenu_id}
                dishesId = {dishesId}
                setPageLoading={setPageLoading}
            />
        </div>
    )
}