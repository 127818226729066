import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import { useTranslation } from "react-i18next"
import {
    Form,
    Modal,
    Title,
    Description
} from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing,
    updateRules,
} from "@utils"
// components
import {
    DeliveryRow,
    ServicesRow,
    AcceptingOrdersRow,
    ServicesTelegramRow
} from "@components"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";
import { ReactComponent as CloudPayments } from "@svg/cloud-payments.svg"

function reducer(form, action) {
    switch (action.type) {
        case "RESET":
            return {
                ...action.payload
            }
        case "INIT_CREATE":
            let createForm = {
                ...form
            }
            const mapField = createForm.columns[1].fields[0].columns[0].fields[1].columns[1].fields[1]
                mapField.location = action.location
                mapField.onPolygon = (data) => action.formRef.current.setMapValidation(["delivery[delivery_zone]", data[0].toString()])
                
            createForm.columns[0].fields[0].columns[0].fields[2].type = action.cloud_payment_status !== "not_available" ? "checkbox" : null
                
            return  updateRules(createForm, action.rules);
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
                submit: action.text.submit,
            }

            updateForm.columns[0].fields[0].columns[0].fields[2].type = action.cloud_payment_status !== "not_available" ? "checkbox" : null

            // Restaurant delivery checkbox field
            updateForm.columns[1].fields[0].columns[0].fields[0].defaultChecked = action.model.delivery.enable;
            // DeliveryRow fields
            // Delivery left side fields
            const deliveryLeft = updateForm.columns[1].fields[0].columns[0].fields[1].columns[0];
                deliveryLeft.fields[0].defaultValue = action.model.delivery.delivery_price
                deliveryLeft.fields[1].defaultValue = action.model.delivery.min_price_for_order
                deliveryLeft.fields[2].defaultValue = action.model.delivery.free_delivery_price
                deliveryLeft.fields[3].defaultValue = action.model.delivery.middle_delivery_time
                deliveryLeft.fields[4].defaultValue = action.model.delivery.discount_percent
            // Delivery right side fields
            const deliveryRight = updateForm.columns[1].fields[0].columns[0].fields[1].columns[1];
                deliveryRight.fields[1].location = action.location
                deliveryRight.fields[1].polygon = action.model.delivery.delivery_zone || []
                deliveryRight.fields[1].onPolygon = (data) => action.formRef.current.setMapValidation(["delivery[delivery_zone]", data[0].toString()])
                // delivery zone field
                deliveryRight.fields[0].defaultValue = action.model.delivery.delivery_zone
            // accept orders in restaurant
            // updateForm.columns[1].fields[0].columns[0].fields[2].defaultChecked = action.model.settings.is_accept_in_restaurant
            updateForm.columns[1].fields[0].columns[0].fields[3].defaultValue = action.model.settings.discount_percent
            updateForm.columns[1].fields[0].columns[0].fields[4].defaultChecked = action.model.self_pickup.enable
            updateForm.columns[1].fields[0].columns[0].fields[5].defaultValue = action.model.self_pickup.average_wait_time
            updateForm.columns[1].fields[0].columns[0].fields[6].defaultValue = action.model.self_pickup.discount_percent
            // updateForm.columns[1].fields[0].columns[0].fields[6].defaultChecked = action.model.preorder.enable
            // updateForm.columns[1].fields[0].columns[0].fields[7].defaultValue = action.model.preorder.discount_percent
            // online pay fileds
            updateForm.columns[0].fields[0].columns[0].fields[0].defaultChecked = action.model.settings.is_pay_cash // pay with cash chcekbox
            updateForm.columns[0].fields[0].columns[0].fields[1].defaultChecked = action.model.settings.is_pay_terminal // pay with terminal chcekbox
            updateForm.columns[0].fields[0].columns[0].fields[2].defaultChecked = action.model.settings.is_pay_card // pay with card chcekbox
            // cloudpayments fields
            updateForm.columns[0].fields[0].columns[1].fields[0].defaultValue = action.model.settings.online_pay_token
            updateForm.columns[0].fields[0].columns[1].fields[1].defaultValue = action.model.settings.online_pay_pass
            // disable field if cptoken is filled
            if (action.model.settings.online_pay_token && action.model.settings.online_pay_pass) {
                updateForm.columns[0].fields[0].columns[1].fields[0].disabled = true
                updateForm.columns[0].fields[0].columns[1].fields[0]["data-disabled"] = true
                updateForm.columns[0].fields[0].columns[1].fields[1].disabled = true
                updateForm.columns[0].fields[0].columns[1].fields[1]["data-disabled"] = true
            } else {
                updateForm.columns[0].fields[0].columns[1].fields[0].disabled = false
                updateForm.columns[0].fields[0].columns[1].fields[1].disabled = false
            }
            // telegram wrapper
            updateForm.columns[1].fields[0].columns[0].fields[9].hash = action.hash

            return updateRules(updateForm, action.rules);
        case "UNBIND":
            let unbindForm = {...form}
            unbindForm.columns[1].fields[0].columns[0].fields[9].hash = action.payload
            return unbindForm;
        default: 
            return form;
    }
}

function reducerPayment(state, action) {
    switch (action.type) {
        case "INIT":
            let initState = { 
                ...state,
                callback: action.payload.callback
            }
            initState.columns[0].fields[0].options = action.payload.company_type
            return initState
        default:
            return state
    }
}

const paymentSelector = "payment-selector";

export default function ManageServicesWrapper({children, updateShort}) {
    const { t, ready } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false); }
    const [ isPaymentOpen, setPaymentOpen ] = useState(false);
    function openPaymentModal(e) { 
        e.preventDefault()
        setPaymentOpen(true) }
    function closePaymentModal() { setPaymentOpen(false) }
    const formRef = useRef();
    const selectorRef = useRef();
    // context methods
    const { getMyServices, updateServices, getPayment, createPayment } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { variables: { phoneMask } } = useContext(globalVariablesContext)
    const [ loading, setLoading ] = useState(true);
    const [ paymentLoading, setPaymentLoading ] = useState(true)
    // form state
    const callbackUpdate = (data, reset, resolve, reject) => {
        setLoading(true);
        updateServices(_transformFormData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, errors => reject({message: errors}))
        }).catch(reject)
        .finally(() => setLoading(false));
    }

    const initialForm = {
        type: "restaurant_services",
        submit: t("buttons.create"),
        callback: callbackUpdate,
        columns: [
            {
                size: 12,
                title: t("manage.form.title_services"),
                description: t("manage.form.description_services"),
                fields: [
                    {
                        type: "wrapper",
                        Wrapper: (props) => <AcceptingOrdersRow {...props}/>,
                        columns: [
                            {
                                size: 12,
                                fields: [
                                    {
                                        type: "checkbox",
                                        name: "settings[is_pay_cash]",
                                        mode: "switch",
                                        defaultChecked: true,
                                        label: t("fields.services.is_pay_cash"),
                                        // wrapperClass: "col-md-12",
                                        description: t("fields.services.is_pay_cash_description")
                                    },
                                    {
                                        type: "checkbox",
                                        name: "settings[is_pay_terminal]",
                                        mode: "switch",
                                        defaultChecked: true,
                                        label: t("fields.services.is_pay_terminal"),
                                        // wrapperClass: "col-md-12",
                                        description: t("fields.services.is_pay_terminal_description")
                                    },
                                    {
                                        // type: "checkbox",
                                        type: null,
                                        name: "settings[is_pay_card]",
                                        mode: "switch",
                                        defaultChecked: false,
                                        label: t("fields.services.is_pay_card"),
                                        // wrapperClass: "col-md-12",
                                        description: t("fields.services.is_pay_card_description", { class: paymentSelector })
                                    },
                                ]
                            },
                            {
                                size: 12,
                                fields: [
                                    {
                                        type: "text",
                                        name: "settings[online_pay_token]",
                                        defaultValue: "",
                                        label: t("fields.services.online_pay_token"),
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        type: "text",
                                        name: "settings[online_pay_pass]",
                                        defaultValue: "",
                                        label: t("fields.services.online_pay_pass"),
                                        wrapperClass: "col-md-6"
                                    }
                                ]
                            },
                        ],
                    },
                ]
            },
            {
                size: 12,
                fields: [
                    {
                        type: "wrapper",
                        Wrapper: (props) => <ServicesRow {...props} initialVisibility={false}/>,
                        columns: [
                            {
                                size: 12,
                                title: t("manage.form.title_services_sub"),
                                description: t("manage.form.description_services_sub"),
                                fields: [
                                    {
                                        // type: "checkbox",
                                        type: null,
                                        name: "delivery[enable]",
                                        mode: "switch",
                                        label: t("fields.services.delivery"),
                                        defaultChecked: false,
                                    },
                                    {
                                        type: "wrapper",
                                        Wrapper: (props) => <DeliveryRow {...props} initialVisibility={false}/>,
                                        columns: [
                                            {
                                                size: 6,
                                                fields: [
                                                    {
                                                        type: "mask",
                                                        mask: "9{*}[.9{0,2}]",
                                                        name: "delivery[delivery_price]",
                                                        defaultValue: 0,
                                                        label: t("fields.services.delivery_price"),
                                                        inputMode: "numeric"
                                                    },
                                                    {
                                                        type: "mask",
                                                        mask: "9{*}[.9{0,2}]",
                                                        defaultValue: null,
                                                        name: "delivery[min_price_for_order]",
                                                        label: t("fields.services.min_price_for_order"),
                                                        inputMode: "numeric"
                                                    },
                                                    {
                                                        type: "mask",
                                                        mask: "9{*}[.9{0,2}]",
                                                        defaultValue: null,
                                                        name: "delivery[free_delivery_price]",
                                                        label: t("fields.services.free_delivery_price"),
                                                        inputMode: "numeric"
                                                    },
                                                    {
                                                        type: "mask",
                                                        mask: "9{*}",
                                                        defaultValue: 60,
                                                        name: "delivery[middle_delivery_time]",
                                                        label: t("fields.services.middle_delivery_time"),
                                                        inputMode: "numeric"
                                                    },
                                                    {
                                                        type: "mask",
                                                        mask: "9{0,2}",
                                                        defaultValue: null,
                                                        name: "delivery[discount_percent]",
                                                        label: t("fields.services.delivery_discount_percent"),
                                                        inputMode: "numeric"
                                                    }
                                                ]
                                            },
                                            {
                                                size: 6,
                                                fields: [
                                                    {
                                                        type: "hidden",
                                                        name: "delivery[delivery_zone]",
                                                        defaultValue: [],
                                                        description: t('fields.services.delivery_zone_map')
                                                    },
                                                    {
                                                        type: "map",
                                                        location: [43.238949, 76.889709],
                                                        polygon: [],
                                                        onPolygon: () => {},
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        // type: "checkbox",
                                        type: null,
                                        name: "settings[is_accept_in_restaurant]",
                                        mode: "switch",
                                        label: t("fields.services.is_accept_in_restaurant"),
                                        description: t("fields.services.is_accept_in_restaurant_description"),
                                        defaultChecked: false,
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        // type: "mask",
                                        type: null,
                                        mask: "9{0,2}",
                                        defaultValue: null,
                                        name: "settings[discount_percent]",
                                        label: t("fields.services.settings_discount_percent"),
                                        inputMode: "numeric",
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        type: "checkbox",
                                        name: "self_pickup[enable]",
                                        mode: "switch",
                                        label: t("fields.services.self_pickup"),
                                        description: t("fields.services.self_pickup_description"),
                                        defaultChecked: false,
                                        wrapperClass: "col-md-12"
                                    },
                                    {
                                        type: "mask",
                                        mask: "9{*}",
                                        defaultValue: null,
                                        name: "self_pickup[average_wait_time]",
                                        label: t("fields.services.self_pickup_average_wait_time"),
                                        inputMode: "numeric",
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        type: "mask",
                                        mask: "9{0,2}",
                                        defaultValue: null,
                                        name: "self_pickup[discount_percent]",
                                        label: t("fields.services.self_pickup_discount_percent"),
                                        inputMode: "numeric",
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        // type: "checkbox",
                                        type: null,
                                        name: "preorder[enable]",
                                        mode: "switch",
                                        label: t("fields.services.preorder"),
                                        description: t("fields.services.preorder_description"),
                                        defaultChecked: false,
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        // type: "mask",
                                        type: null,
                                        mask: "9{0,2}",
                                        defaultValue: null,
                                        name: "preorder[discount_percent]",
                                        label: t("fields.services.preorder_discount_percent"),
                                        inputMode: "numeric",
                                        wrapperClass: "col-md-6"
                                    },
                                    {
                                        type: "wrapper",
                                        Wrapper: (props) => <ServicesTelegramRow {...props}/>,
                                        hash: "",
                                        onUnbind 
                                    }
                                ]
                            },
                        ]
                    },
                ]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm)
    
    function callbackPayment(data, reset, resolve, reject) {
        setPaymentLoading(true)
        const { company_type, contact_phone, owner_phone, phone, ...fields } = data;
        const formData = {
            ...fields,
            company_type: company_type.value,
            phone: phone.replace(/[\D]/g,""),
            contact_phone: contact_phone.replace(/[\D]/g,""),
            owner_phone: owner_phone.replace(/[\D]/g,"")
        }

        createPayment(formData)
        .then(res => {
            serviceProcessing(res, notifyList, (data) => {
                setPaymentOpen(false);
                resolve(data);
                if (selectorRef?.current) {
                    selectorRef.current.onclick = notifyPaymentPending
                }
            }, errors => {
                reject({messages: errors})
            })
        })
        .catch(reject)
        .finally(() => { setPaymentLoading(false) })
    }
    
    const paymentForm = {
        type: "restaurant_payment",
        submit: t("buttons.save"),
        callback: callbackPayment,
        columns: [{
            description: t("manage.services.payment.company_info"),
            size: 12,
            fields: [{
                type: "select",
                name: "company_type",
                label: t("manage.services.payment.company_type"),
                options: [],
                wrapperClass: "col-md-6",
                defaultValue: null,
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "text",
                name: "company_name",
                label: t("manage.services.payment.company_title"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "text",
                name: "restaurant_title",
                label: t("manage.services.payment.restaurant_title"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "text",
                name: "bininn",
                label: t("manage.services.payment.bin"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "textarea",
                name: "legal_address",
                label:  t("manage.services.payment.legal_address"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "textarea",
                name: "physical_address",
                label: t("manage.services.payment.physical_address"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                type: "mask",
                mask: phoneMask,
                name: "phone",
                label: t("manage.services.payment.phone"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
                inputMode: "numeric"
            },{
                type: "email",
                name: "email",
                label: t("manage.services.payment.email"),
                wrapperClass: "col-md-6",
                defaultValue: "",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            }]
        }, {
            description: t("manage.services.payment.bank_details"),
            size: 12,
            fields: [{
                name: "bank_name",
                defaultValue: "",
                label: t("manage.services.payment.bank_title"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                name: "bik",
                defaultValue: "",
                label: t("manage.services.payment.bik"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            }, {
                name: "iban",
                defaultValue: "",
                label: t("manage.services.payment.iban"),
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            }]
        }, {
            description: t("manage.services.payment.company_owner"),
            size: 12,
            fields: [{
                name: "owner_full_name",
                defaultValue: "",
                label: t("manage.services.payment.full_name"),
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                name: "owner_email",
                defaultValue: "",
                label: t("manage.services.payment.email"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
                type: "email"
            }, {
                type: "mask",
                mask: phoneMask,
                name: "owner_phone",
                defaultValue: "",
                label: t("manage.services.payment.phone"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
                inputMode: "numeric"
            }]
        }, {
            description: t("manage.services.payment.contact_person"),
            size: 12,
            fields: [{
                name: "contact_full_name",
                defaultValue: "",
                label: t("manage.services.payment.full_name"),
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
            },{
                name: "contact_email",
                defaultValue: "",
                label: t("manage.services.payment.email"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
                type: "email"
            }, {
                type: "mask",
                mask: phoneMask,
                name: "contact_phone",
                defaultValue: "",
                label: t("manage.services.payment.phone"),
                wrapperClass: "col-md-6",
                rules: {
                    required: {
                        value: true,
                        message: t("fields.general.empty")
                    }
                },
                inputMode: "numeric"
            }]
        }]
    }
    
    const [ formPayment, dispatchPayment ] = useReducer(reducerPayment, paymentForm)

    function onUnbind(hash) {
        dispatch({type: "UNBIND", payload: hash})
    }
    
    function _transformFormData(data) {
        let newData = {...data};
        if (newData.settings.is_pay_card || newData.settings.is_pay_cash) {
            if (newData.delivery.delivery_zone !== undefined) {
                let coords = newData.delivery.delivery_zone.match(/[^,]+,[^,]+/g);
                
                if (coords) {
                    newData.delivery.delivery_zone = [];
                    coords.forEach(element => {
                        newData.delivery.delivery_zone.push([element.split(",")[0] - 0, element.split(",")[1] - 0]);
                    });
                } else {
                    newData.delivery.delivery_zone = [];
                }
            }
            if (!newData.delivery.min_price_for_order && newData.delivery.min_price_for_order !== 0) {
                newData.delivery.min_price_for_order = null;
            }
            if (!newData.delivery.free_delivery_price && newData.delivery.free_delivery_price !== 0) {
                newData.delivery.free_delivery_price = null;
            }
            if (!newData.delivery.delivery_price && newData.delivery.delivery_price !== 0) {
                newData.delivery.delivery_price = 0; // if delivery_price field didn't fill set it 0
            }
            if (!newData.delivery.middle_delivery_time && newData.delivery.middle_delivery_time !== 0) {
                newData.delivery.middle_delivery_time = 60; // if middle_delivery_time field didn't fill set it 60
            }

            // discount
            if (!newData.delivery.discount_percent && newData.delivery.discount_percent !== 0) {
                newData.delivery.discount_percent = null;
            }

            if (!newData.self_pickup.discount_percent && newData.self_pickup.discount_percent !== 0) {
                newData.self_pickup.discount_percent = null;
            }

            if (!newData.self_pickup.average_wait_time && newData.self_pickup.average_wait_time !== 0) {
                newData.self_pickup.average_wait_time = null;
            }
            
            // if (!newData.settings.discount_percent && newData.settings.discount_percent !== 0) {
            //     newData.settings.discount_percent = null;
            // }

            // if (!newData.preorder.discount_percent && newData.preorder.discount_percent !== 0) {
            //     newData.preorder.discount_percent = null;
            // }
            
            // don't forget change defaultValue in initialForm variable
        } else {
            const { settings: { is_pay_card, is_pay_cash } } = newData;
            
            return {
                settings: {
                    is_pay_card,
                    is_pay_cash
                }
            }
        }
        return newData;
    }
        
    function notifyPaymentPending() {
        notify({
            text: t("manage.services.payment.pending_text"),
            type: "success"
        })
    }

    function notifyPaymentFinished() {
        notify({
            text: t("manage.services.payment.finished_text"),
            type: "success"
        })
    }
    
    useEffect(() => {
        let isMounted = true;
        const animationTime = 500

        if (isMounted && isOpen && ready) {            
            getMyServices().then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    // cloud_payment_status: "available" | "not available" | "pending" | "finished"
                    if (data.created) {
                        dispatch({
                            type: "INIT_UPDATE", 
                            ...data, 
                            text: {
                                submit: t("buttons.save")
                            },
                            formRef
                        })
                    } else {
                        dispatch({
                            type: "INIT_CREATE", 
                            ...data,
                            formRef
                        })
                    }
                    if (data.cloud_payment_status !== "not_available") {
                        setTimeout(() => {
                            selectorRef.current = document.querySelector(`.${paymentSelector}`);
                            if (selectorRef.current) {
                                if (data.cloud_payment_status === "available") selectorRef.current.onclick = openPaymentModal
                                if (data.cloud_payment_status === "pending") selectorRef.current.onclick = notifyPaymentPending
                                if (data.cloud_payment_status === "finished") selectorRef.current.onclick = notifyPaymentFinished
                            }
                        }, animationTime)
                    }
                })
            })
            .catch(error => errorProcessing(error, notify, "detail_data"))
            .finally(() => {setLoading(false)})
        }

        return () => {
            isMounted = false;
        }
    }, [isOpen])
    
    useEffect(() => {
        let isMounted = true
        if (isMounted && isPaymentOpen) {
            setPaymentLoading(true)
            getPayment().then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    if (data.enable) {
                        notify({
                            text: t("manage.services.payment.pending_text"),
                            type: "success"
                        })
                        return setPaymentOpen(false)
                    }
                    return dispatchPayment({type: "INIT", payload: {
                        company_type: data.company_type,
                        callback: callbackPayment
                    }})
                })
            }).catch(err => {
                errorProcessing(err, notify, "detail_data")
            })
            setPaymentLoading(false)
        }
        return () => { isMounted = false }
    }, [ isPaymentOpen ])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true)
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                loading={loading}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
            <Modal 
                closeModal={closePaymentModal} 
                isOpen={isPaymentOpen} 
                loading={paymentLoading}
            >
                <div className="row justify-content-between flex-row-reverse">
                    <div className="col-auto">
                        <CloudPayments width="80" height="66"/>
                    </div>
                    <div className="col-md-10">
                        <Title >{t("manage.services.payment.title")}</Title>
                        <Description text={t("manage.services.payment.description")} className="width-limitless"/>
                    </div>
                </div>
                <Form form={formPayment} />
            </Modal>
        </>
    )
}