import React, { useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
// components
import {
    Login
} from "@components"
// routes
import ROUTES from "@routes";

const LoginPage = () => {
    const history = useHistory();
    let { state: prev = ROUTES.manage} = useLocation();

    useEffect(() => {
        let token = localStorage.getItem("token") || sessionStorage.getItem("token")
        if (token) {
            history.push(prev);
        }
    }, [history, prev])
    
    return <Login/>
}

export default LoginPage;