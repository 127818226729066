function makeDouble(n) {
    return n < 10 ? `0${n}` : n
}

function formatTime(time, withH = true) {
    time = Math.round(time);
    let h = makeDouble(Math.floor(time / 3600));
    let m = makeDouble(Math.floor(time % 3600 / 60));
    let s = makeDouble(Math.floor(time % 3600 % 60));

    return withH ? `${h}:${m}:${s}` : `${m}:${s}`
}

export default formatTime;