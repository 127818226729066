import React, {useEffect, useReducer, useRef, useContext, useState} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { OrdersList, LoadingSpinner } from "@components";
import {
    updateList,
    deleteItemFromList
} from "@utils";
import { useTranslation } from "react-i18next";
import {
    Modal,
    Button,
    Title,
    Dropdown,
    Form
} from "@ui-kit"
import { OrderBill } from "@components"
// utils
import { 
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

function enchantText(text, return_text, bonus_text) {
    return text.replace("{RETURN}", return_text).replace("{BONUS}", bonus_text);
}

function reducerForm(form, action) {
    switch (action.type) {
        case "INIT":
            let initForm = { 
                ...form,
                callback: action.callback.bind(undefined, action.orderId)
            };
            initForm.returnText = action.return_text;

            initForm.columns[0].fields[0].defaultValue = action.cancel_list[0].value;
            initForm.columns[1].fields[0].defaultValue = enchantText(
                action.cancel_list[0].text, 
                action.return_text, 
                "", // bonuses are hidden
                // action.bonus_text.replace("{N}",  action.default_points)
            );
            // points default value
            initForm.columns[2].fields[0].defaultValue = action.default_points;
            // buttons list
            initForm.columns[2].fields[1].options = action.apology_points;
            initForm.columns[2].fields[1].defaultValue = action.default_points;
            // update onChange callback
            initForm.columns[2].fields[0].onChange = (e) => {
                let value = e.target.value;
                let bonus_text = value ? action.bonus_text.replace("{N}", value) : "";
                let enchantedText = enchantText(action.cancel_list[0].text, initForm.returnText, bonus_text);
                action.clickPoint(value, enchantedText);
            }
            initForm.columns[2].fields[1].onChange = (value) => {
                let bonus_text = value ? action.bonus_text.replace("{N}", value) : "";
                let enchantedText = enchantText(action.cancel_list[0].text, initForm.returnText, bonus_text);
                action.clickPoint(value, enchantedText);
            }
            
            return initForm
        case "UPDATE_DROPDOWN_CHANGE_HANDLER":
            let temporaryForm = {  ...form };
            // update onChange callback
            temporaryForm.columns[2].fields[0].onChange = (e) => {
                let value = e.target.value;
                let bonus_text = value ? action.bonus_text.replace("{N}", value) : "";
                let enchantedText = enchantText(action.cancel_text, temporaryForm.returnText, bonus_text);
                action.clickPoint(value, enchantedText);
            }
            temporaryForm.columns[2].fields[1].onChange = (value) => {
                let bonus_text = value ? action.bonus_text.replace("{N}", value) : "";
                let enchantedText = enchantText(action.cancel_text, temporaryForm.returnText, bonus_text);
                action.clickPoint(value, enchantedText);
            }
            return temporaryForm
        default:
            return {...form}
    }
}

function reducer(orders, action) {
    switch (action.type) {
        case "INIT":
            return action.payload.map(order => {
                return {
                    ...order,
                    animationCount: 0
                }
            })
        case "UPDATE":
            return updateList(orders, action.payload, true)
        case "DELETE":
            return deleteItemFromList(orders, action.payload)
        case "UPDATE_STATUS":
            return orders.map(el => {
                return el.id === action.payload.order_id ? {
                    ...el,
                    status: action.payload.status,
                    animationCount: el.animationCount + 1
                } : {...el}
            })
        default:
            return orders   
    }
}

const iconPath = "https://console.cheflist.org/media/status_icons"

var parseQueryString = function(searchString) {
    var objURL = {};

    searchString.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
};

const statuses = {
    0: "expects",
    2: "accepted",
    3: "in_delivery",
    4: "done",
    5: "canceled"
}

export default function ManagerOrdersInner(props) {
    const {
        model, 
        delivery_status_list, 
        pick_up_status_list, 
        archive, 
        lastJsonMessage,
        listLoading = false,
        sortingOptions
    } = props
    // manager orders
    const [ list, dispatch ] = useReducer(reducer, model);
    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => {
        let isMounted = true;
        if (model && isMounted) dispatch({type: "INIT", payload: model});
        return () => isMounted = false;
    }, [model])
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (lastJsonMessage) {
                let orders = lastJsonMessage.message.data.model;
                if (orders && (sortingOptions[statuses[orders.status]] || sortingOptions.all)) {
                    dispatch({type: "UPDATE", payload: orders})
                }
            }
        }

        return () => {
            isMounted = false;
        }
    }, [lastJsonMessage, sortingOptions])
    
    // modal
    const formRef = useRef();
    // translation
    const { t } = useTranslation();
    // id form modal window
    const [ id, setId ] = useState(null);
    function closeModal() { 
        setId(null);
        setModalLoading(true);
        
        const searchObj = parseQueryString(location.search);
        delete searchObj.open;
        const params = new URLSearchParams(searchObj);
        history.replace({
            pathname: location.pathname,
            search: params.toString() ? `?${params.toString()}` : ""
        })
    }
    const [ cancelId, setCancelId ] = useState(null);
    function closeCancelModal() { setCancelId(null); }
    const [ cancelText, setText ] = useState("");
    const [ points, setPoints ] = useState(0);
    const [ dropdownValue, setDropdown ] = useState(null);
    
    const {
        getOrder,
        getOrderCancel,
        updateOrder,
        cancelOrder
    } = useContext(managerMethodsContext);
    
    const { notify, notifyList } = useContext(notificationsContext);
    const [ modalLoading, setModalLoading ] = useState(true);
    const [ cancelLoading, setCancelLoading ] = useState(true); // cancel order modal 
    const [ itemLoading, setItemLoading ] = useState(null); // order item loading status
    // dropdown data
    const [ data, setData ] = useState({
        model: {},
        status_list: [],
    });
    // data from dropdown when cancel
    const [ cancelData, setCancelData ] = useState({
        cancel_list: [],
        bonus_text: "",
        return_text: "",
        apology_points: [],
        default_points: 0
    });
    
    useEffect(() => {
        let isMounted = true;
        let searchObj = parseQueryString(location.search)
        if (isMounted) {
            if (id !== null) {
                const params = new URLSearchParams({
                    ...searchObj,
                    open: id
                });
                history.replace({
                    pathname: location.pathname,
                    search: `?${params.toString()}`
                })
                getOrder(id)
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            setData(data);
                            let dropdownValue = data.status_list.find(el => el.value === data.model.status)
                            setDropdown({
                                ...dropdownValue,
                                type: (dropdownValue.value === 5 || dropdownValue.value === 4) ? "cancel" : null 
                            });
                        })
                    })
                    .catch(error => {
                        errorProcessing(error, notify, "detail_data");
                        closeModal();
                    })
                    .finally(() => setModalLoading(false));
            }
        }
        return () => {
            isMounted = false;
        }
    }, [id])

    useEffect(() => {
        let isMounted = true;
        let searchObj = parseQueryString(location.search)
        if (isMounted && searchObj.open && list?.length) {
            setId(searchObj.open - 0)
        }
        return () => isMounted = false;
    }, [location.search, list])
    
    useEffect(() => {
        let isMounted = true;
        
        if (isMounted && (cancelId || cancelId === 0)) {
            getOrderCancel(cancelId)
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        setCancelData({
                            ...data,
                            default_points: 0 // remove line after adding points again
                        });
                        setText(data.cancel_list[0].text);
                        // setPoints(data.default_points);
                        setPoints(0);
                        dispatchForm({
                            type: "INIT", 
                            ...data, 
                            default_points: 0, // remove
                            clickPoint, 
                            callback: callbackCancel,
                            orderId: cancelId
                        });
                    })
                })
                .catch(error => errorProcessing(error, notify, "detail_data"))
                .finally(() => setCancelLoading(false));
        }

        return () => {
            isMounted = false;
            setCancelLoading(true);
        }
    }, [cancelId])

    const onSelectHandler = async (value, order_id) => {
        if (value.value === 5) {
            setCancelId(order_id);
            return "cancel";
        } else {
            setItemLoading(order_id);
            updateOrder({status: value.value}, order_id)
                .then(res => {
                    setItemLoading(order_id);
                    serviceProcessing(res, notifyList, (data) => {
                        if (data.archive) {
                            dispatch({type: "DELETE", payload: data.id})
                        }
                        dispatch({type: "UPDATE_STATUS", payload: {
                            order_id,
                            status: value.value
                        }});
                        dispatchForm({type: "UPDATE_STATUS", order_id, callback: callbackCancel})
                    })
                })
                .catch(errors => errorProcessing(errors, notify, "detail_data"))
                .finally(() => setItemLoading(null));
        }
    }
    
    async function onCancelSelectHandler({value, text}) {
        formRef.current.setMapValidation(["cancel_type", value, { shouldValidate: true }]);
        let bonusText = points ? cancelData.bonus_text.replace("{N}", points) : "";
        let enchantedText = enchantText(text, cancelData.return_text, bonusText);
        formRef.current.setMapValidation(["excuse_text", enchantedText, { shouldValidate: true }]);
        setText(text);
        
        dispatchForm({
            type: "UPDATE_DROPDOWN_CHANGE_HANDLER", 
            cancel_text: text,
            bonus_text: cancelData.bonus_text,
            clickPoint,
        })
        
        return;
    }
    
    function callbackCancel(cancelId, formData, reset, resolve, reject) {
        setCancelLoading(true);
        setItemLoading(cancelId)
        const {cancel_type, points} = formData;
        cancelOrder({
            cancel_type: parseInt(cancel_type),
            points: parseInt(points)
        }, cancelId).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(data)
                console.log(data);
                console.log(cancelId);
                if (data.archive) {
                    dispatch({type: "DELETE", payload: data.id})
                } else {
                    dispatch({type: "UPDATE", payload: data})
                }
                closeCancelModal();
                closeModal();
            }, (errors) => reject({message: errors}))
        })
        .catch(error => errorProcessing(error, notify, "detail_data"))
        .finally(() => {
            setCancelLoading(false)
            setItemLoading(false)
        });
    }
    
    function clickPoint(value, enchantedText) {
        setPoints(value);
        formRef.current.setMapValidation(["points", value, { shouldValidate: true }]);
        formRef.current.setMapValidation(["excuse_text", enchantedText, { shouldValidate: true }]);
    }

    const initialForm = {
        type: "restaurant_orders",
        submit: t("buttons.save"),
        callback: callbackCancel,
        columns: [
            {
                size: 12,
                fields: [{
                    type: "hidden",
                    name: "cancel_type",
                    defaultValue: null
                }]
            },
            {
                size: 12,
                fields: [
                    {
                        type: "textarea",
                        name: "excuse_text",
                        label: t("fields.orders.message"),
                        defaultValue: cancelText,
                        readOnly: true
                    }
                ]
            },
            {
                size: 6,
                fields: [
                    {
                        // type: "mask",
                        type: null,
                        mask: "9{*}",
                        name: "points",
                        label: t("fields.orders.points"),
                        defaultValue: 0,
                        onChange: (e) => {
                            let value = e.target.value;
                            setPoints(value);
                            let bonus_text = value ? cancelData.bonus_text.replace("{N}", value) : "";
                            let enchantedText = enchantText(cancelText, cancelData.return_text, bonus_text);
                            clickPoint(value, enchantedText);
                        }
                    },
                    {
                        // type: "buttons",
                        type: null,
                        name: "points_buttons",
                        defaultValue: 0,
                        options: [0],
                        description: t("fields.orders.points_description"),
                        onChange: (value) => {
                            setPoints(value);
                            let bonus_text = value ? cancelData.bonus_text.replace("{N}", value) : "";
                            let enchantedText = enchantText(cancelText, cancelData.return_text, bonus_text);
                            clickPoint(value, enchantedText);
                        }
                    }
                ]
            }
        ]
    }
    
    const [ form, dispatchForm ] = useReducer(reducerForm, initialForm);
    
    return <>
        <div style={{position: "relative", minHeight: 152}}>
            { listLoading ? <LoadingSpinner position="absolute" className="transparent"/> : null }
            <OrdersList 
                list={list} 
                delivery_status_list={delivery_status_list}
                pick_up_status_list={pick_up_status_list}
                archive={archive}
                onDelete={(id) => dispatch({type: "DELETE", payload: id})}
                openModal={setId}
                onSelectHandler={onSelectHandler}
                itemLoading={itemLoading}
            />
        </div>
        {/* modal */}
        <Modal 
            closeModal={closeModal} 
            isOpen={Boolean(id || id === 0)} 
            loading={modalLoading}
        >
            <div className="row justify-content-between align-items-center">
                <div className="col-md-auto"><Title type="modal" className="mb-4 mb-md-0">{t("orders.form.bill", {id})}</Title></div>
                <div className="col-md-auto">
                    <Dropdown 
                        options={data.status_list}
                        defaultValue={dropdownValue}
                        iconPath={iconPath}
                        onSelectHandler={(value) => onSelectHandler(value, id)}
                        disabled={dropdownValue?.type === "cancel"}
                    />
                </div>
            </div>
            <OrderBill {...data}/>
            <div className="row justify-content-center">
                <Button type="custom-submit" className="save" onClick={closeModal}>{t("buttons.close")}</Button>
            </div>
        </Modal>
        <Modal 
            closeModal={closeCancelModal} 
            isOpen={Boolean(cancelId || cancelId === 0)} 
            title={t("orders.form.cancel")}
            loading={cancelLoading}
        >
            <div className="row mb-4">
                <div className="col-md-5">
                    <Dropdown 
                        options={cancelData.cancel_list}
                        defaultValue={cancelData.cancel_list[0]}
                        onSelectHandler={onCancelSelectHandler}
                    />
                </div>
            </div>
            <Form form={form} ref={formRef}/>
        </Modal>
    </>
}