import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next"
// components
import {
    LoadingSpinner
} from "@components"
// ui-kit
import { 
    Form, 
    Title,
} from "@ui-kit"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";
// routes
import ROUTES from "@routes";
// styles
import s from "./Login.module.sass"
// utils
import { serviceProcessing } from "@utils";

export default function Login() {
    const { t, ready } = useTranslation();
    const { login, updateToken } = useContext(managerMethodsContext);
    const { notifyList } = useContext(notificationsContext);
    const { variables: { status } } = useContext(globalVariablesContext)
    const [ loading, setLoading ] = useState(false);

    function callback(data, reset, resolve, reject) {
        setLoading(true)
        login(data).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (response) => {
                    const { token, role } = response;
                    resolve(res);
                    // clear storage
                    localStorage.removeItem("token");
                    localStorage.setItem("role", role);
                    // set new storage
                    data.remember ? localStorage.setItem("token", token) : sessionStorage.setItem("token", token);
                    updateToken(token);
                    if (role === "MANAGER") {
                        if (status) {
                            return window.location.href = ROUTES.homepage;
                        }
                        return window.location.href = ROUTES.manage;
                    };
                },
                errors =>  reject({messages: errors})
            )
        }).catch((errors) => {
            reject(errors);
            setLoading(false)
        });
    }
    
    const form = {
        type: "login",
        submit: t("buttons.login"),
        callback,
        columns: [
            {
                size: 12,
                fields: [
                    {
                        type: "text",
                        defaultValue: "",
                        name: "login",
                        description: null,
                        label: t("fields.general.login"),
                        autoFocus: "autofocus",
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "password",
                        defaultValue: "",
                        name: "password",
                        description: null,
                        label: t("fields.general.password"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    }
                ]
            }
        ]
    }

    return (<>
        { !ready || loading ? <LoadingSpinner/> : null }
        <div className={s.login}>
            <Title type="page" className="mb-4">{t("login.title")}</Title>
            <p className={s.description}>{t("login.description")}</p>
            <Form form={form}/>
        </div>
    </>)
} 