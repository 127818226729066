import React, { useState, useReducer, useRef, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { 
    Modal,
    Form,
} from "@ui-kit"
import {
    uploadFile,
    serviceProcessing,
    errorProcessing
} from "@utils"
import {
    managerMethodsContext,
    notificationsContext
} from "@context"

function reducer(form, action) {
    switch (action.type) {
        case "INIT":
            let initForm = {
                ...form,
                callback: action.payload.callback.bind(undefined, undefined) // 1 - this, 2 - file
            };

            if (Boolean(action.payload.video)) {
                // video input
                initForm.columns[0].fields[0].hasVideo = Boolean(action.payload.video);
                initForm.columns[0].fields[0].type = "video";
                initForm.columns[0].fields[0].src = action.payload.video;
                initForm.columns[0].fields[0].t_start = action.payload.t_start;
                initForm.columns[0].fields[0].t_end = action.payload.t_end;
                // file input
                initForm.columns[0].fields[1].type = null;
                initForm.columns[0].fields[1].description = null;
                // has video input 
                initForm.columns[0].fields[3].checked = true; // initialize for initial video

            } else {
                initForm.columns[0].fields[0].type = null;
                initForm.columns[0].fields[1].type = "file";
                // has video input 
                initForm.columns[0].fields[3].checked = false;
            }

            return initForm;
        case "CHANGE_VIDEO":
            let changeVideo = { 
                ...form,
                callback: action.callback.bind(undefined, action.file)
            }
            changeVideo.columns[0].fields[0].type = "video";
            changeVideo.columns[0].fields[0].src = action.src;
            // input
            changeVideo.columns[0].fields[1].type = "hidden";
            changeVideo.columns[0].fields[1].description = "";
            // remove
            changeVideo.columns[0].fields[2].checked = false;
            
            return changeVideo;
        case "CLEAR_VIDEO":
            let clearVideo = { 
                ...form,
                callback: action.payload.callback.bind(undefined, undefined) // 1 - this, 2 - file
            };
            // video editor wrapper
            clearVideo.columns[0].fields[0].type = null;
            clearVideo.columns[0].fields[0].src = null;
            clearVideo.columns[0].fields[0].t_start = 0;
            clearVideo.columns[0].fields[0].t_end = 90;
            // file inputs
            clearVideo.columns[0].fields[1].type = "file";
            clearVideo.columns[0].fields[1].defaultValue = "";
            clearVideo.columns[0].fields[1].description = action.payload.text.description;
            
            return clearVideo;
        default:
            return form;
    }
}

export default function ManageVideoWrapper({children, updateShort}) {
    const { setVideo, getVideo } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { t } = useTranslation();
    // modal toggler
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false) }
    // modal loading
    const [ loading, setLoading ] = useState(true);
    const [ progress, setProgress ] = useState(null);
    
    const formRef = useRef();
    const removeRef = useRef();
    const videoRef = useRef();
    
    function onRemove() {
        dispatch({
            type: "CLEAR_VIDEO", 
            payload: { 
                text: { description: t("manage.video.form.drag") },
                callback
            },
        });
        
        setTimeout(() => {removeRef.current.checked = true;}, 0);
    }
    
    function _transformData(data) {
        const { t_start, t_end, file, remove_video } = data;

        if (remove_video) {
            return { remove_video: 1 }
        } else {
            const formData = new FormData();
            formData.append("t_start", t_start);
            formData.append("t_end", t_end);

            if (file) {
                formData.append("video", file);
            }
            return formData
        }
        
    }
    
    const config = {
        onUploadProgress: progressEvent => setProgress(parseInt((progressEvent.loaded/progressEvent.total)*100))
    }
    
    // file is binding in dispatch, others are default args
    function callback(file, data, reset, resolve, reject) {
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
        }
        if (!data.has_video && !file) {
            return reject({messages: {
                global: t("manage.video.notify.upload_video")
            }})
        }
        setLoading(true);
        setVideo(_transformData({...data, file}), config).then(res => {
            serviceProcessing(res, notifyList, data => {
                updateShort(data);
                resolve(data);
                closeModal();
            }, (errors) => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false));
    }
    
    function onChange(e) {
        uploadFile(e, enchantedDispatch, "CHANGE_VIDEO", null, 100, notify, "video")
    }

    function enchantedDispatch(action) {
        dispatch({...action, callback})
    }
    
    function onError(e) {
        dispatch({type: "CLEAR_VIDEO", payload: {
            text: {description: t("manage.video.form.drag")},
            callback
        }})
    }
    
    const initialForm = {
        type: "restaurant_images",
        submit: t("buttons.save"),
        callback,
        columns: [{
            size: 12,
            fields: [{
                type: null, // video
                src: null,
                onError,
                hasVideo: false,
                t_start: 0,
                t_end: 90,
                onRemove,
                refs: videoRef
            },{
                type: "file",
                initialType: "file",
                accept: ".mp4,.flv,.webm",
                name: "video",
                className: "video-editor",
                label: t("manage.video.form.upload"),
                description: t("manage.video.form.drag"),
                onChange
            },{
                type: "hidden",
                initialType: "checkbox",
                name: "remove_video",
                refs: removeRef,
                checked: false,
                readOnly: true
            },{
                type: "hidden",
                initialType: "checkbox",
                name: "has_video",
                defaultValue: 1,
                checked: false,
                readOnly: true
            }]
        }]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm)
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getVideo().then(res => {
                serviceProcessing(res, notifyList, data => {
                    dispatch({type: "INIT", payload: {
                        ...data,
                        callback,
                        text: {
                            description: t("manage.video.form.drag")
                        }
                    }});
                })
            }).catch(errors => errorProcessing(errors, notify, "page_data"))
            .finally(() => setLoading(false))
        }
        return () => isMounted = false;
    }, [isOpen])
    
    return <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true)
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.video.form.title")}
                description={t("manage.video.form.description")}
                loading={loading}
                progress={progress}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
        </>
}