import React, { useState, useReducer, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
    Modal,
    Form
} from "@ui-kit"
import {
    updateRules,
    errorProcessing,
    serviceProcessing
} from "@utils"
import {
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "INIT_UPDATE":
            let updateForm = { ...form };
            updateForm.columns[0].fields[0].defaultValue = action.profile.name;
            updateForm.columns[0].fields[1].defaultValue = action.profile.phone;
            updateForm.columns[0].fields[2].defaultValue = action.profile.email;
            // language list
            updateForm.columns[0].fields[3].options = action.language_list;
            updateForm.columns[0].fields[3].defaultValue = action.language_list.find(el => el.value === action.profile.language);
            // descriptions
            updateForm.columns[1].fields[1].description = action.t("settings.form.profile_warning", {main_email: action.main_email, support_email: "support@cheflist.org"});
            
            return updateRules(updateForm, action.rules);
        default:
            return { ...form }
    }
}

export default function SettingsProfileWrapper({children, onUpdate}) {
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    const { t, i18n } = useTranslation();

    const { 
        getSettingsProfile,
        updateProfile,
        updateAcceptLanguage
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext)
    const { 
        updateLanguage,
        variables: { phoneMask }
    } = useContext(globalVariablesContext)
    const [ loading, setLoading ] = useState(true);
    
    function _transformData(data) {
        const { language: { value: language }, phone, ...formData } = data;
        return {
            ...formData,
            phone: phone.replace(/[^\d]/g,""),
            language
        }
    }
    
    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true)
        updateProfile(_transformData(data)).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    resolve(res);
                    onUpdate(data);

                    const { subscribe_message, status_message, i18nextLng } = data;

                    i18n.changeLanguage(i18nextLng);
                    updateAcceptLanguage(i18nextLng);
                    updateLanguage({
                        language: i18nextLng,
                        subscribe_message,
                        status_message
                    });
                    closeModal();
                },
                (errors) => reject({messages: errors}) 
            )
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    
    const initialForm = {
        type: "restaurant_profile",
        submit: t("buttons.save"),
        callback: callbackUpdate,
        columns: [
            {
                size: 6,
                fields: [{
                    type: "text",
                    defaultValue: "",
                    name: "name",
                    label: t("fields.settings.person"),
                },
                {
                    type: "mask",
                    mask: phoneMask,
                    name: "phone",
                    label: t("fields.settings.phone"),
                    inputMode: "tel",
                    defaultValue: "",
                },
                {
                    type: "email",
                    inputMode: "email",
                    name: "email",
                    label: t("fields.settings.email"),
                    defaultValue: "",
                },
                {
                    type: "select",
                    options: [],
                    isSearchable: true,
                    defaultValue: null,
                    name: "language",
                    label: t("fields.settings.language")
                }]
            },
            {
                size: 6,
                fields: [
                    {
                        type: "description",
                        description: t("settings.form.profile_notice"),
                        wrapperClass: "col-md-12",
                    },
                    {
                        type: "description",
                        wrapperClass: "red col-md-12",
                        description: ""
                    }
                ]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);

    useEffect(() => {
        let isMounted = true;
        if (isMounted && isOpen) {
            getSettingsProfile()
            .then(
                res => {
                    serviceProcessing(res, notifyList, (data) => {
                        dispatch({type: "INIT_UPDATE", ...data, t})
                    })
                }
            ).catch(errors => errorProcessing(errors, notify, "detail_data"))
            .finally(() => setLoading(false));
        }
        return () => {
            isMounted = false;
            setLoading(true)
        }
    }, [isOpen])
    
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("settings.form.title_profile")}
                description={t("settings.form.description_profile")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
        </>
    )
}