import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import { useTranslation } from "react-i18next"
// ui
import { 
    Form, 
    Modal, 
} from "@ui-kit"
// utils
import {
    updateRules,
    errorProcessing,
    serviceProcessing,
    setAsyncTimeout
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "INIT_CREATE":
            let formCreate = {...form}
            formCreate.columns[0].fields[0].options = action.country_list;
            formCreate.columns[0].fields[0].onChangeHandler = ({value}) =>  {
                action.formRef.current.setMapValidation(["city", "", { shouldValidate: true }]);
                action.changeCountry(action.country_list.find(el => el.value === value).cities);
            };
            // formCreate.columns[0].fields[2].options = action.prefix_list;
            formCreate.columns[1].fields[0].center = action.center;
            
            return updateRules(formCreate, action.rules);
        case "INIT_UPDATE":
            let formUpdate = {
                ...form,
                submit: action.t("buttons.save"),
                callback: action.callbackUpdate
            }
            // country file
            formUpdate.columns[0].fields[0].options = action.country_list;
            formUpdate.columns[0].fields[0].defaultValue = action.country;
            formUpdate.columns[0].fields[0].onChangeHandler = ({value}) => {
                action.formRef.current.setMapValidation(["city", "", { shouldValidate: true }]);
                action.changeCountry(action.country_list.find(el => el.value === value).cities);
            };
            // city field
            formUpdate.columns[0].fields[1].options = action.country_list.find(el => el.value === action.country.value).cities;
            formUpdate.columns[0].fields[1].defaultValue = action.city;
            formUpdate.columns[0].fields[1].noOptionsMessage = null;
            // prefix field
            // formUpdate.columns[0].fields[2].options = action.prefix_list;
            // formUpdate.columns[0].fields[2].defaultValue = action.prefix;
            // street field
            formUpdate.columns[0].fields[2].defaultValue = action.street;
            // formUpdate.columns[0].fields[2].label = action.prefix.long?.trim() || action.prefix.label;
            // building number field
            // formUpdate.columns[0].fields[3].defaultValue = action.building_number;
            // map field
            formUpdate.columns[1].fields[0].location = action.location;
            formUpdate.columns[1].fields[0].center = action.center;
            formUpdate.columns[1].fields[1].defaultValue = action.location;
            
            return updateRules(formUpdate, action.rules);
        case "CHANGE_COUNTRY":
            let formChangeCountry = {...form}
                formChangeCountry.columns[0].fields[1].options = action.payload;
                formChangeCountry.columns[0].fields[1].noOptionsMessage = null;
            return formChangeCountry
        default:
            return form
    }
}

export default function ManageAddressWrapper({children, id, updateShort}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    // methods
    const {
        getMyAddress,
        createAddress,
        updateAddress,
    } = useContext(managerMethodsContext);
    const { 
        notify,
        notifyList
    } = useContext(notificationsContext);
    const { 
        updateCurrency
    } = useContext(globalVariablesContext);
    const [ loading, setLoading ] = useState(true);
    // refs
    const formRef = useRef(); // form ref to set values of fields when street and building number values change
    // refs to change address after map clicked
    const streetRef = useRef(); // street's name field ref
    const locationRef = useRef(); // location's coords field ref
    // ref to change cities list after country selected
    const cityRef = useRef(); // city selectField
    const streetLabelRef = useRef(); // to change street field label after prefix change 
    // change address after click on map
    const callbackMap = (coords, street = "") => {
        formRef.current.setMapValidation([locationRef.current.getAttribute("name"), coords, { shouldValidate: true }])
        formRef.current.setMapValidation([streetRef.current.getAttribute("name"), street, { shouldValidate: true }])
    }
    
    const initialForm = {
        type: "restaurant_address",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 6,
                fields: [
                    {
                        type: "select",
                        defaultValue: "",
                        name: "country",
                        description: null,
                        label: t("fields.address.country"),
                        options: [],
                        isSearchable: true,
                        onChangeHandler: () => {}
                    },
                    {
                        type: "select",
                        defaultValue: "",
                        name: "city",
                        description: null,
                        label: t("fields.address.city"),
                        noOptionsMessage: () => t("fields.address.select_country_first"),
                        options: [],
                        isSearchable: true,
                        refs: cityRef
                    },
                    {
                        type: "text",
                        defaultValue: "",
                        name: "street",
                        description: null,
                        label: t("fields.address.street"),
                        refs: streetRef,
                        labelRef: streetLabelRef
                    }
                ]
            },
            {
                size: 6,
                fields: [
                    {
                        type: "map",
                        center: [43.238949, 76.889709],
                        location: null,
                        onClick: callbackMap
                    },
                    {
                        type: "hidden",
                        defaultValue: "",
                        name: "location",
                        refs: locationRef,
                    }
                ]
            }
        ]
    }
    
    function changeCountry(cities) {
        dispatch({type: "CHANGE_COUNTRY", payload: cities})
    }
    
    function _transformFormData(data) {
        return {
            ...data,
            country: data.country.value,
            city: data.city.value,
            location: data.location.split(",")
        }
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createAddress(_transformFormData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                const { currency_code, ...address } = data;
                resolve(res)
                setOpen(false);
                updateCurrency(currency_code);
                updateShort(address);
            }, (errors) => reject({messages: errors}))
        })
        .catch(reject)
        .finally(() => setLoading(false));
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateAddress(_transformFormData(data), id).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                const { currency_code, ...address } = data;
                resolve(res)
                setOpen(false);
                updateCurrency(currency_code);
                updateShort(address);
            }, (errors) => reject({messages: errors}))
        })
        .catch(reject)
        .finally(() => setLoading(false));
    }
    
    const [form, dispatch] = useReducer(reducer, initialForm);

    useEffect(() => {
        let isMounted = true;
        let transitionPromise = async (cb) => await setAsyncTimeout(cb, 500);
        let succesFunc = () => {};
        if (isMounted && isOpen) {
            getMyAddress()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (id) {
                            succesFunc = () => dispatch({type: "INIT_UPDATE", ...data, changeCountry, callbackUpdate, formRef, t})
                        } else {
                            succesFunc = () => dispatch({type: "INIT_CREATE", ...data, changeCountry, formRef})
                        }
                    })
                })
                .catch(error => errorProcessing(error, notify, "detail_data"))
                .finally(() => {
                    transitionPromise(() => { 
                        setLoading(false)
                        succesFunc();
                    })
                })
        }
        return () => { 
            isMounted = false;
            setLoading(true);
            transitionPromise = undefined;
            succesFunc = () => {};
        };
    }, [isOpen]);
            
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true),
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_address")}
                description={t("manage.form.description_address")}
                loading={loading}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
        </>
    )

}