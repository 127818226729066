import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
// components
import { 
    SimpleAppearAnimation,
    LoadingSpinner
} from "@components"
// ui-kit
import { Title, Dropdown } from "@ui-kit";
import { globalVariablesContext } from "@context";
// styles
import s from "./ManageBlock.module.sass";
// icons svg

const completed = 4;
const canceled = 5;
const iconPath = "https://console.cheflist.org/media/status_icons";

export default function ManageOrder(props) {
    const { t } = useTranslation();
    const {
        id, 
        date_create, 
        summa, 
        user, 
        count_of_dishes, 
        status, 
        status_list = [], 
        payment_method,
        onSelectHandler, 
        onClick, 
        archive, 
        itemLoading, 
        animationCount,
        title
    } = props;
    
    const { variables: { currency, customization: { second } } } = useContext(globalVariablesContext);

    const disabled = status === completed || status === canceled
    
    return (
        <div className={classNames({
            [s.clickable]: !itemLoading
        })} onClick={(e) => {
            if (!itemLoading) {
                onClick(e)
            }
        }}>
            <SimpleAppearAnimation index={animationCount}>
                <motion.div className={s.order}>
                    { itemLoading === id ? <LoadingSpinner position="absolute" /> : null }
                    <div className="row justify-content-between align-items-center">
                        <div className="col mb-3 mb-xl-0">
                            <Title className={s.orderTitle}>{title}</Title>
                            <div className="row mx-0 mb-1">
                                <div className="col-sm-auto px-0">
                                    {date_create}
                                </div>
                                <span className={s.delimeter}> • </span>
                                <div className="col-sm-auto px-0">
                                    {user}
                                </div>
                                <span className={s.delimeter}> • </span>
                                <div className="col-sm-auto px-0">
                                    {t("orders.dishes")}: <span style={{color: `#${second}`}}>{count_of_dishes}</span>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-sm-auto px-0">
                                    <span style={{color: `#${second}`}}>{summa}</span> {currency} 
                                </div>
                                <span className={s.delimeter}> • </span>
                                <div className="col-sm-auto px-0">
                                {t("orders.payment_method")}: <span style={{color: `#${second}`}}>{payment_method}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-auto">
                            <Dropdown 
                                options={status_list}
                                defaultValue={status_list.find(el => el.value === status)}
                                iconPath={iconPath}
                                onSelectHandler={(value) => onSelectHandler(value, id)}
                                disabled={archive || disabled}
                            />
                        </div>
                    </div>
                </motion.div>
            </SimpleAppearAnimation>
        </div>
    )
}