import React from "react"
import { useTranslation } from "react-i18next"
import { Title } from "@ui-kit"
import { 
    DashboardRevenue,
    DashboardImpressions,
    DashboardPopular,
    DashboardImpressionsChart,
    DashboardOrders,
    DashboardTips,
    DashboardRestaurant
} from "@components"

export default function ManagerDashboard(props) {
    const { t } = useTranslation();
    const {
        qr_code,
        tip,
        start_day,
        revenue,
        orders,
        daily_impressions,
        popular_food,
        popular_category,
        impressions
    } = props
    
    return (<>
        <Title type="page">{t("dashboard.overall_stats")}</Title>
        <div className="row">
            <div className="col-md-4">
                <DashboardRevenue 
                    start_day={start_day}
                    revenue={revenue}
                />
            </div>
            <div className="col-md-4">
                <DashboardImpressions daily_impressions={daily_impressions} />
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-6">
                        <DashboardPopular
                            title={popular_category.title}
                            image={popular_category.image}
                            label={t("dashboard.popular_category")}
                            // percent="75"
                        />
                    </div>
                    <div className="col-6">
                        <DashboardPopular
                            title={popular_food.title}
                            image={popular_food.image}
                            label={t("dashboard.popular_food")}
                            // percent="75"
                        />
                    </div>
                    <div className="col-md-12">
                        <DashboardImpressionsChart impressions={impressions} start_day={start_day}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-8">
                <DashboardOrders 
                    start_day={start_day} 
                    orders={orders}
                />
            </div>
            <div className="col-md-4">
                <div className="row">
                    {/* <div className="col-md-12">
                        <DashboardTips tip={tip} />
                    </div> */}
                    <div className="col-md-12">
                        <DashboardRestaurant qr={qr_code} />
                    </div>
                </div>
            </div>
        </div>
    </>)
}