import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
// components
import { SimpleAppearAnimation } from "@components";
// style
import s from "./ManageBlock.module.sass";
// icons
import { ReactComponent as Calendar } from "@svg/push-calendar.svg"
import { ReactComponent as Like } from "@svg/like-filled.svg"
import { ReactComponent as Dislike } from "@svg/dislike-filled.svg"

export default function ManageFeedback({date_create, feedback, is_like, is_answered, onClick, animationCount }) {
    const { t } = useTranslation();
    
    return (
        <SimpleAppearAnimation index={animationCount}>
            <motion.div className={classNames(s.item, s.clickable, s.itemFeedback)} onClick={onClick}>
                <div className={classNames(s.body, s.bodyNews)}>
                    <div className={s.newsContent}>
                        <div className={classNames("d-flex flex-wrap", s.widgets)}>
                            <div className="col-sm-auto px-0 mr-3 d-flex align-items-center">
                                {is_like ? <Like className={s.newsIcon}/> : <Dislike className={s.newsIcon}/>}
                                {is_answered ? t("feedback.is_answered") : t("feedback.not_answered") }
                            </div>
                            <div className="col-sm-auto px-0 d-flex align-items-center"><Calendar className={s.newsIcon}/>{date_create}</div>
                        </div>
                        <p className={classNames(s.smallDescription, s.feedbackDescription)}>{feedback}</p>
                    </div>
                </div>
            </motion.div>
        </SimpleAppearAnimation>
    )
}