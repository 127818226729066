import React from "react";
import { useTranslation } from "react-i18next";
import { ManageOrder } from "@components"
import { Title } from "@ui-kit"

const type_delivery = 1;
const type_pickup = 2;

export default function OrdersList(props) {
    const { t } = useTranslation();
    const {
        list = [], 
        delivery_status_list,
        pick_up_status_list,
        archive, 
        openModal,
        onSelectHandler,
        itemLoading
    } = props;

    return (<>
        <Title type="page" className="mb-3">{t("orders.latest_orders")}</Title>
        {list.length ? list.map(({type, ...item}) => {
            const status_list = type === type_delivery ? delivery_status_list : ( type === type_pickup ? pick_up_status_list : [] )
            
            return  <ManageOrder 
                        key={item.id}
                        archive={archive} 
                        onSelectHandler={onSelectHandler}
                        status_list={status_list}
                        onClick={() => openModal(item.id)}
                        itemLoading={itemLoading}
                        {...item} 
                    />
        }) : null}
    </>)
}