import useScript from "./useScript";
import createSecureForm from "./createSecureForm";
import setThousandthDivision from "./setThounsandthDivision";
import errorProcessing from "./errorProcessing";
import getByPath from "./getByPath";
import useCombinedRefs from "./useCombinedRefs";
import uploadFile from "./uploadFile";
import serviceProcessing from "./serviceProcessing";
import sliceStringPattern from "./sliceStringPattern"
import updateRules from "./updateRules"
import formatMaskedData from "./formatMaskedData"
import setAsyncTimeout from "./setAsyncTimeout"
import formatTime from "./formatTime"
import debounce from "./debounce"
import hexToRgba from "./hexToRgba"
import formatNumber from "./formatNumber"

function wordInflection(value, words){
    if (words) {
        value = Math.abs(value) % 100;
        const num = value % 10;
        if(value > 10 && value < 20) return words[2];
        if(num > 1 && num < 5) return words[1];
        if(num === 1) return words[0];
        return words[2];
    }
    return value
}

function smoothScrolling(elementY, duration, parent = document.body) { 
    var startingY = parent.scrollTop;
    var diff = elementY - startingY;
    var start;
  
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        var time = timestamp - start;
        var percent = Math.min(time / duration, 1);
    
        parent.scrollTo(0, startingY + diff * percent);
    
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    })
}

function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}

const updateList = (list, data, create = false) => {
    const idx = list.findIndex((el) => (el.id === data.id && data.id !== undefined) || (el.card_id === data.card_id && data.card_id !== undefined));
    
    if (idx !== -1) {
        return [
                ...list.slice(0, idx),
                {
                    ...data,
                    animationCount: list[idx].animationCount + 1 || 0
                },
                ...list.slice(idx + 1)
            ]
    } else {
        if (create) {
            return [
                {
                    ...data,
                    animationCount: 0
                },
                ...list
            ]
        }
        return [...list]
    }
}

function deleteItemFromList(list, id) {
    if (Array.isArray(id)) {
        return [...list.filter(el => !id.includes(el.id))];
    } else {
        const idx = list.findIndex((el) => el.id === id || el.card_id === id );
        console.log([...list.filter((el, index) => index !== idx)]);
        return [...list.filter((el, index) => index !== idx)]
    }
}

function makeSelectorFromClassList(string) {
    return `.${string.replace(/\s/g,".")}`
}

export { 
    getByPath, 
    useCombinedRefs, 
    updateRules, 
    uploadFile,
    wordInflection,
    smoothScrolling,
    arrayMove,
    sliceStringPattern,
    errorProcessing,
    updateList,
    deleteItemFromList,
    makeSelectorFromClassList,
    useScript,
    createSecureForm,
    setThousandthDivision,
    serviceProcessing,
    formatMaskedData,
    setAsyncTimeout,
    formatTime,
    debounce,
    hexToRgba,
    formatNumber
}