import React from "react";
import {
    ManageDish,
    DishesBlockWrapper
} from "@components"
import { SortableWrapper } from "@ui-kit"
import s from "./DishesList.module.sass"

const Dish = (props) => {
    const {dish, methods, submenuId, setContext, context, contextClass, sortable, dishesId} = props;

    return <div className="col-md-auto">
        <DishesBlockWrapper
            id={dish.id}
            image={dish.image}
            dishesId={dishesId}
            submenuId={submenuId}
            setContext={setContext}
            context={context}
            contextClass={contextClass}
            sortable={sortable}
            {...methods}
        >
            <ManageDish {...dish}/>
        </DishesBlockWrapper>
    </div>
}

export default function DishesList({
    dishes, 
    sortable = true, 
    onSortEnd, 
    updateBeforeSortStart,
    submenuId, 
    setContext,
    context,
    ...methods
}) {
    
    return sortable ? 
        <SortableWrapper
            useDragHandle={false}
            onSortEnd={onSortEnd}
            updateBeforeSortStart={updateBeforeSortStart}
            axis="xy"
            className="col-md-12 row px-0 mx-0"
            helperClass={s.dragging}
            dropAnimationDuration={200}
        >
            {
                (dishes && dishes.list) ? dishes.list.map((dish, index) => {
                    return <Dish 
                        sortKey={dish.id}
                        dish={dish}
                        methods={methods} 
                        setContext={setContext}
                        context={context}
                        dishes_list={dishes.dishes_list} 
                        index={index} // index for sortable-hoc
                        key={dish.id} // key must be index because of sortable-hoc 
                        submenuId={submenuId} // parent submenu id
                        contextClass={s.context}
                        sortable={sortable}
                    />
                }) : []
            }
        </SortableWrapper> : 
        <div className="col-md-12 row">
            {
                (dishes && dishes.list) ? dishes.list.map((dish, index) => {
                    return <Dish 
                        sortKey={dish.id}
                        dish={dish} 
                        index={index} 
                        setContext={setContext}
                        context={context}
                        methods={methods} 
                        dishes_list={dishes.dishes_list} 
                        key={dish.id}
                        submenuId={submenuId}
                        contextClass={s.context}
                        sortable={sortable}
                    />
                }) : null
            }
        </div>
}