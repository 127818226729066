import React, { useState, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
// ui
import {
    Form,
    Modal
} from "@ui-kit";
// utils
import {
    updateRules,
    errorProcessing,
    serviceProcessing,
    setAsyncTimeout
} from "@utils"
// components
import {
    LanguagesRow
} from "@components";
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";

function reducer(form, action) {
    const { tag, average_check, service_percent, types, kitchen, types_list, kitchen_list, translations, language_list, rules: {title, description, ...rules} } = action.payload;    
    switch (action.type) {
        case "INIT_CREATE":
            let formCreate = {
                ...form,
                submit: action.t("buttons.create"),
                callback: action.callBackCreate
            };
            formCreate.columns[1].fields[0].options = types_list; 
            formCreate.columns[1].fields[1].options = kitchen_list;
            formCreate.columns[2].fields[0].rules = {
                titleRule: title, 
                descriptionRule: description
            };
            formCreate.columns[2].fields[0].translations = translations;
            formCreate.columns[2].fields[0].columns[0].fields[0].options = language_list;
            formCreate.columns[2].fields[0].columns[0].fields[0].defaultValue = language_list.find(el => el.main);
            formCreate.columns[2].fields[0].language_list = language_list;

            return updateRules(formCreate, rules);
        case "INIT_UPDATE":
            let formUpdate = {
                ...form,
                submit: action.t("buttons.save"),
                callback: action.callBackUpdate
            };
            formUpdate.columns[0].fields[0].defaultValue = tag;
            formUpdate.columns[0].fields[0].readOnly = true;
            formUpdate.columns[0].fields[1].defaultValue = average_check;
            formUpdate.columns[0].fields[2].defaultValue = service_percent;
            formUpdate.columns[1].fields[0].options = types_list;
            formUpdate.columns[1].fields[0].defaultValue = types;
            formUpdate.columns[1].fields[1].options = kitchen_list;
            formUpdate.columns[1].fields[1].defaultValue = kitchen;
            // update translates
            formUpdate.columns[2].fields[0].translations = translations;
            // select
            formUpdate.columns[2].fields[0].columns[0].fields[0].options = language_list;
            formUpdate.columns[2].fields[0].columns[0].fields[0].defaultValue = language_list[0];
            // title
            formUpdate.columns[2].fields[0].columns[0].fields[1].defaultValue = translations[0].title;
            // description
            formUpdate.columns[2].fields[0].columns[0].fields[2].defaultValue = translations[0].description;

            formUpdate.columns[2].fields[0].language_list = language_list;
            formUpdate.columns[2].fields[0].rules = {
                titleRule: title, 
                descriptionRule: description
            };
            
            return updateRules(formUpdate, rules);
        default:
            return form
    }
}

export default function ManageRestaurantWrapper({children, id, updateShort, updateStatus}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    // close modal
    function closeModal() {
        setOpen(false);
    }
    function openModal() {
        setOpen(true);
    }
    // CONTEXT
    const {
        getMyRestaurant,
        createRestaurant,
        updateRestaurant,
    } = useContext(managerMethodsContext);
    const {
        notify,
        notifyList
    } = useContext(notificationsContext);
    const { updateTag } = useContext(globalVariablesContext);
    const [ loading, setLoading ] = useState(true);
    // const defaultLanguage = localStorage.getItem("i18nextLng");
    const initialForm = {
        type: "restaurant_info",
        submit: t("buttons.create"),
        callback: callBackCreate,
        columns: [
            {
                size: 6,
                fields: [
                    {
                        type: "text",
                        defaultValue: "",
                        name: "tag",
                        description: t("fields.info.tag_description", {support: "support@cheflist.org"}),
                        label: t("fields.info.tag"),
                        autoFocus: "autofocus",
                        autoComplete: "off",
                    },
                    {
                        type: "mask",
                        mask: "9{*}[.9{0,2}]",
                        defaultValue: "",
                        name: "average_check",
                        description: null,
                        label: t("fields.info.average_check"),
                        autoComplete: "off",
                        inputMode: "numeric",
                    },
                    {
                        type: "mask",
                        mask: "9{*}",
                        defaultValue: "",
                        name: "service_percent",
                        label: t("fields.info.service_percent"),
                        autoComplete: "off",
                        inputMode: "numeric",
                        maxLength: 2
                    },
                    
                ]
            },
            {
                size: 6,
                fields: [
                    {
                        type: "select",
                        defaultValue: "",
                        name: "types",
                        description: null,
                        label: t("fields.info.types"),
                        options: [],
                        isMulti: true,
                        isClearable: true,
                        isSearchable: true,
                        removeSelected: false,
                    },
                    {
                        type: "select",
                        defaultValue: "",
                        name: "kitchen",
                        description: null,
                        label: t("fields.info.kitchen"),
                        options: [],
                        isMulti: true,
                        isClearable: true,
                        isSearchable: true,
                        removeSelected: false,
                    }
                ]
            },
            {
                size: 12,
                fields: [
                    {
                        type: "wrapper",
                        Wrapper: (props) => <LanguagesRow {...props}/>,
                        columns: [{
                            fields: [
                                {
                                    type: "select",
                                    options: [],
                                    isSearchable: true,
                                    defaultValue: null,
                                    name: "language",
                                    label: t("fields.translations.select_language")
                                },
                                {
                                    type: "text",
                                    defaultValue: "",
                                    name: "title",
                                    label: t("fields.translations.title")
                                },
                                {
                                    type: "textarea",
                                    defaultValue: "",
                                    name: "description",
                                    label: t("fields.translations.description"),
                                }
                            ],
                        }],
                        translations: [],
                        language_list: [],
                        rules: null
                    }
                ]
            },
        ]
    }

    function _transformData({language, title, description, kitchen, types, ...data}) {
        return {
            ...data,
            kitchen: kitchen.map(el => el.value),
            types: types.map(el => el.value),
        };
    } 

    // call back on create new restaurant
    function callBackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createRestaurant(_transformData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                let { status, restaurant } = data;
                resolve(res);
                updateStatus(status);
                updateTag(restaurant.tag)
                updateShort(restaurant);
                setOpen(false);
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    // call back on update current restaurant
    function callBackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateRestaurant(_transformData(data), id).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                setOpen(false);
                updateShort(data);
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false));
    }
    
    const [form, dispatch] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        let transitionPromise = async (cb) => await setAsyncTimeout(cb, 500);
        let succesFunc = () => {};

        if (isMounted && isOpen) {
            getMyRestaurant()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (data.tag) {
                            succesFunc = () => dispatch({type: "INIT_UPDATE", payload: data, callBackUpdate, t})
                        } else {
                            succesFunc = () => dispatch({type: "INIT_CREATE", payload: data, callBackCreate, t})
                        }
                    })
                }).catch(error => {
                    errorProcessing(error, notify, "detail_data");
                }).finally(() => {
                    transitionPromise(() => { 
                        setLoading(false)
                        succesFunc();
                    })
                });
        }

        return () => {
            isMounted = false;
            setLoading(true);
            transitionPromise = undefined;
            succesFunc = () => {};
        };
    }, [isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: openModal
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_info")}
                description={t("manage.form.description_info")}
                loading={loading}
            >
                <Form form={form} />
            </Modal>
        </>
    )
}