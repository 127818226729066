import React, { useContext, useEffect, useState } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
// context
import { globalVariablesContext } from "@context"
// styles
import s from "./Pagination.module.sass"

const edges = 2;
function pagEdges(n) {
    return {
        edges: n,
        center: 1 + edges*2
    }
}

export default function Pagination({page, count, link}) {
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext);
    page = page - 0; // convert to number

    const [ pagination, setPagination ] = useState(pagEdges(edges));

    function onResize() {
        let width = document.body.clientWidth;
        if (width >= 768) {
            setPagination(pagEdges(edges))
        } else {
            setPagination(pagEdges(edges/2))
        }
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            onResize();
            window.addEventListener("resize", onResize);
        }
        return () => {
            isMounted = false;
            window.removeEventListener("resize", onResize);
        }
    }, [])
    
    return (
        count-1 ? <div className={s.pagination} style={{
            "--accent-color": `#${accent}`,
            "--second-color": `#${second}`,
        }}>
            <PaginationArraow 
                direction="prev" 
                link={page > 1 ? `${link}/${page - 1}` : `${link}/${page}`}
                disabled={page <= 1}
            />
            <PaginationList page={page} count={count} link={link} pagination={pagination}/>
            <PaginationArraow 
                direction="next" 
                link={page < count ? `${link}/${page + 1}` : `${link}/${count}`}
                disabled={page >= count}
            />
        </div> : null
    )
}

const PaginationList = ({count, link, page, pagination: {center, edges}}) => {
    let array = [...Array(count).keys()];
    let prevSkip = false;
    let nextSkip = false;
    // const araoundCenter = (center - 1)/2;

    return (
        <div className={s.list}>
            {
                array.map(el => {
                    let value = el + 1 // pages start from 1, array start from 0
                    if (center < count) {
                        if (value <= edges || value > (count - edges)) {
                            return <PaginationItem 
                                        active={value === page-0}
                                        link={`${link}/${value}`}
                                        val={value}
                                        key={value}
                                    />
                        } else if((value >= page - edges) && (value <= page + edges)) {
                            return <PaginationItem 
                                        active={value === page-0}
                                        link={`${link}/${value}`}
                                        val={value}
                                        key={value}
                                    />
                        } else {
                            if (!prevSkip && value < page && (page - edges - edges) >= edges ) {
                                prevSkip = true;
                                return <span key="skip-prev" className={classNames(s.skip, "mx-2")}>...</span>
                            }
                            
                            if (!nextSkip && value > page && (page + edges + edges) < count) {
                                nextSkip = true;
                                return <span key="skip-next" className={classNames(s.skip, "mx-2")}>...</span>
                            }
                            
                            return null;
                        }
                    } else {
                        return <PaginationItem 
                            active={value === page-0}
                            link={`${link}/${value}`}
                            val={value}
                            key={value}
                        />
                    }
                })
            }
        </div>
    )
}

const PaginationItem = ({val, active, link}) => {
    return (
        <Link 
            to={link} 
            className={classNames(s.link, {
                [s.active]: active
            })}
        >
            {val}
        </Link>
    )
}

const PaginationArraow = ({direction, link, disabled}) => {
    return (
        <Link 
            to={link} 
            className={classNames(s.arrow, {
                [s.disabled]: disabled,
                "mr-1 mr-sm-3": direction === "prev",
                "ml-1 ml-sm-3": direction === "next",
            })}
        >
            {direction === "prev" ? "<" : direction === "next" ? ">" : null}
        </Link>
    )
}