import React from "react";
import classNames from "classnames";
import { motion} from "framer-motion";
// styles
import s from "./icon.module.sass";

const CheckboxSwitch = ({switchVariants, switchWrapperVariants, duration, inactive}) => {
    return (
        <motion.div
            className={classNames(s.switch, {
                [s.inactive]: inactive
            })}
            variants={switchWrapperVariants}
            transition={{
                duration
            }}
        >
            <motion.div 
                className={s.toggler}
                variants={switchVariants}
                transition={{
                    duration
                }}
            ></motion.div>
        </motion.div>
    )
}

export default CheckboxSwitch;