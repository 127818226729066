import React, { forwardRef } from "react";
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbar = forwardRef(({children, ...options}, forwardedRef) => {
    return (
        <Scrollbars
            autoHide={true}
            autoHideTimeout={600}
            ref={forwardedRef}
            {...options}
        >
            {children}
        </Scrollbars>
    )
});

export default CustomScrollbar;