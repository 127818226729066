import React from "react";
// styles
import {
    ColumnsRenderer
} from "@ui-kit"

export default function DeliveryRow(props) {
    const {
        type,
        columns,
        ...form
    } = props

    return (
        columns.map((column, index) => {
            return <ColumnsRenderer {...column} {...form} key={index}/>
        })
    )
}