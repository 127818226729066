import React from "react";
import { motion } from "framer-motion"
import classNames from "classnames";
// components
import { 
    CustomizationItem,
    SimpleAppearAnimation
} from "@components";
// ui
import { Title } from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";

export default function ManageCustomization(props) {
    const {model: {accent, second, animationCount}, title, onClick} = props;
    return (
        <SimpleAppearAnimation index={animationCount}>
            <motion.div className={classNames(s.item, s.clickable)} onClick={onClick}>
                <div className={s.body}>
                    <Title type="block">{title}</Title>
                    <CustomizationItem accent={accent} second={second}/>
                </div>
            </motion.div>
        </SimpleAppearAnimation>
    )
}