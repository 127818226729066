import React, { useContext } from "react";
import classNames from "classnames";
import {
    globalVariablesContext
} from "@context"
// styles
import s from "./Button.module.sass";
// svg
import { ReactComponent as Copy } from "@svg/copy.svg";
import { ReactComponent as Trash } from "@svg/trash.svg";
import { ReactComponent as Clear } from "@svg/modal-close.svg";

export default function Button({children, type = "submit", onClick = () => {}, className = "", ...props}) {
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext);
    
    if (type === "copy") {
        return (
            <button type="button" onClick={onClick} className={s.copy} {...props}>
                <Copy className={s.svg}/>
            </button>
        )
    }
    if (type === "remove") {
        return (
            <button type="button" onClick={onClick} className={s.trash} {...props} tabIndex="-1">
                <Trash className={s.svg}/>
            </button>
        )
    }
    if (type === "clear") {
        return (
            <button type="button" onClick={onClick} className={s.clear} {...props}>
                <Clear className={s.svg}/>
            </button>
        )
    }
    if (type === "tag") {
        return <button type="button" onClick={onClick} className={classNames(s.tag, s.button)} {...props}>{children}</button>
    }
    if (type === "controller") {
        return <button type="button" onClick={onClick} className={classNames(s.button, s.controller, {
            [s.minus]: className.includes("minus"),
            [s.plus]: className.includes("plus"),
            [s.disabled]: className.includes("disabled"),
        })} {...props}></button>
    }
    if (type === "custom-submit") {
        return <CustomButton
            className={className}
            onClick={onClick}
            accent={accent}
            second={second}
            type="submit"
        >
            {children}
        </CustomButton>
    }
    if (type === "custom-button") {
        return <CustomButton
            className={className}
            onClick={onClick}
            accent={accent}
            second={second}
            type="button"
            isSpan={className.includes("load-video")}
        >
            {children}
        </CustomButton>
    }
    if (type === "icon") {
        return children ? <button 
            type="button" 
            onClick={onClick} className={classNames(s.icon, {
                [s.clear]: className.includes("clear"),
                [s.trash]: className.includes("trash"),
                [s.video]: className.includes("video"),
                [s.videoControl]: className.includes("video-control"),
                "mb-3": className.includes("mb-3"),
            })} {...props}>
            {
                React.cloneElement(children, {
                    className: classNames({
                        [s.rotate]: className.includes("rotate"),
                        [s.svgMd]: className.includes("icon-md"),
                        [s.svg]: !className.includes("icon-md")
                    })
                })
            }
        </button> : null
    }
    if (type === "button-text") {
        return <button 
            type="button" 
            className={s.text} 
            onClick={onClick}
            style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`,
            }}
        >{children}</button>
    }
    return (
        <button 
            className={classNames(s.button, s.default, className, {
                [s.blue]: className.includes("blue"),
                [s.gray]: className.includes("gray"),
                [s.login]: className.includes("login"),
                [s.empty]: className.includes("empty"),
                [s.save]: className.includes("save"),
                [s.large]: className.includes("large"),
                [s.loadVideo]: className.includes("load-video")
            })}
            type={type}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

function CustomButton({className, onClick, accent, second, children, type, isSpan}) {
    return isSpan ? 
        <span 
            className={classNames(s.button, s.default, s.custom, className, {
                [s.loadVideo]: isSpan,
                [s.save]: className.includes("save"),
                [s.large]: className.includes("large"),
            })}
            style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`,
            }}
        >
            {children}
        </span> : 
        <button 
            className={classNames(s.button, s.default, s.custom, className, {
                [s.save]: className.includes("save"),
                [s.large]: className.includes("large"),
            })}
            type={type}
            onClick={onClick}
            style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`,
            }}
        >
            {children}
        </button>
}