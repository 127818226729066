import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
// import { motion, AnimatePresence } from "framer-motion";
// styles
import s from "./ContextMenu.module.sass";
// icons
import icons from "./icons";

export default function ContextMenu({list, className="", withToggler = true, isHoverable = true}) {
    const [ showMenu, setMenu ] = useState(false); 
    const [ isDesktop, setDesktop ] = useState(true);
    function onResize() {
        let width = document.body.clientWidth;
        if (width >= 768) {
            setDesktop(true)
        } else {
            setDesktop(false)
        }
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            onResize();
            window.addEventListener("resize", onResize);
        }
        return () => {
            isMounted = false;
            window.removeEventListener("resize", onResize);
        }
    }, [])

    const onClickToggler = useCallback((e) => {
        e.stopPropagation();
        if (isHoverable && !isDesktop) {
            setMenu(!showMenu);
        }
    }, [isDesktop, isHoverable, showMenu])

    return (
        <div 
            className={classNames(s.contextMenu, className, {
                [s.invisible]: !withToggler,
                [s.transparent]: className.includes("transparent")
            })} 
            onMouseEnter={() => {
                if (isHoverable && isDesktop) {
                    setMenu(true);
                }
            }}
            onMouseLeave={() => {
                if (withToggler) {
                    setMenu(false);
                }
            }}
        >
            { withToggler ? <div className={s.toggler} onClick={onClickToggler}></div> : null }
            {
                showMenu || !withToggler ? <div className={classNames(s.menu, {
                    [s.visible]: !withToggler
                })}>
                    {
                        list.map(({type, label, onClick, download}, index) => {
                            if (type ==="delimiter") {
                                return <div className={classNames(s.delimiter, "my-2")} key={index}></div>;
                            } else if (type ==="download") {
                                return <div className={s.item} key={index}>
                                    { icons[type] ? <span className={s.icon}>{icons[type]}</span> : null}
                                    <a href={download} download={download.match(/([^\/]+)\/?$/)[0]} rel="noreferrer" target="_blank">{label}</a>
                                </div>
                            } else {
                                return <div className={classNames(s.item, {
                                    [s.red]: type === "delete"
                                })} key={index} onClick={(e) => {
                                    if (withToggler) {
                                        e.stopPropagation();
                                        setMenu(false);
                                    }
                                    return onClick(e);
                                }}>
                                    { icons[type] ? <span className={s.icon}>{icons[type]}</span> : null}
                                    <span>{label}</span>
                                </div>
                            }
                        })
                    }
                </div> : null
            }
        </div>
    )
}