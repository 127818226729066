import React from "react";
import classNames from "classnames";
// style
import s from "./CustomizationItem.module.sass";
// icons
import { ReactComponent as Check } from "@svg/check-filled.svg";

export default function CustomizationItem({accent = "FFA643", second = "EB6B1B", active = false, onClick = () => {}}) {
    return (
        <div className={classNames(s.block, {
            [s.active]: active
        })} onClick={onClick}>
            <div className={s.outline} style={{backgroundColor: `#${accent}`}}></div>
            <div className={s.accent} style={{backgroundColor: `#${accent}`}}>
                <div className={s.second} style={{backgroundColor: `#${second}`}}></div>
            </div>
            <div className={s.check}><Check className={s.checkSVG}/></div>
        </div>
    )
}