const ROUTES = {
    login: "/login",
    register: "/register",
    manage: "/manage",
    admin: "/admin",
    administration: "/administration",
    waiter: "/administration/waiter",
    feedback: "/administration/feedback",
    menu: "/menu",
    submenu: "/submenu",
    news: "/news",
    push: "/push",
    orders: "/orders",
    cards: "/cards",
    subscribe: "/subscribe",
    updates: "/update-log",
    faq: "/service/faq",
    not_found: "/404",
    market: "/market",
    settings: "/settings",
    // achievements: "/achievements",
    dashboard: "/",
    homepage: "/"
}

export default ROUTES;