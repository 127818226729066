import React, { useContext } from "react"
import classNames from "classnames"
import { setThousandthDivision } from "@utils"
import s from "./ProductLine.module.sass"
import { globalVariablesContext } from "@context"

export default function ProductLine({count, title, price, className=""}) {
    const { variables: {currency} } = useContext(globalVariablesContext);
    return (
        <div className={classNames(s.dish, className, "d-flex align-items-center")}>
            <div className="col pl-0 pr-2 d-flex align-items-center">
                <span className={s.count}>{count}x</span>
                <p className={s.title}>{title}</p>
            </div>
            <div className="col-auto pl-0 pr-0">
                {setThousandthDivision(price.toFixed(2))} {currency}
            </div>
        </div>
    )
}