import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
// components
// style
import s from "./ManageBlock.module.sass";
// ui
import { Title, Description } from "@ui-kit";
import { globalVariablesContext  } from "@context";

export default function ManageAddBlock({
    title,
    description,
    Icon, 
    onClick, 
    className = "", 
    status = null, 
    count = null,
}) {
    const { t } = useTranslation();
    const { variables: { customization: { second } } } = useContext(globalVariablesContext)
    
    return (
        <div className={classNames(s.item, s.addItem, s.clickable, {
            [s.flexBottom]: className.includes("flex-bottom"),
            [s.short]: className.includes("short"),
            [s.square]: !className || !className.includes("short"),
            [s.iconRight]: className.includes("icon-right"),
            [s.marketItem]: className.includes("market-item"),
        })} onClick={(e) => {
            if (typeof onClick === "function") {
                e.stopPropagation(); onClick(e)}
            }
        }
            style={{backgroundColor: `#${second}`}}
        >
            {/* {
                count !== null ? (<div className={s.counterHolder}><div className={classNames(s.counterMark, "notranslate")}>{count}</div></div>) : null
            } */}
            <div className={s.body}>
                <div className={s.icon}><Icon className={s.iconSvg}/></div>
                <div className={s.addContent}>
                    <Title className={s.titleAddDish}>{title}</Title>
                    { description ? <Description text={description} className="block-add width-limitless"/> : null }
                </div>
                <div className="d-flex">
                    {
                        status !== null ? <div className={classNames(s.labelMark, "mr-2")}>{status ? t("buttons.enabled") : t("buttons.disabled")}</div> : null
                    }
                    {
                        count !== null ? ( <div className={classNames(s.labelMark, "mr-2")}>{count}</div>) : null
                    }
                </div>
            </div>
        </div>
    )
}