import React, { useReducer, useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
    ManageBlock,
    SettingsProfileWrapper,
    SettingsPasswordWrapper,
} from "@components"
import { globalVariablesContext } from "@context"
import { formatMaskedData } from "@utils"

function reducer(profile, action) {
    switch (action.type) {
        case "INIT":
            return {
                list: [
                    {
                        title: action.text.name,
                        value: action.profile.name
                    },
                    {
                        title: action.text.phone,
                        value: action.profile.phone
                    },
                    {
                        title: action.text.email,
                        value: action.profile.email
                    },
                    {
                        title: action.text.language,
                        value: action.profile.language
                    }
                ],
                animationCount: profile.animationCount === undefined ? 0 : profile.animationCount + 1,
                visible: true
            }
        default:
            return profile
    }
}

export default function ManagerSettings({profile, rules}) {
    const { t, i18n } = useTranslation();
    const [ prof, setProf ] = useState(null);
    const { variables: { phoneMask } } = useContext(globalVariablesContext);

    function _transformData(profile) {
        return {
            ...profile,
            phone: formatMaskedData(profile.phone, phoneMask),
        }
    }
    
    useEffect(() => {
        let isMouted = true;
        if (isMouted && profile && phoneMask) {
            setProf(_transformData(profile));
        }
        return () => isMouted = false;
    }, [profile, phoneMask])

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (prof || (prof && (i18n.language !== prof.language))) {
                dispatch({
                    type: "INIT", 
                    profile: prof, 
                    text: {
                        name: t("settings.person"),
                        phone: t("settings.phone"),
                        email: t("settings.email"),
                        language: t("settings.language")
                    }
                })
            }
        }
        return () => isMounted = false;
    }, [prof, i18n.language, t])
    
    const [ settings, dispatch ] = useReducer(reducer, [])
    
    return (
        settings.visible ? <div className="row">
                <div className="col-md-auto">
                    <SettingsProfileWrapper onUpdate={(prof) => setProf(_transformData(prof))}>
                        <ManageBlock 
                            title={t("settings.personal_data")} 
                            list={settings.list} 
                            animationCount={settings.animationCount}
                        />
                    </SettingsProfileWrapper>
                </div>
                <div className="col-md-auto">
                    <SettingsPasswordWrapper rules={rules}>
                        <ManageBlock title={t("settings.password")}/>
                    </SettingsPasswordWrapper>
                </div>
            </div>
        : null
        
    )
}