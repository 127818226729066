import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// ui-kit
import { 
    // Title,
    Field,
} from "@ui-kit";
// components
// import Preview from "./Preview";
import { CustomizationItem } from "@components";

export default function CustomizationRow({field, color_list, theme, instance, type, onColorChange, clearErrors, setError, watch, use_in_console, ...form}) {
    // const { t } = useTranslation();
    const [ active, setActive ] = useState(theme);
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setActive(theme);
        }
        return () => { isMounted = false }
    }, [theme])
    return (
        <div className="col-12">
            <div className="row">
                {/* <div className="col-md-5">
                    <Title>{t("manage.customization.example")}:</Title>
                    <Preview theme={theme} instance={instance}/>
                </div> */}
                <div className="col-md-12">
                    <div className="row align-content-center mx-0 mb-4">
                        {
                            color_list.map(color => {
                                return <CustomizationItem
                                    key={color.id}
                                    active={active.id === color.id}
                                    onClick={() => {
                                        setActive(color);
                                        onColorChange(color.id);
                                    }}
                                    {...color}
                                />
                            })
                        }
                        <Field {...field} {...form} />
                    </div>
                </div>
                <Field {...use_in_console} {...form} />
            </div>
        </div>
    )
}