import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// styles
import s from "./Title.module.sass"
// icons
import { ReactComponent as Back} from "@svg/back.svg";
import { globalVariablesContext } from "@context";

const Title = forwardRef(({children, type, className = "", back, ...props}, forwardedRef) => {
    const contextValue = useContext(globalVariablesContext);
    const [ color, setColor ] = useState()
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted && contextValue) {
            let { variables: { customization: { second } } } = contextValue;
            setColor(second)
        }
        return () => isMounted = false;
    }, [contextValue])
    
    if (type === "page")
        return (
            <h1 className={classNames(s.pageTitle, className, "d-flex align-items-center")} {...props} ref={forwardedRef}>
                {back ? <Link to={back} className="mr-3">
                    <Back className={s.back} style={{
                        fill: `#${color}`
                    }}/>
                </Link> : null}
                {children}
            </h1>
        )
    if (type === "block")
        return <h2 className={classNames(s.blockTitle, className)} {...props} ref={forwardedRef}>{children}</h2>
    if (type === "modal")
        return <h2 className={classNames(s.modalTitle, className)} {...props} ref={forwardedRef}>{children}</h2>
    if (type === "section")
        return <h3 className={classNames(s.sectionTitle, className)} {...props} ref={forwardedRef}>{children}</h3>
    if (type === "notify")
        return <h4 className={classNames(s.notifyTitle, className)} {...props} ref={forwardedRef}>{children}</h4>
    return <h4 className={classNames(s.defaultTitle, className)} {...props} ref={forwardedRef}>{children}</h4>
})

export default Title;