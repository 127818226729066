import React, { useRef, useEffect, forwardRef } from "react";
import classNames from "classnames";
// styles
import s from "./Fields.module.sass";
// ui
import {
    getByPath,
    useCombinedRefs
} from "@utils"

const HiddenField = ({label, description, refRegister, errors, rules = [], wrapperClass = "col-md-12", refs, initialType = "text", setValue, defaultValue, ...props}) => {
    const combinedRef = useCombinedRefs(useRef, useEffect, refRegister({ ...rules }), refs);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setValue(props.name, defaultValue)
        }
        return () => isMounted = false;
    }, [defaultValue])
    
    return (
        <div className={wrapperClass}>
            <input 
                ref={combinedRef} 
                type={initialType}
                {...props}
                defaultValue={defaultValue}
                style={{display: "none"}}
            />
            { getByPath(errors, props.name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${props.name}.message`)}</p> }
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
}

export default HiddenField;