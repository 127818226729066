import React from "react"
import { useRouteMatch } from "react-router";
// styles
import s from "./PagelessWrapper.module.sass";
// image
import src from "@images/login-bg.jpg";
// routes
import ROUTES from "@routes"

export default function PagelessWrapper({ children }) {
    let isLoginPage = useRouteMatch(ROUTES.login);
    let isRegisterPage = useRouteMatch(ROUTES.register);

    return <section className={s.wrapper}>
                {( isLoginPage || isRegisterPage ) ? <div className={s.bg} style={{backgroundImage: `url(${src})`}}></div> : null}
                { children }
            </section> 
}