import React, { useEffect, useState, forwardRef, useRef } from "react";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
// styles
import s from "./Fields.module.sass";
// icons
import {
    CheckboxIcon,
    CheckboxSwitch
} from "../Icons";
import {
    useCombinedRefs
} from "@utils"
import { ReactComponent as Cloudpayments } from "@svg/cloud-payments.svg"

const tickVariants = {
    pressed: (isChecked) => ({ pathLength: isChecked ? 0.85 : 0.2 }),
    checked: { pathLength: 1 },
    unchecked: { pathLength: 0 }
};

const boxVariants = {
    hover: { scale: 1.05, strokeWidth: 55, stroke: "#0780E3" },
    pressed: { scale: 0.95, strokeWidth: 35 },
    checked: { stroke: "#0780E3" },
    unchecked: { stroke: "#5AC8FA", strokeWidth: 50 }
};

const switchVariants = {
    pressed: { width: 30 },
    checked: { 
        left: "100%", 
        transform: "translateX(-100%)",
    },
    unchecked: { 
        left: 0, 
        transform: "translateX(0%)",
    }
}

const switchWrapperVariants ={
    checked: { backgroundColor: "#34C759" },
    unchecked: { backgroundColor: "#e03838" },
}

const CheckboxField = forwardRef(({
    inactive = false, 
    label, 
    negativeLabel, 
    defaultChecked, 
    onChange, 
    wrapperClass = "col-md-12", 
    checkboxClass = "", 
    errors, 
    mode = "checkbox", 
    description, 
    watch,
    ...props
}, forwardedRef) => {
    const [checked, setChecked] = useState(defaultChecked);
    const [ dur, setDur ] = useState(0);
    const val = watch ? watch(props.name) : defaultChecked;

    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs(useRef, useEffect, forwardedRef, innerRef);

    const setCheckedInput = checked => {
        if (innerRef.current.checked !== checked) {
            innerRef.current.click();
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted && defaultChecked !== undefined) {
            setTimeout(() => {
                setDur(0.2);
            }, 200)
            setCheckedInput(defaultChecked);
        }
        return () => isMounted = false;
    }, [defaultChecked])
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setCheckedInput(checked);
            if (onChange) {
                onChange(checked);
            }
        }
        return () => isMounted = false;
    }, [checked, onChange]);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (val !== undefined) {
                setChecked(val)
            }
        }
        return () => isMounted = false;
    }, [val])
    
    let dynamicLabel = negativeLabel ? (checked ? label : negativeLabel) : label;
    
    return (
        <div className={wrapperClass}>
            <div className={classNames(s.checkbox, {
                "mb-3": mode === "switch" && !checkboxClass,
                [checkboxClass]: checkboxClass,
            })}>
                <AnimatePresence initial={false}>
                    <motion.div 
                        className={classNames(s.checkboxLabel, {
                            [s.switchLabel]: mode === "switch",
                            [s.inactive]: inactive
                        })}
                        whileHover="hover"
                        whileTap="pressed"
                        initial={defaultChecked ? "checked" : "unchecked"}
                        animate={checked ? "checked" : "unchecked"}
                        onClick={(e) => {
                            e.stopPropagation(); 
                            setChecked(!checked)}
                        }
                    >
                        <input 
                            className={s.checkboxInput}
                            type="checkbox"
                            ref={combinedRef}
                            onChange={e => {
                                setChecked(e.target.checked);
                            }}
                            {...props}
                        />
                        { 
                            mode === "checkbox" ? 
                            <motion.span className={s.checkboxIcon}>
                                <CheckboxIcon 
                                    tickVariants={tickVariants} 
                                    boxVariants={boxVariants} 
                                    isChecked={checked} 
                                />
                            </motion.span>
                            : null
                        }
                        {
                            mode === "switch" ?
                            <motion.div className={s.switchIcon}>
                                <CheckboxSwitch 
                                    switchVariants={switchVariants} 
                                    switchWrapperVariants={switchWrapperVariants} 
                                    inactive={inactive}
                                    duration={dur}
                                />
                            </motion.div>
                            : null
                        }
                        <span>{dynamicLabel} { props.name === "settings[is_pay_card]" ? <Cloudpayments className="ml-2"/> : null }</span>
                    </motion.div>
                </AnimatePresence>
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
})

export default CheckboxField;