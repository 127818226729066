import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
// components
import {
    Breadcrumbs,
    ProductLine
} from "@components"
// ui-kit
import {
    Title,
} from "@ui-kit"
import {
    setThousandthDivision,
    formatMaskedData
} from "@utils"
import ROUTES from "@routes";
import s from "./OrderBill.module.sass"
import { globalVariablesContext } from "@context";
import classNames from "classnames";

export default function OrderBill({
    model: {
        date_create, 
        orders, 
        summa = "00.00", // comes with stroke 
        discount_percent = 0, 
        discount_sum = 0, 
        service_percent = 0, 
        service_sum = 0, 
        result = 0,
        user,
        comment,
        transaction = null,
        type,
        // selfpickup
        phone,
        pay_type,
        date_self_pickup,
        // delivery
        delivery_price,
        user_address,
        free_delivery,
        // preorder
        date_pre_order,
    }
}) {
    const { t } = useTranslation();
    const { variables: { currency, phoneMask } } = useContext(globalVariablesContext);
    
    return (
        <div className={"my-5"}>
            <div className="row">
                <div className="col-md-12 mb-4">
                    <Title>{t("orders.bill.dish_list")}</Title>
                    {
                        (orders && orders.length) ? (
                            orders.map(submenu => {
                                const { id, title, dishes } = submenu;
                                let newList = [
                                    // ...list,
                                    {
                                        title: title,
                                        link: `${ROUTES.submenu}/${id}`
                                    }
                                ]
                                return (
                                    <div className="mb-4" key={id}>
                                        <Breadcrumbs list={newList} noGap={true}/>
                                        {
                                            dishes.map(dish => {
                                                return (
                                                    <ProductLine key={dish.id} {...dish}/>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        ) : null
                    }
                </div>
                <div className="col-md-12">
                    <Title>{type || t("orders.bill.info")}</Title>
                    <div className="mb-4">
                        <InfoRow title={t("orders.bill.date_created")} value={date_create} />
                        <InfoRow title={t("orders.bill.pay_type")} value={pay_type} />
                        <InfoRow title={t("orders.bill.date_preorder")} value={date_pre_order} />
                        <InfoRow title={t("orders.bill.date_self_pickup")} value={date_self_pickup} />
                        <InfoRow title={t("orders.bill.user")} value={user} />
                        <InfoRow title={t("orders.bill.phone")} value={formatMaskedData(phone, phoneMask)} />
                        <InfoRow title={t("orders.bill.user_address")} value={user_address} />
                    </div>
                    { comment ? 
                        <div className="mb-4">
                            <InfoRow title={t("orders.bill.comment")} value={comment} />
                        </div>
                        : null
                    }
                    <div>
                        <span className={s.gray}>{t("orders.bill.summ")} </span>
                        <span>{/*setThousandthDivision(summa.toFixed(2))*/summa} {currency}</span>
                    </div>
                    {
                        delivery_price ? <div>
                            <span className={s.gray}>{t("orders.bill.delivery_price")} </span>
                            <span className={classNames({
                                [s.strike]: free_delivery
                            })}>{setThousandthDivision(delivery_price.toFixed(2))} {currency}</span>
                        </div> : null
                    }
                    <div>
                        <span className={s.gray}>{t("orders.bill.discount", {discount_percent})} </span>
                        <span>{setThousandthDivision(discount_sum.toFixed(2))} {currency}</span>
                    </div>
                    <div>
                        <span className={s.gray}>{t("orders.bill.service", {service_percent})} </span>
                        <span>{setThousandthDivision(service_sum.toFixed(2))} {currency}</span>
                    </div>
                    <div>
                        <span className={s.gray}>{t("orders.bill.result")} </span>
                        <span>{setThousandthDivision(result.toFixed(2))} {currency}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function InfoRow({title, value}) {
    return value ? <div className="pb-1">
        <span className={s.gray}>{title} </span>
        <span>{value}</span>
    </div> : null
}