import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
// instance preview
import Preview from "./Preview";
// ui
import {
    Field,
    Button
} from "@ui-kit"
// utils
import {
    arrayMove,
    sliceStringPattern,
    // deleteItemFromList
} from "@utils"
// context
import { globalVariablesContext } from "@context"

const typeURL = 0;
const typeWhatsApp = 1;
const typeEmail = 2;
const typePhone = 3;
const typeTelegram = 4;

function enchantedRules(rule, rules) {
    let newRules = {};
    if (rules.value && rules.value.rules) {
        newRules = {
            ...rules.value.rules,
            ...rule,
        }
    }
    if (rule && rule.pattern) {
        newRules = {
            ...newRules,
            pattern: {
                value: sliceStringPattern(rule.pattern.value),
                message: rule.pattern.message
            }
        }
    }
    return newRules;
}

function changeValueType(fields, type, phoneMask, text) {
    if (type === typeURL) {
        fields.value.type = "url";
        fields.value.initialType = "url";
        fields.value.inputMode = "url";
        fields.value.label = text.link;
        fields.title.label = text.text_link;
    }
    if (type === typeWhatsApp) {
        fields.value.type = "mask";
        fields.value.mask = phoneMask;
        fields.value.initialType = "tel";
        fields.value.inputMode = "tel";
        fields.value.label = text.whatsapp;
        fields.title.label = text.text_button;
    }
    if (type === typeEmail) {
        fields.value.type = "email";
        fields.value.initialType = "email";
        fields.value.inputMode = "email";
        fields.value.label = text.email;
        fields.title.label = text.text_button;
    }
    if (type === typePhone) {
        fields.value.type = "mask";
        fields.value.mask = phoneMask;
        fields.value.initialType = "tel";
        fields.value.inputMode = "tel";
        fields.value.label = text.phone;
        fields.title.label = text.text_button;
    }
    if (type === typeTelegram) {
        fields.value.type = "mask";
        fields.value.mask = "@[*|_]{*}";
        fields.value.initialType = "text";
        fields.value.inputMode = "text";
        fields.value.label = text.tg_username;
        fields.title.label = text.text_button;
    }
    return fields;
}

function reducer(fields, action) {
    switch (action.type) {
        case "INIT":
            let initForm ={ 
                ...fields,
                rules: action.rules
            }
            initForm.select.options = action.list_of_types;
            initForm.select.defaultValue = action.list_of_types[0];
            initForm.value.rules = enchantedRules(action.list_of_types[0].rules, action.rules);
            if (action.rules.title && action.rules.title.rules) {
                initForm.title.rules = {...action.rules.title.rules}
            }
            initForm.links = [...action.model];
            return initForm;
        case "SORT":
            let sortForm = {
                ...fields
            }
            sortForm.links = arrayMove(action.array, action.oldIndex, action.newIndex)
            return sortForm;
        case "CHECK":
            let checkForm = {...fields}
            checkForm.checked = action.index;
            checkForm.select.readOnly = true;
            checkForm.value.rules = enchantedRules(action.rules, checkForm.rules);
            return changeValueType(checkForm, action.selectType, action.phoneMask, action.text);
        case "UNCHECK":
            let uncheckForm = {...fields}
            uncheckForm.checked = null;
            uncheckForm.select.readOnly = false;
            uncheckForm.value.rules = enchantedRules(action.rules, uncheckForm.rules);
            return changeValueType(uncheckForm, action.selectType, action.phoneMask, action.text);
        case "REMOVE":
            return {
                ...fields,
                links: [
                    ...fields.links.slice(0, action.index),
                    ...fields.links.slice(action.index+1),
                ]
            };
        case "UPDATE":
            let updateFields = {
                ...fields
            }

            if (!isNaN(fields.checked) && fields.checked === null) {
                updateFields.links = [
                    ...fields.links,
                    {
                        ...action.payload,
                        type: action.payload.type.value
                    }
                ]
            } else {
                updateFields.links = [
                    ...fields.links.slice(0, fields.checked),
                    {
                        ...action.payload,
                        type: action.payload.type.value,
                        id: fields.links[fields.checked] ? fields.links[fields.checked].id : null
                    },
                    ...fields.links.slice(fields.checked+1),
                ]
            }
            // change value type to url
            updateFields.checked = null;
            updateFields.select.readOnly = false;
            updateFields.value.type = "url"
            updateFields.value.inputMode = "url";
            updateFields.value.label = action.text.link;
            updateFields.title.label = action.text.text_link;
            updateFields.value.rules = enchantedRules(updateFields.select.options[0].rules, updateFields.rules);
            return updateFields;
        case "CHANGE":
            let changeFields = {
                ...fields
            }
            changeFields.value.rules = enchantedRules(action.rules, changeFields.rules);
            return changeValueType(changeFields, action.selectType, action.phoneMask, action.text);
        case "ADD":
            return {
                ...fields,
                lins: {
                    ...fields.links,
                    ...action.payload
                }
            }
        default:
            return {...fields}
    }
}

export default function LinksRow({ link, list_of_types, model, instance, getValues, clearErrors, setError, watch, type, rules, mask, ...form }) {
    const { handleSubmit, 
        register: rnew, 
        errors: enew, 
        setValue: snew, 
        control: cnew,
    } = useForm();
    
    const { variables: { phoneMask } } = useContext(globalVariablesContext);
    const {t} = useTranslation();
    const [ focused, setFocus ] = useState(false);
    
    const Footer = useCallback(() => {
        return focused ? (
            <div className="row justify-content-between align-items-center">
                <div className="col-auto pr-0">
                    <Button type="button" className="gray large" onClick={onCancel}>{t("fields.general.cancel")}</Button>
                </div>
                <div className="col">
                    <Button 
                        type="custom-button" 
                        className="large"
                        onClick={handleSubmit(onUpdate)}>
                        {t("fields.general.change")}
                    </Button>
                </div>
            </div>) : (
            <div className="row justify-content-center align-items-center mx-0">
                <Button type="custom-button" className="large" onClick={handleSubmit(onUpdate)}>{t("fields.general.append")}</Button>
            </div>)
    }, [focused])
    
    const initialFields = {
        checked: null,
        select: {
            type: "select",
            name: "type",
            options: [],
            defaultValue: null,
            label: t("fields.multilink.check_type"),
            readOnly: false,
        },
        title: {
            name: "title",
            defaultValue: "",
            label: t("fields.multilink.text_link"),
            rules: rules.title,
        },
        value: {
            name: "value",
            defaultValue: "",
            label: t("fields.multilink.link"),
            rules: rules.value,
        },
        links: []
    }
    
    const [ fields, dispatch ] = useReducer(reducer, initialFields);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch({
                type: "INIT",
                list_of_types,
                model,
                rules,
            })
        }
    }, [list_of_types, model, rules, phoneMask])
    
    // sort end
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            dispatch({
                type: "SORT",
                oldIndex,
                newIndex,
                array: fields.links
            })
        }
    };
    // click on sortable element
    const onCheck = (index) => {
        let title = getValues(`links[${index}][title]`);
        let value = getValues(`links[${index}][value]`);
        let type = getValues(`links[${index}][type]`);
        
        let select = fields.select.options.find(el => el.value === parseInt(type));
        
        dispatch({
            type: "CHECK", 
            index,
            rules: select.rules,
            selectType: parseInt(type),
            text: {
                link: t("fields.multilink.link"),
                whatsapp: t("fields.multilink.whatsapp"),
                email: t("fields.multilink.email"),
                tg_username: t("fields.multilink.tg_username"),
                phone: t("fields.multilink.phone"),
                text_link: t("fields.multilink.text_link"),
                text_button: t("fields.multilink.text_button"),
            },
            phoneMask
        });

        setTimeout(()=> {
            snew('type', select);
            snew('title', title, {shouldValidate: true});
            snew('value', value, {shouldValidate: true});
            setFocus(true);
        }, 0)
    }
    // blur checked sortable element
    const onCancel = () => {
        dispatch({
            type: "UNCHECK",
            rules: fields.select.options[0].rules,
            selectType: 0,
            text: {
                link: t("fields.multilink.link"),
                whatsapp: t("fields.multilink.whatsapp"),
                email: t("fields.multilink.email"),
                tg_username: t("fields.multilink.tg_username"),
                phone: t("fields.multilink.phone"),
                text_link: t("fields.multilink.text_link"),
                text_button: t("fields.multilink.text_button"),
            },
            phoneMask
        })
        setTimeout(()=>{
            snew('type', fields.select.options[0]);
            snew('title', "");
            snew('value', "");
            setFocus(false);
        }, 0)
    }
    // on remove
    const onRemove = (index) => {
        dispatch({
            type: "REMOVE",
            index,
            rules: fields.select.options[0].rules,
            selectType: 0
        });
        setTimeout(() => {
            onCancel();
        }, 0)
    }
    // on append / update
    const onUpdate = (data) => {
        dispatch({
            type: "UPDATE",
            payload: data,
            text: {
                link: t("fields.multilink.link"),
                whatsapp: t("fields.multilink.whatsapp"),
                email: t("fields.multilink.email"),
                tg_username: t("fields.multilink.tg_username"),
                phone: t("fields.multilink.phone"),
                text_link: t("fields.multilink.text_link"),
                text_button: t("fields.multilink.text_button"),
            }
        });
        snew("type", fields.select.options[0]);
        snew("title", "");
        snew("value", "");
        setFocus(false);
    }
    // change rules
    const onChange = (rules, type) => {
        dispatch({
            type: "CHANGE",
            rules,
            selectType: type,
            text: {
                link: t("fields.multilink.link"),
                whatsapp: t("fields.multilink.whatsapp"),
                email: t("fields.multilink.email"),
                tg_username: t("fields.multilink.tg_username"),
                phone: t("fields.multilink.phone"),
                text_link: t("fields.multilink.text_link"),
                text_button: t("fields.multilink.text_button"),
            },
            phoneMask
        })
    }
    
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="col-md-12" dangerouslySetInnerHTML={{__html: t("fields.multilink.link_description", { link })}}></div>
                <Field 
                    {...fields.select} 
                    refRegister={rnew} 
                    errors={enew} 
                    setValue={snew} 
                    control={cnew}
                    onChangeHandler={(selected) => {
                        onChange(selected.rules, selected.value);
                    }}
                />
                <Field {...fields.title} refRegister={rnew} errors={enew} setValue={snew} control={cnew}/>
                <Field {...fields.value} refRegister={rnew} errors={enew} setValue={snew} control={cnew}/>
                <div className="col-md-12">
                    <Footer/>
                </div>
            </div>
            <div className="col-md-6">
                <Preview 
                    {...instance} 
                    links={fields.links} 
                    onSortEnd={onSortEnd} 
                    onLinkChecked={onCheck}
                    onRemove={onRemove}
                    {...form}
                />
            </div>
        </div>
    )
}