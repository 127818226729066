import axios from "axios";
import ROUTES from "@routes";

// const apiUrl = 'http://127.0.0.1:8000';
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const apiUrl = 'https://console.cheflist.org';
axios.defaults.baseURL = apiUrl;

export default class managerService {
    _apiManager = "/api_manager";
    _token = `Bearer ${localStorage.getItem("token") || sessionStorage.getItem("token")}`;
    _browserLanguage = navigator?.language;
    _language = localStorage.getItem("i18nextLng") || this._browserLanguage || "en";

    _withToken = axios.create({
        'Content-Type': 'multipart/form-data',
        headers: {
            "Accept-Language": this._language,
            "Timezone": timezone,
            common: {
                Authorization: this._token
            }
        }
    });
    
    _withoutToken = axios.create({
        headers: {
            "Accept-Language": this._language,
        }
    })
    
    /*
    ** general
    */ 
    // login
    login = async (data) => await this._withoutToken.post(`${this._apiManager}/manage/login/`, data);
    // register
    register = async (data) => await this._withoutToken.post(`${this._apiManager}/manage/register/`, data);
    getRegister = async () => await this._withoutToken.get(`${this._apiManager}/manage/rules/`);
    // clear tokens if unautorized and redirect
    clearTokenAndRedirect = () => {
        let loginPage = ROUTES.login;
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        window.location.href = loginPage;
    }
    // update initilized token after login
    updateToken = (token) => {
        this._withToken.defaults.headers.common['Authorization'] = `Bearer ${token}`
    };
    updateAcceptLanguage = (language) => {
        this._withToken.defaults.headers['Accept-Language'] = language;
    };
    // cloud payments 
    cloudpaymentsPublicId = "pk_a36ee02a4b0c3049c27125988c333";
    /*
    ** manage page
    */
    // global
    getStorageData = async () => await this._withToken.get(`${this._apiManager}/country/get_storage_data/`);
    // blocks
    getRestaurantInfo = async () => await this._withToken.get(`${this._apiManager}/restaurant/short_info_all/`);
    // blocks inner
    // restaurant info
    getMyRestaurant = async () => await this._withToken.get(`${this._apiManager}/restaurant/`);
    createRestaurant = async (data) => await this._withToken.post(`${this._apiManager}/restaurant/`, data);
    updateRestaurant = async (data, id) => await this._withToken.put(`${this._apiManager}/restaurant/${id}/`, data);
    // restaurant address
    getMyAddress = async () => await this._withToken.get(`${this._apiManager}/address/`);
    createAddress = async (data) => await this._withToken.post(`${this._apiManager}/address/`, data);
    updateAddress = async (data, id) => await this._withToken.put(`${this._apiManager}/address/${id}/`, data);
    // restaurant contacts
    getMyContacts = async () => await this._withToken.get(`${this._apiManager}/contact/`);
    createContacts = async (data) => await this._withToken.post(`${this._apiManager}/contact/`, data);
    updateContacts = async (data, id) => await this._withToken.put(`${this._apiManager}/contact/${id}/`, data);
    // restaurant schedule
    getMySchedule = async () => await this._withToken.get(`${this._apiManager}/mode/`);
    createSchedule = async (data) => await this._withToken.post(`${this._apiManager}/mode/`, data);
    updateSchedule = async (data, id) => await this._withToken.put(`${this._apiManager}/mode/${id}/`, data);
    // restaurant services
    getMyServices = async () => await this._withToken.get(`${this._apiManager}/service/`);
    updateServices = async (data) => await this._withToken.post(`${this._apiManager}/service/`, data);
    checkTelegram = async () => await this._withToken.get(`${this._apiManager}/service/check_telegram/`);
    unbindTelegram = async () => await this._withToken.get(`${this._apiManager}/service/disconnect_telegram/`);
    // payment
    getPayment = async () => await this._withToken.get(`${this._apiManager}/cloud_payment/`);
    createPayment = async (data) => await this._withToken.post(`${this._apiManager}/cloud_payment/`, data)
    // restaurant customization
    getCustomization = async () => await this._withToken.get(`${this._apiManager}/customization/`);
    updateCustomization = async (data) => await this._withToken.post(`${this._apiManager}/customization/`, data);
    // restaurant images
    updateLogo = async (data, id) => await this._withToken.patch(`${this._apiManager}/restaurant/${id}/change_image_logo/`, data);
    updateBg = async (data, id) => await this._withToken.patch(`${this._apiManager}/restaurant/${id}/change_image_background/`, data);
    // restaurant video
    setVideo = async (data, conf) => await this._withToken.post(`${this._apiManager}/restaurant_video/`, data, conf); 
    getVideo = async () => await this._withToken.get(`${this._apiManager}/restaurant_video/`); 
    // restaurant multilinks
    getLinks = async () => await this._withToken.get(`${this._apiManager}/link/`);
    getOpenLinks = async () => await this._withToken.get(`${this._apiManager}/link/open_tap_link`);
    updateLinks = async (data) => await this._withToken.post(`${this._apiManager}/link/`, data);
    // restaurant waiters
    getAllWaiters = async () => await this._withToken.get(`${this._apiManager}/employee/`);
    getWaiter = async (id) => await this._withToken.get(`${this._apiManager}/employee/${id}`);
    createWaiter = async (data) => await this._withToken.post(`${this._apiManager}/employee/`, data);
    updateWaiter = async (data, id) => await this._withToken.patch(`${this._apiManager}/employee/${id}/`, data);
    deleteWaiter = async (id) => await this._withToken.delete(`${this._apiManager}/employee/${id}/`);
    // menu
    getAllMenu = async () => await this._withToken.get(`${this._apiManager}/menu/`);
    getMenuRules = async () => await this._withToken.get(`${this._apiManager}/menu/rules_for_create/`)
    createMenu = async (data) => await this._withToken.post(`${this._apiManager}/menu/`, data);
    getMenu = async (id) => await this._withToken.get(`${this._apiManager}/menu/${id}/`);
    deleteMenu = async (id) => await this._withToken.delete(`${this._apiManager}/menu/${id}/`);
    updateMenu = async (data, id) => await this._withToken.put(`${this._apiManager}/menu/${id}/`, data);
    // submenu
    getAllSubmenu = async (menuId) => await this._withToken.get(`${this._apiManager}/submenu/?menu_id=${menuId}`);
    getSubmenuRules = async () => await this._withToken.get(`${this._apiManager}/submenu/rules_for_create/`)
    createSubmenu = async (data, menuId) => await this._withToken.post(`${this._apiManager}/submenu/?menu_id=${menuId}`, data);
    getSubmenu = async (id) => await this._withToken.get(`${this._apiManager}/submenu/${id}/`);
    deleteSubmenu = async (id) => await this._withToken.delete(`${this._apiManager}/submenu/${id}/`);
    updateSubmenu = async (data, id) => await this._withToken.put(`${this._apiManager}/submenu/${id}/`, data);
    // dishes
    getAllDishes = async (submenu_id) => await this._withToken.get(`${this._apiManager}/dish/?submenu_id=${submenu_id}`);
    getDish = async (id) => await this._withToken.get(`${this._apiManager}/dish/${id}/`);
    createDish = async (data, submenu_id) => await this._withToken.post(`${this._apiManager}/dish/?submenu_id=${submenu_id}`, data);
    updateDish = async (data, id) => await this._withToken.put(`${this._apiManager}/dish/${id}/`, data);
    deleteDish = async (data) => await this._withToken.delete(`${this._apiManager}/dish/delete/`, {data}); // delete multiple dishes
    copyDishes = async (data) => await this._withToken.post(`${this._apiManager}/dish/copy_dishes/`, data);
    moveDishes = async (data) => await this._withToken.post(`${this._apiManager}/dish/move_dishes/`, data);
    copyDishesIntoSubmenu = async (data) => await this._withToken.post(`${this._apiManager}/dish/copy_dishes_in_submenu/`, data);
    unpublishDishes = async (data) => await this._withToken.post(`${this._apiManager}/dish/un_publish/`, data);
    publishDishes = async (data) => await this._withToken.post(`${this._apiManager}/dish/publish/`, data);
    searchDishes = async (data) => await this._withToken.post(`${this._apiManager}/dish/search/`, data);
    getNestedMenu = async () => await this._withToken.get(`${this._apiManager}/dish/nested_menus/`);
    createMultipleDishes = async (data, submenu_id) => await this._withToken.post(`${this._apiManager}/dish/create_multiple_dishes/?submenu_id=${submenu_id}`, data);
    sortMenu = async (data) => await this._withToken.post(`${this._apiManager}/menu/change_position/`, data);
    sortDishes = async (data, submenu_id) => await this._withToken.post(`${this._apiManager}/dish/change_position/?submenu_id=${submenu_id}`, data);
    sortSubmenu = async (data, menu_id) => await this._withToken.post(`${this._apiManager}/submenu/change_position/?menu_id=${menu_id}`, data);
    // news
    getAllNews = async () => await this._withToken.get(`${this._apiManager}/news/`);
    getNews = async (id) => await this._withToken.get(`${this._apiManager}/news/${id}/`);
    createNews = async (data) => await this._withToken.post(`${this._apiManager}/news/`, data);
    updateNews = async (data, id) => await this._withToken.put(`${this._apiManager}/news/${id}/`, data);
    deleteNews = async (id) => await this._withToken.delete(`${this._apiManager}/news/${id}/`);
    // push
    getAllPush = async ({archive, page}) => await this._withToken.get(`${this._apiManager}/push_notification/${archive ? `?archive=1&page=${page}` : ""}`);
    getPush = async (id) => await this._withToken.get(`${this._apiManager}/push_notification/${id}/`);
    createPush = async (data) => await this._withToken.post(`${this._apiManager}/push_notification/`, data);
    updatePush = async (data, id) => await this._withToken.patch(`${this._apiManager}/push_notification/${id}/`, data);
    deletePush = async (id) => await this._withToken.delete(`${this._apiManager}/push_notification/${id}/`);
    // push template
    getPushTemplate = async () => await this._withToken.get(`${this._apiManager}/push_template/`);
    createPushTemplate = async (data) => await this._withToken.post(`${this._apiManager}/push_template/`, data);
    // orders
    getOrdersCount = async () => await this._withToken.get(`${this._apiManager}/order/count/`);
    getOrders = async ({archive, page, expects, accepted, in_delivery, done, canceled, created }) => await this._withToken.get(`${this._apiManager}/order/?page=${page}${archive ? `&archive=1` : ""}${expects ? `&expects=1` : ""}${accepted ? `&accepted=1` : ""}${in_delivery ? `&in_delivery=1` : ""}${done ? `&done=1` : ""}${canceled ? `&canceled=1` : ""}${created ? `&created=1` : ""}`);
    getOrder = async (id) => await this._withToken.get(`${this._apiManager}/order/${id}/`);
    getOrderCancel = async (id) => await this._withToken.get(`${this._apiManager}/order/order_cancel/?order_id=${id}`);
    updateOrder = async (data, id) => await this._withToken.patch(`${this._apiManager}/order/${id}/`, data)
    cancelOrder = async (data, id) => await this._withToken.patch(`${this._apiManager}/order/cancel_order/?order_id=${id}`, data)
    // excel
    getOrdersExcell = async ({date_start, date_end}) => await this._withToken.get(`${this._apiManager}/order/export_to_excel/?date_start=${date_start}&date_end=${date_end}`)
    // feedback
    getAllFeedback = async ({archive, page}) => await this._withToken.get(`${this._apiManager}/feedback/${archive ? `?archive=1&page=${page}` : ""}`);
    getFeedback = async (id) => await this._withToken.get(`${this._apiManager}/feedback/${id}/`);
    updateFeedback = async (data, id) => await this._withToken.patch(`${this._apiManager}/feedback/${id}/`, data);
    // updates
    getUpdates = async () => await this._withToken.get(`${this._apiManager}/update_log/`);
    // faq
    getFAQ = async () => await this._withToken.get(`${this._apiManager}/faq/`);
    // settings
    getSettings = async () => await this._withToken.get(`${this._apiManager}/manage/get_settings/`);
    getSettingsProfile = async () => await this._withToken.get(`${this._apiManager}/manage/get_profile/`);
    updateProfile = async (data) => await this._withToken.patch(`${this._apiManager}/manage/create_or_update_profile/`, data);
    updatePassword = async (data) => await this._withToken.patch(`${this._apiManager}/manage/update_password/`, data);
    // subscribe
    getSubscribePlans = async () => await this._withToken.get(`${this._apiManager}/subscribe_plan/`);
    getSubscribePlan = async (id) => await this._withToken.get(`${this._apiManager}/subscribe_plan/${id}/`);
    payWithCard = async (data) => await this._withToken.post(`${this._apiManager}/subscribe/`, data);
    payWithInvoise = async (data) => await this._withToken.post(`${this._apiManager}/legal/`, data);
    // cloudpayments secure
    cpSecureTermUrl = `${apiUrl}${this._apiManager}/subscribe/post3ds/`;
    cpSecureTermUrlMarket = `${apiUrl}${this._apiManager}/market_order_pay/post3ds/`;
    // smart cards
    getAllCards = async () => await this._withToken.get(`${this._apiManager}/market_item_link/`);
    getCard = async (id) => await this._withToken.get(`${this._apiManager}/market_item_link/${id}/`);
    updateCard = async (data, id) => await this._withToken.patch(`${this._apiManager}/market_item_link/${id}/`, data);
    //  market
    getMarketAll = async () => await this._withToken.get(`${this._apiManager}/market_item/`);
    getMarketItem = async (id) => await this._withToken.get(`${this._apiManager}/market_item/${id}/`);
    getMarketRulesOne = async (id) => await this._withToken.get(`${this._apiManager}/market_order/rules/?market_item=${id}`);
    getMarketRulesTwo = async (id) => await this._withToken.get(`${this._apiManager}/market_order/set_step_2/?market_item=${id}`);
    createMarketOrder = async (data) => await this._withToken.post(`${this._apiManager}/market_order/`, data);
    // delete payment card
    deleteCard = async (id) => await this._withToken.delete(`${this._apiManager}/manager_pay_card/${id}/`);
    // set firebase token
    setFirebaseToken = async (firebase_token) => await this._withToken.post(`${this._apiManager}/manage/set_firebase_token/`, {firebase_token});
    // custom text
    getCustomText = async () => await this._withToken.get(`${this._apiManager}/custom_text/`);
    createCustomText = async (data) => await this._withToken.post(`${this._apiManager}/custom_text/`, data);
    // achievements
    getAchievements = async () => await this._withToken.get(`${this._apiManager}/achievement/`);
    getAchievementsDiscount = async () => await this._withToken.get(`${this._apiManager}/achievement_discount/`);
    createAchievements = async (data) => await this._withToken.post(`${this._apiManager}/achievement_discount/`, data);
    getAchievementsFree = async () => await this._withToken.get(`${this._apiManager}/achievement_for_free/`);
    createAchievementsFree = async (data) => await this._withToken.post(`${this._apiManager}/achievement_for_free/`, data);
    // dashboard
    getDashboard = async () => await this._withToken.get(`${this._apiManager}/analytic/`);
}