import React, { useContext, useEffect, useReducer } from "react"
import { Chart } from "@components"
import { globalVariablesContext } from "@context"
import s from "./DashboardOrders.module.sass"
import { useTranslation } from "react-i18next";

let width, height, gradient;
function getGradient({
    ctx, chartArea,
    startColor,
    stopColor
}) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, startColor);
        gradient.addColorStop(0, stopColor);
    }

    return gradient;
}
function reducer(store, action) {
    switch(action.type) {
        case "INIT":
            return {
                ...store,
                data: {
                    ...store.data,
                    labels: action.payload.labels,
                    datasets: [{
                        ...store.data.datasets[0],
                        data: action.payload.orders.self_pick_up
                    },{
                        ...store.data.datasets[1],
                        data: action.payload.orders.delivery
                    }]
                }
            }
        default:
            return store
    }
}
export default function DashboardOrders(props) {
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext)
    const { t } = useTranslation()
    const initialChart = {
        type: "line",
        data: {
            labels: t("dashboard.week", { returnObjects: true }),
            datasets: [{
                label: t("dashboard.orders.pickup"),
                data: [ 1,0,0,0,0,0,0 ],
                borderColor: `#${accent}`,
                backgroundColor: `#${accent}`,
                fill: function(context) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;

                    if (!chartArea) {
                        return
                    }
                    return {
                        target: "origin",
                        above: getGradient({ctx, chartArea, startColor: `#${accent}`, stopColor: "rgba(255,255,255,0)"})
                    }
                },
                tension: 0.2,
                pointBorderColor: "#fff",
                pointRadius: 4,
                pointHoverRadius: 6,
                pointBorderWidth: 1
            },{
                label: t("dashboard.orders.delivery"),
                data: [ 3,0,0,0,0,0,0 ],
                backgroundColor: `#${second}`,
                borderColor: `#${second}`,
                tension: 0.2,
                borderDash: [10, 5],
                pointBorderColor: "#fff",
                pointRadius: 4,
                pointHoverRadius: 6,
                pointBorderWidth: 1
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        count: 6,
                        callback: function(value) {
                            var ranges = [
                                { divider: 1e6, suffix: 'M' },
                                { divider: 1e3, suffix: 'k' }
                            ];
                            function formatNumber(n) {
                                for (var i = 0; i < ranges.length; i++) {
                                    if (n >= ranges[i].divider) {
                                        return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                    }
                                }
                                return n;
                            }
                            return formatNumber(value);
                        },
                        padding: 12,
                        font: {
                            size: 12,
                            family: "SF Pro Display"
                        },
                        precision: 0
                    },
                    grid: {
                        drawBorder: false,
                        color: "#EFEFEF",
                        drawTicks: false
                    }
                },
                x: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                        drawBorder: false,
                        color: "#EFEFEF",
                        drawTicks: false
                    },
                    ticks: {
                        padding: 24,
                        font: {
                            size: 12,
                            family: "SF Pro Display"
                        },
                    }
                },
            },
            borderRadius: 4000,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
            },
            maintainAspectRatio: false,
            responsive: true,
        }
    }
    const [ chart, dispatch ] = useReducer(reducer, initialChart)
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            dispatch({
                type: "INIT",
                payload: {
                    ...props,
                    labels: [...t("dashboard.week", { returnObjects: true }).slice(props.start_day), ...t("dashboard.week", { returnObjects: true }).slice(0, props.start_day)]
                }
            })
        }
        return () => { isMounted = false }
    }, [props])
    return <div className={s.item}>
        <div style={{
            pesponsive: true,
        }}>
            <Chart {...chart} height={296}/>
        </div>
        <div className="row justify-content-center">
            <div className="col-auto">
                <span className={s.label}>{t("dashboard.orders.title")}</span>
            </div>
            <div className="col-auto">
                <span className={s.dot} style={{"--dot-color": `#${second}`}}>{t("dashboard.orders.delivery")}</span>
            </div>
            <div className="col-auto">
                <span className={s.dot} style={{"--dot-color": `#${accent}`}}>{t("dashboard.orders.pickup")}</span>
            </div>
        </div>
    </div>
}