import React from "react";
import classNames from "classnames";
import {
    Title, 
    Description,
    FieldsRenderer,
} from "@ui-kit"
import s from "./Form.module.sass"

export default function ColumnsRenderer({size, fields, title, description, type, refs, ...formRendererProps}) {
    return (
        <div className={classNames("mb-3", {
            [`col-md-${size}`]: size !== null,
            "col-md": size === null,
            [s.waiterImgRemover]: type === "restaurant_waiter" && size === "auto"
        })} ref={refs}>
            {title ? <Title type="modal" className={
                classNames({
                    "mb-2": description,
                    "mb-4": !description
                })
            }>{title}</Title> : null}
            {description ? <Description text={description}/> : null}
            <div className="row">
                <FieldsRenderer
                    fields={fields}
                    {...formRendererProps}
                />
            </div>
        </div>
    )
}