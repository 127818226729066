import React, { useEffect, useState } from "react"
import useWebSocket from 'react-use-websocket';

function createSocketUrl(token) {
    return `wss://console.cheflist.org/ws/left_bar/${token}/`
}

export default function WithNavbarSocket({children}) {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    const [ socketURL, setSocketURL ] = useState(createSocketUrl(token));
    
    const {
        lastJsonMessage,
    } = useWebSocket(socketURL, {
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        reconnectInterval: 2000
    }, Boolean(token))
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted && token) {
            setSocketURL(createSocketUrl(token))
        }
        return () => {
            isMounted = false;
        }
    }, [token])
    
    return React.cloneElement(children, {
        lastJsonMessage
    })
}
