import i18n from "../i18n";

const _URL = window.URL || window.webkitURL;
function uploadFile(e, dispatch, type, sizes, fileSize = 20, notify, fileType = "image") {
    let file, obj;
    if (e.dataTransfer) {
        file = e.dataTransfer.files;
    } else if (e.target) {
        file = e.target.files;
    }
    if (file[0]) {
        let fileInput = e.target;
        let objectUrl = _URL.createObjectURL(file[0]);
        // check for file's size (Mb)
        if (fileSize && (file[0].size > fileSize*1024*1024)) {
            if (typeof notify === "function") {
                notify({title: i18n.t("warning"), text: i18n.t("fields.general.file_size", {file_size: `${fileSize}`}), type: "warning"})
            } else {
                alert(i18n.t("fields.general.file_size", {file_size: `${fileSize}`}));
            }
            fileInput.value = ""; // clear value if image is invalid
            return false;
        }
        
        if (fileType === "image") {
            obj = new Image();
            obj.src = objectUrl;
            obj.onload = function (e) {
                // check for image's width/height
                if (sizes && (e.target.width < sizes[0] || e.target.height < sizes[1])) {
                    if (typeof notify === "function") {
                        notify({title: i18n.t("warning"), text: i18n.t("fields.general.photo_sizes", {sizes: `${sizes[0]}x${sizes[1]}`}), type: "warning"})
                    } else {
                        alert(i18n.t("fields.general.photo_sizes", {sizes: `${sizes[0]}x${sizes[1]}`}));
                    }
                    fileInput.value = ""; // clear value if image is invalid
                    return false;
                }
                
                const reader = new FileReader();
                reader.readAsDataURL(file[0]);
                reader.onload = () => {
                    dispatch({
                        type,
                        file: file[0],
                        image: reader.result
                    });
                };
                _URL.revokeObjectURL(objectUrl);
                return true;
            };
        }
        if (fileType === "video") {
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                dispatch({
                    type, 
                    src: reader.result,
                    file: file[0]
                });
            };
            _URL.revokeObjectURL(objectUrl);
            return true
        }
    }
}

export default uploadFile;