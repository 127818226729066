import React, { useContext, useEffect, useState, useReducer } from "react";
import { useTranslation } from "react-i18next"
// components
import {
    LoadingSpinner
} from "@components"
// ui-kit
import { 
    Form, 
    Title,
} from "@ui-kit"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// routes
import ROUTES from "@routes";
// styles
import s from "./Register.module.sass"
// utils
import { 
    serviceProcessing,
    updateRules
} from "@utils";

const phoneMask = [
    "+9 999 999 99 99",
    "+999 99 999 99 99",
    "+999 9999 999 999"
]

// const { rules, phone_mask, language_list } = props;
function reducer(form, action) {
    switch (action.type) {
        case "INIT":
            const initForm = { ...form }

            initForm.columns[0].fields[1].mask = action.phone_mask;
            // language select
            initForm.columns[0].fields[3].options = action.language_list;
            initForm.columns[0].fields[3].defaultValue = action.language_list?.find(el => el.main);
            return updateRules(initForm, action.rules)
        default:
            return form;
    }
}

export default function Register(props) {    
    const { t, ready } = useTranslation();
    const { register, updateToken } = useContext(managerMethodsContext);
    const { notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(false);

    function _transformData(data) {
        const { language: { value: language }, phone, ...form } = data;
        return {
            ...form,
            language,
            phone: phone.replace(/[\D]/g,"")
        }
    }
    
    function callback(data, reset, resolve, reject) {
        setLoading(true)
        register(_transformData(data)).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (response) => {
                    const { token, role } = response;
                    resolve(res);
                    // clear storage
                    localStorage.removeItem("token");
                    localStorage.setItem("role", role);
                    // set new storage
                    data.remember ? localStorage.setItem("token", token) : sessionStorage.setItem("token", token);
                    updateToken(token);
                    if (role === "MANAGER") {
                        window.location.href = ROUTES.manage;
                    };
                },
                errors =>  reject({messages: errors})
            )
        }).catch((errors) => {
            reject(errors);
        }).finally(() => setLoading(false));
    }
    
    const initialForm = {
        type: "register",
        submit: t("register.buttons.submit"),
        callback,
        columns: [
            {
                size: 6,
                fields: [
                    {
                        type: "email",
                        defaultValue: "",
                        name: "email",
                        label: t("register.fields.email"),
                        autoFocus: "autofocus",
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "mask",
                        mask: phoneMask,
                        name: "phone",
                        defaultValue: "",
                        label: t("register.fields.phone"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "text",
                        name: "restaurant",
                        defaultValue: "",
                        label: t("register.fields.restaurant"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "select",
                        options: [],
                        isSearchable: true,
                        defaultValue: null,
                        name: "language",
                        label: t("register.fields.language")
                    },
                ]
            },
            {
                size: 6,
                fields: [
                    {
                        type: "mask",
                        regex: "[a-zA-Z0-9_]+",
                        defaultValue: "",
                        name: "login",
                        label: t("register.fields.login"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "password",
                        defaultValue: "",
                        name: "password",
                        label: t("register.fields.password"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    },
                    {
                        type: "password",
                        defaultValue: "",
                        name: "password_confirm",
                        label: t("register.fields.password_confirm"),
                        rules: {
                            required: {
                                value: true,
                                message: t("fields.general.empty")
                            }
                        }
                    }
                ]
            }
        ]
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted && props) {
            dispatch({type: "INIT", ...props});
            setLoading(false);
        }
        return () => isMounted = false;
    }, [props])

    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    return (<>
        { !ready || loading ? <LoadingSpinner/> : null }
        <div className={s.login}>
            <Title type="page" className="mb-4">{t("register.title")}</Title>
            <p className={s.description}>{t("register.description")}</p>
            <Form form={form}/>
        </div>
    </>)
} 