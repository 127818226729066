import React, { useState, useContext, useReducer, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
    Form,
    Modal,
    Button
} from "@ui-kit"
import { 
    errorProcessing, 
    serviceProcessing, 
    uploadFile, 
    updateRules
} from "@utils"
import {
    managerMethodsContext,
    notificationsContext
} from "@context";

const titleMax = 30;
const descriptionMax = 120;

function reducer(form, action) {
    switch (action.type) {
        case "RESET":
            let formReset = { ...action.initialForm };
            return updateRules(formReset, action.rules);
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
                type: "restaurant_push_update",
                submit: action.text.save,
                callback: action.callbackUpdate,
            };
            // title
            updateForm.columns[0].fields[0].defaultValue = action.model.title;
            updateForm.columns[0].fields[0].label = action.text.title;
            // description
            updateForm.columns[0].fields[1].defaultValue = action.model.description;
            updateForm.columns[0].fields[1].label = action.text.description;

            updateForm.columns[1].fields[0].defaultValue = action.model.date_publication;

            if (action.model.image) {
                updateForm.columns[1].fields[1].type = "checkbox";
                updateForm.columns[1].fields[2].label = null;
                updateForm.columns[1].fields[2].description = "";
                updateForm.columns[1].fields[2].file = action.model.image;
            } else {
                updateForm.columns[1].fields[1].type = null;
                updateForm.columns[1].fields[2].description = action.text.pushPhotoEmptyDescription;
                updateForm.columns[1].fields[2].file = "none";
                updateForm.columns[1].fields[2].label = action.text.pushPhotoEmpty;
            }
            
            return updateRules(updateForm, action.rules);
        case "LOAD_IMAGE":
            let loadForm = {...form};

            loadForm.columns[1].fields[2].description = "";
            loadForm.columns[1].fields[2].file = action.image;
            return updateRules(loadForm, action.rules);
        default:
            return { ...form }
    }
}

export default function PushBlockWrapper({children, rules, onAdd, id = null, onDelete, onUpdate}) {
    const { t } = useTranslation();
    // detail modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false); }
    // delete modal
    const [ isOpenDelete, setOpenDelete ] = useState(false);
    function closeDeleteModal() { setOpenDelete(false); }
    const {
        getPush,
        createPush,
        deletePush,
        updatePush,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext)
    const [ loading, setLoading ] = useState(true);
    const [ itemLoading, setItemLoading ] = useState(false);

    const titleRef = useRef();
    const descriptionRef = useRef();
    const titleLabelRef = useRef();
    const descriptionLabelRef = useRef();
    
    const pushPhotoEmpty = t("fields.push.photo");
    const pushPhotoEmptyDescription = (file_size = 20) => {
        return t("fields.general.photo_info", {file_size})
    }
    
    function _transformData(data) {
        const { date_publication, image, title, description, remove_image } = data;
        let form = new FormData();
        if (typeof remove_image === "boolean") {
            form.append("remove_image", remove_image - 0);
        } else {
            if (image && image[0]) {
                form.append("image", image[0]);
            }
        }

        form.append("date_publication", date_publication === null ? 0 : parseInt(date_publication.valueOf()/1000))
        form.append("title", title);
        form.append("description", description);
        
        return form;
    }
    
    function onDeletePush() {
        setItemLoading(true)
        deletePush(id).then(res => {
            serviceProcessing(res, notifyList, () => {
                onDelete(id);
            })
        }).catch(errors => {
            errorProcessing(errors, notify, "detail_data")
        }).finally(() => setItemLoading(false));
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createPush(_transformData(data))
        .then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                data => {
                    onAdd(data);
                    setOpen(false);
                    resolve(res);
                },
                errors => { reject({messages: errors})}
            )
        })
        .catch(reject)
        .finally(() => setLoading(false));
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updatePush(_transformData(data), id)
        .then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                data => {
                    resolve(data);
                    onUpdate(data);
                    setOpen(false);
                    resolve(res);
                },
                errors => { reject(errors)}
            )
        })
        .catch(reject)
        .finally(() => setLoading(false))
    }
    
    function onFileChange(e) {
        e.preventDefault();
        uploadFile(e, dispatch, "LOAD_IMAGE", [400,400], 20, notify);
    }
    
    const initialForm = {
        type: "restaurant_push_add",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 8,
                fields: [{
                    type: "text",
                    defaultValue: "",
                    maxLength: titleMax,
                    name: "title",
                    label: t("fields.push.title", {count: titleMax}),
                    refs: titleRef,
                    labelRef: titleLabelRef,
                    onChange: (e) => {
                        titleLabelRef.current.innerText = t("fields.push.title", {count: titleMax - titleRef.current.value.length})
                    }
                },
                {
                    type: "textarea",
                    defaultValue: "",
                    maxLength: descriptionMax,
                    name: "description",
                    label: t("fields.push.description", {count: descriptionMax}),
                    refs: descriptionRef,
                    labelRef: descriptionLabelRef,
                    onChange: (e) => {
                        descriptionLabelRef.current.innerText = t("fields.push.title", {count: descriptionMax - descriptionRef.current.value.length})
                    }
                }]
            },
            {
                size: 4,
                fields: [
                    {
                        type: "datetime",
                        defaultValue: null,
                        name: "date_publication",
                        label: t("fields.push.date_publication"),
                        placeholder: "Отправить сейчас",
                        isClearable: true,
                        isValidDate: (currentDate) => {
                            return currentDate.isAfter(moment(Math.ceil(moment().valueOf() / 3600000) * 3600000).subtract(1, "days"))
                        },
                        initialViewDate: moment(Math.ceil(moment().valueOf() / 3600000) * 3600000),
                        timeZone: true,
                        timeConstraints: {
                            hours: { 
                                step: 1,
                                min: 8,
                                max: 21
                            },
                            minutes: { step: 60}
                        }
                    },
                    {
                        type: null,
                        mode: "switch",
                        defaultChecked: false,
                        name: "remove_image",
                        label: `${t("fields.general.photo_delete")} (${t("yes")})`,
                        negativeLabel: `${t("fields.general.photo_delete")} (${t("no")})`
                    },
                    {
                        type: "file",
                        defaultValue: "",
                        file: "none",
                        name: "image",
                        label: pushPhotoEmpty,
                        description: pushPhotoEmptyDescription(20),
                        accept: ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG",
                        onChange: onFileChange,
                        wrapperClass: "mb-3 col-md-12"
                    }
                ]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {            
            if (isOpen) {
                if (rules && id === null) {
                    dispatch({
                        type: "RESET",
                        rules,
                        initialForm
                    })
                    setLoading(false)
                }
    
                if (id !== null) {
                    getPush(id)
                        .then(res => {
                            serviceProcessing(res, notifyList, data => {
                                dispatch({
                                    type: "INIT_UPDATE", 
                                    ...data,
                                    text: {
                                        save: t("buttons.save"),
                                        title: t("fields.push.title", {count: titleMax - data.model.title.length}),
                                        description: t("fields.push.title", {count: descriptionMax - data.model.description.length}),
                                        pushPhotoEmpty,
                                        pushPhotoEmptyDescription: pushPhotoEmptyDescription(20)
                                    },
                                    callbackUpdate
                                });
                                setLoading(false)
                            })
                        }).catch(errors => errorProcessing(errors, notify, "detail_data"))
                        .finally(() => setLoading(false))
                }
            }
        }
        return () => {
            isMounted = false
            setLoading(true)
        };
        
    }, [rules, isOpen, id])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                    onDelete: () => setOpenDelete(true), // menu add has no onDelete
                    onSettings: () => setOpen(true), // menu add has no Settings
                    loading: itemLoading
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={id ? t("push.form.title_edit") : t("push.form.title_create")}
                description={t("push.form.description")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
            {
                id !== null ? 
                <Modal 
                    closeModal={closeDeleteModal} 
                    isOpen={isOpenDelete}
                    title={t("warning")}
                    description={t("context_menu.delete_confirmation")}
                    className="notice-modal"
                    closeTimeoutMS={200}
                >
                    <div className="row pt-2">
                        <div className="col-6">
                            <Button 
                                type="custom-button" 
                                className="large"
                                onClick={(e) => {
                                    onDeletePush(e);
                                    closeDeleteModal(e);
                                }}
                            >{t("fields.general.delete")}</Button>
                        </div>
                        <div className="col-6">
                            <Button 
                                type="button" 
                                className="gray large"
                                onClick={closeDeleteModal}
                            >{t("fields.general.cancel")}</Button>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    )
}