import React from "react"
import classNames from "classnames"
import s from "./LoadingSpinner.module.sass"

import {ReactComponent as Loader} from "@svg/loader.svg"

export default function LoadingSpinner({position = "fixed", className = "", progress = null}) {
    return (
        <div className={classNames( s.spinner, "d-flex align-items-center justify-content-center", {
            [s.modal]: className.includes("modal"),
            [s.item]: className.includes("item"),
            [s.transparent]: className.includes("transparent")
        })} style={{position}}>
            { Number.isFinite(progress) ? <div className={s.progress}>{progress}%</div> : null }
            <Loader className={classNames({
                [s.large]: Number.isFinite(progress)
            })}/>
        </div>
    )
}