import React, { useState, useContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
// components
import { LanguagesRow } from "@components";
// ui-kit
import {
    Form,
    Modal,
    Button
} from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing,
    uploadFile,
    updateRules
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "RESET":
            let formReset = { ...action.initialForm };
            // select language
            formReset.columns[2].fields[0].columns[0].fields[0].options = action.language_list;
            formReset.columns[2].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // translations
            formReset.columns[2].fields[0].translations = action.translations;
            // language list
            formReset.columns[2].fields[0].language_list = action.language_list;
            // rules
            formReset.columns[2].fields[0].rules = {
                titleRule: action.rules.title, 
                descriptionRule: action.rules.description, 
            };

            return updateRules(formReset, action.rules);
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
                type: "restaurant_news_update",
                submit: action.t("buttons.save"),
                callback: action.callbackUpdate,
            };
            // select language
            updateForm.columns[2].fields[0].columns[0].fields[0].options = action.language_list;
            updateForm.columns[2].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // title
            updateForm.columns[2].fields[0].columns[0].fields[1].defaultValue = action.model.translations.find(el => el.main).title;
            // translations
            updateForm.columns[2].fields[0].translations = action.model.translations;
            // language list
            updateForm.columns[2].fields[0].language_list = action.language_list;
            // language row fields
            updateForm.columns[2].fields[0].columns[0].fields[0].defaultValue = action.language_list[0];
            updateForm.columns[2].fields[0].columns[0].fields[1].defaultValue = action.model.translations[0].title;
            updateForm.columns[2].fields[0].columns[0].fields[2].defaultValue = action.model.translations[0].description;

            // news image
            if (action.model.image) {
                updateForm.columns[1].fields[0].type = "checkbox";
                updateForm.columns[1].fields[1].label = null;
                updateForm.columns[1].fields[1].description = "";
                updateForm.columns[1].fields[1].file = action.model.image;
            } else {
                updateForm.columns[1].fields[0].type = null;
                updateForm.columns[1].fields[1].description = action.t("fields.general.photo_info", {file_size: 20});
                updateForm.columns[1].fields[1].file = "none";
                updateForm.columns[1].fields[1].label = action.t("fields.menu.photo");
            }
            
            // rules
            updateForm.columns[2].fields[0].rules = {
                titleRule: action.rules.title, 
                descriptionRule: action.rules.description, 
            };
            
            return updateRules(updateForm, action.rules);
        case "LOAD_IMAGE":
            let loadForm = {...form};

            loadForm.columns[1].fields[1].description = "";
            loadForm.columns[1].fields[1].file = action.image;

            return loadForm; //updateRules(loadForm, action.rules);
        default:
            return form;
    }
}

export default function NewsBlockWrapper({children, language_list, translations, rules, onAdd, id = null, onDelete, onUpdate}) {
    const { t } = useTranslation();
    // detail modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false); }
    // delete modal
    const [ isOpenDelete, setOpenDelete ] = useState(false);
    function closeDeleteModal() { setOpenDelete(false); }
    const {
        createNews,
        getNews,
        deleteNews,
        updateNews,
    } = useContext(managerMethodsContext);
    const { 
        notify,
        notifyList
    } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    const [ itemLoading, setItemLoading ] = useState(false);
    
    function _transformData(data) {
        const { is_published, date_publication, translations, image, remove_image } = data;
        let form = new FormData();

        if (typeof remove_image === "boolean") {
            form.append("remove_image", remove_image - 0);
            if (!remove_image && image && image[0]) {
                form.append("image", image[0]);
            }
        } else {
            if (image && image[0]) {
                form.append("image", image[0]);
            }
        }

        form.append("is_published", is_published);
        form.append("date_publication", parseInt(date_publication.valueOf()/1000));
        form.append("translations", JSON.stringify(translations));

        return form;
    }
    
    function onDeleteNews() {
        setItemLoading(true);
        deleteNews(id)
            .then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    onDelete(id)
                })
            })
            .catch(error => errorProcessing(error, notify, "detail_data"))
            .finally(() => setItemLoading(false));
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true)
        createNews(_transformData(data)).then(res => {
            resolve(res);
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    onAdd(data);
                    setOpen(false);
                    setLoading(false)
                },
                (errors) => reject({messages: errors})
            )
        }).catch(reject);
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true)
        updateNews(_transformData(data), id).then(res => {
            resolve(res);
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    onUpdate(data);
                    setOpen(false);
                    setLoading(false)
                },
                (errors) => reject({messages: errors})
            )
        }).catch(reject)
    }
    
    function onFileChange(e) {
        e.preventDefault();
        uploadFile(e, dispatch, "LOAD_IMAGE", [400,400], 20, notify);
    }
    
    const initialForm = {
        type: "restaurant_menu_add",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 6,
                fields: [{
                    type: "checkbox",
                    mode: "switch",
                    defaultChecked: true,
                    name: "is_published",
                    label: t("fields.general.is_published"),
                    description: t("fields.news.publish_description"),
                },
                {
                    type: "datetime",
                    name: "date_publication",
                    label: t("fields.news.date_publication"),
                    defaultValue: parseInt(moment().valueOf() / 1000),
                    isValidDate: (currentDate) => {return currentDate.isAfter(moment().subtract(1, 'days'))},
                    initialViewDate: moment(),
                    timeZone: true
                }]
            },
            {
                size: 6,
                fields: [
                    {
                        type: null, // "checkbox"
                        mode: "switch",
                        defaultChecked: false,
                        name: "remove_image",
                        label: `${t("fields.general.photo_delete")} (${t("yes")})`,
                        negativeLabel: `${t("fields.general.photo_delete")} (${t("no")})`
                    },
                    {
                        type: "file",
                        defaultValue: "",
                        file: "none",
                        name: "image",
                        label: t("fields.news.photo"),
                        description: t("fields.general.photo_info", {file_size: 20}),
                        accept: ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG",
                        onChange: onFileChange,
                        wrapperClass: "mb-3 col-md-12"
                    }
                ]
            },
            {
                size: 12,
                fields: [{
                    type: "wrapper",
                    Wrapper: (props) => <LanguagesRow {...props}/>,
                    columns: [{
                        fields: [
                            {
                                type: "select",
                                options: [],
                                isSearchable: true,
                                defaultValue: null,
                                name: "language",
                                label: t("fields.translations.select_language")
                            },
                            {
                                type: "text",
                                defaultValue: "",
                                name: "title",
                                label: t("fields.translations.title"),
                            },
                            {
                                type: "textarea",
                                defaultValue: "",
                                name: "description",
                                label: t("fields.translations.description")
                            }
                        ]
                    }],
                    translations: [],
                    language_list: []
                }]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (isOpen) {
                if ((language_list && translations && rules) && id === null) {
                    dispatch({
                        type: "RESET",
                        language_list,
                        translations,
                        rules,
                        initialForm
                    });
                    setLoading(false);
                }
    
                if (id !== null) {
                    getNews(id)
                        .then(res => {
                            serviceProcessing(res, notifyList, (data) => {
                                dispatch({type: "INIT_UPDATE", ...data, callbackUpdate, t});
                            })
                        })
                        .catch(error => errorProcessing(error, notify, "detail_data"))
                        .finally(() => {setLoading(false);});
                }
            } else {
                setLoading(true);
            }
        }
        return () => {
            isMounted = false;
            setLoading(true);
        };
        
    }, [language_list, translations, rules, isOpen, id])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                    onDelete: () => setOpenDelete(true), // menu add has no onDelete
                    onSettings: () => setOpen(true), // menu add has no Settings
                    loading: itemLoading // set loading loader while item deleting
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={id === null ? t("news.form.title_create") : t("news.form.title_edit")}
                description={t("news.form.description")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
            {
                id !== null ? 
                <Modal 
                    closeModal={closeDeleteModal} 
                    isOpen={isOpenDelete}
                    title={t("warning")}
                    description={t("context_menu.delete_confirmation")}
                    className="notice-modal"
                    closeTimeoutMS={200}
                >
                    <div className="row pt-2">
                        <div className="col-6">
                            <Button 
                                type="custom-button" 
                                className="large"
                                onClick={(e) => {
                                    onDeleteNews(e);
                                    closeDeleteModal(e);
                                }}
                            >{t("fields.general.delete")}</Button>
                        </div>
                        <div className="col-6">
                            <Button 
                                type="button" 
                                className="gray large"
                                onClick={closeDeleteModal}
                            >{t("fields.general.cancel")}</Button>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    )
}