import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    Breadcrumbs,
    ManageAddBlock,
    ManagerFeedback,
    LoadingSpinner,
    Pagination
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
// icons
import {ReactComponent as Archive} from "@svg/archive-circle.svg"
// routes
import ROUTES from "@routes";
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

const linkPattern = `${ROUTES.feedback}/archive`

export default function FeedbackPage() {
    const [ data, setData ] = useState({count_page: 1});
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const breadcrumbs = [
        {
            title: t("feedback.title"),
            link: ROUTES.feedback
        },
    ]
    const [ breadcrumbsList, setBreadcrumbs ] = useState(breadcrumbs)
    const match = Boolean(pathname.match("archive"));
    const { page } = useParams();

    const { getAllFeedback } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (match && !page) {
                history.replace(`${ROUTES.feedback}/archive/1`)
            } else {
                getAllFeedback({archive: match, page})
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            // if (data.count_page < page) {
                            //     history.replace(ROUTES.not_found)
                            // } else {
                            //     setData(data)
                            // }
                            setData(data)
                        })
                    })
                    .catch(error => errorProcessing(error, notify, "page_data"))
                    .finally(() => setLoading(false));
    
                if (match) {
                    setBreadcrumbs([
                        ...breadcrumbs,
                        {
                            title: t("archive"),
                            link: `${ROUTES.feedback}/archive`
                        }
                    ])
                } else {
                    setBreadcrumbs(breadcrumbs);
                }
            }
        }
        return () => {
            setLoading(true);
            isMounted = false;
        }
    }, [match, page])
    
    return (
        <>
            {loading ? <LoadingSpinner/> : null}
            <Breadcrumbs list={breadcrumbsList} />
            <section>
                <div className="container-fluid">
                    <Title
                        className="mb-md-5 mb-4" 
                        type="page"
                        back={match ? ROUTES.feedback : null}
                    >{t("feedback.title")} {match ? ` (${t("archive")})` : null}</Title>
                    {
                        !match ? 
                        <ManageAddBlock 
                            title={t("archive")} 
                            Icon={Archive} 
                            className="short icon-right"
                            onClick={() => history.push(`${ROUTES.feedback}/archive/1`)}
                        /> : null
                    }
                    <ManagerFeedback {...data}/>
                    { match ? <Pagination link={linkPattern} count={data.count_page} page={page}/> : null }
                </div>
            </section>
        </>
    )
}