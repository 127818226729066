import React, {useEffect, useReducer} from "react";
import { useTranslation } from "react-i18next";
import { 
    ManageAddBlock,
    NewsBlockWrapper,
    NewsList
} from "@components";
import {
    updateList,
    deleteItemFromList
} from "@utils";
import { ReactComponent as AddNewsIcon } from "@svg/news_new.svg"

function reducer(list, action) {
    switch (action.type) {
        case "INIT":
            return action.payload.map(news => {
                return {
                    ...news,
                    animationCount: 0
                }
            });
        case "ADD_LIST":
            return [
                {
                    ...action.payload,
                    animationCount: 0
                },
                ...list
            ]      
        case "UPDATE_LIST":
            return updateList(list, action.payload)
        case "DELETE_LIST":
            return deleteItemFromList(list, action.payload)      
        default:
            return list       
    }
}

export default function ManagerSubmenu({model, ...data}) {
    const { t } = useTranslation();
    const [ list, dispatch ] = useReducer(reducer, model);
    
    useEffect(() => {
        let isMounted = true;
        if (model && isMounted) dispatch({type: "INIT", payload: model});
        return () => isMounted = false;
    }, [model])

    return (
        <div className="row">
            <div className="col-md-auto">
                <NewsBlockWrapper 
                    {...data}
                    onAdd={(data) => dispatch({type: "ADD_LIST", payload: data})}
                >
                    <ManageAddBlock className="short icon-right" title={t("news.add_news")} Icon={AddNewsIcon}/>
                </NewsBlockWrapper>
            </div>
            {
                (list && list.length) ? 
                <div className="row col-12 px-0 mx-0">
                    <NewsList 
                        list={list}
                        onDelete={(id) => dispatch({type: "DELETE_LIST", payload: id})}
                        onUpdate={(data) => dispatch({type: "UPDATE_LIST", payload: data})}
                    />
                </div> : null
            }
        </div>
    )
}