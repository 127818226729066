import {ReactComponent as Manage} from "@svg/navbar/manage.svg";
import {ReactComponent as Restaurant} from "@svg/navbar/restaurant.svg";
import {ReactComponent as Menu} from "@svg/navbar/menu.svg";
import {ReactComponent as News} from "@svg/navbar/news.svg";
import {ReactComponent as Achievement} from "@svg/navbar/achievement.svg";
import {ReactComponent as Push} from "@svg/navbar/notifications.svg";
import {ReactComponent as Orders} from "@svg/navbar/orders.svg";
import {ReactComponent as Feedback} from "@svg/navbar/feedback.svg";
// import {ReactComponent as Cards} from "@svg/navbar/cards.svg";
import {ReactComponent as Subscribe} from "@svg/navbar/subscribe.svg";
import {ReactComponent as Market} from "@svg/navbar/market.svg";
import {ReactComponent as Update} from "@svg/navbar/updates.svg";
import {ReactComponent as FAQ} from "@svg/navbar/faq.svg";
import {ReactComponent as Settings} from "@svg/navbar/settings.svg";
import {ReactComponent as Employees} from "@svg/navbar/employees.svg";
import {ReactComponent as Logout} from "@svg/navbar/logout.svg";
import {ReactComponent as Dashboard} from "@svg/navbar/dashboard.svg";
const icons = {
    manage: Manage,
    restaurant: Restaurant,
    menu: Menu,
    news: News,
    achievement: Achievement,
    push: Push,
    orders: Orders,
    feedback: Feedback,
    // cards: Cards,
    subscribe: Subscribe,
    market: Market,
    updates: Update,
    faq: FAQ,
    settings: Settings,
    employees: Employees,
    logout: Logout,
    dashboard: Dashboard
}

export default icons;