import React from "react"
import { AnimatePresence } from "framer-motion"

const variants = {
    initial: { 
        opacity: 0,
        scale: 0.8,
        duration: 0
    },
    animate: { 
        opacity: 1,
        scale: 1,
        duration: 0
    },
}

// App wrapped with Notifications component wich has an AnimatePresence
export default function SimpleAppearAnimation({children, index}) {    
    return (
        <AnimatePresence exitBeforeEnter>
            {
                React.cloneElement(children, {
                    variants,
                    initial: "initial",
                    animate: "animate",
                    exit: "initial",
                    key: index,
                })
            }
        </AnimatePresence>
    ) 
}