import React, { useContext, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { 
    ManagerDishes,
    LoadingSpinner
} from "@components";
import { 
    Form,
    Title,
} from "@ui-kit";
import { 
    errorProcessing,
    serviceProcessing
} from "@utils";
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// routes
import ROUTES from "@routes";

export default function ManagerSearch(props) {
    const [ searchList, setSearchList ] = useState([]);
    const [ searchTitle, setSearchTitle ] = useState("");
    const { t } = useTranslation();
    const { searchDishes } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(false)
    
    const formRef = useRef();
    
    const initialForm = {
        type: "search",
        submit: null,
        callback: submitSearch,
        columns: [{
            size: 12,
            fields: [{
                type: "text",
                name: "title",
                label: t("fields.menu.search"),
                defaultValue: "",
            }]
        }]
    }

    function submitSearch(data, reset, resolve, reject) {
        setLoading(true);
        searchDishes(data)
            .then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    setSearchTitle(formRef.current.getFormValues("title"));
                    setSearchList(data);
                    resolve(res);
                }, errors => reject({messages: errors}))
            })
            .catch(error => errorProcessing(error, notify, "wrapper_data"))
            .finally(() => setLoading(false))
    }
    
    return <>
        <div style={{
            width: "324px",
            maxWidth: "100%"
        }}>
            <Form form={initialForm} ref={formRef}/>
        </div>
        {
            loading ? <div style={{
                position: "relative",
                height: 250
            }}><LoadingSpinner position="absolute"/></div> :
            searchList.length ? (
                <div className="mb-5 mt-3">
                    {
                        // searchList.map(menu => {
                        //     const { title, id, submenus } = menu;
                        //     let breadcrumbs = [{
                        //         title: title,
                        //         link: `${ROUTES.menu}/${id}`
                        //     }]
                        //     return searchList
                        // })
                        searchList.map(submenu => {
                            const { title, id, dishes } = submenu;
                            let list = [
                                // ...breadcrumbs,
                                {
                                    title: title,
                                    link: `${ROUTES.menu}/${id}`
                                }
                            ]

                            return <div key={id} style={{
                                position: "relative",
                                // zIndex: 2
                            }}>
                                {
                                    dishes ? <>
                                        <ManagerDishes 
                                            model={dishes} 
                                            isSearch={true} 
                                            sortable={false}
                                            breadcrumbs={list}
                                            submenuId={id}
                                            {...props}
                                        />
                                    </> : null
                                }
                            </div>
                        })
                    }
                </div>
            ) : (searchTitle ? <Title className="mb-5 pb-3">{t("search.not_found", {searchTitle})}</Title> : null)
        }
    </>
}