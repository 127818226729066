import React from "react"
import { useTranslation } from "react-i18next"
import {
    ColumnsRenderer,
    Description
} from "@ui-kit"

export default function AcceptingOrdersRow(props) {
    const { columns, ...form } = props;
    
    const { t } = useTranslation();
    let withCard = form.watch("settings[is_pay_card]");
    // const cpRef = useRef();
    
    return (
        <>
            <ColumnsRenderer {...columns[0]} {...form}/>
            {
                withCard ? <><ColumnsRenderer {...columns[1]} {...form}/>
                <div className="col-md-12">
                    <Description text={t("manage.form.description_online")} className="width-limitless fz-sm"/>
                </div></> : null
            }
        </>
    )
}