import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
// components
import {
    ContextMenu,
    LoadingSpinner,
    SimpleAppearAnimation
} from "@components";
// ui
import { Title } from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";
// svg
import placeholderBg from "@svg/empty-image.svg"
import { ReactComponent as Calendar } from "@svg/push-calendar.svg"
import { ReactComponent as Users } from "@svg/users_fill_black.svg"
import { ReactComponent as Views } from "@svg/news_views.svg"

export default function ManagePush(props) {
    const {
        title, description, date_publication, image, users_count, views_count, is_sent, 
        onClick, onDelete, 
        animationCount,
        loading
    } = props;
    const { t } = useTranslation();
    const contextMenu = [
        {
            type: "settings",
            onClick: onClick,
            label: t("context_menu.settings")
        },
        {
            type: "delete",
            onClick: onDelete,
            label: t("context_menu.delete")
        }
    ];
    
    return (
        <SimpleAppearAnimation index={animationCount}>
            <motion.div 
                className={classNames(s.item, s.itemNews, {
                    [s.clickable]: !is_sent
                })}
                onClick={(e) => is_sent ? {} : onClick(e)}
            >
                {is_sent ? null : <ContextMenu list={contextMenu}/>}
                <div className={s.newsImage}>
                    { image ? <img alt={title} src={image} className={s.fitImg}/> : <div style={{backgroundImage: `url("${placeholderBg}")`}} className={s.emptyImg}></div> }
                </div>
                <div className={classNames(s.body, s.bodyNews)}>
                    <div className={s.newsContent}>
                        <div className={classNames("d-flex", s.widgets)}>
                            <div className="mr-3 d-flex align-items-center"><Calendar className={s.newsIcon}/>{date_publication}</div>
                            <div className="mr-3 d-flex align-items-center"><Users className={s.newsIcon}/>~{users_count}</div>
                            <div className="d-flex align-items-center"><Views className={s.newsIcon}/>{views_count}</div>
                        </div>
                        <Title className={s.smallTitle}>{title}</Title>
                        <p className={classNames(s.smallDescription, s.newsDescription)}>{description}</p>
                    </div>
                </div>
                { loading ? <LoadingSpinner position="absolute" /> : null }
            </motion.div>
        </SimpleAppearAnimation>
    )
}