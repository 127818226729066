import React from "react";
import {
    ManageFeedback,
    FeedbackBlockWrapper
} from "@components"

export default function FeedbackList({list = [], onDelete, onUpdate}) {
    return (
        <div className="row">
            {list.length ? list.map(item => {
                return <div className="col-md-auto" key={item.id}>
                        <FeedbackBlockWrapper id={item.id} is_answered={item.is_answered} onDelete={onDelete} onUpdate={onUpdate}>
                            <ManageFeedback {...item}/>
                        </FeedbackBlockWrapper>
                    </div>
            }) : null}
        </div>
    )
}