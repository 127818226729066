import React, { useContext } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import s from "./DishModal.module.sass";
import { globalVariablesContext } from "@context";
// import {
//     Accordion
// } from "@ui-kit"

export default function DishModal({list, submenuId, checkedDishesList, onClick}) {
    // const [expanded, setExpanded] = useState(null);
    const { variables: { customization: { second } } } = useContext(globalVariablesContext)
    return (list.length ? (
        // list.map(({title, is_published, submenu_list}, index) => {
        //     let List = () => submenu_list.map(el => {
        //         return (submenuId !== el.value) ? <motion.div 
        //             key={el.value} 
        //             className={classNames(s.listItem, {
        //                 [s.red]: !el.is_published
        //             })}
        //             onClick={() => onClick(el.value, checkedDishesList)}
        //         >{el.label}</motion.div> : null
        //     })
            
        //     return submenu_list.length ? <Accordion
        //         key={index}
        //         i={index}
        //         title={title}
        //         expanded={expanded}
        //         setExpanded={setExpanded}
        //         is_published={is_published}
        //         type="dishes"
        //     >
        //         <List/>
        //     </Accordion> : null
        // })
        list.map(el => {
            return (submenuId !== el.value) ? <motion.div 
                key={el.value} 
                className={classNames(s.listItem, {
                    [s.red]: !el.is_published
                })}
                onClick={() => onClick(el.value, checkedDishesList)}
                style={{"--second-color": `#${second}`}}
            >{el.label}</motion.div> : null
        })
    ) : null)
}