import React, { Component } from "react"
import {
    notificationsContext
} from "@context"

class ErrorBoundary extends Component {
    state = {
        hasError: false
    }
    
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log(error, info);
    }
    
    render() {
        const { children } = this.props;

        if (this.state.hasError) {
            return <div>Ошибка при обработке State</div>
        }

        return children
        
    }
}

const withContext = (Wrapped) => {
    return (props) => {
        return <notificationsContext.Consumer>
            {
                ({ onAdd }) => <Wrapped {...props} onAdd={onAdd} />
            }
        </notificationsContext.Consumer>
    }
}

export default withContext(ErrorBoundary)