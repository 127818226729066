import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
// ui-kit
import {
    Title,
    Field,
    SortableWrapper
} from "@ui-kit";
// context
import { globalVariablesContext } from "@context";
// styles
import s from "./Preview.module.sass";
// placeholder
import logo_placeholder from "@svg/logo-placeholder.svg";
import { ReactComponent as Minus } from "@svg/minus-btn.svg";

export default function Preview({thumbnail_image_logo, title, types, links = [], onSortEnd, onLinkChecked, onRemove, ...form}) {
    const [linksState, setLinks] = useState([]);
    useEffect(() => {
        setLinks(links);
    }, [links])

    return (
        <div className={s.preview}>
            <img src={thumbnail_image_logo || logo_placeholder} alt={title} className={s.logo}/>
            <Title className={s.title}>{title}</Title>
            <p className={s.type}>{types}</p>
            <div className={s.links}>
                <SortableWrapper
                    useDragHandle
                    onSortEnd={onSortEnd}
                    helperClass={s.dragging}
                    axis="y"
                >
                    {
                        linksState.length ? linksState.map((link, index) => {
                            let Handle = () => <div className={s.drag}><span className={s.lines}></span></div>;
                            return (
                                <Link 
                                    key={index}
                                    index={index} 
                                    Handle={Handle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onLinkChecked(index);
                                    }}
                                    onRemove={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onRemove(index);
                                    }}
                                    {...link} 
                                    {...form}
                                />)
                        }) : null
                    }
                </SortableWrapper>
            </div>
        </div>
    )
}

const Link = ({title, value, type, id, index, Handle, onClick, onRemove, ...form}) => {
    const {
        variables: {
            customization: {
                accent,
                second
            }
        }
    } = useContext(globalVariablesContext);

    const [stateTitle, setTitle] = useState(title);
    const [stateValue, setValue] = useState(value);
    const [stateType, setType] = useState(type);
    const [stateId, setId] = useState(id || null);
    
    useEffect(() => {
        setTitle(title);
        setValue(value);
        setType(type);
        setId(id || null);
        
        if (form.setValue) {
            form.setValue(`links[${index}][title]`, title, {shouldValidate: true});
            form.setValue(`links[${index}][value]`, value, {shouldValidate: true});
            form.setValue(`links[${index}][type]`, type, {shouldValidate: true});
            form.setValue(`links[${index}][id]`, id, {shouldValidate: true});
        }
    }, [title, value, type, id, form.setValue])
    
    return (
        <motion.div 
            className={s.link} 
            onClick={onClick}
            style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`,
            }}
        >
            { stateValue && <Handle/> }
            <Title className="mb-0">{stateTitle}</Title>
            {stateValue && <div className={s.delete} onClick={onRemove}><Minus/></div>}
            {(stateValue && stateId) && <Field type="hidden" name={`links[${index}][id]`} defaultValue={stateId} {...form}/>}
            {stateValue && <Field type="hidden" name={`links[${index}][type]`} defaultValue={stateType} {...form}/>}
            {stateValue && <Field type="hidden" name={`links[${index}][title]`} defaultValue={stateTitle} {...form}/>}
            {stateValue && <Field type="hidden" name={`links[${index}][value]`} defaultValue={stateValue} {...form}/>}
        </motion.div>
    )
}