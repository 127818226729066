import React, { useCallback, forwardRef, useImperativeHandle, useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
// fields
import {
    Field,
    Button,
    ColumnsRenderer
} from "@ui-kit"
// styles
import s from "./Form.module.sass";
// context
import { notificationsContext } from "@context"
// routes
import ROUTES from "@routes"

const Form = forwardRef(({form:{type = "", submit, columns, callback, backButton = () => {}}}, ref) => {
    const { clearErrors, register, handleSubmit, errors, reset, control, setValue, getValues, setError, watch, unregister } = useForm({
        mode: 'onSubmit',
    });
    const { t } = useTranslation();
    const { notify } = useContext(notificationsContext);
    const onSubmit = (data) => new Promise(
        function(resolve, reject) {
            callback(data, reset, resolve, reject);
        }
    )
    .catch(errors => {
        console.log(errors);
        if (errors.request && errors.request.status === 500) {
            return notify({title: t("error.error_title"),text: t("error.error_500"), type: "error"});
        } 
        if (errors.request && errors.request.status === 400) {
            return notify({title: t("error.error_title"),text: t("error.error_400"), type: "error"});
        }
        if (errors.request && errors.request.status === 404) {
            return notify({title: t("error.error_title"),text: t("error.undefined_error"), type: "error"});
        }
        if (errors.request && errors.request.response) {
            try {
                return serverErrors(JSON.parse(errors.request.response).errors);
            } catch (e) {
                return notify({title: t("error.error_title"),text: t("error.undefined_error"), type: "error"})
            }
        } else if (errors.messages) {
            return serverErrors(errors.messages);
        } else {
            notify({title: t("error.error_title"),text: t("error.undefined_error"), type: "error"})
        }
    })
    
    const onError = (data) => console.log(data);
    
    const FormFooter = useCallback(() => {
        const checkbox = {
            type: "checkbox", 
            value: true, 
            name: "remember", 
            required: false, 
            label: t("fields.general.remember_me"), 
            description: null, 
            defaultChecked: false,
            wrapperClass: "col-12"
        }
        return (
            type === "login" || type === "register" ? 
            (<div className="row justify-content-between align-items-center">
                {type === "login" ? <Field {...checkbox} refRegister={register} /> : null}
                <div className={classNames("col-12 my-3", {
                    "col-12": type === "login",
                    "col-md-6 mx-auto": type === "register"
                })}>
                    <Button type="submit" className="blue large">{submit}</Button>
                </div>
                { type === "login" ? 
                    <div className="col-12 text-center"><Link to={ROUTES.register}>{t("register.title")}</Link></div>
                 : null }
                { type === "register" ? 
                <div className="col-12 text-center"><Link to={ROUTES.login}>{t("login.title")}</Link></div>
                : null }
            </div>) :
            (
                type === "restaurant_market" ? 
                <div className="row justify-content-center align-items-center mx-0">
                    <span onClick={backButton} className={classNames(s.back, "mr-5")}>{t("buttons.back")}</span>
                    <Button type="custom-submit" className="save">{submit}</Button>
                </div> : (
                    submit === null ? null : (
                        <div className="row justify-content-center align-items-center mx-0">
                            <Button type="custom-submit" className="save">{submit}</Button>
                        </div>
                    )
                )
            )
        )
    }, [type, register, submit])
    // backButton
    function createErrorKey(errors, newKey) {
        if (errors) {
            for (const [key, value] of Object.entries(errors)) {
                newKey += `[${key}]`;
                if (typeof value !== "string") {
                    return createErrorKey(errors[key], newKey);
                }
                return {
                    name: newKey,
                    message: errors[key]
                }
            }
        }
        return {};
    }
    
    function serverErrors(errors) {
        if (errors && Object.entries(errors).length) {
            for (const [key, value] of Object.entries(errors)) {
                let newKey = "";
                let {name, message} = createErrorKey({[key]: value}, newKey);
                
                if (name && message) {
                    if (name.includes("global")) {
                        notify({title: message, type: "error"})
                    } else {
                        setError(name, {
                            type: "manual",
                            message
                        })
                    }
                }
            }
        } else {
            // notify({title: t("error.error_title"),text: t("error.undefined_error"), type: "error"})
            // console.log("errors has no keys");
        }
    }
    
    useImperativeHandle(ref, () => ({
        setMapValidation(value) {
            setValue(...value);
        },
        getFormValues(names = null) {
            return getValues(names);
        },
        unregisterInput(name) {
            return unregister(name)
        }
    }))
    
    const formRendererProps = {
        errors,
        control,
        setValue,
        register,
        getValues,
        setError,
        clearErrors,
        watch,
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} encType="multipart/form-data">
            <div className="row">
                {
                    columns.map((column, index) => {
                        return <ColumnsRenderer 
                            key={index}
                            type={type} 
                            {...column} 
                            {...formRendererProps}
                        />
                    })
                }
            </div>
            {/* { submit ? <br/> : null } */}
            <FormFooter/>
        </form>
    )
})



export default Form;