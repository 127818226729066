import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next"
import classNames from "classnames";
// styles
import s from "./LanguagesRow.module.sass";
import c from "./Card.module.sass";
// ui
import {
    Field,
    Title,
    Button,
    CustomScrollbar,
} from "@ui-kit"
// utils
import { smoothScrolling } from "@utils";

// just languagesRow component where title renamed to close_text

function reducer(state, action) {
    switch (action.type) {
        case "INIT_LANGUAGE":
            let initLanguage = {
                ...state,
                currentLanguage: action.language,
            }
            // cards
            initLanguage.cards = action.translations.map(el => {
                return {
                    ...el,
                    existed: true
                }
            });
            initLanguage.fields = action.fields
            
            return initLanguage
        case "CHANGE_LANGUAGE":
            return {
                ...state,
                currentLanguage: action.language
            };
        case "REMOVE_CARD": 
            return {
                ...state,
                cards: [
                    ...state.cards.slice(0, action.payload),
                    ...state.cards.slice(action.payload + 1)
                ],
            }
        case "CREATE_CARD":
            return {
                ...state,
                cards: [
                    ...state.cards,
                    action.payload
                ],
            }
        default:
            return state
    }
}

export default function LanguagesRowCustomText(props) {
    const {
        type,
        columns, 
        translations, language_list, rules, 
        errors, getValues, setError, watch, clearErrors,
        ...form
    } = props;
    const [ close_textRule, setTitleRule ] = useState();
    const [ descriptionRule, setDescriptionRule ] = useState();
    const [ close_textType, setTitleType ] = useState("text");
    // const { t } = useTranslation();
    const scrollbar = useRef();
    const cardRef = useRef({});
        
    // initial state
    const initialState = {
        currentLanguage: localStorage.getItem("i18nextLng"),
        cards: [],
        fields: []
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    // card
    function changeLanguage({select, language}) {
        let index = state.cards.findIndex(el => el.language === language);
        let close_text = getValues(`translations[${index}][close_text]`);
        // let description = getValues(`translations[${index}][description]`);
        
        if (cardRef.current[language]) {
            let top = cardRef.current[language].offsetTop;
            smoothScrolling(top, 300, scrollbar.current.view);
        } else {
            smoothScrolling(scrollbar.current.view.scrollHeight, 300, scrollbar.current.view);
        }

        form.setValue("language", select);
        if (close_textType === "select") {
            form.setValue("close_text", {
                label: close_text,
                value: close_text
            }, {shouldValidate: true});
        } else {
            form.setValue("close_text", close_text, {shouldValidate: true});
        }
        // form.setValue("description", description, {shouldValidate: true});

        dispatch({
            type: "CHANGE_LANGUAGE",
            language
        });
    }
    
    function onClickCard(language) {
        changeLanguage({
            select: language_list.find(el => el.label === language),
            language
        });
    }
    
    function onRemove(index) {
        dispatch({
            type: "REMOVE_CARD",
            payload: index
        });
    }
    
    function createCard(language, close_text, description) {
        dispatch({
            type: "CREATE_CARD",
            payload: {
                close_text,
                description,
                language,
                is_active: true,
                existed: false
            }
        })
    }
    
    function preCreateCard(language) {
        dispatch({
            type: "CHANGE_LANGUAGE",
            language
        });
        form.setValue("close_text", "", {shouldValidate: true});
        form.setValue("description", "", {shouldValidate: true});
    }
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (columns[0].fields.length && translations.length) {
                if (columns[0].fields.find(el => el.name === "close_text").type === "select") {
                    setTitleType("select");
                }
                dispatch({
                    type: "INIT_LANGUAGE",
                    fields: columns[0].fields,
                    translations,
                    language: translations.find(el => el.main).language
                });
            }
        }
    }, [columns, translations, language_list])

    useEffect(() => {
        if (rules) {
            if (rules.close_textRule) setTitleRule(rules.close_textRule);
            if (rules.descriptionRule) setDescriptionRule(rules.descriptionRule);
        }
    }, [rules])

    useEffect(() => {
        let isMounted = true;
        let timeout = null;
        if (isMounted) {
            if (Object.keys(errors)[0] && Object.keys(errors)[0] === "translations" && errors.translations) {
                let index = Object.keys(errors.translations)[0];
                if (state.cards[index]) {
                    changeLanguage({
                        select: language_list.find(el => el.label === state.cards[index].language),
                        language: state.cards[index].language
                    });
                    timeout = setTimeout(() => {
                        if (errors.translations[index].close_text) {
                            setError("close_text", {
                                type: "manual",
                                message: errors.translations[index].close_text.message
                            })
                        }
                        if (errors.translations[index].description) {
                            setError("description", {
                                type: "manual",
                                message: errors.translations[index].description.message
                            })
                        }
                    }, 0)
                }
            }
        }

        return () => {
            isMounted = false;
            clearTimeout(timeout);
        }
    }, [errors])
    
    useEffect(() => {
        let isMounted = true;
        // scroll to new language card after creating
        if (isMounted && cardRef.current[state.currentLanguage]) {
            let top = cardRef.current[state.currentLanguage].offsetTop;
            smoothScrolling(top, 300, scrollbar.current.view);
            // close_text
            let close_textName = `translations[${state.cards.findIndex(el => el.language === state.currentLanguage)}][close_text]`;
            let close_textValue = getValues("close_text").value || getValues("close_text");
            // description
            let descriptionName = `translations[${state.cards.findIndex(el => el.language === state.currentLanguage)}][description]`;
            let descriptionValue = getValues("description");
            
            form.setValue(close_textName, close_textValue, {shouldValidate: true});
            form.setValue(descriptionName, descriptionValue, {shouldValidate: true});
        }
        
        return () => isMounted = false;
    }, [state.cards])
    
    function languageOnChangeHandler(selected) {
        let card = state.cards.find(el => el.language === selected.label);
        if (card) {                                    
            changeLanguage({
                select: selected,
                language: selected.label
            });
        } else {
            preCreateCard(selected.label);
        }
    }
    
    function removeEmptyCard(type) {
        let value = getValues(type).value || getValues(type);
        let index = state.cards.findIndex(el => el.language === state.currentLanguage)

        if (getValues("close_text") || getValues("description")) {
            if ( index === -1) {
                let close_text = getValues("close_text");
                let description = getValues("description");
                createCard(state.currentLanguage, close_text, description);
            }
        } else {
            if (index !== -1 && !state.cards[index].existed) {
                onRemove(index);
            }
        }
        form.setValue(`translations[${index}][${type}]`, value, {shouldValidate: true})
    }

    return (
        <div className="col-12">
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        {
                            state.fields.length ? state.fields.map(field => {
                                if (field.type === null) {
                                    return null
                                }
                                if (field.name === "language") {
                                    return <Field key={field.name}
                                        {...field}
                                        {...form}
                                        className={s.uppercase}
                                        onChangeHandler={(selected) => {
                                            if (field.onChangeHandler) { field.onChangeHandler(selected); }
                                            languageOnChangeHandler(selected);
                                        }}
                                        errors={errors}
                                        rules={null}
                                        wrapperClass={classNames("col-md-12", {
                                            "d-none": language_list.length <= 1
                                        })}
                                    />
                                }
                                if (field.name === "close_text" || field.name === "description") {
                                    if (field.type !== "select") {
                                        return <Field key={field.name} 
                                            {...field}
                                            {...form}
                                            onChange={() => removeEmptyCard(field.name)}
                                            errors={errors}
                                            rules={null}
                                        />
                                    }
                                    if (field.type === "select") {
                                        return <Field key={field.name} 
                                            {...field}
                                            {...form}
                                            onChangeHandler={(selected) => {
                                                setTimeout(() => removeEmptyCard(field.name), 0);
                                                return selected;
                                            }}
                                            errors={errors}
                                            rules={null}
                                        />
                                    }
                                }
                                return <Field key={field.name} 
                                    {...field}
                                    {...form}
                                    errors={errors}
                                />
                            }) : null
                        }
                    </div>
                </div>
                <div className={classNames("col-md-6", {
                    "d-none": language_list.length <= 1
                })}>
                    <div className={s.cards}>
                        <CustomScrollbar
                            ref={scrollbar}
                        >
                            {
                                state.cards.map(({main, ...card}, index) => {
                                    let icon = language_list.find(el => el.label === card.language)?.icon;
                                    return <LanguageCard 
                                        key={index} 
                                        index={index}
                                        inactive={main}
                                        focused={card.language === state.currentLanguage}
                                        remove={!main}
                                        icon={icon}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (card.language !== state.currentLanguage) {
                                                onClickCard(card.language);
                                            }
                                        }}
                                        cardRef={cardRef}
                                        close_textRule={close_textRule}
                                        descriptionRule={descriptionRule}
                                        onRemove={(e) => {
                                            e.stopPropagation();
                                            onRemove(index);
                                            changeLanguage({
                                                select: language_list.find(el => el.main),
                                                language: language_list.find(el => el.main).label
                                            });
                                        }}
                                        watch={watch}
                                        {...card}
                                        {...form}
                                    />
                                })
                            }
                        </CustomScrollbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

function LanguageCard({
    is_active, 
    existed, 
    focused, 
    remove, 
    close_text, description, language, 
    index, 
    icon, 
    onClick, 
    cardRef, 
    inactive, 
    close_textRule, 
    descriptionRule, 
    onRemove, 
    watch, ...form
}) {
    const { t } = useTranslation();
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (close_text?.value) {
                form.setValue(`translations[${index}][close_text]`, close_text.value, {shouldValidate: true});
            } else {
                form.setValue(`translations[${index}][close_text]`, close_text, {shouldValidate: true});
            }
            // form.setValue(`translations[${index}][description]`, description, {shouldValidate: true});
        }
        return () => isMounted = false;
    }, [close_text]) //, description

    let label = (
        <div className="row align-items-center">
            <span className={c.icon} style={{backgroundImage: `url("${icon}")`}}></span>
            {language.toUpperCase()}
        </div>
    )
    
    return (
        <div 
            className={classNames(c.card, {
                [c.active]: focused
            })}
            ref={el => cardRef.current[language] = el}
            onClick={onClick}
        >
            <div className="row">
                <Field 
                    type="checkbox" 
                    mode="switch" 
                    defaultChecked={is_active} 
                    label={label} 
                    name={`translations[${index}][is_active]`}
                    wrapperClass="col-md-auto"
                    inactive={inactive}
                    {...form}
                />
                {
                    remove && (
                        <label className={c.remove} onClick={onRemove}>
                            <Button type="remove" title={t("fields.translations.delete")}/>
                        </label>
                    )
                }
            </div>
            <Field
                type="hidden"
                name={`translations[${index}][language]`}
                defaultValue={language}
                {...form}
            />
            <Title className={c.close_text}>{watch(`translations[${index}][close_text]`)}</Title>
            <Field
                type="hidden"
                name={`translations[${index}][close_text]`}
                defaultValue={close_text}
                {...close_textRule}
                {...form}
            />
            {/* <p className={c.description}>{watch(`translations[${index}][description]`)}</p>
            <Field
                type="hidden"
                name={`translations[${index}][description]`}
                defaultValue={description}
                {...descriptionRule}
                {...form}
            /> */}
        </div>
    )
}