import { ReactComponent as SettingsSVG } from "@svg/settings.svg"
import { ReactComponent as TrashSVG } from "@svg/trash.svg"
import { ReactComponent as CopySVG } from "@svg/context/copy.svg"
import { ReactComponent as CopyToSVG } from "@svg/context/copy_to.svg"
import { ReactComponent as MoveToSVG } from "@svg/context/move_to.svg"
import { ReactComponent as ShowSVG } from "@svg/context/show.svg"
import { ReactComponent as HideSVG } from "@svg/context/hide.svg"
const icons = {
    settings: <SettingsSVG/>,
    delete: <TrashSVG/>,
    copy: <CopySVG/>,
    copy_to: <CopyToSVG/>,
    move_to: <MoveToSVG/>,
    show: <ShowSVG/>,
    hide: <HideSVG/>
}

export default icons;