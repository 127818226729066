import React, { useState, useContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Form,
    Modal,
    Title
} from "@ui-kit"
import {
    errorProcessing,
    serviceProcessing,
    updateRules
} from "@utils"
import { FeedbackModalContent } from "@components"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "INIT_UPDATE":
            let updateForm = {...form};
            return updateRules(updateForm, action.rules);
        default:
            return form
    }
}

export default function FeedbackBlockWrapper({children, id, onUpdate, onDelete, is_answered}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    const [ feedbackData, setFeedback ] = useState({
        date_create: "",
        feedback: "",
        is_like: true,
        answer: null,
        is_answered: false
    })
    function closeModal() {
        setOpen(false);
    }
    const {
        getFeedback,
        updateFeedback,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateFeedback(data, id).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                if (data.archive) {onDelete(data.id);}
                else {onUpdate(data);}
                closeModal();
            }, (errors) => reject({messages: errors})) 
        })
        .catch(reject)
        .finally(() => setLoading(false))
    }
    
    const initialForm = {
        type: "restaurant_menu_add",
        submit: t("buttons.answer"),
        callback: callbackUpdate,
        columns: [
            {
                size: 12,
                fields: [{
                    type: "textarea",
                    defaultValue: null,
                    name: "answer",
                    label: t("fields.feedback.answer"),
                    wrapperClass: "col-md-12",
                }]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (isOpen) {
                if (id !== null) {
                    getFeedback(id)
                        .then((res) => {
                            serviceProcessing(res, notifyList, (data) => {
                                dispatch({type: "INIT_UPDATE", ...data});
                                setFeedback(data.model);
                                setLoading(false);
                            })
                        })
                        .catch(error => errorProcessing(error, notify, "detail_data"))
                        .finally(() => setLoading(false))
                }
            }
        }
        return () => {
            isMounted = false;
            setLoading(true);
        }
        
    }, [isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true)
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                loading={loading}
            >
                <Title type="modal" className="mb-4">{t("feedback.form.title")}</Title>
                <FeedbackModalContent {...feedbackData}/>
                {
                    is_answered ? null : <Form form={form}/>
                }
            </Modal>
        </>
    )
}