import React, { useContext } from "react";
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next";
import { SimpleAppearAnimation } from "@components"
// ui
import { Title } from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";
// context
import { globalVariablesContext } from "@context"

export default function ManageStatus(props) {
    const {title, list, qr, animationCount} = props;
    const { t } = useTranslation();
    const { variables: {customization: { second, accent } } } = useContext(globalVariablesContext)
    return (
        <SimpleAppearAnimation key={animationCount}>
            <motion.div className={s.item}>
                <div className={s.body}>
                    <Title type="block">{title}</Title>
                    <div className="row">
                        {
                            qr ? (
                                <div className="col-auto pr-0">
                                    <img src={qr} alt={t("manage.status.download_qr")} className={s.qrImg}/>
                                </div>
                            ) : null
                        }
                        <div className="col">
                            {
                                list.map(({title, value}, index) => {
                                    return (
                                        <div className={s.field} key={index}>
                                            <span className={s.title}>{`${title}: `}</span>
                                            <span className={s.value}>{value}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {qr ? <div>
                        <a 
                            href={qr} 
                            download="qr_code.jpg" 
                            className={s.qrDownload} 
                            style={{ 
                                "--accent-color": `#${accent}`,
                                "--second-color": `#${second}`
                            }}
                        >{t("manage.status.download_qr")}</a>
                    </div> : null}
                </div>
            </motion.div>
        </SimpleAppearAnimation>
    )
}