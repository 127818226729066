import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    sortableHandle
} from 'react-sortable-hoc';

const SortableItem = SortableElement(({elem, ...props}) => {
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    return React.cloneElement(elem, { 
        onMouseDown,
        ...props
    })
})

const SortableList = SortableContainer(({items, className = ""}) => {
    return (
        <div className={className}>
            {items.map(child => {
                const { Handle, index, collection = 0, sortKey, ...childProps } = child.props;
                const EnchantedHandle = sortableHandle((props) => <Handle {...props}/>);
                if (Handle) {
                    return <SortableItem 
                        elem={child} 
                        index={index} 
                        key={`item-${sortKey || index}`}
                        collection={collection}
                        Handle={EnchantedHandle}
                        {...childProps}
                    />
                } else {
                    return <SortableItem 
                        elem={child} 
                        index={index} 
                        key={`item-${sortKey}`}
                        collection={collection}
                        {...childProps}
                    />
                }
            })}
        </div>
    )
})

const SortableWrapper = ({children, ...options}) => {
    const [ isDesktop, setDesktop ] = useState(true);
    function onResize() {
        let width = document.body.clientWidth;
        if (width >= 768) {
            setDesktop(true)
        } else {
            setDesktop(false)
        }
    } 
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            onResize();
            window.addEventListener("resize", onResize);
        }
        return () => {
            isMounted = false;
            window.removeEventListener("resize", onResize);
        }
    }, [])
    
    return (
        children ? <SortableList 
            {...options} 
            items={[...children]} 
            onSortStart={(data) => {
                if (typeof options.onSortStart === "function") {
                    options.onSortStart(data)
                }
            }}
            onSortEnd={(data) => {
                if (typeof options.onSortEnd === "function") {
                    options.onSortEnd(data)
                }
            }}
            pressDelay={isDesktop ? 0 : (options.useDragHandle ? 0 : 200)}
            distance={isDesktop ? 4 : 0}
        /> : null
    )
    
}

export default SortableWrapper;