import React from "react"
import s from "./DashboardPopular.module.sass"
import placeholderLogo from "@svg/logo-placeholder.svg";

export default function DashboardPopular({
    // percent,
    title, 
    label,
    image
}) {
    return <div className={s.popular}>
        <div className={s.img} style={{
            backgroundImage: `url(${image ? image : placeholderLogo})`,
            // marginBottom: percent ? "0" : "24px"
            marginBottom: "9px"
        }}></div>
        {/* { percent ? <p className={s.percent}>{percent}%</p> : null } */}
        <p className={s.title} title={title}>{title}</p>
        <p className={s.label}>{label}</p>
    </div>
}