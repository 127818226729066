import React from "react";
import {
    ManageNews,
    NewsBlockWrapper
} from "@components"

export default function NewsList({list, onDelete, onUpdate}) {
    return (
        list.map(item => {
            return <div className="col-md-auto" key={item.id}>
                    <NewsBlockWrapper id={item.id} onDelete={onDelete} onUpdate={onUpdate}>
                        <ManageNews {...item}/>
                    </NewsBlockWrapper>
                </div>
        })
    )
}