import React from "react";
import classNames from "classnames";
import s from "./InfoAlerts.module.sass";

export default function InfoAlerts({text, className = ""}) {
    return (
        <div 
            className={classNames(s.alert, className, {
                [s.textCenter]: className.includes("center"),
                [s.notify]: className.includes("notify"),
                [s.warning]: className.includes("warning"),
                [s.error]: className.includes("error"),
                [s.success]: className.includes("success")
            })}
            dangerouslySetInnerHTML={{__html: text.join("")}}
        ></div>
    )
}