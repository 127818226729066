import React, { createContext, useEffect, useState, useReducer } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch
} from "react-router-dom";
// pages
import {
  ManagePage,
  // WaiterPage,
  LoginPage,
  RegisterPage,
  // AdministrationPage,
  // MenuPage,
  SubmenuPage,
  DishesPage,
  NewsPage,
  PushPage,
  // OrdersPage,
  OrdersInnerPage,
  FeedbackPage,
  // CardsPage,
  // SubscribePage,
  UpdatesPage,
  FAQPage,
  SettingsPage,
  // MarketPage,
  // MarketInnerPage
  // AchievementsPage,
  DashboardPage
} from "@pages"
import { 
  Notifications,
  ErrorBoundary,
  LoadingSpinner,
  Layout,
} from "@components"
import {
  serviceProcessing
} from "@utils"
// service
import adminService from "@services/admin-service";
import managerService from "@services/manager-service";
import "../../fonts/fonts.css";
import ROUTES from "@routes";

import firebase from "firebase/app"
import 'firebase/messaging'

const adminMethods = new adminService();
const managerMethods = new managerService();
const adminMethodsContext = createContext(null);
const managerMethodsContext = createContext(null);
const globalVariablesContext = createContext(null);
const defaultCustomization = {
  accent: "FFA643",
  second: "EB6B1B"
}

const variablesDef = {
  currency: "KZT",
  customization: defaultCustomization,
  title: "",
  status: false,
  status_message: "", 
  subscribe: false,
  subscribe_message: "",
  language: "ru",
  phoneMask: [
    "+9 999 999 99 99",
    "+999 99 999 99 99",
    "+999 9999 999 999"
  ]
}

const vapidKey = "BKa-jgzdhLFLh07lA4BKq_iPP42WQzfUOTgUyB-sV4-qUQQglLL0GIawy3NTDsHEITLytB44GoFW8YNbGtN8ozk"

function reducer(store, action) {
  switch (action.type) {
    case "INIT":
      return {
        ...action.payload
      }
    case "UPDATE_TITLE":
      return {
        ...store,
        title: action.payload
      }
    case "UPDATE_CUSTOM":
      return {
        ...store,
        customization: action.payload || defaultCustomization
      }
    case "UPDATE_CURRENCY":
      return {
        ...store,
        currency: action.payload
      }
    case "UPDATE_LANGUAGE":
      return {
        ...store,
        language: action.payload.language,
        subscribe_message: action.payload.subscribe_message,
        status_message: action.payload.status_message,
      }
    case "UPDATE_SUBSCRIBE":
      return {
        ...store,
        subscribe: action.payload
      }
    case "UPDATE_STATUS":
      return {
        ...store,
        status: action.payload
      }
    case "UPDATE_TAG":
      return {
        ...store,
        tag: action.payload
      }
    default:
      return store
  }
}

function createPayload(data) {
  const { 
    currency_code: currency,
    phone_mask: phoneMask,
    customization: { accent, second },
    ...another
  } = data;

  const payload = {
    customization: { accent, second },
    currency,
    phoneMask,
    ...another
  }
  return payload
}

var firebaseConfig = {
  apiKey: "AIzaSyCWDWAZSMdBFwqqyMCkD-jZGQAtSy8pkyg",
  authDomain: "our-menu-appetite.firebaseapp.com",
  databaseURL: "https://our-menu-appetite.firebaseio.com",
  projectId: "our-menu-appetite",
  storageBucket: "our-menu-appetite.appspot.com",
  messagingSenderId: "733084878740",
  appId: "1:733084878740:web:9574ada51af716b2643181",
  measurementId: "G-CPDLMVFF0S"
};

function App() {
  const { ready } = useTranslation();
  const [ loading, setLoading ] = useState(true);
  const { getStorageData, setFirebaseToken } = managerMethods;
  const history = useHistory();
  
  function updateVariables() {
    getStorageData().then(res => {
      serviceProcessing(res, null, (data) => {
        reinitVariables(data);
        if (data.language !== i18next.language) {
          i18next.changeLanguage(data.language)
        }
      })
    }).finally(() => setLoading(false))
  }
  
  function reinitVariables(data) {
    localStorage.setItem("storageData", JSON.stringify(createPayload(data)))
    dispatch({type: "INIT", payload: createPayload(data) })
  }
  
  const isLogin = useRouteMatch(ROUTES.login)
  const isRegister = useRouteMatch(ROUTES.register)
  
  useEffect(() => {
    const token = localStorage.getItem("token") || sessionStorage.getItem("token");
    if (!token && !(isLogin || isRegister)) {
      return window.location.href = ROUTES.login;
    } 
    if (!(isLogin || isRegister)) { 
      return setTimeout(updateVariables, 0)
    }
  }, [])

  const [ menu, setMenu ] = useState(null);
  
  const localStorageData = localStorage.getItem("storageData");
  const defaultStore = localStorageData ? JSON.parse(localStorageData) : variablesDef;

  const [ variables, dispatch ] = useReducer(reducer, defaultStore)
  
  useEffect(() => {
    localStorage.setItem("storageData", JSON.stringify(createPayload(variables)))
  }, [ variables ])
  
  // firebase
  function swMessageHandler(event) {
    if (event?.data?.link) {
      history.push(event?.data?.link)
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const token = localStorage.getItem("token") || sessionStorage.getItem("token");
      
      if (token) {
        if (firebase?.messaging?.isSupported()) {
          firebase.initializeApp(firebaseConfig);
          const messaging = firebase.messaging();
          messaging.getToken({vapidKey})
            .then((currentToken) => {
                if (currentToken) { 
                  setFirebaseToken(currentToken);
                  navigator.serviceWorker.addEventListener('message', swMessageHandler);
                }
                else { console.log('No registration token available. Request permission to generate one.'); }
            }).catch(() => {
              // console.log(error);
            })
        } else {
          console.log("Your browser doesn't support firebase psuh notifications");
        }
      }
    }
    return () => {
      isMounted = false;
      navigator.serviceWorker.removeEventListener('message', swMessageHandler);
    }
  }, [])
  
  if (!ready && loading) {
    return <LoadingSpinner/>
  }

  const routes = [{
    path: ROUTES.register,
    Component: RegisterPage,
    exact: false
  },{
    path: ROUTES.login,
    Component: LoginPage,
    exact: false
  },{
    path: ROUTES.manage,
    Component: ManagePage,
    exact: true
  },
  // {
  //   path: ROUTES.administration,
  //   Component: AdministrationPage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.waiter,
  //   Component: WaiterPage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.menu,
  //   Component: MenuPage,
  //   exact: true
  // },
  // {
  //   path: `${ROUTES.menu}/:id`,
  //   Component: SubmenuPage,
  //   exact: true
  // },
  {
    path: ROUTES.menu,
    Component: SubmenuPage,
    exact: true
  },
  // {
  //   path: `${ROUTES.submenu}/:id`
  //   Component: DishesPage,
  //   exact: true
  // },
  {
    path: `${ROUTES.menu}/:id`,
    Component: DishesPage,
    exact: true
  },{
    path: ROUTES.news,
    Component: NewsPage,
    exact: true
  },{
    path: ROUTES.push,
    Component: PushPage,
    exact: true
  },{
    path: `${ROUTES.push}/archive`,
    Component: () => <Redirect to={`${ROUTES.push}/archive/1`}/>,
    exact: true,
  },{
    path: `${ROUTES.push}/archive/:page`,
    Component: PushPage,
    exact: true
  },
  // {
  //   path: ROUTES.orders,
  //   Component: OrdersPage,
  //   exact: true
  // },
  {
    path: ROUTES.orders,
    Component: () => <Redirect to={`${ROUTES.orders}/1`}/>,
    exact: true
  },
  {
    path: `${ROUTES.orders}/:page`,
    Component: OrdersInnerPage,
    exact: true
  },
  {
    path: `${ROUTES.orders}/archive/:page`,
    Component: OrdersInnerPage,
    exact: true
  },
  {
    path: ROUTES.feedback,
    Component: FeedbackPage,
    exact: true
  },{
    path: `${ROUTES.feedback}/archive`,
    Component: () => <Redirect to={`${ROUTES.feedback}/archive/1`}/>,
    exact: true,
  },{
    path: `${ROUTES.feedback}/archive/:page`,
    Component: FeedbackPage,
    exact: true
  },
  // {
  //   path: ROUTES.cards,
  //   Component: CardsPage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.subscribe,
  //   Component: SubscribePage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.updates,
  //   Component: UpdatesPage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.faq,
  //   Component: FAQPage,
  //   exact: true
  // },
  {
    path: ROUTES.settings,
    Component: SettingsPage,
    exact: true
  },
  // {
  //   path: ROUTES.market,
  //   Component: MarketPage,
  //   exact: true
  // },
  // {
  //   path: `${ROUTES.market}/:id`,
  //   Component: MarketInnerPage,
  //   exact: true
  // },
  // {
  //   path: ROUTES.achievements,
  //   Component: AchievementsPage,
  //   exact: true
  // },
  {
    path: ROUTES.dashboard,
    Component: DashboardPage,
    exact: true
  }
] 

  return (
    <Notifications>
      <ErrorBoundary>
        <adminMethodsContext.Provider value={adminMethods}>
          <managerMethodsContext.Provider value={managerMethods}>
            <globalVariablesContext.Provider value={{
              variables,
              updateTitle: (payload) => dispatch({type: "UPDATE_TITLE", payload}),
              updateCustom: (payload) => dispatch({type: "UPDATE_CUSTOM", payload}),
              updateCurrency: (payload) => dispatch({type: "UPDATE_CURRENCY", payload}),
              updateLanguage: (payload) => dispatch({type: "UPDATE_LANGUAGE", payload}),
              updateSubscribeStatus: (payload) => dispatch({type: "UPDATE_SUBSCRIBE", payload}),
              updateStatus: (payload) => dispatch({type: "UPDATE_STATUS", payload}),
              updateTag: (payload) => dispatch({type: "UPDATE_TAG", payload}),
              reinitVariables,
              updateVariables
            }}>
                <Layout menu={menu} setMenu={setMenu}>
                  <Switch>
                    {
                      routes.map(({path, Component, exact}) => {
                        return <Route path={path} exact={exact} key={path}>
                          <Component/>
                        </Route>
                      })
                    }
                    <Route exact path={ROUTES.not_found} render={() => <div>404 Not Found</div>} />
                    <Route>
                      <Redirect to={ROUTES.not_found}/>
                    </Route>
                  </Switch>
                </Layout>
            </globalVariablesContext.Provider>
          </managerMethodsContext.Provider>
        </adminMethodsContext.Provider>
      </ErrorBoundary>
    </Notifications>
  )
}

export { 
  adminMethodsContext,
  managerMethodsContext,
  globalVariablesContext
};
export default App;