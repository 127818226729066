import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames"
import {
    globalVariablesContext
} from "@context"
import s from "./DashboardImpressions.module.sass"
import { ReactComponent as Views } from "@svg/dashboard-views.svg";
import { ReactComponent as Actions } from "@svg/dashboard-actions.svg";
import { ReactComponent as Subscriptions } from "@svg/dashboard-subscriptions.svg";
import { ReactComponent as Reviews } from "@svg/dashboard-reviews.svg";

export default function DashboardImpressions(props) {
    const { t } = useTranslation()
    const [ data, setData ] = useState({
        views: {
            count: 0,
            overall: 0,
            percent: 0
        },
        actions: {
            count: 0,
            overall: 0,
            percent: 0
        },
        subscriptions: {
            count: 0,
            overall: 0,
            percent: 0
        },
        reviews: {
            count: 0,
            overall: 0,
            percent: 0
        }
    })
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const {daily_impressions} = props
            setData(daily_impressions)
        }
        return () => { isMounted = false }
    }, [props])
    
    return <div className={s.item}>
        <div className={s.list}>
            <Impression 
                title={t("dashboard.impressions.views")}
                count={data.views.count}
                overall={data.views.overall}
                percent={data.percent}
                Icon={Views}
            />
            <Impression 
                title={t("dashboard.impressions.actions")}
                count={data.actions.count}
                overall={data.actions.overall}
                percent={data.percent}
                Icon={Actions}
            />
            <Impression 
                title={t("dashboard.impressions.subscriptions")}
                count={data.subscriptions.count}
                overall={data.subscriptions.overall}
                percent={data.percent}
                Icon={Subscriptions}
            />
            <Impression 
                title={t("dashboard.impressions.reviews")}
                count={data.reviews.count}
                overall={data.reviews.overall}
                percent={data.percent}
                Icon={Reviews}
            />
        </div>
        <p className={s.label}>{t("dashboard.impressions.daily_impressions")}</p>
    </div>
}

function Impression({
    Icon,
    count,
    percent,
    title,
    overall
}) {
    const { t } = useTranslation()
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext)

    return (
        <div className={classNames(s.impression, "row align-items-center justify-content-between")}
            style={{
                "--color-second": `#${second}`,
                "--color-accent": `#${accent}`,
            }}
        >
            <div className={classNames("d-flex flex-wrap col-md-auto")}>
                <span className={s.icon}><Icon/></span>
                <div className={s.status}>
                    <span className={s.count}>{ count }{ percent ? <sup className={classNames(s.percent, {
                        [s.green]: percent > 0,
                        [s.red]: percent < 0,
                    })}>{percent}%</sup> : null}</span>
                    <p className={s.title}>{title}</p>
                </div>
            </div>
            <div className={classNames(s.overall, "col-md-auto")}>{t("dashboard.overall")} {overall}</div>
        </div>
    )
    
}