import React, { useContext, useEffect, useReducer, useState, useCallback } from "react"
import { Chart } from "@components"
import s from "./DashboardRevenue.module.sass"
import { globalVariablesContext } from "@context"
import { useTranslation } from "react-i18next"
import {
    formatNumber,
    debounce,
    hexToRgba
} from "@utils"

function reducer(store, action) {
    switch (action.type) {
        case "INIT":
            return {
                ...store,
                data: {
                    ...store.data,
                    labels: action.payload.labels,
                    datasets: [{
                        ...store.data.datasets[0],
                        data: action.payload.revenue.cash
                    },{
                        ...store.data.datasets[1],
                        data: action.payload.revenue.online
                    }]
                }
            }
        default:
            return store
    }
}

export default function DashboardRevenue(props) {
    const { t } = useTranslation()
    const [ revenues, setRevenues ] = useState([0,0]) // 0 cash | 1 online
    const { 
        variables: { 
            customization: { 
                accent, second 
            },
            currency
        } 
    } = useContext(globalVariablesContext)
    
    const initialChart = {
        type: "bar",
        data: {
            labels: t("dashboard.week", { returnObjects: true }),
            datasets: [{
                label: t("dashboard.revenue.cash"),
                data: [ 0,0,0,0,0,0,0 ],
                borderColor: `#${accent}`,
                borderWidth: 1,
                backgroundColor: `#${second}`,
            },{
                label: t("dashboard.revenue.online"),
                data: [ 0,0,0,0,0,0,0 ],
                backgroundColor: hexToRgba(`#${accent}`, 0.5),
                order: 1
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        count: 6,
                        callback: function(value) {
                            return formatNumber(value);
                        },
                        padding: 12,
                        font: {
                            size: 12,
                            family: "SF Pro Display"
                        },
                        precision: 0,
                    },
                    grid: {
                        drawBorder: false,
                        color: "#EFEFEF",
                        drawTicks: false
                    }
                },
                x: {
                    beginAtZero: true,
                    stacked: true,
                    grid: {
                        drawBorder: false,
                        color: "#EFEFEF",
                        drawTicks: false
                    },
                    ticks: {
                        padding: 24,
                        font: {
                            size: 12,
                            family: "SF Pro Display"
                        },
                    }
                },
            },
            borderRadius: 4000,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        beforeBody: (body) => {
                            changeRevenue([body[0].formattedValue, body[1].formattedValue])
                        }
                    }
                },
            },
            barThickness: 12,
        }
    }
    
    const [ chart, dispatch ] = useReducer(reducer, initialChart)
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch({ type: "INIT", payload: {
                ...props,
                labels: [...t("dashboard.week", { returnObjects: true }).slice(props.start_day), ...t("dashboard.week", { returnObjects: true }).slice(0, props.start_day)]
            } })
        }
        return () => { isMounted = false }
    }, [props])
    
    const changeRevenue = useCallback( debounce((val) => setRevenues(val), 150), [] )
    
    return (<div className={s.item}>
        <Chart { ...chart } style={{
            margin: "0 0 -20px 0"
        }}/>
        <div className="row justify-content-center mb-1">
            <div className="col-auto"><span style={{color: `#${second}`, "fontWeight": 600}}>{`${currency} ${revenues[0]}`}</span></div>
            <div className="col-auto"><span style={{color: `#${accent}`, "fontWeight": 600}}>{`${currency} ${revenues[1]}`}</span></div>
        </div>
        <div className="row justify-content-center">
            <div className="col-auto">
                <span className={s.label}>{t("dashboard.revenue.title")}</span>
            </div>
            <div className="col-auto">
                <span className={s.dot} style={{"--dot-color": `#${second}`}}>{t("dashboard.revenue.cash")}</span>
            </div>
            <div className="col-auto">
                <span className={s.dot} style={{"--dot-color": `#${accent}`}}>{t("dashboard.revenue.online")}</span>
            </div>
        </div>
    </div>)
}