import React, { useState, useRef, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// ui
import {
    Modal,
    Form,
} from "@ui-kit";
// utils
import { 
    uploadFile,
    serviceProcessing 
} from "@utils"

function reducer(store, action) {
    switch (action.type) {
        case "UPLOAD_LOGO":
            let formLogo = { ...store }
            formLogo.callback = action.onLogoUpdate.bind(undefined, action.file);
            formLogo.columns[0].fields[0].initialRatio = 1;
            formLogo.columns[0].fields[0].image = action.image;
            formLogo.columns[0].fields[1].defaultValue = action.file.name;
            return formLogo
        case "UPLOAD_BG":
            let formBg = { ...store }
            formBg.callback = action.onBgUpdate.bind(undefined, action.file);
            formBg.columns[0].fields[0].initialRatio = 16/9;
            formBg.columns[0].fields[0].image = action.image;
            formBg.columns[0].fields[1].defaultValue = action.file.name;
            return formBg
        default:
            return store
    }
}

export default function ManageImagesWrapper({children, id, updateShort, updateStatus}) {
    const { t } = useTranslation();
    const formRef = useRef();
    // modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    
    const {
        updateLogo,
        updateBg,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    
    const initialForm = {
        type: "restaurant_images",
        submit: t("buttons.save"),
        callback: (data) => {console.log(data);},
        columns: [{
            size: 12,
            fields: [
                {
                    initialRatio: 16/9,
                    image: null,
                    callback: onCrop,
                    type: "cropper",
                },
                {
                    type: "hidden",
                    defaultValue: "",
                    name: "image"
                },
                {
                    type: "hidden",
                    defaultValue: "",
                    name: "x"
                },
                {
                    type: "hidden",
                    defaultValue: "",
                    name: "y"
                },
                {
                    type: "hidden",
                    defaultValue: "",
                    name: "width"
                },
                {
                    type: "hidden",
                    defaultValue: "",
                    name: "height"
                }
            ]
        }]
    }
    
    function onCrop(event) {
        if (formRef.current) {
            formRef.current.setMapValidation(["x", parseInt(event.detail.x)]);
            formRef.current.setMapValidation(["y", parseInt(event.detail.y)]);
            formRef.current.setMapValidation(["width", parseInt(event.detail.width)]);
            formRef.current.setMapValidation(["height", parseInt(event.detail.height)]);
        }
    }
    
    // update restaurant form
    function callback(action) {
        dispatch({...action, onLogoUpdate, onBgUpdate});
        setOpen(true);
        setLoading(false);
    }
    const onFileChange = (e, type, sizes, fileSize) => {
        e.preventDefault();
        uploadFile(e, callback, type, sizes, fileSize, notify)
    }

    function _transformData(data) {
        let formData = new FormData();
        formData.append("image", data.file);
        formData.append("x", data.x);
        formData.append("y", data.y);
        formData.append("width", data.width);
        formData.append("height", data.height);
        
        return formData;
    }
    // file argument added with bind on dispatch
    function onLogoUpdate(file, data, reset, resolve, reject) {
        setLoading(true);
        updateLogo(_transformData({file, ...data}), id)
        .then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    resolve(res);
                    updateShort(data.images);
                    if (data.images.thumbnail_image_logo && data.images.thumbnail_image_bg) {
                        updateStatus(data.status);
                    }
                    closeModal();
                },
                (errors) => reject({messages: errors})
            )
        }).catch(reject)
        .finally(() => setLoading(false));
    }

    function onBgUpdate(file, data, reset, resolve, reject) {
        setLoading(true);
        updateBg(_transformData({file, ...data}), id).then(res => {
            resolve(res);
            const { success, notifications, data, errors } = res.data;
            if (success) {
                updateShort(data.images);
                if (data.images.thumbnail_image_logo && data.images.thumbnail_image_bg) {
                    updateStatus(data.status);
                }
                setLoading(false);
                closeModal();
            } else {
                reject({messages: errors});
            }
            if (notifications) notifyList(notifications);
        }).catch(reject);
    }
    
    // form ref for set value
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onFileChange
                })
            }
            <Modal 
                shouldCloseOnOverlayClick={false}
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={""}
                description={""}
                loading={loading}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
        </>
    )
}