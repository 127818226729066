import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { SimpleAppearAnimation } from "@components"
// ui
import { 
    Button, 
    Title, 
} from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";

export default function ManageBlock(props) {
    const { t } = useTranslation();
    const {title, list=[], onClick = () => {}, status, animationCount} = props;
    let listLength = 0; 

    return (
        <div className={s.clickable} onClick={onClick}>
            <SimpleAppearAnimation index={animationCount}>
                <motion.div className={s.item}>
                    <div className={s.body}>
                        <Title type="block" className={classNames(s.blockTitle, {
                            [s.status]: typeof status === "boolean",
                            [s.activeStatus]: status
                        })}>{title}</Title>
                        {
                            list.length ? (
                            <div className={s.itemInnerList}>
                                {
                                    list.map(({title, value}, index) => {
                                        listLength += 1;
                                        if (value && listLength <= 4) {
                                            return (
                                                <div className={s.field} key={index}>
                                                    <span className={s.title}>{`${title}: `}</span>
                                                    <span className={s.value}>{value}</span>
                                                </div>
                                            )
                                        }
                                        return null
                                    })
                                }
                                { listLength > 4 ? <div className={s.chevron}></div> : null }
                            </div>
                            ) : <div className={s.btnWrapper}><Button type="button" className="empty">{t("buttons.enter_the_data")}</Button></div>
                        }
                    </div>
                </motion.div>
            </SimpleAppearAnimation>
        </div>
    )
}

