import React, { useState, useEffect, useContext } from "react";
// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    Breadcrumbs,
    ManagerSubmenu,
    ManagerSearch,
    LoadingSpinner
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
import { 
    managerMethodsContext,
    notificationsContext
 } from "@context";
import ROUTES from "@routes";

export default function SubmenuPage() {
    const { t } = useTranslation();
    // let { id } = useParams();
    const [data, setData] = useState({});
    const { getAllSubmenu } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    // dish states
    const [ context, setContext ] = useState({
        id: null,
        sortable: false,
        temporary: null
    });
    const [ dishesId, setDishesId ] = useState({});

    const methods = {
        context,
        setContext,
        dishesId,
        setDishesId,
        setPageLoading: setLoading
    }
    
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            getAllSubmenu().then(res => { // id
                serviceProcessing(res, notifyList, (data) => {
                    setData(data);
                })
            }).catch(error => errorProcessing(error, notify, "page_data"))
            .finally(() => setLoading(false));
        }

        return () => {
            isMounted = false;
            setLoading(true);
        }
        
    }, []);
    
    const breadcrumbs = [
        {
            title: t("menu.menu"),
            link: ROUTES.menu
        },
        // {
        //     title: data.parent_title,
        //     link: `${ROUTES.menu}/${id}`
        // }
    ]
    
    return (
        <>
            {loading ? <LoadingSpinner/> : null}
            <Breadcrumbs list={breadcrumbs} />
            <section>
                <div className="container-fluid">
                    {/* <Title className="mb-md-5 mb-4" type="page" back={ROUTES.menu}>{data.parent_title}</Title> */}
                    <ManagerSearch {...methods} />
                    <ManagerSubmenu {...data}/>
                </div>
            </section>
        </>
    )
}