import React, {useEffect, useReducer} from "react";
import { FeedbackList } from "@components";
import {
    updateList,
    deleteItemFromList
} from "@utils";

function reducer(feedback, action) {
    switch (action.type) {
        case "INIT":
            return action.payload.map(feedback => {
                return {
                    ...feedback,
                    animationCount: 0
                }
            })
        case "UPDATE_FEEDBACK":
            return updateList(feedback, action.payload)
        case "DELETE_FEEDBACK":
            return deleteItemFromList(feedback, action.payload)      
        default:
            return feedback
    }
}

export default function ManagerFeedback({model}) {
    const [ list, dispatch ] = useReducer(reducer, model);
    
    useEffect(() => {
        let isMounted = true;
        if (model && isMounted) dispatch({type: "INIT", payload: model});
        return () => isMounted = false;
    }, [model])

    return <FeedbackList 
        list={list}
        onDelete={(id) => dispatch({type: "DELETE_FEEDBACK", payload: id})}
        onUpdate={(data) => dispatch({type: "UPDATE_FEEDBACK", payload: data})}
    />
}