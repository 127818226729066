import React, { useState, useRef, useContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next"
// components
import { CustomizationRow } from "@components";
// ui
import { 
    Modal, 
    Form,
} from "@ui-kit";
// utils
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";
// logo
// import logo from "@svg/logo-placeholder.svg"

function reducer(form, action) {
    switch (action.type) {
        case "INIT":
            let initForm = {
                ...form,
            };
            initForm.columns[0].fields[0] = {
                ...form.columns[0].fields[0],
                theme: action.payload.theme,
                color_list: action.payload.color_list,
                field: {
                    ...form.columns[0].fields[0].field,
                    defaultValue: action.payload.theme.id
                },
                use_in_console: {
                    ...form.columns[0].fields[0].use_in_console,
                    defaultChecked: action.payload.use_in_console
                }
            }
            return initForm;
        default:
            return form;
    }
}

export default function ManageCustomizationWrapper({children, updateShort}) {    
    const { t } = useTranslation();
    // module
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    // methods
    const {
        getCustomization,
        updateCustomization,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { updateCustom } = useContext(globalVariablesContext);
    const [ loading, setLoading ] = useState(true);
    // form
    const formRef = useRef();
    function onColorChange(id) {
        formRef.current.setMapValidation(["theme", id]);
    }
    
    const initialForm = {
        type: "restaurant_customization",
        submit: t("buttons.save"),
        callback: callbackUpdate,
        columns: [{
            size: 12,
            fields: [
                {
                    type: "wrapper",
                    Wrapper: (props) => <CustomizationRow {...props} onColorChange={onColorChange}/>,
                    field: {
                        type: "hidden",
                        name: "theme",
                        defaultValue: 10,
                        rules: {
                            valueAsNumber: true,
                        }
                    },
                    use_in_console: {
                        type: "checkbox",
                        mode: "switch",
                        defaultChecked: true,
                        label: "Use for console",
                        description: "Use the selected palette not only for the restaurant page but also for the console design",
                        wrapperClass: "col-md-6",
                        name: "use_in_console"
                    },
                    theme: {
                        id: 10,
                        accent: "FFA643",
                        second: "EB6B1B",
                        title: ""
                    },
                    color_list: [{
                        id: 10,
                        accent: "FFA643",
                        second: "EB6B1B",
                        title: ""
                    }],
                    // instance: {
                    //     thumbnail_image_logo: logo,
                    //     view_count: 0,
                    //     title: t("manage.your_establishment"),
                    //     dishes: 0,
                    //     types: t("manage.customization.type"),
                    //     kitchen: t("manage.customization.kitchen"),
                    //     kitchen_count: null,
                    //     currency_symbol: ""
                    // }
                }
            ]
        }]
    }
    
    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        const useInConsole = data.use_in_console;
        updateCustomization(data).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data.theme);

                const { accent, second } = data.theme;
 
                if (useInConsole) {
                    updateCustom({
                        accent,
                        second
                    })
                } else { updateCustom(null) }

                setOpen(false);
            }, (errors) => reject({messages: errors}))
        })
        .catch(reject)
        .finally(() => setLoading(false))
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (isOpen) {
                getCustomization()
                .then(res => {
                    serviceProcessing(
                        res, 
                        notifyList, 
                        (data) => {
                            dispatch({type: "INIT", payload: data})
                        }
                    )
                })
                .catch(error => errorProcessing(error, notify, "detail_data"))
                .finally(() => setLoading(false));
            }
        }

        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true)
                })
            }
            <Modal 
                shouldCloseOnOverlayClick={false}
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_customization")}
                description={t("manage.form.description_customization")}
                loading={loading}
            >
                <Form form={form} ref={formRef}/>
            </Modal>
        </>
    )
}