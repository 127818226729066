import React, { useRef, useEffect, forwardRef, useState, useContext } from "react";
import classNames from "classnames";
// styles
import s from "./Fields.module.sass";
// ui
import {
    getByPath,
    useCombinedRefs
} from "@utils"
import { globalVariablesContext } from "@context";
import { ReactComponent as Eye } from "@svg/pass-eye.svg"
import { ReactComponent as EyeClosed } from "@svg/pass-eye-closed.svg"

const TextField = forwardRef(({type, label, description, refRegister, errors, rules, wrapperClass = "col-md-12", refs, labelRef, defaultValue, setValue, className = "", ...props}, forwardedRef) => {
    const combinedRef = useCombinedRefs(useRef, useEffect, forwardedRef, refRegister({ ...rules }), refs);

    useEffect(() => {
        setValue(props.name, defaultValue);
    }, [defaultValue])

    function setType(isPass) {
        const fieldInput = combinedRef.current;
        if (isPass) {
            fieldInput.type = "password"
        } else {
            fieldInput.type = "text"
        }
    }
    
    return (
        <div className={wrapperClass}>
            <div className={classNames(s.group, {
                [s.readonly]: props.readOnly
            })}>
                <input 
                    className={classNames(s.input, className)} 
                    ref={combinedRef} 
                    {...props}
                    placeholder=" "
                    type={type}
                    autoComplete="off"
                    defaultValue={defaultValue}
                />
                <label className={s.label} ref={labelRef}>
                    {label}
                </label>
                { type === "password" ? <PassEye setType={setType}/> : null }
                { getByPath(errors, props.name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${props.name}.message`)}</p> }
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
})

function PassEye({setType}) {
    const [ isPass, setPass ] = useState(true);
    const { variables: { customization: { second } } } = useContext(globalVariablesContext)

    return <span className={s.eye} onClick={(e) => {
        e.stopPropagation();
        setType(!isPass);
        setPass(!isPass);
    }}>{isPass ? <Eye/> : <EyeClosed/> }</span>
}

export default TextField;