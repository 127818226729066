import React, { forwardRef, useRef, useEffect } from "react";
import classNames from "classnames";
// styles
import s from "./Fields.module.sass";
// ui
import {
    getByPath,
    useCombinedRefs
} from "@utils"

const TextareaField = forwardRef(({type, label, description, refRegister, errors, rules, wrapperClass = "col-md-12", refs, labelRef, setValue, ...props}, forwardedRef) => {
    const combinedRef = useCombinedRefs(useRef, useEffect, forwardedRef, refRegister({ ...rules }), refs);
    
    useEffect(() => {
        setValue(props.name, props.defaultValue)
    }, [props.defaultValue])
    
    return (
        <div className={wrapperClass}>
            <div className={s.group}>
                <textarea
                    className={s.textarea} 
                    placeholder=" "
                    {...props}
                    ref={combinedRef} 
                ></textarea>
                <label className={s.label} ref={labelRef}>
                    {label}
                </label>
                { getByPath(errors, props.name) && <p className={classNames("form-text text-danger", s.notice)}>{getByPath(errors, `${props.name}.message`)}</p> }
            </div>
            { description ? <div className={s.description} dangerouslySetInnerHTML={{__html: description}}></div> : null }
        </div>
    )
})

export default TextareaField;