import React, { useRef, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import placeholder from "@svg/menu.svg";
// ui
import { 
    Title,
    Button
} from "@ui-kit"
// utils
import { useCombinedRefs } from "@utils"
// context
import { globalVariablesContext } from "@context"
// style
import s from "./Fields.module.sass";
// icon
import { ReactComponent as Icon } from "@svg/video-icon.svg";

const FileField = (
    {
        className = "", 
        file, 
        refRegister, 
        refs, 
        errors, 
        label, 
        description, 
        wrapperClass = "col-md-12", 
        labelRef,
        initialType,
        ...props
    },
) => {
    const { t } = useTranslation();
    const combinedRef = useCombinedRefs(useRef, useEffect, refRegister, refs);
    const { variables: { customization: { second } } } = useContext(globalVariablesContext);
    
    const [ dragIn, setDragIn ] = useState(false);
    const isEditor = className.includes("video-editor");
    const dropRef = useRef();
    
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    
    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragIn(true);
    }
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragIn(false);
    }
    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.onChange(e);
    }
    
    useEffect(() => {
        let isMounted = true;
        let div = dropRef.current;

        if (isMounted && div) {
            div.addEventListener('dragenter', handleDragIn)
            div.addEventListener('dragleave', handleDragOut)
            div.addEventListener('dragover', handleDrag)
            div.addEventListener('drop', handleDrop)
        }
        return () => {
            isMounted = false;
            if (div) {
                div.removeEventListener('dragenter', handleDragIn)
                div.removeEventListener('dragleave', handleDragOut)
                div.removeEventListener('dragover', handleDrag)
                div.removeEventListener('drop', handleDrop)
            }
        }
    }, [])
    
    return (
            isEditor ? 
            <div className="col-md-12">
                <label className={classNames(s.editor, {
                    [s.dragIn]: dragIn
                })} ref={dropRef} style={{
                    "--second-color": `#${second}`
                }}>
                    <input 
                        type="file" 
                        style={{display: "none"}} 
                        ref={combinedRef}
                        {...props}
                    />
                    <div className={s.videoContent}>
                        <div className="mb-4"><Icon/></div>
                        {description && <p className={classNames(s.info, "mb-5")} dangerouslySetInnerHTML={{__html: description}}></p>}
                        <div><Button type="custom-button" className="load-video" onClick={(e) => e.preventDefault()}>{label}</Button></div>
                    </div>
                </label>
                <p className={classNames("mt-3 mt-md-4", s.requirements)}>{t('manage.video.form.requirements')}</p>
            </div>
             : <div className={wrapperClass}>
                {label && <Title>{label}</Title>}
                <label className={classNames({
                    [s.round]: className.includes("round"),
                    [className]: !className.includes("round"),
                    [s.defaultImg]: !className || className.includes("default")
                }, s.labelFile)}
                style={{
                    backgroundImage: file ? `url(${file})` : `url(${placeholder})`
                }} ref={labelRef}>
                    <input 
                        type="file" 
                        style={{display: "none"}} 
                        ref={combinedRef}
                        {...props}
                    />
                    {description && <p className={classNames(s.info, {
                        "px-0": className.includes("push-template")
                    })} dangerouslySetInnerHTML={{__html: description}}></p>}
                </label>
            </div>
        )
}

export default FileField;