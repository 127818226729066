import React, { useEffect, useRef } from "react"
import {
    ColumnsRenderer
} from "@ui-kit"

export default function ServicesRow(props) {
    const {columns, type, ...form} = props;
    const columnRef = useRef();
    const payCard = form.watch("settings[is_pay_card]");
    const payCash = form.watch("settings[is_pay_cash]");
    const payTerminal = form.watch("settings[is_pay_terminal]");
    
    let enable = payCard || payCash || payTerminal;

    useEffect(() => {
        let isMounted = true;
        if (isMounted && columnRef.current) {
            if (enable) {
                columnRef.current.classList.remove("d-none");
            } else {
                columnRef.current.classList.add("d-none");
            }
        }
        return () => isMounted = false;
    }, [enable])
    
    return (
        <ColumnsRenderer {...columns[0]} {...form} refs={columnRef} />
    )
}