import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// styles
import s from "./ScheduleRow.module.sass";
import {
    Field,
    Button
} from "@ui-kit"

export default function ScheduleRow({day, select, open, close, onCopy, type, setError, watch, clearErrors, ...form}) {
    const { t } = useTranslation();
    const [ times, setTimes ] = useState(false);

    function selectChange({value}) {
        if (value !== 0) {
            setTimes(false);
        } else {
            setTimes(true);
        }
    }
    
    useEffect(() => {
        selectChange(select.defaultValue);
    }, [select.defaultValue])
    
    return (
        <div className={classNames("row col-12 align-items-center", s.schedule)}>
            <div className="col-12 col-md-1 align-self-center">
                <p className={s.day}>{t("manage.schedule.week_short", { returnObjects: true })[day.defaultValue]}</p>
                <Field {...day} {...form}/>
            </div>
            <Field 
                {...select} 
                {...form}
                onChangeHandler={(selected) => {
                    selectChange(selected);
                }}
            />
            {
                times ? (<>
                    <Field {...open} watch={watch} {...form} />
                    <Field {...close} watch={watch} {...form} />
                </>) : null
            }
            <div className="d-none d-md-block col-md-1">
                <div className={s.copy}>
                    <Button 
                        type="copy" 
                        onClick={() => {
                            setTimes(true);
                            onCopy(setTimes);
                        }} 
                        title={t("manage.schedule.copy")}
                    />
                </div>
            </div>
        </div>
    )
}