import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames"
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import s from "../Fields.module.sass"

const Option = ({children, ...props}) => {
    return (
        <components.Option {...props}>
            {children}
        </components.Option>
    )
}
    
export default function DishesSelect({
    options = [],
    wrapperClass = "col-md-12",
    label,
    className = "",
    noOptionsMessage
}) {
    const { t } = useTranslation();
    const filterDishes = useCallback((inputValue) => {
        return options.filter((i) => {
            return i.label.toLowerCase().includes(inputValue.toLowerCase()) || i.options.filter((k) => {
                return k.label.toLowerCase().includes(inputValue.toLowerCase())
            }).length
        }
            
        );
    }, [options])
    
    const promiseOptions = (inputValue) => new Promise(
        (resolve) => {
            setTimeout(() => {
                resolve(filterDishes(inputValue));
            }, 1000);
        }
    );
    
    return (
        <div className={wrapperClass}>
            <div className={s.group}>
                <AsyncSelect
                    cacheOptions
                    defaultOptions={options}
                    loadOptions={promiseOptions}
                    className={classNames("react-select", className)}
                    classNamePrefix="react-select"
                    noOptionsMessage={noOptionsMessage || (() => t("fields.general.select_empty"))}
                    placeholder=" "
                    components={{
                        Option
                    }}
                />
                <label className={classNames(s.label, s.labelSelect)}>
                    {label}
                </label>
            </div>
        </div>
    )
}