import React from "react"
import classNames from "classnames";
import {
    Map,
    Cropper,
    Field,
    Button,
    VideoEditor
} from "@ui-kit"
import s from "./Form.module.sass"

export default function FieldsRenderer(props) {
    const {
        fields = [],
        errors,
        control,
        setValue,
        register,
        getValues,
        setError,
        clearErrors,
        watch,
        refRegister
    } = props;

    return (
        fields.map(({Wrapper, ...field}, index) => {
            if (field.type === null) return null;
            if (field.type === "map") {
                return <div className="col-md-12" key={index}>
                    <Map {...field}/>
                </div>
            }
            if (field.type === "video") {
                return (
                    <VideoEditor 
                        {...field} 
                        key={index}
                        errors={errors} 
                        control={control} 
                        setValue={setValue} 
                        refRegister={register || refRegister} 
                        getValues={getValues} 
                        setError={setError} 
                        watch={watch}
                    />
                )
                
            }
            if (field.type === "cropper") {
                return <Cropper {...field} key={index}/>
            }
            if (field.type === "wrapper") {
                return <Wrapper 
                    key={index} 
                    {...field} 
                    errors={errors} 
                    control={control} 
                    setValue={setValue} 
                    refRegister={register || refRegister} 
                    getValues={getValues} 
                    setError={setError} 
                    clearErrors={clearErrors} 
                    watch={watch}
                />
            }
            if (field.type === "description") {
                return  <p
                            key={index}
                            className={classNames(s.description, {
                                [s.red]: field.wrapperClass && field.wrapperClass.includes("red"),
                                "col-md-12": field.wrapperClass && field.wrapperClass.includes("col-md-12"),
                            })}
                            dangerouslySetInnerHTML={{__html: field.description}}
                        ></p>
            }
            if (field.type === "button-text") {
                return  <div className="col-md-12" key={index}>
                            <Button 
                                type="button-text" 
                                onClick={field.onClick} 
                            >
                                {field.label}
                            </Button>
                        </div>
            }
            return <Field 
                        key={index} 
                        ref={field.ref || null} 
                        refRegister={register || refRegister} 
                        errors={errors} 
                        control={control} 
                        setValue={setValue} 
                        getValues={getValues} 
                        watch={watch}
                        {...field} 
                    />
        })
    )
} 