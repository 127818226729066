import React, { useContext, useState, useEffect } from "react";
import { SideNav } from "@components";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
// socket component
import {
    WithNavbarSocket
} from "@components"
import {
    PageWrapper,
    PagelessWrapper,
    Modal,
    Button
} from "@ui-kit"
// context
import { globalVariablesContext } from "@context";
// styles
import "@styles/styles.css"
import s from "./Layout.module.sass"
// ROUTES
import ROUTES from "@routes"

const Layout = (props) => {
    const { children, menu, setMenu } = props; // menu means toggle navbar
    const { variables: {
        customization: { accent, second },
    } } = useContext(globalVariablesContext);
    const { t, ready } = useTranslation();
    
    const [ isOpen, setOpen ] = useState(false);
    const [ description, setDescription ] = useState("");
    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => {
        let isMounted = true;
        
        if (isMounted && location.state && location.state.message) {
            setDescription(location.state.message);
            setOpen(true);
        }
        
        return () => {
            isMounted = false;
        }
    }, [ location.state ])
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !isOpen) {
            closeModal();
        }
        return () => isMounted = false;
    }, [isOpen])
    
    function closeModal() {
        setOpen(false);
        let state = { ...location.state };
        if (state.message) {
            delete state.message;
            history.replace({
                ...location,
                state
            })
        }
    }

    const isLogin = useRouteMatch(ROUTES.login);
    const isRegister = useRouteMatch(ROUTES.register);
    
    return (
            isLogin || isRegister ? 
            <PagelessWrapper>{children}</PagelessWrapper> : <>
            <main className={s.main} style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`
            }}>
                <WithNavbarSocket>
                    <SideNav menu={menu} setMenu={setMenu} />
                </WithNavbarSocket>
                <PageWrapper menu={menu}>{children}</PageWrapper>
                <Modal 
                    closeModal={closeModal} 
                    isOpen={isOpen} 
                    title={t("warning")}
                    description={description}
                    loading={ ready ? false : true }
                    className="notice-modal"
                    closeTimeoutMS={200}
                >
                    <Button 
                        onClick={closeModal} 
                        type="custom-button" 
                        className="save">
                        Ок
                    </Button>
                </Modal>
            </main>
            </>
    )
}

export default Layout;