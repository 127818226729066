import React, { useState, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
// ui
import {
    Form,
    Modal
} from "@ui-kit";
// utils
import {
    updateRules,
    errorProcessing,
    serviceProcessing,
} from "@utils"
// components
import {
    LanguagesRowCustomText
} from "@components";
// context
import { 
    managerMethodsContext,
    notificationsContext,
} from "@context";

function reducer(form, action) {
    const { translations, language_list, rules: {close_text, description, ...rules} } = action.payload;    
    switch (action.type) {
        case "INIT":
            let formInit = {
                ...form
            };
            formInit.columns[0].fields[0].translations = translations;
            formInit.columns[0].fields[0].language_list = language_list;
            formInit.columns[0].fields[0].columns[0].fields[0].options = language_list;
            formInit.columns[0].fields[0].columns[0].fields[0].defaultValue = language_list.find(el => el.main);
            formInit.columns[0].fields[0].columns[0].fields[1].defaultValue = translations.find(el => el.main).close_text;
            formInit.columns[0].fields[0].rules = {
                close_textRule: close_text 
            };

            return updateRules(formInit, rules);
        default:
            return form
    }
}

export default function ManageCustomTextWrapper({children, updateShort}) {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState(false);
    // close modal
    function closeModal() {
        setOpen(false);
    }
    function openModal() {
        setOpen(true);
    }
    // CONTEXT
    const {
        getCustomText,
        createCustomText,
    } = useContext(managerMethodsContext);
    const {
        notify,
        notifyList
    } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    // const defaultLanguage = localStorage.getItem("i18nextLng");
    const initialForm = {
        type: "restaurant_info",
        submit: t("buttons.create"),
        callback: callbackFunction,
        columns: [
            {
                size: 12,
                fields: [
                    {
                        type: "wrapper",
                        Wrapper: (props) => <LanguagesRowCustomText {...props}/>,
                        columns: [{
                            fields: [
                                {
                                    type: "select",
                                    options: [],
                                    isSearchable: true,
                                    defaultValue: null,
                                    name: "language",
                                    label: t("fields.translations.select_language")
                                },
                                {
                                    type: "text",
                                    defaultValue: "",
                                    name: "close_text",
                                    label: t("fields.custom_text.closed_text")
                                }
                            ],
                        }],
                        translations: [],
                        language_list: [],
                        rules: null
                    }
                ]
            },
        ]
    }

    function _transformData(data) {
        const { translations } = data;
        return {
            translations
        }
    } 

    // call back on create new restaurant
    function callbackFunction(data, reset, resolve, reject) {
        setLoading(true);
        createCustomText(_transformData(data)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                updateShort(data)
                closeModal();
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    // call back on update current restaurant

    const [form, dispatch] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;

        if (isMounted && isOpen) {
            getCustomText()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        dispatch({type: "INIT", payload: data})
                    })
                }).catch(error => {
                    errorProcessing(error, notify, "detail_data");
                }).finally(() => {
                        setLoading(false)
                })
        }

        return () => {
            isMounted = false;
            setLoading(true);
        };
    }, [isOpen])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: openModal
                })

            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_custom_text")}
                description={t("manage.form.description_custom_text")}
                loading={loading}
            >
                <Form form={form} />
            </Modal>
        </>
    )
}