import React, { useState, useRef, useContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next"
// components
import { LinksRow } from "@components";
// ui
import {
    Modal,
    Form,
} from "@ui-kit";
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";

const whatsupType = 1;
const demoLink = "https://console.cheflist.org/our_restaurant/links";

function reducer(form, action) {
    switch (action.type) {
        case "INIT":
            let createForm = {
                ...form
            }
            createForm.columns[0].fields[0].instance = { ...action.instance };
            createForm.columns[0].fields[0].link = action.link;
            createForm.columns[0].fields[0].list_of_types = [...action.list_of_types]
            createForm.columns[0].fields[0].model = [...action.model]
            createForm.columns[0].fields[0].rules = action.rules;
            
            return createForm;
        default:
            return {...form}
    }
}

export default function ManageLinksWrapper({children, updateShort}) {
    const { t } = useTranslation();
    // modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() {
        setOpen(false);
    }
    // context
    const {
        getLinks,
        updateLinks,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { variables: { phoneMask } } = useContext(globalVariablesContext);
    const [loading, setLoading] = useState(true);
    // initial form
    const initialForm = {
        type: "restaurant_links",
        submit: t("buttons.save"),
        callback: callbackUpdate,
        columns: [
            {
                size: 12,
                fields: [
                    {
                        type: "wrapper",
                        Wrapper: (props) => <LinksRow {...props}/>,
                        instance: {
                            thumbnail_image_logo: null,
                            title: "",
                            types: ""
                        },
                        mask: phoneMask,
                        link: demoLink,
                        list_of_types: [{
                            value: 0,
                            label: ""
                        }],
                        model: [],
                        rules: {
                            title: null,
                            value: null
                        }
                    }
                ]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            if (isOpen) {
                getLinks()
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        dispatch({ type: "INIT", ...data });
                    })
                })
                .catch(error => errorProcessing(error, notify, "detail_data"))
                .finally(() => setLoading(false));
            }
        }

        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [isOpen])
    
    function _transformData(data = []) {
        let newData = {};
        newData.links = data.map((el) => {
            return {
                ...el,
                value: parseInt(el.type) === whatsupType ? el.value.replace(/[\D]/g,"") : el.value,
                type: parseInt(el.type),
                id: parseInt(el.id) || null
            }
        });
        return newData;
    }
    // form callback
    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateLinks(_transformData(data.links)).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                resolve(res);
                updateShort(data);
                setOpen(false);
            }, errors => reject({messages: errors}))
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true),
                })
            }
            <Modal 
                shouldCloseOnOverlayClick={false}
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={t("manage.form.title_multilink")}
                description={t("manage.form.description_multilink")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
        </>
    )
}