function serviceProcessing(res, notifyList, successCB, errorCB) {
    const { success, notifications, errors, data } = res.data;
    if (notifications && typeof notifyList === "function") {
        notifyList(notifications);
    }
    if (success && typeof successCB === "function") {
        return successCB(data)
    } else if (errors && typeof errorCB === "function" ){
        return errorCB(errors)
    }
}

export default serviceProcessing