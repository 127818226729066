import React, { useEffect, useState, useContext, useReducer, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
    Breadcrumbs,
    ManageAddBlock,
    ManagerOrdersInner,
    LoadingSpinner,
    WithInnerOrdersSocket,
    Pagination,
    Sorting,
    OrdersExcelWrapper
} from "@components"
// utils
import { 
    errorProcessing,
    serviceProcessing,
    debounce
} from "@utils"
// icons
import {ReactComponent as Archive} from "@svg/archive-circle.svg"
import {ReactComponent as Excel} from "@svg/excel.svg"
import {ReactComponent as All} from "@svg/sorting/all.svg"
import {ReactComponent as Expects} from "@svg/sorting/expects.svg"
import {ReactComponent as Accepted} from "@svg/sorting/accepted.svg"
import {ReactComponent as Canceled} from "@svg/sorting/canceled.svg"
import {ReactComponent as Done} from "@svg/sorting/done.svg"
import {ReactComponent as InDelivery} from "@svg/sorting/in_delivery.svg"
// routes
import ROUTES from "@routes";
// context
import { 
    managerMethodsContext,
    notificationsContext,
} from "@context";

function reducer(orders, action) {
    switch (action.type) {
        case "UPDATE_DATA":
            return {
                ...orders,
                data: action.payload,
                loading: false,
            }
        case "RESET_DATA":
            return {
                ...orders,
                data: {},
                loading: true
            }
        case "SET_LOADING":
            return {
                ...orders,
                loading: action.payload
            }
        case "SET_SOCKETS_LOADING":
            return {
                ...orders,
                socket: action.payload
            }
        case "SET_LIST_LOADING":
            return {
                ...orders,
                listLoading: action.payload
            }
        default:
            return orders
    }
}

export default function OrdersInnerPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const match = Boolean(pathname.match('archive'));
    // current pagination page
    const { page } = useParams();
    const breadcrumbs = [
        {
            title: t("review.title"),
            link: ROUTES.orders
        },
    ]
    const [ breadcrumbsList, setBreadcrumbs ] = useState(breadcrumbs);
    const [ sortingOptions, setSortingOptions ] = useState({
        all: true,
        expects: false,
        accepted: false,
        in_delivery: false,
        done: false,
        canceled: false,
        created: false
    })
    const { getOrders } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);

    const [ data, dispatch ] = useReducer(reducer, {
        loading: true,
        data: {},
        socket: true,
        listLoading: false
    });

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (match && !page) {
                history.replace(`${ROUTES.orders}/archive/1`)
            } else {
                getOrders({archive: match, page}).then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        if (data.count_page !== 0 && data.count_page < page) {
                            history.replace(ROUTES.not_found)
                        } else {
                            dispatch({type: "UPDATE_DATA", payload: data})
                        }
                    })
                })
                .catch(error => {
                    errorProcessing(error, notify, "page_data")
                })
                .finally(() => dispatch({type: "SET_LOADING", payload: false}))
            }
        }
        return () => {
            isMounted = false;
            dispatch({type: "RESET_DATA"})
        }
    }, [match, page]);
    
    useEffect(() => {
        let list = [...breadcrumbs];
        if (match) {
            list.push({
                title: t("archive"),
                link: `${ROUTES.orders}/archive`
            })
        }
        setBreadcrumbs(list);
    }, [match])
    
    const linkPattern = `${ROUTES.orders}/archive`
    const sorting = [{
        label: t('orders.sorting_options.all'),
        value: "all",
        Icon: All
    },{
        label: t('orders.sorting_options.created'),
        value: "created",
        Icon: All
    },{
        label: t('orders.sorting_options.expects'),
        value: "expects",
        Icon: Expects
    },{
        label: t('orders.sorting_options.accepted'),
        value: "accepted",
        Icon: Accepted
    },{
        label: t('orders.sorting_options.in_delivery'),
        value: "in_delivery",
        Icon: InDelivery
    },{
        label: t('orders.sorting_options.done'),
        value: "done",
        Icon: Done
    },{
        label: t('orders.sorting_options.canceled'),
        value: "canceled",
        Icon: Canceled
    }];
    
    function onSort(val) {
        if (val !== "all") {
            let sorting = { 
                ...sortingOptions, 
                all: false, 
                [val]: !sortingOptions[val] 
            }
            if (Object.keys(sorting).every(k => !sorting[k])) {
                sorting = {
                    ...sorting,
                    all: true
                }
            }

            const { all, ...newSorting } = sorting;
            if (Object.keys(newSorting).every(k => sorting[k])) {
                sorting = {
                    all: true,
                    expects: false,
                    accepted: false,
                    in_delivery: false,
                    done: false,
                    canceled: false,
                    created: false
                }
            }
            
            setSortingOptions(sorting)
        } else {
            setSortingOptions({
                all: true,
                expects: false,
                accepted: false,
                in_delivery: false,
                done: false,
                canceled: false,
                created: false
            })
        }
    }
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            dispatch({type: "SET_LIST_LOADING", payload: true})
            sort(sortingOptions);
        }
        return () => isMounted = false
    }, [sortingOptions])

    const sort = useCallback(
        debounce(val => {
            getOrders({archive: match, page, ...val})
            .then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    if (data.count_page !== 0 && data.count_page < page) {
                        history.replace(ROUTES.not_found)
                    } else {
                        dispatch({type: "UPDATE_DATA", payload: data})
                    }
                })
            })
            .catch(error => errorProcessing(error, notify, "page_data"))
            .finally(() => dispatch({type: "SET_LIST_LOADING", payload: false}))
        }, 500),
        [match, page]
    );
    
    return (
        <>
            {(data.loading || (data.socket && !match)) ? <LoadingSpinner/> : null}
                <>
                    <Breadcrumbs list={breadcrumbsList} />
                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    match ? null :
                                    <div className="col-md-auto">
                                        <ManageAddBlock 
                                            title={t("archive")} 
                                            Icon={Archive} 
                                            className="short icon-right"
                                            onClick={() => history.push(`${ROUTES.orders}/archive/1`)}
                                        />
                                    </div>
                                }
                                <div className="col-md-auto">
                                    <OrdersExcelWrapper>
                                        <ManageAddBlock 
                                            title={t("orders.excell.title")} 
                                            Icon={Excel} 
                                            className="short icon-right"
                                        />
                                    </OrdersExcelWrapper>
                                </div>
                            </div>
                            {
                                <Sorting 
                                    list={sorting} 
                                    onSort={onSort}
                                    sortingOptions={sortingOptions}
                                />
                            }
                            <WithInnerOrdersSocket 
                                archive={match - 0}
                                setLoading={(data) => dispatch({type: "SET_SOCKETS_LOADING", payload: data})}
                            >
                                <ManagerOrdersInner 
                                    {...data.data} 
                                    archive={match} 
                                    listLoading={data.listLoading}
                                    sortingOptions={sortingOptions}
                                />
                            </WithInnerOrdersSocket>
                            { (match && data.data.count_page) ? <Pagination link={linkPattern} count={data.data.count_page} page={page}/> : null }
                        </div>
                    </section>
                </>
        </>
    )
}