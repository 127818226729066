import React, {useEffect, useReducer, useContext} from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    ManageAddBlock,
    SubmenuBlockWrapper,
    SubmenuList
} from "@components";
import {
    updateList,
    deleteItemFromList,
    errorProcessing,
    serviceProcessing,
    arrayMove
} from "@utils";
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
import { ReactComponent as AddMenuIcon } from "@svg/addmenu.svg"

function reducer(submenu, action) {
    switch (action.type) {
        case "INIT":
            return action.payload.map(submenu => {
                return {
                    ...submenu,
                    animationCount: 0
                }
            })
        case "ADD":
            return [
                {
                    ...action.payload,
                    animationCount: 0
                },
                ...submenu
            ]      
        case "UPDATE":
            return updateList(submenu, action.payload)
        case "DELETE":
            return deleteItemFromList(submenu, action.payload)      
        case "SORT":
            return [...action.sortedArray];
        default:
            return submenu
    }
}

export default function ManagerSubmenu({model = []}) {
    const { sortSubmenu } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);

    const { id: menu_id } = useParams();
    const [ submenu, dispatch ] = useReducer(reducer, model);
    
    useEffect(() => {
        let isMounted = true;
        if (model.length && isMounted) dispatch({type: "INIT", payload: model});
        return () => isMounted = false;
    }, [model])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const oldMenu = [...submenu];
            const sortedArray = arrayMove(submenu, oldIndex - 1, newIndex - 1); // subtract bcs of add block (0 element). sortable index start from 1. list from 0
            const sortedArrayIndex = sortedArray.map(el => el.id);
            
            dispatch({
                type: "SORT",
                sortedArray
            });
            
            sortSubmenu({
                positions: sortedArrayIndex
            }, menu_id)
            .then(res => {
                serviceProcessing(res, notifyList)
            })
            .catch(error => {
                errorProcessing(error, notify, "wrapper_data");
                dispatch({
                    type: "SORT",
                    sortedArray: oldMenu
                });
            })
        }
    };
    
    // console.log(parent_title, excel_template);
    
    return (
        <SubmenuList
            onAdd={(data) => dispatch({type: "ADD", payload: data})}
            onDelete={(id) => dispatch({type: "DELETE", payload: id})}
            onUpdate={(data) => dispatch({type: "UPDATE", payload: data})}
            onSortEnd={onSortEnd}
            AddComponent={(props) => <SubmenuAdd {...props}/>}
            submenu={submenu}
        />
    )
}

function SubmenuAdd(props) {
    const { t } = useTranslation();
    
    return (
        <div 
            className="col-md-auto" 
            index={0} 
            key={0}
            collection={0} // another collection to disable sort
            disabled={true} 
        >
            <SubmenuBlockWrapper 
                {...props}
            >
                <ManageAddBlock title={t("menu.add_submenu")} Icon={AddMenuIcon}/>
            </SubmenuBlockWrapper>
        </div>
    )
}