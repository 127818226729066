import React, { useState, useContext, useReducer, useEffect, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { 
    LanguagesRow,
    ContextMenu,
    DishModal,
} from "@components";
// ui-kit
import {
    Form,
    Modal,
    Title,
    Button
} from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing,
    uploadFile,
    updateRules,
} from "@utils"
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "RESET":
            let formReset = { ...action.initialForm };
            // language select
            formReset.columns[2].fields[0].columns[0].fields[0].options = action.language_list;
            formReset.columns[2].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // title
            formReset.columns[2].fields[0].columns[0].fields[1].defaultValue = action.translations.find(el => el.main).title;
            // translations
            formReset.columns[2].fields[0].translations = action.translations;
            // language list
            formReset.columns[2].fields[0].language_list = action.language_list;
            // rules
            formReset.columns[2].fields[0].rules = {
                titleRule: action.rules.title, 
                descriptionRule: action.rules.description, 
            };

            return updateRules(formReset, action.rules);
            // return formReset;
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
                type: "restaurant_menu_update",
                callback: action.callbackUpdate,
                submit: action.t("buttons.save"),
            };
            // is published
            updateForm.columns[0].fields[0].defaultChecked = action.model.is_published;
            // price
            updateForm.columns[0].fields[1].defaultValue = action.model.price;
            // language select
            updateForm.columns[2].fields[0].columns[0].fields[0].options = action.language_list;
            updateForm.columns[2].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // title
            updateForm.columns[2].fields[0].columns[0].fields[1].defaultValue = action.model.translations.find(el => el.main).title;
            updateForm.columns[2].fields[0].columns[0].fields[2].defaultValue = action.model.translations.find(el => el.main).description;
            // translations
            updateForm.columns[2].fields[0].translations = action.model.translations;
            // language list
            updateForm.columns[2].fields[0].language_list = action.language_list;
            // dish image
            if (action.model.image) {
                updateForm.columns[1].fields[0].type = "checkbox";
                updateForm.columns[1].fields[1].label = null;
                updateForm.columns[1].fields[1].description = "";
                updateForm.columns[1].fields[1].file = action.model.image;
            } else {
                updateForm.columns[1].fields[0].type = null;
                updateForm.columns[1].fields[1].description = action.t("fields.general.photo_info", {file_size: 20});
                updateForm.columns[1].fields[1].file = "none";
                updateForm.columns[1].fields[1].label = action.t("fields.menu.photo");
            }
            
            // rules
            updateForm.columns[2].fields[0].rules = {
                titleRule: action.rules.title, 
                descriptionRule: action.rules.description, 
            };
            
            return updateRules(updateForm, action.rules);
        case "LOAD_IMAGE":
            let loadForm = {...form};

            loadForm.columns[1].fields[1].description = "";
            loadForm.columns[1].fields[1].file = action.image;
            return updateRules(loadForm, action.rules);
        default:
            return { ...form }
    }
}

function createDishesListData(dishes_list, temporary) {
    if (dishes_list) {
        let list = [...new Set(Object.values(dishes_list).flat())]
        if (temporary !== null && !list.includes(temporary)) list.push(temporary);
        return {dishes_list: list}
    }
    return {dishes_list: []}
}

export default function DishesBlockWrapper(props) {
    const {
        children, 
        language_list, translations, rules,
        id = null, image,
        onDelete, onDeleteMultiple, onUpdate, onCheckToggle, onAdd, onPublish, onHide, onCheckAll, onCheckInvert, onCopy, onUncheckAll,
        setPageLoading,
        context = {
            id: null,
        }, setContext,
        submenuId,
        sortable,
        contextClass,
        dishesId,
    } = props;
    
    const { t } = useTranslation();
    const [ posX, setPosX ] = useState(0);
    const [ posY, setPosY ] = useState(0);
    const [ checkedDishesList, setList ] = useState([]);
    
    const [ contextmenu, setContextmenu ] = useState([]);
    const [ itemmenu, setItemmenu ] = useState([]);
    const contextmenuRef = useRef();

    const blockRef = useRef({});
    // detail modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false); }
    // copy into another modal
    const [ isOpenCopy, setOpenCopy ] = useState(false);
    function closeCopyModal() { setOpenCopy(false); }
    // move into another modal
    const [ isOpenMove, setOpenMove ] = useState(false);
    function closeMoveModal() { setOpenMove(false); }
    // context menu delete modal
    const [ isOpenDelete, setOpenDelete ] = useState(false);
    function closeDeleteModal() { 
        setOpenDelete(false);
    }
    const [ submenuList, setSubmenuList] = useState([]);
    
    const {
        createDish,
        getDish,
        deleteDish,
        updateDish,
        copyDishes,
        copyDishesIntoSubmenu,
        moveDishes,
        publishDishes,
        unpublishDishes,
        getNestedMenu
    } = useContext(managerMethodsContext);

    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    const [ itemLoading, setItemLoading ] = useState(false);
    const [ copyLoading, setCopyLoading ] = useState(true);
    const [ shouldUncheck, setUncheck ] = useState(false);
    
    const { id: submenu_id } = useParams(); // get submenu id from url
    
    function _transformData(data) {
        let form = new FormData();
        const {image, price, is_published, translations, remove_image} = data;

        if (typeof remove_image === "boolean") {
            form.append("remove_image", remove_image - 0);
            if (!remove_image && image && image[0]) {
                form.append("image", image[0]);
            }
        } else {
            if (image && image[0]) {
                form.append("image", image[0]);
            }
        }

        form.append("price", price);
        form.append("is_published", is_published);
        form.append("translations", JSON.stringify(translations));
        
        return form;
    }
    
    function toggleItemLoading(length, bool) {
        if (length > 1) {
            setPageLoading(bool); // set page loader if it's multiple items
        } else {
            setItemLoading(bool); // set item loader if it's single item
        }
    }
    
    function onDeleteDish(e, list) {
        toggleItemLoading(list.length, true);

        deleteDish({dishes_list: list}).then(res => {
            serviceProcessing(res, notifyList, () => {
                onDelete(list);
                setList([]); // clear list after delete
            })
        }).catch(error => {
            errorProcessing(error, notify, "detail_data");
        }).finally(() => toggleItemLoading(list.length, false));
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createDish(_transformData(data), submenu_id)
            .then(res => {
                serviceProcessing(
                    res, 
                    notifyList, 
                    (data) => {
                        resolve(res);
                        onAdd(data.dishes_list);
                        setOpen(false);
                    },
                    (errors) => reject({messages: errors})
                )
            })
            .catch(reject)
            .finally(() => setLoading(false));
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateDish(_transformData(data), id).then(res => {
            resolve(res);
            serviceProcessing(
                res, 
                notifyList,
                (data) => {
                    onUpdate(data.dishes_list);
                    setOpen(false);
                    setLoading(false);
                }
            )
        }).catch(reject)
    }
    
    function onFileChange(e) {
        e.preventDefault();
        uploadFile(e, dispatch, "LOAD_IMAGE", [400,400], 20, notify);
    }
 
    const initialForm = {
        type: "restaurant_menu_add",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 6,
                fields: [{
                    type: "checkbox",
                    mode: "switch",
                    defaultChecked: true,
                    name: "is_published",
                    label: t("menu.is_published"),
                    description: t("menu.is_published_description_dish"),
                },{
                    type: "mask",
                    mask: "9{*}[.9{0,2}]",
                    defaultValue: "",
                    name: "price",
                    description: null,
                    label: t("fields.menu.price"),
                    autoComplete: "off",
                    min: 0,
                    inputMode: "numeric",
                }]
            },
            {
                size: 6,
                fields: [
                    {
                        type: null,
                        mode: "switch",
                        defaultChecked: false,
                        name: "remove_image",
                        label: `${t("fields.general.photo_delete")} (${t("yes")})`,
                        negativeLabel: `${t("fields.general.photo_delete")} (${t("no")})`
                    },
                    {
                        type: "file",
                        defaultValue: "",
                        file: "none",
                        name: "image",
                        label: t("fields.menu.photo"),
                        description: t("fields.general.photo_info", {file_size: 20}),
                        accept: ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG",
                        onChange: onFileChange,
                        wrapperClass: "mb-3 col-md-12"
                    }
                ]
            },
            {
                size: 12,
                fields: [{
                    type: "wrapper",
                    Wrapper: (props) => <LanguagesRow {...props}/>,
                    columns: [
                        {
                            fields: [
                                {
                                    type: "select",
                                    options: [],
                                    isSearchable: true,
                                    defaultValue: null,
                                    name: "language",
                                    label: t("fields.translations.select_language")
                                },
                                {
                                    type: "text",
                                    defaultValue: "",
                                    name: "title",
                                    label: t("fields.translations.title"),
                                },
                                {
                                    type: "textarea",
                                    defaultValue: "",
                                    name: "description",
                                    label: t("fields.translations.description")
                                }
                            ],
                        }
                    ],
                    translations: [],
                    language_list: [],
                    rules: null
                }]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
        
    useEffect(() => {
        let isMounted = true;
        if (isMounted && isOpen) {
            if ((language_list && translations && rules) && id === null) {
                dispatch({
                    type: "RESET",
                    language_list,
                    translations,
                    rules,
                    initialForm
                })
                setLoading(false);
            }

            if (id !== null) {
                getDish(id)
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            dispatch({type: "INIT_UPDATE", 
                                ...data,
                                callbackUpdate,
                                t
                            });
                        })
                    }).catch(error => {
                        errorProcessing(error, notify, "detail_data");
                    }).finally(() => setLoading(false));
            }
        }
        return () => {
            isMounted = false;
            setLoading(true);
        }
        
    }, [language_list, translations, rules, isOpen, id])
        
    function contextMenuPosition(e) {
        e.preventDefault();
        let target = e.currentTarget;
        let targetCoords = target.getBoundingClientRect();

        setPosX(e.clientX - targetCoords.left + target.offsetLeft);
        setPosY(e.clientY - targetCoords.top + target.offsetTop);
    }
    
    const openContextMenu = useCallback((e) => {
        if (context.id === null || context.id !== id || context.sortable !== sortable) {
            contextMenuPosition(e);
            setContext({
                id,
                sortable
            });
        } else if (context.id === id) {
            contextMenuPosition(e);
        } else {
            setContext({
                id: null,
                sortable,
            });
        }
    }, [context, id, sortable, setContext])

    const closeContextMenu = useCallback((e, uncheckTemporary = true) => {
        if (context.id !== null || context.sortable !== sortable) {
            if (e) e.stopPropagation();
            if (uncheckTemporary) {
                setContext({
                    sortable,
                    id: null,
                });
            } else {
                setContext({
                    sortable,
                    id: null,
                });
            }
        }
    }, [sortable, setContext, context.id, context.sortable])

    // contextmenu events
    useEffect(() => {
        let isMounted = true;
        let block = blockRef.current[id];
        if (isMounted && id !== null) {
            if (block) {
                block.addEventListener("contextmenu", openContextMenu);
                document.addEventListener("click", closeContextMenu);
            }
        }

        return () => {
            isMounted = false;
            if (block && id !== null) {
                block.removeEventListener("contextmenu", openContextMenu);
                document.removeEventListener("click", closeContextMenu);
            }
        }
    }, [blockRef, closeContextMenu, openContextMenu, id])
    
    const createMenu = useCallback((isContextmenu) => {
        const list = isContextmenu ? createDishesListData(dishesId, context.id).dishes_list : [id];

        const closeCM = (e) => {
            if (isContextmenu) 
                closeContextMenu(e)
        }
        let menu = [
            {
                label: t("context_menu.copy"),
                type: "copy",
                onClick: (e) => {
                    onCopyDishes(list, isContextmenu);
                    closeCM(e);
                    if (isContextmenu) setUncheck(true);
                }
            },
            {
                label: t("context_menu.copy_to"),
                type: "copy_to",
                onClick: (e) => {
                    setList(list);
                    setOpenCopy(true);
                    closeCM(e);
                    if (isContextmenu) setUncheck(true);
                }
            },
            {
                label: t("context_menu.move_to"),
                type: "move_to",
                onClick: (e) => {
                    setList(list);
                    setOpenMove(true);
                    closeCM(e);
                    if (isContextmenu) setUncheck(true);
                }
            },
            {
                label: t("context_menu.delete"),
                type: "delete",
                onClick: (e) => {
                    setList(list);
                    setOpenDelete(true);
                    closeCM(e);
                }
            },
            { type: "delimiter"},
            {
                label: t("context_menu.publish"),
                type: "show",
                onClick: (e) => {
                    onPublishDishes(list, isContextmenu);
                    closeCM(e);
                }
            },
            {
                label: t("context_menu.hide"),
                type: "hide",
                onClick: (e) => {
                    onHideDishes(list, isContextmenu);
                    closeCM(e);
                }
            }
        ]

        let contextmenu = [
            ...menu,
            { type: "delimiter"},
            {
                label: t("context_menu.check_all"),
                type: "check_all",
                onClick: (e) => {
                    onCheckAll();
                    closeCM(e);
                }
            },
            {
                label: t("context_menu.invert_checked"),
                type: "check_all",
                onClick: (e) => {
                    onCheckInvert()
                    closeCM(e);
                }
            }
        ]
        
        return isContextmenu ? contextmenu : menu;
    }, [id, closeContextMenu, dishesId, context.id])
    
    // create context menu on dish_list changing
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let contextmenu = createMenu(true);
            
            if (image) {
                setContextmenu([
                    ...contextmenu,
                    { type: "delimiter" },
                    {
                        label: t("context_menu.download_image"),
                        type: "download",
                        download: image
                    }
                ])
            } else {
                setContextmenu([
                    ...contextmenu
                ])
            }
            setItemmenu(createMenu(false)); // items on hover menu
        }
        return () => isMounted = false
    }, [createMenu])

    // context
    function onCopyDishes(list, isContextmenu) {
        toggleItemLoading(list.length, true);
        copyDishes({
            dishes_list: list,
            submenu_id: submenuId
        })
            .then(res => {
                serviceProcessing(res, notifyList, data => {
                    onCopy(data.dishes_list, isContextmenu);
                    if (isContextmenu) {
                        onUncheckAll();
                    }
                })
            })
            .catch(error => {
                errorProcessing(error, notify, "detail_data");
            })
            .finally(() => {
                closeContextMenu();
                toggleItemLoading(list.length, false);
            });
    }

    function onCopyDishesIntoSubmenu(submenuId, list) {
        toggleItemLoading(list.length, true);

        copyDishesIntoSubmenu({
            submenu_id: submenuId,
            dishes_list: list
        }).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                closeCopyModal(false);
                if (shouldUncheck) {
                    onUncheckAll();
                    setUncheck(false);
                }
            })
        }).catch(error => {
            errorProcessing(error, notify, "detail_data");
        }).finally(() => {
            setCopyLoading(false);
            toggleItemLoading(list.length, false);
        });
    }

    function onMoveDishes(submenuId, list) {
        toggleItemLoading(list.length, true);

        moveDishes({
            submenu_id: submenuId,
            dishes_list: list
        }).then(res => {
            serviceProcessing(res, notifyList, () => {
                onDeleteMultiple(list);
                closeMoveModal();
                setCopyLoading(false);
                if (shouldUncheck) {
                    onUncheckAll();
                    setUncheck(false);
                }
            })
        }).catch(error => {
            errorProcessing(error, notify, "detail_data");
        }).finally(() => {
            setCopyLoading(false);
            toggleItemLoading(list.length, false);
        });
    }
    
    function onHideDishes(list, isContextmenu) {
        toggleItemLoading(list.length, true);

        unpublishDishes({dishes_list: list}).then(res => {
            serviceProcessing(res, notifyList, () => {
                onHide(list, isContextmenu)
            })
        }).catch(error => {
            errorProcessing(error, notify, "detail_data");
        }).finally(() => {
            toggleItemLoading(list.length, false);
        });
    }
    
    function onPublishDishes(list, isContextmenu) {
        toggleItemLoading(list.length, true);
        
        publishDishes({dishes_list: list}).then(res => {
            serviceProcessing(res, notifyList, () => {
                onPublish(list, isContextmenu);
            })
        }).catch(error => {
            errorProcessing(error, notify, "detail_data");
        }).finally(() => {
            toggleItemLoading(list.length, false);
        });
    }

    // accordion copy or move list modal
    useEffect(() => {
        let isMounted = true;
        if (isMounted && (isOpenCopy || isOpenMove)) {
            getNestedMenu().then( res => {
                serviceProcessing(res, notifyList, (data) => {
                    setSubmenuList(data);
                })
            }).catch(error => {
                errorProcessing(error, notify, "detail_data");
            }).finally(() => setCopyLoading(false));
        }
        return () => {
            isMounted = false;
            setCopyLoading(true);
        }
    }, [isOpenCopy, isOpenMove])

    let showContext = context.id !== null && context.id === id && context.sortable === sortable;
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                    // onDelete: () => setOpenSingleDelete(true), // menu add has no onDelete
                    onSettings: () => setOpen(true), // menu add has no Settings
                    onCheck: () => onCheckToggle([id]),
                    temporary_checked: context.id === id,
                    forwardedRef: ref => blockRef.current[id] = ref,
                    loading: itemLoading,
                    contextmenu: itemmenu,
                    isHoverable: !showContext
                })
            }
            <AnimatePresence>
                {
                    (showContext) ? (
                        <motion.div
                            initial={{ opacity: 0}}
                            animate={{ opacity: 1, translateX: posX, translateY: posY }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0 }}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 3
                            }}
                            ref={contextmenuRef}
                        >
                            <ContextMenu 
                                list={contextmenu} 
                                withToggler={false}
                                className={contextClass}
                            />
                        </motion.div>
                    ) : null
                }
            </AnimatePresence>
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={id === null ? t("menu.form.create_dish") : t("menu.form.edit_dish")}
                description={t("menu.form.description_dish")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
            <Modal 
                closeModal={closeCopyModal} 
                isOpen={isOpenCopy}
                loading={copyLoading}
            >
                <Title type="section">{t("menu.form.copy_dish_to")}</Title>
                <DishModal 
                    list={submenuList}
                    onClick={onCopyDishesIntoSubmenu}
                    submenuId={submenuId}
                    checkedDishesList={checkedDishesList}
                />
            </Modal>
            <Modal 
                closeModal={closeMoveModal} 
                isOpen={isOpenMove}
                loading={copyLoading}
            >
                <Title type="section">{t("menu.form.move_dish_to")}</Title>
                <DishModal 
                    list={submenuList}
                    onClick={onMoveDishes}
                    submenuId={submenuId}
                    checkedDishesList={checkedDishesList}
                />
            </Modal>
            {
                id !== null ? <>
                    <Modal 
                        closeModal={closeDeleteModal} 
                        isOpen={isOpenDelete}
                        title={t("warning")}
                        description={t("context_menu.delete_confirmation")}
                        className="notice-modal"
                        closeTimeoutMS={200}
                    >
                        <div className="row pt-2">
                            <div className="col-6">
                                <Button 
                                    type="custom-button" 
                                    className="large"
                                    onClick={(e) => {
                                        closeDeleteModal(e);
                                        onDeleteDish(e, checkedDishesList);
                                    }}
                                >{t("fields.general.delete")}</Button>
                            </div>
                            <div className="col-6">
                                <Button 
                                    type="button" 
                                    className="gray large"
                                    onClick={closeDeleteModal}
                                >{t("fields.general.cancel")}</Button>
                            </div>
                        </div>
                    </Modal>
                </> : null
            }
        </>
    )
}