import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {ReactComponent as Chevron} from "@svg/chevron.svg"
// styles
import s from "./Breadcrumbs.module.sass";

export default function Breadcrumbs({list = [], noGap = false, type}) {
    return (
        <nav aria-label="breadcrumb">
            <div className={classNames("container-fluid", {
                "px-0": noGap
            })}>
                <ol className={classNames(s.breadcrumbs, {
                    "mb-2": noGap,
                    [s.dishes]: type === "dishes"
                })}>
                    {
                        list.map(({title, link}, idx) => {
                            return (idx === list.length - 1 && type !== "dishes") ? 
                                <li key={idx} className={classNames(s.breadcrumbsItem, s.current)}>{title}</li> :
                                <li key={idx} className={s.breadcrumbsItem}><Link to={link}>{title}</Link><Chevron className={s.divider}/></li>
                        })
                    }
                </ol>
            </div>
        </nav>
    )
}