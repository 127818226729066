import React from "react"
import ReactChart from "react-chartjs-2"

export default function Chart({
    width = 300, 
    height = 274, 
    type, data, options,
    style
}) {
    return <ReactChart 
        type={type}
        data={data}
        options={options}
        width={width}
        height={height}
        style={style}
    />
}