import React, { useContext, useEffect, useReducer } from "react";
import { Chart } from "@components";
import { globalVariablesContext } from "@context"
import s from "./DashboardImpressionsChart.module.sass"
import { useTranslation } from "react-i18next";

const minR = 2;
const maxR = 13;
function calcR(val, max) {
    if (val !== 0) {
        const calcedVal = (val/max)*maxR;
        return calcedVal < minR ? minR : calcedVal
    }
    return 1
}

function reducer(store, action) {
    switch (action.type) {
        case "INIT":
            let max = Math.max(...[...action.payload.impressions.views_count, ...action.payload.impressions.actions_count])
            return {
                ...store,
                data: {
                    ...store.data,
                    datasets: [{
                        ...store.data.datasets[0],
                        data: action.payload.impressions.views_count.map((el,index) => ({
                            x: index,
                            y: 1,
                            r: calcR(el, max)
                        }))
                    }, {
                        ...store.data.datasets[1],
                        data: action.payload.impressions.actions_count.map((el,index) => ({
                            x: index,
                            y: 0,
                            r: calcR(el, max)
                        }))
                    }]
                },
                options: {
                    ...store.options,
                    plugins: {
                        ...store.options.plugins,
                        tooltip: {
                            ...store.options.plugins.tooltip,
                            callbacks: {
                                ...store.options.plugins.tooltip.callbacks,
                                label: function(context) {
                                    let arrIndex = context.datasetIndex
                                    let elIndex = context.dataIndex
                                    let label = context.label;
                                    if (arrIndex === 0)
                                        return label + " " + action.payload.impressions.views_count[elIndex]
                                    if (arrIndex === 1)
                                        return label + " " + action.payload.impressions.actions_count[elIndex]
                                }
                            }
                        }
                    },
                    scales: {
                        ...store.options.scales,
                        x: {
                            ...store.options.scales.x,
                            ticks: {
                                ...store.options.scales.x.ticks,
                                callback: function(val) {
                                    return action.payload.labels[val]
                                }
                                
                            }
                        }
                    }
                }
            }
        default:
            return store
    }
}

export default function DashboardImpressionsChart(props) {
    const { t } = useTranslation();
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext)
    const initialChart = {
        type: "bubble",
        data: {
            datasets: [{
                label: t("dashboard.impressions.views")+":",
                data: [ 
                    { x: 0, y: 0, r: 13 },
                    { x: 1, y: 0, r: 6 },
                    { x: 2, y: 0, r: 2 },
                    { x: 3, y: 0, r: 13 },
                    { x: 4, y: 0, r: 6 },
                    { x: 5, y: 0, r: 13 },
                    { x: 6, y: 0, r: 2 },
                ],
                backgroundColor: `#${second}`,
            },{
                label: t("dashboard.impressions.actions")+":",
                data: [ 
                    { x: 0, y: 1, r: 10 },
                    { x: 1, y: 1, r: 13 },
                    { x: 2, y: 1, r: 6 },
                    { x: 3, y: 1, r: 13 },
                    { x: 4, y: 1, r: 2 },
                    { x: 5, y: 1, r: 9 },
                    { x: 6, y: 1, r: 2 },
                ],
                backgroundColor: `#${accent}`,
            }]
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                    grid: {
                        display: false,
                        drawBorder: false,
                        tickMarkLength: 2
                    },
                    ticks: {
                        display: false,
                        padding: 12,
                        font: {
                            size: 16,
                            weight: 600,
                            family: "SF Pro Display"
                        },
                        color: [`#${accent}`, `#${second}`]
                    },
                    position: "right",
                    count: 2
                },
                x: {
                    beginAtZero: true,
                    ticks: {
                        padding: 24,
                        autoSkipPadding: 4,
                        callback: function(val) {
                            return t("dashboard.week", { returnObjects: true })[val]
                        },
                        tickWidth: "20px",
                        color: "#B7B7B7",
                        font: {
                            size: 12,
                            family: "SF Pro Display"
                        },
                    },
                    position: "top",
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label;
                            return label
                        }
                    }
                },
            },
        }
    }
    
    const [ chart, dispatch ] = useReducer(reducer, initialChart)
    
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            dispatch({type: "INIT", payload: {
                ...props,
                labels: [...t("dashboard.week", { returnObjects: true }).slice(props.start_day), ...t("dashboard.week", { returnObjects: true }).slice(0, props.start_day)]
            }})
        }
        return () => { isMounted = false }
    }, [props])

    return <div className={s.item}>
        <Chart {...chart} height="96" style={{
            marginBottom: 13
        }}/>
        <div className="row justify-content-center">
            <div className="col-auto pr-1">
                <span className={s.label}>{t("dashboard.impressions.title")}</span>
            </div>
            <div className="col-auto px-1">
                <span className={s.dot} style={{"--dot-color": `#${second}`}}>{t("dashboard.impressions.views")}</span>
            </div>
            <div className="col-auto pl-1">
                <span className={s.dot} style={{"--dot-color": `#${accent}`}}>{t("dashboard.impressions.actions")}</span>
            </div>
        </div>
    </div>
}