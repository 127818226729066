import React, { createContext, useEffect, useReducer } from "react"
import classNames from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import { Title } from "@ui-kit"
import s from "./Notifications.module.sass"

const notificationsContext = createContext(null);

function reducer(list, action) {
    switch (action.type) {
        case "ADD":
            return [ ...list, action.payload]
        case "DELETE":
            let index = list.findIndex(el => el.id === action.payload);
            if (index !== -1) {
                return [
                    ...list.slice(0, index),
                    ...list.slice(index + 1)
                ]
            }
            return list;
        default:
            return list;
    }
}

const Notifications = ({children}) => {
    const [notifications , dispatch] = useReducer(reducer, []);
    let timeoutFunc = [];
    
    function onAdd(data) {
        const id = '_' + Math.random().toString(36).substr(2, 9)
        dispatch({type: "ADD", payload: {id, ...data}})
    }
    function onDelete(id) {
        dispatch({type: "DELETE", payload: id});
    }
    function notifyList(list = []) {
        if (list.length) list.forEach(notification => onAdd(notification))
    }
    
    return <notificationsContext.Provider  value={{
        notify: onAdd,
        notifyList
    }}>
        {children}
        {
            <AnimatePresence initial={false} exitBeforeEnter>
                <ul className={s.notifications}>
                    {
                        notifications.map((el, index) => {
                            const { id, type, text, title, interval = 5000 } = el;
                            
                            return <NotificationItem
                                key={id}
                                type={type}
                                text={text}
                                title={title}
                                interval={interval}
                                onDelete={() => onDelete(id)}
                                index={index}
                                timeoutFunc={timeoutFunc}
                            />
                        })
                    }
                </ul>
            </AnimatePresence>
        }
    </notificationsContext.Provider>
}

function NotificationItem({interval, title, text, type, onDelete, timeoutFunc, index}) {
    useEffect(() => {
        let isMounted = true;
        if (interval && isMounted) {
            timeoutFunc[index] = setTimeout(onDelete, interval)
        }
        return () => {
            isMounted = false;
            clearTimeout(timeoutFunc[index])
        }
    }, [interval])
    
    return (
        <motion.li
            className={classNames(s.notification, {
                [s.success]: type === "success",
                [s.error]: type === "error",
                [s.warning]: type === "warning",
                [s.notify]: !type
            })}
            layout
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
        >
            <span 
                className={s.close}
                onClick={onDelete}
            ></span>
            <Title type="notify">{title}</Title>
            <p className={s.text}>{text}</p>
            <motion.div 
                initial={{width: "0%"}}
                animate={{width: "100%"}}
                transition={{duration: interval/1000}}
                className={s.progress}
            ></motion.div>
        </motion.li>
    )
}

export {
    notificationsContext
}

export default Notifications;