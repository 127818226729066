import React, { useContext } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import {
    Title,
    Button
} from "@ui-kit"
import { 
    serviceProcessing,
    errorProcessing
} from "@utils"
// style
import s from "./ServicesTelegramRow.module.sass"
import { 
    managerMethodsContext,
    notificationsContext
} from "@context"
// svg
import {ReactComponent as Telegram} from "@svg/telegram-color.svg"

export default function ServicesTelegramRow({hash, onUnbind = () => {}}) {
    const {
        checkTelegram,
        unbindTelegram
    } = useContext(managerMethodsContext);

    const { t } = useTranslation();
    const { notify, notifyList } = useContext(notificationsContext);

    function checkTelegramHandler() {
        checkTelegram()
            .then(res => serviceProcessing(res, notifyList))
            .catch(errors => errorProcessing(errors, notify, "detail_data"))
    }
    
    function onClick() {
        unbindTelegram().then((res) => {
            serviceProcessing(res, notifyList, (data) => {
                const { hash } = data;
                onUnbind(hash)

            })
        }).catch(errors => errorProcessing(errors, notify, "row_data"))
    }
    return (
        <div className={classNames("col-md-12", s.telegram)}>
            <hr className="my-5"/>
            <div className="d-flex">
                <span className={classNames("mr-2", s.icon)}><Telegram/></span>
                <Title>{t("manage.services.telegram.title")}</Title>
            </div>
            
                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        { !hash ? 
                            (<>
                                <p dangerouslySetInnerHTML={{__html: t("manage.services.telegram.subscribed", {link: "https://t.me/MenushkaAppBot"})}}></p>
                                <Button type="button-text" onClick={onClick}>{t("manage.services.telegram.unbind")}</Button>
                            </>) : (
                                <>
                                    <p> 
                                        <span dangerouslySetInnerHTML={{__html: t("manage.services.telegram.unsubscribed", {link: `https://t.me/MenushkaAppBot?start=${hash}`})}}></span>
                                        <Button type="button-text" onClick={(e) => {
                                            e.preventDefault();
                                            checkTelegramHandler();
                                        }}>{t("manage.services.telegram.check_subscribe")}</Button>
                                    </p>
                                    <p>{t("manage.services.telegram.save")}</p>
                                </>
                            )
                        }
                    </div>
                </div>
        </div>
    )
}
