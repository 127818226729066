import React, { useState, useRef, forwardRef, useEffect, useContext } from "react";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useCombinedRefs } from "@utils";
import { globalVariablesContext } from "@context";
// styles
import s from "./Dropdown.module.sass";
// default icon
import defaultIcon from "@svg/default-icon.svg";

const Dropdown = forwardRef(({defaultValue, inputProps, iconPath = null, onSelectHandler, disabled, ...props}, forwardedRef) => {
    const {
        variables: {
            customization: { accent, second }
        }
    } = useContext(globalVariablesContext);
    const inputRef = useRef(null);
    const [ selected, setValue ] = useState(defaultValue);
    const [ showOptions, setOptions] = useState(false);
    const [ ignoreClick, setIgnore ] = useState(false);


    function onSelect(e, value) {
        e.stopPropagation();
        if (selected.value !== value.value) {
            if (typeof onSelectHandler === "function") {
                onSelectHandler(value).then((data) => {
                    if (data !== "cancel") {
                        setValue(value);
                    }
                })
            }
            setOptions(false);
        }
    }

    const combinedRef = useCombinedRefs(useRef, useEffect, inputRef, forwardedRef)

    useEffect(() => {
        let isMounted = true;
        if (selected && isMounted) {
            inputRef.current.value = selected.value;
            inputRef.current.blur();
        }
        return () => isMounted = false;
    }, [selected])

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setValue(defaultValue);
        }
        return () => isMounted = false;
    }, [defaultValue])

    return (
        <motion.div 
            className={classNames(s.dropdown, {
                [s.disabled]: disabled
            })}
            onMouseEnter={() => {
                setOptions(true);
                setIgnore(true);
            }}
            onClick={(e) => {
                e.stopPropagation();
                if (!ignoreClick) {
                    setOptions(!showOptions);
                }
            }}
            onMouseLeave={() => {
                setOptions(false);
                setIgnore(false);
            }}
        >
            <div className={s.dropdownInput}>
                <input {...inputProps} defaultValue={selected?.value || null} ref={combinedRef}/>
            </div>
            <motion.div 
                className={classNames("d-flex align-items-center", s.value, {
                    [s.pointer]: !disabled
                })}
                style={{
                    "--accent-color": `#${accent}`,
                    "--second-color": `#${second}`,
                }}
            >
                <span className={s.icon} style={{
                    WebkitMaskImage: (iconPath !== null && selected?.value) ? `url(${iconPath}/${selected.value}.svg)` : `url(${defaultIcon})`,
                    maskImage: (iconPath !== null && selected?.value) ? `url(${iconPath}/${selected.value}.svg)` : `url(${defaultIcon})`}
                }></span>
                { selected ? selected.label : null }
            </motion.div>
            <SelectList 
                {...props} 
                iconPath={iconPath} 
                selected={selected} 
                onSelect={onSelect} 
                disabled={disabled}
                showOptions={showOptions}/>
        </motion.div>
    )
})

function SelectList({options=[], showOptions, disabled, ...props}) {
    return (
        <AnimatePresence initial={false}>
            {(!disabled && showOptions) ? 
            <motion.div 
                className={s.options}
                animate="open"
                initial="collapsed"
                exit="collapsed"
                variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.2 }}
            >
                {options.map((item) => item.hidden ? null : <ListItem key={item.value} {...item} {...props}/>)}
            </motion.div>
            : null}
        </AnimatePresence>
    )
}

function ListItem({label, value, text = null, selected, onSelect, setOptions, iconPath, ...props}) {
    const statusCancel = 5; // if status_list value equal's to 5 mean cancel an order

    return (
        <motion.div 
            className={classNames("d-flex align-items-center", s.item, {
                [s.selected]: value === selected?.value,
                [s.red]: value === statusCancel
            })}
            onClick={(e) => onSelect(e, {value, label, text})}
            {...props}>
            <span className={s.icon} style={{
                WebkitMaskImage: (iconPath !== null && value) ? `url(${iconPath}/${value}.svg)` : `url(${defaultIcon})`,
                maskImage: (iconPath && value) ? `url(${iconPath}/${value}.svg)` : `url(${defaultIcon})`}}
            ></span><span className={s.text}>{label}</span>
        </motion.div>
    )
}

export default Dropdown;