import React from "react";
import {
    ManagePush,
    PushBlockWrapper
} from "@components"

export default function PushList({list, onDelete, onUpdate}) {
    return (
        list.map(item => {
            return <div className="col-md-auto" key={item.id}>
                    <PushBlockWrapper id={item.id} onDelete={onDelete} onUpdate={onUpdate}>
                        <ManagePush {...item}/>
                    </PushBlockWrapper>
                </div>
        })
    )
}