import React from "react";
import { useTranslation } from "react-i18next"
import { 
    ManageRestaurantWrapper,
    ManageAddressWrapper,
    ManageContactsWrapper,
    ManageScheduleWrapper,
    ManageImagesWrapper,
    ManageServicesWrapper,
    ManageCustomizationWrapper,
    ManageLinksWrapper,
    ManageVideoWrapper,
    ManageCustomTextWrapper,
    ManageBlock,
    ManageStatus,
    ManageImages,
    ManageCustomization,
    ManageVideo,
    ManageSchedule
} from "@components";
// ui-kit
import { Title } from "@ui-kit";
// video

export default function ManagerRestaurant({updates, ...data}) {
    const { t } = useTranslation();
    const { 
        updateStatus, 
        updateInfo, 
        updateAddress, 
        updateContacts, 
        updateSchedule, 
        updateImages, 
        updateServices,
        updateCustom,
        updateLinks,
        updateVideo,
        updateCustomText
    } = updates;
    const {
        status,
        info,
        address,
        contacts,
        schedule,
        services,
        images,
        custom,
        links,
        video,
        close_text
    } = data;
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Title type="section" className="mb-4">{t("manage.base_settings")}</Title>
                </div>
                {
                    status.visible ? <div className="col-md-auto">
                        <ManageStatus 
                            title={`${t("manage.status.title")}: ${status.enable ? t("manage.status.enabled") : t("manage.status.disabled")}`}
                            list={status.list}
                            qr={status.qr_code}
                            animationCount={status.animationCount}
                        />
                    </div> : null
                }
                {
                    info.visible ? <div className="col-md-auto">
                        <ManageRestaurantWrapper id={info.id} updateShort={updateInfo} updateStatus={updateStatus}>
                            <ManageBlock title={t("manage.info.title")} list={info.list} animationCount={info.animationCount}/>
                        </ManageRestaurantWrapper>
                    </div> : null
                }
                {
                    info.id ? (
                        <div className="col-md-auto">
                            <ManageScheduleWrapper id={info.id} updateShort={updateSchedule}>
                                <ManageSchedule 
                                    title={t("manage.schedule.title")} 
                                    list={schedule.list} 
                                    animationCount={schedule.animationCount}
                                />
                            </ManageScheduleWrapper>
                        </div>
                    ) : null
                }
                { 
                    schedule.list.length ? (
                        <div className="col-md-auto">
                            <ManageAddressWrapper id={address.id} updateShort={updateAddress}>
                                <ManageBlock 
                                    title={t("manage.address.title")} 
                                    list={address.list} 
                                    animationCount={address.animationCount}
                                />
                            </ManageAddressWrapper>
                        </div>
                    ) : null
                }
                { 
                    (schedule.list.length && address.id) ? (
                        <div className="col-md-auto">
                            <ManageContactsWrapper id={contacts.id} updateShort={updateContacts} animationCount={contacts.animationCount}>
                                <ManageBlock
                                    title={t("manage.contacts.title")} 
                                    list={contacts.list}
                                    animationCount={contacts.animationCount}
                                />
                            </ManageContactsWrapper>
                        </div>
                    ) : null
                }
                {
                    contacts.id ? (<>
                        <div className="col-md-auto">
                            <ManageServicesWrapper updateShort={updateServices} id={info.id}>
                                <ManageBlock
                                    title={t("manage.services.title")} 
                                    list={services.list}
                                    animationCount={services.animationCount}
                                />
                            </ManageServicesWrapper>
                        </div>
                    </>) : null
                }
                {
                    services.is_created ? (
                        <div className="col-md-auto">
                            <ManageImagesWrapper id={info.id} updateShort={updateImages} updateStatus={updateStatus}>
                                <ManageImages 
                                    title={t("manage.images.title")} 
                                    animationCount={images.animationCount}
                                    {...images}
                                />
                            </ManageImagesWrapper>
                        </div>
                    ) : null
                }
            </div>
            {
                services.is_created ? <div className="row mt-5">
                    <div className="col-12">
                        <Title type="section" className="mb-4">{t("manage.additional_settings")}</Title>
                    </div>
                    
                    <div className="col-md-auto">
                        <ManageVideoWrapper updateShort={updateVideo}>
                            <ManageVideo
                                title={t("manage.video.title")}
                                list={video.list}
                                img={video.img}
                                animationCount={video.animationCount}
                            />
                        </ManageVideoWrapper>
                    </div>
                    <div className="col-md-auto">
                        <ManageCustomizationWrapper updateShort={updateCustom}>
                            <ManageCustomization
                                title={t("manage.customization.title")} 
                                model={custom}
                            />
                        </ManageCustomizationWrapper>
                    </div>
                    <div className="col-md-auto">
                        <ManageLinksWrapper updateShort={updateLinks}>
                            <ManageBlock
                                title={t("manage.multilinks.title")} 
                                list={links.list}
                                animationCount={links.animationCount}
                            />
                        </ManageLinksWrapper>
                    </div>
                    <div className="col-md-auto">
                        <ManageCustomTextWrapper updateShort={updateCustomText}>
                            <ManageBlock
                                title={t("manage.custom_text.title")} 
                                list={close_text.list}
                                animationCount={close_text.animationCount}
                            />
                        </ManageCustomTextWrapper>
                    </div>
                </div> : null
            }
        </>
    )
}