import React, { useState, useEffect, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// components
import { ManagerRestaurant, LoadingSpinner, Breadcrumbs } from "@components"
// utils
import { errorProcessing, serviceProcessing, formatMaskedData, } from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext,
    globalVariablesContext
} from "@context";
import ROUTES from "@routes"

function reducer(data, action) {
    switch (action.type) {
        case "UPDATE_STATUS":
            return {
                ...data,
                status: {
                    ...action.payload,
                    visible: true,
                    animationCount: data.status.animationCount + 1
                }
            }
        case "UPDATE_INFO":
            return {
                ...data,
                info: {
                    ...action.payload,
                    visible: true,
                    animationCount: data.status.animationCount + 1
                }
            }
        case "UPDATE_ADDRESS":
            return {
                ...data,
                address: {
                    ...action.payload,
                    animationCount: data.address.animationCount + 1
                }
            }
        case "UPDATE_CONTACTS":
            return {
                ...data,
                contacts: {
                    ...action.payload,
                    animationCount: data.contacts.animationCount + 1
                }
            }
        case "UPDATE_SCHEDULE":
            return {
                ...data,
                schedule: {
                    ...action.payload,
                    animationCount: data.schedule.animationCount + 1
                }
            }
        case "UPDATE_SERVICES":
            return {
                ...data,
                services: {
                    ...action.payload,
                    animationCount: data.services.animationCount + 1
                }
            }
        case "UPDATE_IMAGES":
            return {
                ...data,
                images: {
                    ...action.payload,
                    animationCount: data.images.animationCount + 1
                }
            }
        case "UPDATE_CUSTOM":
            return {
                ...data,
                custom: {
                    ...action.payload,
                    animationCount: data.custom.animationCount + 1
                }
            }
        case "UPDATE_LINKS":
            return {
                ...data,
                links: {
                    list: action.payload,
                    animationCount: data.links.animationCount + 1
                }
            }
        case "UPDATE_VIDEO":
            return {
                ...data,
                video: {
                    ...action.payload,
                    animationCount: data.video.animationCount + 1
                }
            }
        case "UPDATE_CUSTOM_TEXT":
            return {
                ...data,
                close_text: {
                    ...action.payload,
                    animationCount: data.close_text.animationCount + 1
                }
            }
        case "UPDATE_ALL":
            return {
                ...action.payload,
                status: {
                    ...action.payload.status,
                    visible: true
                },
                info: action.payload.info ? {
                    ...action.payload.info,
                    visible: true
                } : {
                    ...data.info,
                    visible: true
                }
            }
        default:
            return data;
    }
}

const ManagePage = () => {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();

    let initialData = {
        status: {
            enable: false,
            qr_code: null,
            visible: false,
            list: [
                {
                    title: t("manage.status.created"),
                    value: "Еще не создано"
                },
                {
                    title: t("manage.status.id"),
                    value: "Еще не создано"
                }
            ],
            animationCount: 0
        },
        info: {
            id: null,
            visible: false,
            list: [],
            animationCount: 0
        },
        address: {
            id: null,
            list: [],
            animationCount: 0
        },
        contacts: {
            id: null,
            list: [],
            animationCount: 0
        },
        schedule: {
            list: [],
            animationCount: 0
        },
        services: {
            list: [],
            is_created: false,
            animationCount: 0
        },
        images: {
            thumbnail_image_logo: null,
            thumbnail_image_bg: null,
            animationCount: 0
        },
        custom: {
            id: 10,
            accent: "FFA643",
            second: "EB6B1B",
            title: "",
            animationCount: 0
        },
        links: {
            list: [],
            animationCount: 0
        },
        video: {
            img: null,
            list: [],
            animationCount: 0
        },
        close_text: {
            list: [],
            animationCount: 0
        }
    }
    // context
    const {  getRestaurantInfo } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const { 
        variables: { 
            phoneMask,
        }, 
        updateTitle,
        updateStatus,
        updateTag
    } = useContext(globalVariablesContext);

    const transformStatus = (data) => {
        let {enable, qr_code, date_create, id} = data;
        return {
            enable,
            qr_code,
            list: [
                {
                    title: t("manage.status.created"),
                    value: date_create
                },
                {
                    title: t("manage.status.id"),
                    value: id
                }
            ],
            animationCount: store.status.animationCount + 1
        }
    }
    
    const transformInfo = (data) => {
        if (data) {
            let {id, service_percent, tag, title} = data;
            let animationCount = store.info.animationCount;
            if (store.info.id !== null) {
                animationCount = store.info.animationCount + 1
            }
            updateTitle(title);
            
            return {
                id,
                list: [
                    {
                        title: t("manage.info.name"),
                        value: title
                    },
                    {
                        title: t("manage.info.tag"),
                        value: tag
                    },
                    {
                        title: t("manage.info.service_percent"),
                        value: service_percent
                    }
                ],
                animationCount
            }
        }

        return store.info
    }
    
    const transformSchedule = (data) => {
        if (data) {
            return {
                list: [...data],
                animationCount: store.schedule.animationCount + 1
            };
        }
        return store.schedule
    }
    
    const transformAddress = (data) => {
        if (data) {
            let { id, city, address} = data;
            
            return {
                id,
                list: [
                    {
                        title: t("manage.address.city"),
                        value: city
                    },
                    {
                        title: t("manage.address.street"),
                        value: address
                    }
                ],
                animationCount: store.address.animationCount + 1
            }
        }
        return store.address
    }
    
    const transformContacts = (data) => {
        if (data) {
            const { id, phones: {main, delivery, administration, preorder} } = data;
            return {
                id,
                list: [
                    {
                        title: t("manage.contacts.main"),
                        value: formatMaskedData(main, phoneMask),
                    },
                    {
                        title: t("manage.contacts.delivery"),
                        value: formatMaskedData(delivery, phoneMask),
                    },
                    {
                        title: t("manage.contacts.administration"),
                        value: formatMaskedData(administration, phoneMask),
                    },
                    {
                        title: t("manage.contacts.preorder"),
                        value: formatMaskedData(preorder, phoneMask),
                    }
                ],
                animationCount: store.contacts.animationCount + 1
            }
        }
        return store.contacts
    }
    
    const transformServices = (data) => {
        if (data) {
            const { have_delivery, have_self_pickup, have_preorder, accept_in_restaurant, is_created } = data;
            let list = [];
            if (have_delivery || have_self_pickup || have_preorder || accept_in_restaurant) {
                list = [
                    {
                        title: t("manage.services.accept_in_restaurant"),
                        value: accept_in_restaurant
                    },
                    {
                        title: t("manage.services.have_delivery"),
                        value: have_delivery
                    },
                    {
                        title: t("manage.services.have_self_pickup"),
                        value: have_self_pickup
                    },
                    {
                        title: t("manage.services.have_preorder"),
                        value: have_preorder
                    },
                ]
            }
            
            return {
                list,
                is_created,
                animationCount: store.services.animationCount + 1
            };
        }
        return store.services
    }

    const transformCustom = (data) => {
        if (data) {
            return {
                ...data,
                animationCount: store.custom.animationCount + 1
            };
        }
        return store.custom
    }

    const transformImages = (data) => {
        if (data) {
            return {
                ...data,
                animationCount: store.images.animationCount + 1
            };
        }
        return store.images
    }
    
    const transformLinks = (data) => {
        if (data) {
            return {
                list: data.map(({title, value, is_phone}) => {
                    return {
                        title,
                        value: is_phone ? formatMaskedData(value, phoneMask) : value
                    }
                }),
                animationCount: store.links.animationCount + 1
            };
        }
        return store.links
    }
    
    const transformVideo = (data) => {
        if (data) {
            let { video_image, date_update, video_duration } = data;
            return {
                img: video_image,
                list: [
                    {
                        title: t("manage.video.uploaded"),
                        value: date_update
                    },
                    {
                        title: t("manage.video.duration"),
                        value: video_duration
                    }
                ],
                animationCount: store.video.animationCount + 1
            }
        }
        return store.video
    }
    
    const transformCustomText = (data) => {
        if (data) {
            let { close_text } = data;
            return {
                list: [
                    {
                        title: t("manage.custom_text.if_close"),
                        value: close_text ? t("manage.custom_text.enabled") : t("manage.custom_text.disabled")
                    }
                ],
                animationCount: store.close_text.animationCount + 1
            }
        }
        return store.close_text
    }
    
    const updates = {
        updateStatus: (data) => {
            updateStatus(data.enable)
            return dispatch({type:"UPDATE_STATUS", payload: transformStatus(data)})
        },
        updateInfo: (data) => dispatch({type:"UPDATE_INFO", payload: transformInfo(data)}),
        updateSchedule: (data) => dispatch({type:"UPDATE_SCHEDULE", payload: transformSchedule(data)}),
        updateAddress: (data) => dispatch({type:"UPDATE_ADDRESS", payload: transformAddress(data)}),
        updateContacts: (data) => dispatch({type:"UPDATE_CONTACTS", payload: transformContacts(data)}),
        updateServices: (data) => dispatch({type:"UPDATE_SERVICES", payload: transformServices(data)}),
        updateImages: (data) => dispatch({type:"UPDATE_IMAGES", payload: transformImages(data)}),
        updateCustom: (data) => dispatch({type:"UPDATE_CUSTOM", payload: transformCustom(data)}),
        updateLinks: (data) => dispatch({type:"UPDATE_LINKS", payload: data}),
        updateVideo: (data) => dispatch({type:"UPDATE_VIDEO", payload: transformVideo(data)}),
        updateCustomText: (data) => dispatch({type:"UPDATE_CUSTOM_TEXT", payload: transformCustomText(data)}),
    }
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (location.state && location.state.message) {
                window.history.replaceState({}, document.title)
            }
            
            getRestaurantInfo().then(res => {
                serviceProcessing(
                    res, 
                    notifyList, 
                    (data) => {
                        const { status, restaurant, schedule, address, contact, service, images, customization, links, video, close_text } = data;
                        dispatch({type: "UPDATE_ALL", payload: {
                            status: transformStatus(status),
                            info: transformInfo(restaurant),
                            schedule: transformSchedule(schedule),
                            address: transformAddress(address),
                            contacts: transformContacts(contact),
                            custom: transformCustom(customization),
                            services: transformServices(service),
                            images: transformImages(images),
                            links: transformLinks(links),
                            video: transformVideo(video),
                            close_text: transformCustomText({close_text})
                        }})
                        if (restaurant) {
                            updateTag(restaurant.tag)
                        }
                    }
                )
            }).catch(error => errorProcessing(error, notify, "page_data"))
            .finally(() => setLoading(false));
        }

        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, [])
    
    const [ store, dispatch ] = useReducer(reducer, initialData);
    
    const breadcrumbs = [
        {
            title: t("manage.title"),
            link: ROUTES.manage
        },
    ]
    
    return (
        <>
            {loading ? <LoadingSpinner/> : null}
            <Breadcrumbs list={breadcrumbs}/>
            <section>
                <div className="container-fluid">
                    <ManagerRestaurant 
                        {...store}
                        updates={updates}
                    />
                </div>
            </section>
        </>
    )
}

export default ManagePage;