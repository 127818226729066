import history from "../history";
import ROUTES from "@routes";
import i18n from "../i18n";

import managerService from "@services/manager-service";
const { clearTokenAndRedirect } = new managerService();

const errorProcessing = (errors, notify, type) => {
    if (errors.request) {
        if (errors.request.status === 0) {
            return notify({
                title: i18n.t("error.error_title"), 
                text: i18n.t("error.network_error"), 
                type: "error"
            });
        }
        if (errors.request.status === 400) {
            return notify({title: i18n.t("error.error_title"),text: i18n.t("error.error_400"), type: "error"});
        }
        if (errors.request.status === 401) {
            return clearTokenAndRedirect();
        }
        if (errors.request.status === 404) {
            if (type === "page_data") {
                return history.replace(ROUTES.not_found);
            } else {
                return notify({type: "error", title: i18n.t("error.not_found")})
            }
        }
        if (errors.request.status === 500) {
            return notify({title: i18n.t("error.error_title"),text: i18n.t("error.error_500"), type: "error"});
        }
        if (errors.request.response) {
            try {
                let redirect = JSON.parse(errors.request.response).redirect;
                let message = JSON.parse(errors.request.response).errors;
                if (ROUTES[redirect]) {
                    history.push(ROUTES[redirect], { message })
                    return "redirect";
                } else {
                    return history.replace(ROUTES.not_found)
                }
            }
            catch (e) {
                return notify({title: i18n.t("error.undefined_error"), type: "error"});
            }
        } else {
            return notify({title: i18n.t("error.undefined_error"), type: "error", interval: null});
        }
    } else {
        return notify({title: i18n.t("error.undefined_error"), type: "error"});
    }
}

export default errorProcessing;