import React from "react"
import { motion } from "framer-motion"
import { SimpleAppearAnimation } from "@components"
import { useTranslation } from "react-i18next"
import {
    Title,
    Button
} from "@ui-kit"
// styles
import s from "./ManageBlock.module.sass";

export default function ManageVideo(props) {
    const { 
        img, 
        title, 
        list = [], 
        onClick = () => {},
        animationCount
    } = props;
    const { t } = useTranslation();
    return (
        <div className={s.clickable} onClick={onClick}>
            <SimpleAppearAnimation key={animationCount}>
                <motion.div className={s.item}>
                    <div className={s.body}>
                        <Title type="block">{title}</Title>
                        { img || list.length ? 
                            <div className="row">
                                { img ? <div className="col-auto pr-0">
                                    <img src={img} alt={title} className={s.videoImg}/>
                                </div> : null }
                                { list.length ? 
                                    <div className="col">
                                        {
                                            list.map(({title, value}, index) => {
                                                return (
                                                    <div className={s.field} key={index}>
                                                        <span className={s.title}>{`${title}: `}</span>
                                                        <span className={s.value}>{value}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                 : null
                                }
                            </div>
                            : <div className={s.btnWrapper}>
                                <Button type="button" className="empty">{t("buttons.enter_the_data")}</Button>
                            </div>
                        }
                    </div>
                </motion.div>
            </SimpleAppearAnimation>
        </div>
    )
}