import React, { useContext, useEffect, useRef } from "react";
import s from "./Modal.module.sass"
import Modal from 'react-modal';
import classNames from "classnames";
import { motion } from "framer-motion"
// components
import { LoadingSpinner } from "@components"
// ui
import { Title } from "@ui-kit"
import {
    globalVariablesContext
} from "@context"
const customStyles = {
    content : {}
};

export default function Popup(props) {
    const {
        isOpen, 
        closeModal, 
        children, 
        title = null, 
        description = null, 
        className = "", 
        loading = false,
        progress = null,
        closeTimeoutMS = 500
    } = props;

    const ovRef = useRef();
    const coRef = useRef();
    
    // Because of incorrect work of onRequestClose method and noop() I wrote this(↓) useEffect
    // https://www.eventbrite.com/engineering/a-story-of-a-react-re-rendering-bug/
    useEffect(() => {
        let isMounted = true;
        let clicked = false;
        function handleMouseDown(e) {
            if (!className.includes("secure-modal")) {
                if (e.target === e.currentTarget && e.button === 0) {
                    clicked = true;
                }
            }
        }
        function handleMouseUp(e) {
            if (!className.includes("secure-modal")) {
                if (e.target === e.currentTarget && clicked && e.button === 0) {
                    closeModal();
                    clicked = false;
                }
            }
        }
        
        if (isMounted && ovRef.current) {
            ovRef.current.addEventListener("mousedown", handleMouseDown)
            ovRef.current.addEventListener("mouseup", handleMouseUp)
        }
        return () => {
            isMounted = false;
            if (ovRef.current) {
                ovRef.current.removeEventListener("mousedown", handleMouseDown)
                ovRef.current.removeEventListener("mouseup", handleMouseUp)
            }
        }
    }, [ovRef.current, coRef.current])
    
    const { variables: { customization: { second, accent } } } = useContext(globalVariablesContext)
    
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            overlayClassName={classNames(s.overlay, {
                [s.overlayFast]: closeTimeoutMS < 500,
            })}
            className={classNames(s.contentClear, {
                [s.secureModal]: className.includes("secure-modal"),
                "row align-items-center mx-auto": className.includes("notice-modal"),
                [s.fixedContent]: !className.includes("notice-modal"),
            })}
            shouldCloseOnOverlayClick={className.includes("secure-modal") ? false : true}
            appElement={document.getElementById('root')}
            parentSelector={() => document.getElementById('root')}
            closeTimeoutMS={closeTimeoutMS}
            shouldCloseOnEsc={false}
            // preventScroll={true}
            overlayRef={ref => ovRef.current = ref}
            contentRef={ref => coRef.current = ref}
        >
            {
                className.includes("notice-modal") ? 
                <ScaleInContent isOpen={isOpen} duration={closeTimeoutMS}>
                    <button onClick={closeModal} className={classNames(s.close, {
                        [s.topRight]: className.includes("notice-modal")
                    })} type="button">
                        <CloseBtnSvg/>
                    </button>
                    <div className={s.innerContent} style={{
                        "--second-color": `#${second}`,
                        "--accent-color": `#${accent}`
                    }}>
                        { title ? <Title type="modal" className={classNames({
                            "mb-2": description,
                            "mb-4": !description
                        })}>{title}</Title> : null}
                        { description ? <p className={s.description}>{description}</p> : null}
                        {children}
                    </div>
                </ScaleInContent>
                :
                <TranslateContent isOpen={isOpen} duration={closeTimeoutMS}>
                    <button onClick={closeModal} className={classNames(s.close, {
                        [s.topRight]: className.includes("notice-modal")
                    })} type="button">
                        <CloseBtnSvg/>
                    </button>
                    <div className={s.innerContent} style={{
                        "--second-color": `#${second}`,
                        "--accent-color": `#${accent}`
                    }}>
                        { title ? <Title type="modal" className={classNames({
                            "mb-2": description,
                            "mb-4": !description
                        })}>{title}</Title> : null}
                        { description ? <p className={s.description}>{description}</p> : null}
                        {children}
                    </div>
                    { loading ? <LoadingSpinner position="absolute" className="modal" progress={progress}/> : null }
                </TranslateContent>
            }
        </Modal>
    )
}

function CloseBtnSvg() {
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext)

    return (
        <svg width="76" height="76" viewBox="0 0 76 76">
            <defs>
                <linearGradient id="linear-gradient-modal" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={`#${second}`}/>
                    <stop offset="1" stopColor={`#${accent}`}/>
                </linearGradient>
                <filter id="ellipse-modal" x="0" y="0" width="76" height="76" filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="3" result="blur"/>
                    <feFlood floodOpacity="0.102"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(-1187 -6)">
                <g transform="matrix(1, 0, 0, 1, 1187, 6)" filter="url(#ellipse-modal)">
                    <circle data-name="Ellipse 328" cx="29" cy="29" r="29" transform="translate(9 9)" fill="url(#linear-gradient-modal)"/>
                </g>
                <path data-name="Path 54110" d="M14.385,29.893a2.31,2.31,0,0,0,2.335-2.479V17.3h9.542a2.339,2.339,0,1,0,0-4.67H16.719V2.479a2.339,2.339,0,1,0-4.67,0V12.626H2.479a2.339,2.339,0,1,0,0,4.67h9.57V27.414A2.31,2.31,0,0,0,14.385,29.893Z" transform="translate(1225.765 23.83) rotate(45)" fill="#fff"/>
            </g>
        </svg>
    )
}

function TranslateContent({children, isOpen, duration}) {
    return (
        <motion.div 
            className={s.content}
            variants={{
                enter: {
                    x: 0
                },
                exit: {
                    x: "calc(100% + 38px)"
                }
            }}
            initial="exit"
            animate={isOpen ? "enter" : "exit"}
            transition={{
                duration: duration/1000
            }}
            >
            { children }
        </motion.div>
    )
}

function ScaleInContent({children, isOpen, duration}) {
    return (
        <motion.div 
            className={s.contentCenter}
            variants={{
                enter: {
                    opacity: 1,
                    scale: 1
                },
                exit: {
                    opacity: 0,
                    scale: 0.8
                }
            }}
            initial="exit"
            animate={isOpen ? "enter" : "exit"}
            transition={{
                duration: duration/1000
            }}
            >
            { children }
        </motion.div>
    )
}