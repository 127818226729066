import React from "react";
import classNames from "classnames";
import s from "./Description.module.sass";

export default function Description({text, className = ""}) {
    return <p className={classNames(s.description, className, {
        [s.widthLimit]: className.includes("width-limit"),
        [s.widthLimitless]: className.includes("width-limitless"),
        [s.blockAdd]: className.includes("block-add"),
        [s.fzLg]: className.includes("fz-lg"),
        [s.fzSm]: className.includes("fz-sm")
    })}>{text}</p>
}