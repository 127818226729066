import React, { useState, useContext, useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    LanguagesRow
} from "@components";
import {
    Form,
    Modal,
    Button
} from "@ui-kit"
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";

function reducer(form, action) {
    switch (action.type) {
        case "RESET":
            console.log(action);
            let formReset = { ...action.initialForm };
            // select select
            formReset.columns[1].fields[0].columns[0].fields[0].options = action.language_list;
            formReset.columns[1].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // title
            formReset.columns[1].fields[0].columns[0].fields[1].options = action.submenu_list[action.translations.find(el => el.main).language];
            // translations
            formReset.columns[1].fields[0].translations = action.translations;
            // language list
            formReset.columns[1].fields[0].language_list = action.language_list;
            // rules
            formReset.columns[1].fields[0].rules = {
                titleRule: action.rules, 
            };

            return formReset;
        case "INIT_UPDATE":
            let updateForm = {
                ...form,
                type: "restaurant_submenu_update",
                submit: action.submit,
                callback: action.callbackUpdate,
            };
            updateForm.columns[0].fields[0].defaultChecked = action.model.is_published;
            // updateForm.columns[0].fields[1].defaultChecked = action.model.is_delivered;
            
            // select select
            updateForm.columns[1].fields[0].columns[0].fields[0].options = action.language_list;
            updateForm.columns[1].fields[0].columns[0].fields[0].defaultValue = action.language_list.find(el => el.main);
            // title
            updateForm.columns[1].fields[0].columns[0].fields[1].options = action.submenu_list[action.model.translations.find(el => el.main).language];
            // translations
            updateForm.columns[1].fields[0].translations = action.model.translations;
            // language list
            updateForm.columns[1].fields[0].language_list = action.language_list;
            // fields
            updateForm.columns[1].fields[0].columns[0].fields[0].defaultValue = action.language_list[0];
            updateForm.columns[1].fields[0].columns[0].fields[1].defaultValue = action.model.translations[0].title;
            updateForm.columns[1].fields[0].columns[0].fields[2].defaultValue = action.model.translations[0].description;
            // rules
            updateForm.columns[1].fields[0].rules = {
                titleRule: action.rules.title, 
                descriptionRule: action.rules.description, 
            };
            
            return updateForm;
        default:
            return form
    }
}
 
export default function SubmenuBlockWrapper({children, onAdd, id = null, onDelete, onUpdate}) {
    const { t } = useTranslation();
    // detail modal
    const [ isOpen, setOpen ] = useState(false);
    function closeModal() { setOpen(false); }
    // delete modal
    const [ isOpenDelete, setOpenDelete ] = useState(false);
    function closeDeleteModal() { setOpenDelete(false); }
    const {
        createSubmenu,
        getSubmenu,
        getSubmenuRules,
        deleteSubmenu,
        updateSubmenu,
    } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    // page props
    let { id: menuId } = useParams();
    
    function _transformData(data) {
        const { is_published, translations } = data;
        return { 
            is_published, 
            translations 
        };
    }
    
    function onDeleteSubmenu() {
        setLoading(true);
        deleteSubmenu(id).then((res) => {
            serviceProcessing(res, notifyList, () => {
                setLoading(false);
                onDelete(id)
            })
        }).catch(error => errorProcessing(error, notify, "detail_data"));
    }
    
    function callbackCreate(data, reset, resolve, reject) {
        setLoading(true);
        createSubmenu(_transformData(data), menuId).then(res => {
            serviceProcessing(
                res, 
                notifyList, 
                (data) => {
                    resolve(res);
                    onAdd(data)
                    setOpen(false)
                },
                (errors) => reject({messages: errors})
            )
        }).catch(reject)
        .finally(() => setLoading(false));
    }

    function callbackUpdate(data, reset, resolve, reject) {
        setLoading(true);
        updateSubmenu(data, id).then(res => {
            serviceProcessing(
                res, 
                notifyList,
                (data) => {
                    resolve(res);
                    onUpdate(data);
                    setOpen(false);
                },
                (errors) => reject({messages: errors})
            )
            
        }).catch(reject)
        .finally(() => setLoading(false))
    }
    
    const initialForm = {
        type: "restaurant_submenu_add",
        submit: t("buttons.create"),
        callback: callbackCreate,
        columns: [
            {
                size: 12,
                fields: [{
                    type: "checkbox",
                    mode: "switch",
                    defaultChecked: true,
                    name: "is_published",
                    label: t("menu.is_published"),
                    description: t("menu.is_published_description_sub"),
                    wrapperClass: "col-md-6",
                }]
            },
            {
                size: 12,
                fields: [{
                    type: "wrapper",
                    Wrapper: (props) => <LanguagesRow {...props}/>,
                    columns: [{
                        fields: [
                            {
                                type: "select",
                                options: [],
                                isSearchable: true,
                                defaultValue: null,
                                name: "language",
                                label: t("fields.translations.select_language")
                            },
                            {
                                type: "select",
                                defaultValue: "",
                                name: "title",
                                label: t("fields.translations.title"),
                                isCreatable: true
                            },
                            {
                                type: null,
                                defaultValue: "",
                                name: "description",
                                label: t("fields.translations.description")
                            }
                        ],
                    }],
                    translations: [],
                    language_list: [],
                    rules: null,
                }]
            }
        ]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm);
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted && isOpen) {
            if (id !== null) {
                getSubmenu(id)
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            dispatch({type: "INIT_UPDATE", ...data, callbackUpdate, submit: t("buttons.save")});
                        })
                    }).catch(errors => errorProcessing(errors, notify, "detail_data"))
                    .finally(() => setLoading(false));
            } else {
                getSubmenuRules()
                    .then(res => {
                        serviceProcessing(res, notifyList, (data) => {
                            dispatch({type: "RESET", ...data, initialForm});
                        })
                    })
                    .catch(errors => errorProcessing(errors, notify, "detail_data"))
                    .finally(() => setLoading(false));
            }
        }
        return () => {
            isMounted = false;
            setLoading(true)
        }
        
    }, [isOpen, id])
    
    return (
        <>
            {
                React.cloneElement(children, {
                    onClick: () => setOpen(true), // menu detail has no onClick
                    onDelete: () => setOpenDelete(true), // menu add has no onDelete
                    onSettings: () => setOpen(true) // menu add has no onDelete
                })
            }
            <Modal 
                closeModal={closeModal} 
                isOpen={isOpen} 
                title={id === null ? t("menu.form.create_submenu") : t("menu.form.edit_submenu")}
                description={t("menu.form.description_submenu")}
                loading={loading}
            >
                <Form form={form}/>
            </Modal>
            {
                id !== null ? 
                <Modal 
                    closeModal={closeDeleteModal} 
                    isOpen={isOpenDelete}
                    title={t("warning")}
                    description={t("context_menu.delete_confirmation")}
                    className="notice-modal"
                    closeTimeoutMS={200}
                >
                    <div className="row pt-2">
                        <div className="col-6">
                            <Button 
                                type="custom-button" 
                                className="large"
                                onClick={(e) => {
                                    onDeleteSubmenu();
                                    closeDeleteModal(e);
                                }}
                            >{t("fields.general.delete")}</Button>
                        </div>
                        <div className="col-6">
                            <Button 
                                type="button" 
                                className="gray large"
                                onClick={closeDeleteModal}
                            >{t("fields.general.cancel")}</Button>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    )
}