import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    Breadcrumbs,
    ManagerDishes,
    ManagerSearch,
    LoadingSpinner
} from "@components"
// ui-kit
import { Title } from "@ui-kit"
// utils
import {
    errorProcessing,
    serviceProcessing
} from "@utils"
import { 
    managerMethodsContext,
    notificationsContext
 } from "@context";
import ROUTES from "@routes";

export default function DishesPage() {
    const { t } = useTranslation();
    let { id: submenu_id } = useParams();
    const { getAllDishes } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);
    const [ loading, setLoading ] = useState(true);
    // dish states
    const [ context, setContext ] = useState({
        id: null,
        sortable: false,
    });
    const [ dishesId, setDishesId ] = useState({[submenu_id]: []});

    const [data, setData] = useState({});
    
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getAllDishes(submenu_id)
                .then(res => {
                    serviceProcessing(res, notifyList, (data) => {
                        setData(data);
                    })
                })
                .catch(error => errorProcessing(error, notify, "page_data"))
                .finally(() => setLoading(false))
        }

        return () => {
            isMounted = false;
            setLoading(true);
        }
    }, []);
    
    const breadcrumbs = [
        {
            title: t("menu.menu"),
            link: ROUTES.menu
        },
        // {
        //     title: data.menu_title,
        //     link: `${ROUTES.menu}/${data.menu_id}`
        // },
        {
            title: data.submenu_title,
            link: `${ROUTES.submenu}/${data.submenu_id}`
        }
    ]
    
    const [ dishConnection, setConnection ] = useState(null);
    
    const methods = {
        dishConnection,
        setConnection,
        context,
        setContext,
        dishesId,
        setDishesId,
        setPageLoading: setLoading
    }

    return (
        <>
            {loading ? <LoadingSpinner/> : null}
            <Breadcrumbs list={breadcrumbs} />
            <section>
                <div className="container-fluid">
                    {/* <Title className="mb-md-5 mb-4" type="page" back={`${ROUTES.menu}/${data.menu_id}`}>{data.submenu_title}</Title> */}
                    <Title className="mb-md-5 mb-4" type="page" back={`${ROUTES.menu}`}>{data.submenu_title}</Title>
                    <ManagerSearch 
                        isDishPage={true}
                        {...methods}
                    />
                    <ManagerDishes 
                        submenuId={submenu_id - 0}
                        {...data}
                        {...methods}
                    />
                </div>
            </section>
        </>
    )
}