import React, { useEffect, useRef } from "react";
import ReactCropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function Cropper({image, initialRatio=16/9, callback}) {
    const cropperRef = useRef();

    useEffect(() => {
        if (cropperRef.current.cropper) {
            cropperRef.current.cropper.setAspectRatio(initialRatio);
        }
    }, [initialRatio])
    
    return (
        <ReactCropper
            style={{ height: 400, width: "100%" }}
            initialAspectRatio={initialRatio}
            src={image}
            viewMode={1}
            guides={true}
            minCropBoxHeight={0}
            minCropBoxWidth={0}
            background={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC"}
            responsive={true}
            checkOrientation={false}
            crop={callback}
            zoom={false}
            ref={cropperRef}
        />
    )
}