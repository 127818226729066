import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import {
    SimpleAppearAnimation
} from "@components"
// ui-kit
import { Title, Field } from "@ui-kit";
// icons
import placeholderLogo from "@svg/logo-placeholder.svg";
import placeholderBg from "@svg/bg-placeholder.svg";
// style
import s from "./ManageBlock.module.sass";

export default function ManageImages({title, thumbnail_image_logo, thumbnail_image_bg, onFileChange, animationCount}) {
    const [ logo, setLogo ] = useState(placeholderLogo);
    const [ bg, setBg ] = useState(placeholderBg);
    
    useEffect(() => {
        if (thumbnail_image_logo) {
            setLogo(thumbnail_image_logo);
        }
        if (thumbnail_image_bg) {
            setBg(thumbnail_image_bg);
        }
    }, [thumbnail_image_logo, thumbnail_image_bg])
    
    function onLogoChanged(event) {
        if (Boolean(event.target.value)) {
            onFileChange(event, "UPLOAD_LOGO", [200,200], 20);
        }
    }
    
    function onBgChanged(event) {
        if (Boolean(event.target.value)) {
            onFileChange(event, "UPLOAD_BG", [800,600], 20);
        }
    }

    return (
        <SimpleAppearAnimation index={animationCount}>
            <motion.div className={s.item}>
                <div className={s.body}>
                    <Title type="block">{title}</Title>
                    <div className="row">
                        <Field 
                            wrapperClass="col-4 pr-0"
                            type="file"
                            name="image_logo" 
                            file={logo} 
                            onChange={onLogoChanged}
                            accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                            className={classNames(s.image, s.logo, "pr-0", {
                                [s.empty]: !logo
                            })}/>
                        <Field 
                            wrapperClass="col-8"
                            type="file"
                            name="image_bg" 
                            file={bg} 
                            onChange={onBgChanged}
                            accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                            className={classNames(s.image, s.bg, {
                                [s.empty]: !bg
                            })}
                        />
                    </div>
                </div>
            </motion.div>
        </SimpleAppearAnimation>
    )


}