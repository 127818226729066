import React, { useContext } from "react"
import classNames from "classnames"
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// context
import { globalVariablesContext } from "@context";
// styles
import s from "./PageWrapper.module.sass"
// icons
import {ReactComponent as Restaurant} from "@svg/navbar/restaurant.svg";

const restaurantURL = "https://cheflist.org"

export default function PageWrapper({children, menu, title}) {
    const { i18n, t } = useTranslation();
    const { variables: { tag } } = useContext(globalVariablesContext);
    return (<>
        <Helmet htmlAttributes={{ lang : i18n.language }}/>
        <div className={classNames(s.page, {
            [s.toggleMenu]: menu
        })}>
            {children}
            {tag ? <CustomLink link={`${restaurantURL}/${tag}`} title={t("manage.your_establishment")}/> : null}
        </div>
    </>)
}

function CustomLink({link, title}) {
    const { variables: { customization: { second } } } = useContext(globalVariablesContext);
    return <a 
        target="_blank" 
        href={link} 
        rel="noreferrer" 
        title={title}
        className={s.link}
        style={{
            "--second-color": `#${second}`
        }}
    >
        <span className={s.icon}><Restaurant/></span>
        <span className={s.pulse}></span>
    </a>
}