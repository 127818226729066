import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"
// components
import {
    ContextMenu,
    SimpleAppearAnimation,
    LoadingSpinner
} from "@components";
// ui
import { Title } from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";
// routes
import ROUTES from "@routes";

export default function ManageSubmenu(props) {
    const {
        id, title, is_published, dishes_count, 
        onSettings, onDelete, 
        index, animationCount,
        loading
    } = props;
    const { t } = useTranslation();

    // animation on update except sort
    const animationRef = useRef({index, id, animationCount})

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (
                (
                    animationRef.current.index === index &&
                    animationRef.current.id === id
                ) || (
                    animationRef.current.index === index &&
                    animationRef.current.animationCount === animationCount
                )
            ) {
                animationRef.current.index = index;
                animationRef.current.id = id;
                animationRef.current.animationCount = animationCount;
            }
        }
        return () => isMounted = false;
    }, [index, id, animationCount])
    // animation on update except sort
    
    const contextMenu = [
        {
            type: "settings",
            onClick: onSettings,
            label: t("context_menu.settings")

        },
        {
            type: "delete",
            onClick: onDelete,
            label: t("context_menu.delete")
        }
    ];


    return (
        <SimpleAppearAnimation index={animationRef.current.animationCount}>
            <motion.div className={classNames(s.item, s.square, s.clickable)}>
                <ContextMenu list={contextMenu}/>
                {/* <Link to={`${ROUTES.submenu}/${id}`} className={s.body}> */}
                <Link to={`${ROUTES.menu}/${id}`} className={s.body}>
                    <Title>{title}</Title>
                    <div className={s.itemInnerList}>
                        <div className={s.field}>
                            <span className={s.title}>{`${t("menu.status")}: `}</span>
                            <span className={s.value}>{is_published ? t("menu.is_published") : t("menu.not_published")}</span>
                        </div>
                        <div className={s.field}>
                            <span className={s.title}>{`${t("menu.dishes")}: `}</span>
                            <span className={s.value}>{dishes_count}</span>
                        </div>
                    </div>
                </Link>
                { loading ? <LoadingSpinner position="absolute" className="item" /> : null }
            </motion.div>
        </SimpleAppearAnimation>
    )
}