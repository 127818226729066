import React from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
// ui
import { Title } from "@ui-kit"
// style
import s from "./FeedbackModalContent.module.sass"
// icons
import { ReactComponent as Calendar } from "@svg/push-calendar.svg"
import { ReactComponent as Like } from "@svg/like-filled.svg"
import { ReactComponent as Dislike } from "@svg/dislike-filled.svg"

export default function FeedbackModalContent(props) {
    const { date_create, feedback, is_like, answer, is_answered } = props;
    const { t } = useTranslation();
    
    return (
        <div className={classNames(s.body, s.bodyNews)}>
            <div className={s.newsContent}>
                <div className={classNames("d-flex", s.widgets)}>
                    <div className="mr-3 d-flex align-items-center">
                        {is_like ? <Like className={s.icon}/> : <Dislike className={s.icon}/>}
                        {is_answered ? t("feedback.is_answered") : t("feedback.not_answered") }
                    </div>
                    <div className="d-flex align-items-center"><Calendar className={s.icon}/>{date_create}</div>
                </div>
                <Title className="mt-3">{t("feedback.guest_wrote")}</Title>
                <p className={s.description}>{feedback}</p>
                {
                    is_answered ? (<>
                        <Title className="mt-3">{t("feedback.your_answer")}</Title>
                        <p className={s.description}>{answer}</p>
                    </>) : null
                }
            </div>
        </div>
    )

}