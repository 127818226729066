import React, { useContext, useEffect, useRef } from "react"
// nouislider
import noUiSlider from "nouislider"
// context
import { globalVariablesContext } from "@context"
// sass modules
import s from "./NoUiSlider.module.sass"

export default function Slider({
    max, 
    refs, 
    onUpdate = () => {}, 
    onStart = () => {}, 
    onEnd = () => {},
    tooltipFormat,
    defaultValue
}) {
    const { variables: { customization: { accent, second } } } = useContext(globalVariablesContext);
    const sliderRef = useRef();
    
    useEffect(() => {
        let isMounted = true;
        let slider = sliderRef.current;
        if (isMounted && slider && max) {
            noUiSlider.create(slider, {
                start: defaultValue,
                behaviour: 'drag-tap',
                connect: [false, true, false],
                tooltips: {
                    to: value => {
                        return tooltipFormat(value)
                    },
                },
                margin: 10, // min time
                limit: 90, // max time
                range: {
                    min: 0,
                    max: Math.floor(max)
                },
                orientation: "horizontal",
                direction: "ltr",
                cssPrefix: "",
                cssClasses: { 
                    ...s
                },
                step: 1
            });
            refs.current = slider.noUiSlider;
            slider.noUiSlider.on("update", onUpdate)
            slider.noUiSlider.on("start", onStart)
            slider.noUiSlider.on("end", onEnd)
        }
        return () => {
            isMounted = false;
            if (slider) {
                slider.noUiSlider.off("update", onUpdate)
                slider.noUiSlider.off("start", onStart)
                slider.noUiSlider.off("end", onEnd)
                slider.noUiSlider.destroy();
            }
        }
    }, [max])
    
    return (
        <div 
            ref={sliderRef}
            style={{
                "--accent-color": `#${accent}`,
                "--second-color": `#${second}`,
            }}
        ></div>
    )
}