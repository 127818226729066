import React, { useContext, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { motion, AnimatePresence } from "framer-motion"
// styles
import s from "./SideNav.module.sass";
// svg
import logo from "@svg/logo.svg"
// routes
import ROUTES from "@routes"
// icons
import icons from "./icons";
// context
import {
    managerMethodsContext,
    globalVariablesContext
} from "@context";
// menu burger button
import MenuButton from "./MenuButton";
// audio
import audio from "../../../audio/sound.mp3"

function toggleMenu(prev, next, setMenu) {
    if (prev !== next) {
        setMenu(next)
    } else {
        setMenu(null)
    }
}

const variants = {
    open: {
        x: "0%",
        transition: {
            duration: 0.3,
        }
    },
    close: {
        x: "100%",
        transition: {
            duration: 0.3,
        }
    }
}

export default function Nav({lastJsonMessage, menu, setMenu}) {
    const { t } = useTranslation();
    const [ counter, setCounter ] = useState({
        update_log_count: 0,
        new_orders_count: 0,
        new_feedbacks_count: 0,
    })
    const location = useLocation();
    const [ isDesktop, setDesktop ] = useState(true); // window resize detector
    const [ isOpen, setOpen ] = useState(false);
    const { 
        variables: { 
            customization: { 
                second,
                accent
            }
        } 
    } = useContext(globalVariablesContext);
    const audioRef = useRef(); // audio file's ref
    // close menu
    useEffect(() => {
        let isMounted = true;
        if (isMounted && setMenu) {
            setMenu(null)
            setOpen(false);
        }
        return () => isMounted = false;
    }, [location.pathname, setMenu, setOpen])
    // manage submenu
    const menu_admin_submenu = [
        {
            Icon: icons.restaurant,
            title: t("navigation.restaurant"),
            to: ROUTES.manage
        },
        {
            Icon: icons.menu,
            title: t("navigation.menu"),
            to: ROUTES.menu
        },
        // {
        //     Icon: icons.employees,
        //     title: t("navigation.employees"),
        //     to: ROUTES.waiter
        // },
        {
            Icon: icons.news,
            title:  t("navigation.news"),
            to: ROUTES.news
        },
        // {
        //     Icon: icons.achievement,
        //     title: t("navigation.loaylty"),
        //     to: ROUTES.achievements
        // },
        {
            Icon: icons.push,
            title: t("navigation.push"),
            to: ROUTES.push
        },
        {
            Icon: icons.feedback,
            title: t("navigation.feedback"),
            to: ROUTES.feedback
        },
    ]
    // menu list
    const menu_top = [
        {
            Icon: icons.dashboard,
            title: t("navigation.dashboard"),
            to: ROUTES.dashboard
        },
        {
            type: "menu-button"
        },
        {
            Icon: icons.orders,
            title: t("navigation.orders"),
            to: ROUTES.orders
        },
        // {
        //     Icon: icons.cards,
        //     title: t("navigation.cards"),
        //     to: ROUTES.cards
        // },
        // {
        //     Icon: icons.updates,
        //     title: t("navigation.updates"),
        //     to: ROUTES.updates
        // },
        // {
        //     Icon: icons.subscribe,
        //     title: t("navigation.subscribes"),
        //     to: ROUTES.subscribe
        // },
        // {
        //     Icon: icons.market,
        //     title: t("navigation.shop"),
        //     to: ROUTES.market
        // },
        // {
        //     Icon: icons.faq,
        //     title: t("navigation.faq"),
        //     to: ROUTES.faq
        // },
        {
            Icon: icons.settings,
            title: t("navigation.settings"),
            to: ROUTES.settings
        }
    ]
    
    const { clearTokenAndRedirect } = useContext(managerMethodsContext);
    // websocket's message update
    useEffect(() => {
        let isMounted = true;
        let audio = audioRef.current;
        function playAudio() { audio.play().then(() => {
            document.removeEventListener("click", playAudio);
        }).catch(() => { console.warn("Wait for first interact"); }) }

        if (lastJsonMessage?.message && isMounted) {
            setCounter({...lastJsonMessage.message});
            if (
                counter.new_orders_count < lastJsonMessage.message.new_orders_count || 
                counter.new_feedbacks_count < lastJsonMessage.message.new_feedbacks_count
            ) {
                audio.play().then(() => {
                    document.removeEventListener("click", playAudio)
                })
                .catch(() => {
                    document.addEventListener("click", playAudio)
                });
            }
        }
        return () => {
            isMounted = false;
            document.removeEventListener("click", playAudio)
        }
    }, [lastJsonMessage])
    
    function onResize() {
        let width = document.body.clientWidth;
        if (width >= 768) {
            setDesktop(true)
        } else {
            setDesktop(false)
        }
    } 
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            onResize();
            window.addEventListener("resize", onResize);
        }
        return () => {
            isMounted = false;
            window.removeEventListener("resize", onResize);
        }
    }, [])

    return (
        <nav className={s.nav} style={{
            "--accent-color": `#${accent}`,
            "--second-color": `#${second}`,
        }}>
            <div className={classNames("mx-0 row flex-column", s.navContent)} >
                <div className={classNames(s.menuInner, "d-md-none d-flex justify-content-between align-items-center")}>
                    <Link className={s.logo} to={ROUTES.manage}>
                        <img src={logo} alt="CHEFLIST" title="CHEFLIST" className={s.logoImg}/>
                    </Link>
                    <div className={s.menuButton}>
                        <MenuButton 
                            isOpen={isOpen}
                            onClick={() => setOpen(!isOpen)}
                            strokeWidth="4"
                            color="#ffffff"
                            lineProps={{ strokeLinecap: "round" }}
                            transition={{ type: "spring", stiffness: 260, damping: 20 }}
                            width="24"
                            height="24"
                        />
                    </div>
                </div>
                <AnimatePresence initial={isDesktop ? "open" : "close"}>
                    {
                        (isDesktop || isOpen) ?
                        <motion.div 
                            className={s.menu} 
                            variants={variants}
                            initial="close"
                            animate="open"
                            exit="close"
                        >
                            <div className={classNames(s.menuInner, "d-none d-md-block")}>
                                <Link className={s.logo} to={ROUTES.manage}>
                                    <img src={logo} alt="CHEF LIST" title="CHEF LIST" className={s.logoImg}/>
                                </Link>
                            </div>
                            <div className={classNames(s.menuInner, "d-flex flex-column")}>
                                <div className={s.droptdownItem}>
                                    {
                                        <div className={
                                            classNames(s.submenu, "d-flex flex-column justify-content-center", {
                                                "d-md-none": menu !== ROUTES.manage
                                            })
                                        }>
                                            <span className={classNames(s.navItem, "d-none d-md-inline-flex")}>
                                                <span className={s.navTitle}>{t("navigation.manage")}</span>
                                            </span>
                                            {
                                                menu_admin_submenu.map((el) => {
                                                    return <CustomNavLink 
                                                        key={el.to} 
                                                        withTitle={true} 
                                                        {...el} 
                                                        {...counter}
                                                    />
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    menu_top.map((el) => {
                                        if (el.type === "menu-button") {
                                            return <CustomNavLink 
                                                title={t("navigation.manage")} 
                                                Icon={icons.manage} 
                                                onClick={() => toggleMenu(menu, ROUTES.manage, setMenu)}
                                                isOpen={menu === ROUTES.manage}
                                                className="mob"
                                                {...counter}
                                                key={el.type}
                                            />
                                        } else {
                                            return <CustomNavLink 
                                                key={el.to} 
                                                {...el} 
                                                {...counter}
                                            />
                                        }
                                    })
                                }
                            </div>
                            <div className={classNames(s.menuInner, "d-flex flex-column")}>
                                <CustomNavLink Icon={icons.logout} title={t("log_out")} onClick={clearTokenAndRedirect}/>
                            </div>
                        </motion.div>
                        : null
                    }
                </AnimatePresence>
            </div>
            <audio 
                tabIndex="0" 
                preload="auto" 
                src={audio} 
                ref={audioRef} 
                className="d-none" 
            ></audio>
        </nav>
    )
}

function preventTransition({inactive, to, acceptedLinks, onPrevent}) {
    if (inactive) {
        if (!acceptedLinks.includes(to)) {
            onPrevent();
        }
    }
}

function CustomNavLink({
    Icon, 
    title, 
    to, 
    onClick, 
    withTitle = false, 
    isOpen,
    className="",
    update_log_count, new_orders_count, new_feedbacks_count // ...counter
}) {
    const history = useHistory();
    let acceptedForUnsubscribed = [ ROUTES.subscribe, ROUTES.updates, ROUTES.faq, ROUTES.settings ];
    let acceptedForInactive = [ ROUTES.updates, ROUTES.faq, ROUTES.settings, ROUTES.manage ];
    const { 
        variables: { 
            customization: { 
                second 
            },
            subscribe,
            subscribe_message,
            status,
            status_message,
        } 
    } = useContext(globalVariablesContext);

    const inactive = !status ? "status" : ( !subscribe ? "subscribe" : false);
    const acceptedLinks = !status ? acceptedForInactive : ( !subscribe ? acceptedForUnsubscribed : []);
    const message = !status ? status_message : ( !subscribe ? subscribe_message : "");
    const redirect = !status ? ROUTES.manage : (!subscribe ? ROUTES.subscribe : false);
    
    let match = useRouteMatch(to);
    let isSubmenu = useRouteMatch(ROUTES.submenu)
    let homePage = ROUTES.dashboard // route with empty(/) link

    if (to) {
        let active = match;
        if (match && to === homePage) {
            active = match.isExact;
        }
        if (isSubmenu && to === ROUTES.menu) {
            active = true;
        }
        return (<div>
            <Link 
                className={classNames(s.navItem, {
                    [s.active]: active
                })} 
                to={to} 
                title={title}
                style={{
                    color: `#${second}`
                }}
                onClick={(event) => preventTransition({ 
                    inactive, 
                    to, 
                    acceptedLinks, 
                    onPrevent: () => {
                        event.preventDefault();
                        history.push(redirect, { message })
                    },
                })}
            >
                { (to === ROUTES.updates && update_log_count) ? <span className={s.counter}>{update_log_count}</span> : null }
                { (to === ROUTES.orders && new_orders_count) ? <span className={s.counter}>{new_orders_count}</span> : null }
                { (to === ROUTES.feedback && new_feedbacks_count) ? <span className={s.counter}>{new_feedbacks_count}</span> : null }
                <span  className={s.icon}>
                    <Icon className={s.svgIcon}/>
                </span>
                <span className={classNames(s.navTitle, {
                    "d-md-none": !withTitle
                })}>{title}</span>
            </Link>
        </div>)
    } else {
        /* for administaration button only */
        return (
            <span
                className={classNames(s.navItem, {
                    [s.active]: isOpen,
                    "d-none d-md-inline-flex": className.includes("mob")
                })} 
                title={title} 
                onClick={onClick}
            >
                { new_feedbacks_count ? <span className={s.counter}>{new_feedbacks_count}</span> : null }
                <span 
                    className={s.icon} 
                    title={title}
                    style={{
                        color: `#${second}`
                    }}
                >
                    <Icon className={s.svgIcon}/>
                </span>
                <span className={classNames(s.navTitle, {
                    "d-md-none": !withTitle
                })}>{title}</span>
            </span>
        )
    }
}