import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
// components
import { 
    Register,
} from "@components"
// utils
import {
    serviceProcessing,
    errorProcessing
} from "@utils"
// context
import { 
    managerMethodsContext,
    notificationsContext
} from "@context";
// routes
import ROUTES from "@routes";

const RegisterPage = () => {
    const [ data, setData ] = useState({});
    const history = useHistory();
    let { state: prev = ROUTES.manage} = useLocation(); // get prev page from state or redirect to home page

    const { getRegister } = useContext(managerMethodsContext);
    const { notify, notifyList } = useContext(notificationsContext);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getRegister().then(res => {
                serviceProcessing(res, notifyList, (data) => {
                    setData(data)
                })
            }).catch(error => errorProcessing(error, notify, "page_data"))
        }
        return () => { isMounted = false; }
    },[])

    useEffect(() => {
        let token = localStorage.getItem("token") || sessionStorage.getItem("token")
        if (token) {
            history.push(prev);
        }
    }, [history, prev])
    
    return <Register {...data} />
}

export default RegisterPage;