import React, { useEffect, useRef, useState } from "react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next"
import ymaps from 'ymaps';
import {
    LoadingSpinner
} from "@components"

export default function Map({center, location, polygon, onClick, onPolygon}) {
    const { t } = useTranslation();
    const mapLanguage = i18n.language;
    const [ loading, setLoading ] = useState(true);
    
    const mapElRef = useRef();
    const mapRef = useRef();
    
    // eslint-disable-next-line
    useEffect(() => {
        let isMounted = true;
        let timeout;
        let cancelPolygonEditing;

        if (isMounted && mapLanguage && (location || center)) {
            timeout = setTimeout(() => {
                ymaps.load(`https://api-maps.yandex.ru/2.1/?apikey=225f6a9b-7670-4b7d-ae12-2fdd4e7af4e2&lang=${mapLanguage}`).then(ymaps => {
                    if (isMounted) {
                        let myPlacemark;
                        let myPolygon;
                        
                        if (mapRef.current) mapRef.current.destroy();
                    
                        mapRef.current = new ymaps.Map(mapElRef.current, {
                            center: location || center,
                            zoom: 12
                        }, { 
                            searchControlProvider: 'yandex#search',
                            minZoom: 4
                        });
                        
                        if (location) {
                            mapRef.current.geoObjects.add(new ymaps.Placemark(location));
                        }
                        
                        if (polygon) {                            
                            myPolygon = new ymaps.Polygon([[...polygon]], {}, {
                                // Курсор в режиме добавления новых вершин.
                                editorDrawingCursor: "crosshair",
                                // Максимально допустимое количество вершин.
                                editorMaxPoints: 9,
                                // Цвет заливки.
                                fillColor: '#5AC8FA',
                                // Прозрачность заливки
                                fillOpacity: 0.5,
                                // Цвет обводки.
                                strokeColor: '#0780E3',
                                // Ширина обводки.
                                strokeWidth: 3,
                                draggable: true
                            });
                            mapRef.current.geoObjects.add(myPolygon);
                        }
                        
                        if (onClick) {
                            mapRef.current.events.add('click', function (e) {
                                var coords = e.get('coords');
                                // Moving the placemark if it was already created
                                if (myPlacemark) {
                                    myPlacemark.geometry.setCoordinates(coords);
                                }
                                // Otherwise, creating it.
                                else {
                                    myPlacemark = createPlacemark(coords);
                                    mapRef.current.geoObjects.add(myPlacemark);
                                    // Listening for the dragging end event on the placemark.
                                    myPlacemark.events.add('dragend', function () {
                                        getAddress(myPlacemark.geometry.getCoordinates());
                                    });
                                }
                                getAddress(coords);
                                onClick(coords, '', '');
                            });
                        }
                        
                        if (onPolygon) {
                            let stateMonitor = new ymaps.Monitor(myPolygon.editor.state);
                            stateMonitor.add("drawing", function (newValue) {
                                myPolygon.options.set("strokeColor", newValue ? '#FF0000' : '#0000FF');
                            });
                            // myPolygon.editor.startDrawing();
            
                            myPolygon.events.add("geometrychange", function(e) {
                                let coords = myPolygon.geometry.getCoordinates();
                                onPolygon(coords);
                                if (!coords[0].length) {
                                    myPolygon.editor.startDrawing();
                                }
                            });

                            cancelPolygonEditing = (e) => {
                                // keyCode 27 is Escape key
                                // keyCode 13 is Enter key
                                if (e.keyCode === 27 || e.keyCode === 13) {
                                    myPolygon.editor.stopDrawing();
                                }
                            }
                            
                            document.addEventListener("keyup", cancelPolygonEditing);
                            mapRef.current.events.add("click", () => {
                                if (stateMonitor._values && !stateMonitor._values.drawing) {
                                    myPolygon.editor.startDrawing();
                                }
                            });
                        }
                        
                        function createPlacemark(coords) {
                            return new ymaps.Placemark(coords, {
                                iconCaption: t("map.searching")
                            }, {
                                preset: 'islands#violetDotIconWithCaption',
                                draggable: true
                            });
                        }
                        
                        function getAddress(coords) {
                            myPlacemark.properties.set('iconCaption', t("map.searching"));
                            ymaps.geocode(coords).then(function (res) {
                                var firstGeoObject = res.geoObjects.get(0);
            
                                let address = firstGeoObject.getThoroughfare();
                                let building = firstGeoObject.getPremiseNumber();
                                let street = ""

                                if (address ?? building) {
                                    street = address.trim() + (building ? ` ${building}` : "") || "";
                                }
                                
                                onClick(coords, street);
                                
                                myPlacemark.properties
                                    .set({
                                        // Forming a string with the object's data.
                                        iconCaption: [
                                            // The name of the municipality or the higher territorial-administrative formation.
                                            firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                                            // Getting the path to the toponym; if the method returns null, then requesting the name of the building.
                                            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                                        ].filter(Boolean).join(', '),
                                        // Specifying a string with the address of the object as the balloon content.
                                        balloonContent: firstGeoObject.getAddressLine()
                                    });
                            });
                        }
                        
                        if (mapRef.current.geoObjects.getBounds()) {
                            mapRef.current.setBounds(mapRef.current.geoObjects.getBounds(), {checkZoomRange:true}).then(function(){
                                if(mapRef.current.getZoom() > 12) mapRef.current.setZoom(12); // Если значение zoom превышает 12, то устанавливаем 12.
                            });
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    if (isMounted) {
                        setLoading(false)
                    }
                });
            }, 500)
        }

        return () => {
            isMounted = false;
            clearTimeout(timeout);
            if (mapRef.current) mapRef.current.destroy();
        }
    }, [location, mapLanguage, center, polygon]) 
    
    return (
        <div style={{
            position: "relative",
            width: "100%"
        }}>
            { loading ? <LoadingSpinner position="absolute" /> : null }
            <div ref={mapElRef} style={{width: "100%", height: "288px"}}></div> 
        </div>
    )
    
}