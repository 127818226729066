import React, { useState, useRef, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { serviceProcessing, errorProcessing } from "@utils"
import { Form, Modal } from "@ui-kit"
import { managerMethodsContext, notificationsContext } from "@context"

function reducer(state, action) {
    switch (action.type) {
        case "SET_BEFORE_LIMIT":
            const beforeLimit = { ...state }
            beforeLimit.columns[0].fields[1].isValidDate = (currentDate) => {return currentDate.isBefore(action.payload)}
            return beforeLimit
        case "SET_AFTER_LIMIT":
            const afterLimit = { ...state }
            afterLimit.columns[1].fields[1].isValidDate = (currentDate) => {
                return currentDate.isAfter(action.payload) && currentDate.isBefore(moment())
            }
            return afterLimit
        default:
            return state
    }
}

export default function OrdersexcellWrapper({children}) {
    const { getOrdersExcell } = useContext(managerMethodsContext)
    const { notify, notifyList } = useContext(notificationsContext)
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState(false)
    const [ isOpen, setOpen ] = useState(false)
    const formRef = useRef();
    function callback(data) {
        setLoading(true);
        const { date_start, date_end } = data
        getOrdersExcell({ date_start, date_end }).then(res => {
            serviceProcessing(res, notifyList, (data) => {
                const { download_url } = data;
                window.open(download_url, "Download")
                setOpen(false)
            })
        }).catch(error => { errorProcessing(error, notify, "detail_data") })
        .finally(() => {setLoading(false)})
    }

    const initialForm = {
        type: "restaurant_push_add",
        submit: t("buttons.download"),
        callback: callback,
        columns: [{
            size: 6,
            fields: [
                {
                    name: "date_start",
                    type: "text",
                    defaultValue: null,
                    readOnly: true,
                    label: t('orders.excell.date_start')
                }, {
                    name: "date_start_datepicker",
                    type: "date",
                    open: true,
                    hidden: true,
                    className: "static",
                    defaultValue: null,
                    initialViewDate: moment().subtract(1, "months"),
                    isValidDate: (currentDate) => {return currentDate.isBefore(moment())},
                    onChangeHandler: (val) => {
                        formRef.current.setMapValidation(["date_start", val.format("DD.MM.yyyy")])
                        dispatch({ type: "SET_AFTER_LIMIT", payload: val })
                    }
                }
            ]
        },
        {
            size: 6,
            fields: [
                {
                    name: "date_end",
                    type: "text",
                    defaultValue: null,
                    readOnly: true,
                    label: t('orders.excell.date_end')
                }, {
                    name: "date_end_datepicker",
                    type: "date",
                    open: true,
                    hidden: true,
                    className: "static",
                    defaultValue: null,
                    isValidDate: (currentDate) => {return currentDate.isBefore(moment())},
                    onChangeHandler: (val) => {
                        formRef.current.setMapValidation(["date_end", val.format("DD.MM.yyyy")])
                        dispatch({ type: "SET_BEFORE_LIMIT", payload: val })
                    }
                }
            ]
        }]
    }
    
    const [ form, dispatch ] = useReducer(reducer, initialForm)
    
    return <>
        {
            React.cloneElement(children, {
                onClick: () => setOpen(true), // menu detail has no onClick
            })
        }
        <Modal 
            closeModal={() => setOpen(false)} 
            isOpen={isOpen} 
            title={t("orders.excell.title")}
            description={t("orders.excell.description")}
            loading={isLoading}
        >
            <Form form={form} ref={formRef}/>
        </Modal>
    </>
}