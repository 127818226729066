import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// components
import { 
    ContextMenu,
    SimpleAppearAnimation,
    LoadingSpinner
} from "@components";
import { motion } from "framer-motion"
// ui
import { Title } from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";
// svg
import placeholderBg from "@svg/empty-image.svg"
import { ReactComponent as Views } from "@svg/news_views.svg"
import { ReactComponent as Likes } from "@svg/news_likes.svg"

export default function ManageNews(props) {
    const {title, description, date_publication, image, likes_count, views_count, onClick, onDelete, animationCount = 0, loading} = props;
    const { t } = useTranslation();
    const contextMenu = [
        {
            type: "delete",
            onClick: onDelete,
            label: t("context_menu.delete")
        }
    ];
    return (
        <SimpleAppearAnimation index={animationCount}>
            <motion.div className={classNames(s.item, s.itemNews, s.clickable)} onClick={onClick}>
                <ContextMenu list={contextMenu}/>
                <div className={s.newsImage}>
                    { image ? <img alt={title} src={image} className={s.fitImg}/> : <div style={{backgroundImage: `url("${placeholderBg}")`}} className={s.emptyImg}></div> }
                </div>
                <div className={classNames(s.body, s.bodyNews)}>
                    <div className={s.newsContent}>
                        <div className={classNames("row justify-content-between", s.widgets)}>
                            <div className="col-auto row">
                                <div className="col-auto d-flex align-items-center"><Views className={s.newsIcon}/>{views_count}</div>
                                <div className="col-auto">{date_publication}</div>
                            </div>
                            <div className="col-auto d-flex align-items-center"><Likes className={s.newsIcon}/>{likes_count}</div>
                        </div>
                        <Title className={s.smallTitle}>{title}</Title>
                        <p className={s.newsDescription}>{description}</p>
                    </div>
                </div>
                { loading ? <LoadingSpinner position="absolute" className="item"/> : null }
            </motion.div>
        </SimpleAppearAnimation>
    )
}