import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { SimpleAppearAnimation } from "@components"
// ui
import { 
    Button, 
    Title, 
} from "@ui-kit";
// style
import s from "./ManageBlock.module.sass";

export default function ManageSchedule(props) {
    const { t } = useTranslation();
    const {title, list=[], onClick = () => {}, status, animationCount} = props;
    let listLength = 0; 

    return (
        <div className={s.clickable} onClick={onClick}>
            <SimpleAppearAnimation index={animationCount}>
                <motion.div className={classNames(s.item, s.scheduleItem)}>
                    <div className={s.body}>
                        <Title type="block" className={classNames(s.blockTitle, {
                            [s.status]: typeof status === "boolean",
                            [s.activeStatus]: status
                        })}>{title}</Title>
                        {
                            list.length ? (
                            <div className={s.schedule}>
                                {
                                    list.map(({day, status, time_open, time_close, icon}, index) => {
                                        return  <div className={s.day} key={index}>
                                                    <span className={s.title}>{day}</span>
                                                    <div className={s.value}>
                                                        { 
                                                            icon ? <img 
                                                                src={icon} 
                                                                alt={t("manage.schedule.status", { returnObjects: true })[status]} 
                                                                title={t("manage.schedule.status", { returnObjects: true })[status]} 
                                                                className={s.scheduleIcon}
                                                            /> : <>
                                                            <p className={s.time}>{time_open}</p>
                                                            <p className={s.time}>{time_close}</p> </>
                                                        }
                                                    </div>
                                                </div>
                                    })
                                }
                                { listLength > 4 ? <div className={s.chevron}></div> : null }
                            </div>
                            ) : <div className={s.btnWrapper}><Button type="button" className="empty">{t("buttons.enter_the_data")}</Button></div>
                        }
                    </div>
                </motion.div>
            </SimpleAppearAnimation>
        </div>
    )
}

