import React, { forwardRef, useEffect, useRef, useState } from "react";
import classNames from "classnames/";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
// utils
import {
    useCombinedRefs
} from "@utils";
// style
import s from "./Accordion.module.sass";
// svg
import {ReactComponent as Icon} from "@svg/question.svg";

const Accordion = forwardRef(({ i, title, description, onExpand = () => {}, expanded, setExpanded, children, is_published, type }, forwardedRef) => {
  const isOpen = i === expanded;
  const accRef = useRef();
  const combinedRef = useCombinedRefs(useRef, useEffect, forwardedRef, accRef)
  const [ isDesktop, setDesktop ] = useState(true);

    function onResize() {
        let width = document.body.clientWidth;
        if (width >= 768) {
            setDesktop(true)
        } else {
            setDesktop(false)
        }
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            onResize();
            window.addEventListener("resize", onResize);
        }
        return () => {
            isMounted = false;
            window.removeEventListener("resize", onResize);
        }
    }, [])
  
  return (
    <motion.div 
        className={classNames(s.accordion, {
            [s.isClosed]: !isOpen,
            [s.isOpen]: isOpen,
            [s.faq]: type === "faq",
            [s.dishes]: type === "dishes"
        })}
        animate={isOpen ? "open" : "collapsed"}
        variants={ type === "faq" ? {
            open: { translateX: isDesktop ? 40 : 0 },
            collapsed: { translateX: 0 }
        } : null}
        transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        ref={combinedRef}>
        <motion.header
            initial={false}
            className={classNames(s.header, {
                [s.red]: !is_published
            })}
            onClick={(e) => {
                e.stopPropagation();
                setExpanded(isOpen ? false : i);
                if (isOpen) onExpand(e) ;
            }}
        >
            { type === "faq" ? <Icon className={s.icon}/> : null}
            {title}
        </motion.header>
        <AnimatePresence initial={false}>
            {isOpen && (
                type === "faq" ? 
                <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto", marginTop: 20, transition: { duration: 0.2 } },
                        collapsed: { opacity: 0, height: 0, marginTop: 0, transition: { duration: 0.2 } }
                    }}
                    transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
                    dangerouslySetInnerHTML={{__html: description}}
                    className={s.body}
                    onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(isOpen ? false : i);
                        if (isOpen) onExpand(e) ;
                    }}
                ></motion.div> : 
                <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto", margin: 18 },
                        collapsed: { opacity: 0, height: 0, margin: 0 }
                    }}
                    transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
                    className={classNames(s.description, s.body)}
                    >
                        <AnimateSharedLayout>
                            {children}
                        </AnimateSharedLayout>
                </motion.div>
            )}
        </AnimatePresence>
    </motion.div>
  );
});

export default Accordion;